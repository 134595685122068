import React, { useState, useContext } from "react";
import axios from "axios";
import Endpoint from "../../../endpoints/Endpoint";
import { axiosConfig, CALENDAR_DATE_FORMAT, DATE_FORMAT, useEffectOnce } from "../../../utils/Utils";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment";
import MessageType from "../../../infrastructure/MessageType";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import DialogPolja from "./DialogPolja";
import { AppContext } from "../../Store";

export default function Log() {
  const [logPodaci, setLogPodaci] = useState<any>();
  const [datumOd, setDatumOd] = useState();
  const [datumDo, setDatumDo] = useState();
  const [radnikSelected, setRadnikSelected] = useState<any>([]);
  const [akcijaSelected, setAkcijaSelected] = useState<any>([]);
  const [klasaSelected, setKlasaSelected] = useState<any>([]);
  const [logovi, setLogovi] = useState();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [poljaZaPrikaz, setPoljaZaPrikaz] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    const requestLogPodaci = axios.get(Endpoint.LOG + "/podaciZaPretraguLog", axiosConfig(authData.token));
    axios.all([requestLogPodaci]).then(
      axios.spread((responseLogPodaci) => {
        setLogPodaci(responseLogPodaci.data);
      })
    );
  });

  const validateInput = () => {
    if (!datumOd) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_PRETRAGE, Labels.MESSAGES_POLJE_DATUM_OD);
      return false;
    }
    return true;
  };

  const pretraga = () => {
    if (!validateInput()) {
      return;
    }
    if (!klasaSelected) {
      setKlasaSelected(logPodaci.klase);
    }
    if (!radnikSelected) {
      setRadnikSelected(logPodaci.radnici);
    }
    if (!akcijaSelected) {
      setAkcijaSelected(logPodaci.akcije);
    }

    let klaseArray = klasaSelected.map((x: any) => x.id);
    let akcijeArray = akcijaSelected.map((x: any) => x.id);
    let radniciArray = radnikSelected.map((x: any) => x.id);

    axios
      .get(
        Endpoint.LOG + "/pretraga/",
        axiosConfig(authData.token, {
          datumOd: moment(datumOd).format(DATE_FORMAT),
          datumDo: moment(datumDo).format(DATE_FORMAT),
          klaseArray: klaseArray.toString(),
          akcijeArray: akcijeArray.toString(),
          radniciArray: radniciArray.toString(),
        })
      )
      .then((response) => {
        let dataLog: any = [];
        response.data.forEach((log: any) => {
          let object: any = {};
          object["radnik"] = log.radnik;
          object["nazivKlase"] = log.klasa;
          object["akcijaNaziv"] = log.akcija;
          object["vremePristupa"] = log.vremePristupa;
          object["opis"] = log.opis;
          object["akcije"] = log.polja;
          dataLog.push(object);
        });
        setLogovi(dataLog);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeDialog = () => {
    setDisplayDialog(false);
  };

  const onCancel = () => {
    closeDialog();
  };

  const selectedWorkerTemplate = (option: any) => {
    return <span>{option ? option.imePrezime + ", " : Labels.PLACEHOLDER_RADNIK} </span>;
  };

  const selectedClassTemplate = (option: any) => {
    return <span>{option ? option.naziv + ", " : Labels.PLACEHOLDER_NAZIV_KLASE} </span>;
  };

  const selectedActionTemplate = (option: any) => {
    return <span>{option ? option.naziv + ", " : Labels.PLACEHOLDER_AKCIJE} </span>;
  };

  if (!logPodaci) {
    return null;
  }

  const leftContents = () => (
    <React.Fragment>
      <Calendar
        showIcon
        placeholder={Labels.PLACEHOLDER_DATUM_OD}
        dateFormat={CALENDAR_DATE_FORMAT}
        value={datumOd}
        onChange={(e: any) => {
          setDatumOd(e.value);
        }}
      />
      &nbsp;
      <Calendar
        showIcon
        placeholder={Labels.PLACEHOLDER_DATUM_DO}
        dateFormat={CALENDAR_DATE_FORMAT}
        value={datumDo}
        onChange={(e: any) => {
          setDatumDo(e.value);
        }}
      />
      &nbsp;
      <MultiSelect
        filter
        selectedItemTemplate={selectedWorkerTemplate}
        placeholder={Labels.PLACEHOLDER_RADNIK}
        optionLabel="imePrezime"
        dataKey="id"
        value={radnikSelected}
        options={logPodaci.radnici}
        maxSelectedLabels={3}
        selectedItemsLabel={Labels.MULTISELECT_RADNIK}
        onChange={(e: any) => {
          setRadnikSelected(e.value);
        }}
      />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      &nbsp;
      <MultiSelect
        selectedItemTemplate={selectedClassTemplate}
        filter
        placeholder={Labels.PLACEHOLDER_NAZIV_KLASE}
        optionLabel="naziv"
        dataKey="id"
        value={klasaSelected}
        options={logPodaci.klase}
        maxSelectedLabels={3}
        selectedItemsLabel={Labels.MULTISELECT_KLASA}
        onChange={(e: any) => {
          setKlasaSelected(e.value);
        }}
      />
      &nbsp;
      <MultiSelect
        selectedItemTemplate={selectedActionTemplate}
        filter
        placeholder={Labels.PLACEHOLDER_AKCIJE}
        optionLabel="naziv"
        dataKey="id"
        value={akcijaSelected}
        options={logPodaci.akcije}
        maxSelectedLabels={3}
        onChange={(e: any) => {
          setAkcijaSelected(e.value);
        }}
      />
      &nbsp;
      <Button className="p-button-danger" label={Labels.BUTTON_PRETRAGA} icon="pi pi-search" iconPos="left" onClick={pretraga} />
    </React.Fragment>
  );

  return (
    <div className="layout-log">
      <Panel header={Labels.TITLE_PANEL_LOG} className="panel-web-service-call-log-list">
        <Toolbar left={leftContents} right={rightContents} />
        <DataTable
          filterDisplay="row"
          value={logovi}
          paginator={true}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
          rows={20}
          rowsPerPageOptions={[5, 10, 20]}
          selectionMode="single"
          onRowSelect={(e) => {
            setPoljaZaPrikaz(JSON.parse(e.data.akcije));
            setDisplayDialog(true);
          }}
          alwaysShowPaginator={false}
          emptyMessage={Labels.LABEL_NEMA_PODATAKA}
        >
          <Column className="column-align-left" field={"radnik"} header={Labels.COLUMN_HEADER_RADNIK} filterMatchMode="contains" showFilterMenu={false} filter sortable />
          <Column className="column-align-left" field={"nazivKlase"} header={Labels.COLUMN_HEADER_NAZIV_KLASE} filterMatchMode="contains" showFilterMenu={false} filter sortable />
          <Column className="column-align-left" field={"akcijaNaziv"} header={Labels.COLUMN_HEADER_AKCIJA} filterMatchMode="contains" showFilterMenu={false} filter sortable />
          <Column className="column-align-left" field={"vremePristupa"} header={Labels.COLUMN_HEADER_VREME_PRISTUPA} filterMatchMode="contains" showFilterMenu={false} filter sortable />
          <Column className="column-align-left" field={"opis"} header={Labels.COLUMN_HEADER_OPIS} filterMatchMode="contains" showFilterMenu={false} filter sortable />
        </DataTable>
        <Dialog header={Labels.TITLE_DIALOG_POLJA} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }}>
          <DialogPolja polja={poljaZaPrikaz} onCancel={onCancel} />
        </Dialog>
      </Panel>
    </div>
  );
}
