import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useImperativeHandle, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig, isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import CrudEnumStavkaList from "./CrudEnumStavkaList";

interface CrudTipAtributaProps {
  tipAtributaOperation: string;
  tipAtributa?: any;
  onCreateTipAtributa: Function;
  onUpdateTipAtributa: Function;
  onDeleteTipAtributa: Function;
  onCancel: Function;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudTipAtributa(props: CrudTipAtributaProps) {
  const { tipAtributaOperation, tipAtributa, onCreateTipAtributa, onUpdateTipAtributa, onDeleteTipAtributa, dialogRef, setIndex, index } = props;
  const [tipAtributaChange, setTipAtributaChange] = useState<Partial<any>>(tipAtributa);
  const [tipVrednostiAtributa, setTipVrednostiAtributa] = useState();
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    const requestTipVrednostiAtributa = axios.get(Endpoint.TIP_VREDNOSTI_ATRIBUTA + "/search/findByAktivan", axiosConfig(authData.token, { aktivan: true, page: 0, size: 50000000 }));
    axios.all([requestTipVrednostiAtributa]).then(
      axios.spread((responseTipVrednostiAtributa) => {
        setTipVrednostiAtributa(responseTipVrednostiAtributa.data._embedded.tipVrednostiAtributas);
      })
    );
  });

  const validateInput = (message: string) => {
    if (!tipAtributaChange || !tipAtributaChange.naziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    if (!tipAtributaChange || !tipAtributaChange.nazivTipaVrednostiAtributa) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_TIP_VREDNOSTI_ATRIBUTA);
      return false;
    }
    return true;
  };

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
        return;
      }
      onCreateTipAtributa(tipAtributaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
        });
    },
    onUpdate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
        return;
      }
      onUpdateTipAtributa(tipAtributaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
        });
    },
    onDelete: () => {
      onDeleteTipAtributa(tipAtributaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    },
  }));

  return (
    <div className="panel-attribute">
      <TabView
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.TAB_TIP_ATRIBUTA}>
          <div className="col-8 xl:col-8 lg:col-8 md:col-6 sm:col-12">
            <div className="grid align-items-center">
              <div className="col-4 mb-3">{Labels.LABEL_NAZIV}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(tipAtributaOperation)}
                  value={tipAtributaChange && tipAtributaChange.naziv ? tipAtributaChange.naziv : ""}
                  onChange={(e: any) => {
                    setTipAtributaChange({
                      ...tipAtributaChange,
                      naziv: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_TIP_VREDNOSTI_ATRIBUTA}</div>
              <div className="col-8 p-fluid p-0">
                <Dropdown
                  disabled={isFormDisabled(tipAtributaOperation)}
                  dataKey="id"
                  value={tipAtributaChange && tipAtributaChange.nazivTipaVrednostiAtributa ? tipAtributaChange.nazivTipaVrednostiAtributa : ""}
                  options={tipVrednostiAtributa}
                  editable={true}
                  onChange={(e: any) => {
                    setTipAtributaChange({
                      ...tipAtributaChange,
                      nazivTipaVrednostiAtributa: e.value.naziv,
                      tipVrednostiAtributa: Endpoint.TIP_VREDNOSTI_ATRIBUTA + "/" + e.value.id,
                    });
                  }}
                  optionLabel="naziv"
                  filter={true}
                  filterBy="naziv"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_NAPOMENA}</div>
              <div className="col-8 p-fluid p-0">
                <InputTextarea
                  disabled={isFormDisabled(tipAtributaOperation)}
                  value={tipAtributaChange && tipAtributaChange.napomena ? tipAtributaChange.napomena : ""}
                  onChange={(e: any) => {
                    setTipAtributaChange({
                      ...tipAtributaChange,
                      napomena: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_DECIMALE_BROJNE_VREDNOSTI}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(tipAtributaOperation)}
                  value={tipAtributaChange && tipAtributaChange.decimaleBrojneVrednosti ? tipAtributaChange.decimaleBrojneVrednosti : ""}
                  onChange={(e: any) => {
                    setTipAtributaChange({
                      ...tipAtributaChange,
                      decimaleBrojneVrednosti: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_DONJA_GRANICNA_VREDNOST}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  tooltip={Labels.LABEL_MINIMALNA_BROJCANA_VREDNOST}
                  disabled={isFormDisabled(tipAtributaOperation)}
                  value={tipAtributaChange && tipAtributaChange.donjaGranicaBrojneVrednosti !== null ? tipAtributaChange.donjaGranicaBrojneVrednosti : ""}
                  onChange={(e: any) => {
                    setTipAtributaChange({ ...tipAtributaChange, donjaGranicaBrojneVrednosti: e.target.value });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_GORNJA_GRANICNA_VREDNOST}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  tooltip={Labels.LABEL_MAKSIMALNA_BROJCANA_VREDNOST}
                  disabled={isFormDisabled(tipAtributaOperation)}
                  value={tipAtributaChange && tipAtributaChange.gornjaGranicaBrojneVrednosti !== null ? tipAtributaChange.gornjaGranicaBrojneVrednosti : ""}
                  onChange={(e: any) => {
                    setTipAtributaChange({ ...tipAtributaChange, gornjaGranicaBrojneVrednosti: e.target.value });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_SIRINA}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  tooltip={Labels.LABEL_SIRINA_POLJA}
                  disabled={isFormDisabled(tipAtributaOperation)}
                  value={tipAtributaChange && tipAtributaChange.sirina ? tipAtributaChange.sirina : ""}
                  onChange={(e: any) => {
                    setTipAtributaChange({
                      ...tipAtributaChange,
                      sirina: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_VISINA}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  tooltip={Labels.LABEL_VISINA_KOD_TEKSTUALNIH_POLJA}
                  disabled={isFormDisabled(tipAtributaOperation)}
                  value={tipAtributaChange && tipAtributaChange.visina ? tipAtributaChange.visina : ""}
                  onChange={(e: any) => {
                    setTipAtributaChange({
                      ...tipAtributaChange,
                      visina: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_JEDINICA}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(tipAtributaOperation)}
                  value={tipAtributaChange && tipAtributaChange.jedinica ? tipAtributaChange.jedinica : ""}
                  onChange={(e: any) => {
                    setTipAtributaChange({
                      ...tipAtributaChange,
                      jedinica: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.TAB_ENUM_STAVKA} disabled={tipAtributaOperation === EntityOperation.CREATE || (tipAtributa && tipAtributa.nazivTipaVrednostiAtributa !== "Skupovni")}>
          {tipAtributaOperation !== EntityOperation.CREATE && tipAtributa && tipAtributa.nazivTipaVrednostiAtributa === "Skupovni" && (
            <CrudEnumStavkaList tipAtributaOperation={tipAtributaOperation} tipAtributaId={tipAtributaChange.id} />
          )}
        </TabPanel>
      </TabView>
    </div>
  );
}
