import React from "react";
import { NotificationContainer } from "react-notifications";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminRouter from "./administracija/AdminRouter";
import KorisnickiProfil from "./administracija/radnik/KorisnickiProfil";
import Reload from "./administracija/reload/Reload";
import NewIzvestaji from "./NewIzvestaji";
import LoginGet from "./LoginGet";
import NotFound from "./NotFound";
import Pacijent from "./pacijent/Pacijent";
import RegistarRouter from "./RegistarRouter";
import Unos from "./unos/Unos";
import UnosArhiva from "./unos/UnosArhiva";
import Routers from "../utils/Routers";
import PacijentArhiva from "./pacijent/PacijentArhiva";
import Unos2 from "./unos/Unos2";

export default function Main() {
  return (
    <main>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={Routers.REGISTRI} />} />
        <Route path={Routers.REGISTRI} component={RegistarRouter} />
        <Route path={Routers.IZVESTAJI} component={NewIzvestaji} />
        <Route path={`${Routers.PACIJENTI}/:id`} component={Pacijent} />
        <Route path={`${Routers.PACIJENT_ARHIVA}/:id`} component={PacijentArhiva} />
        <Route path={`${Routers.UNOSI}/:number`} component={Unos} />
        <Route path={`${Routers.UNOSI}2/:number`} component={Unos2} />
        <Route path={`${Routers.UNOS_ARHIVA}/:number`} component={UnosArhiva} />
        <Route path={Routers.UNOSI_NOVI} component={Unos} />
        <Route path={Routers.KORISNICKI_PROFIL} component={KorisnickiProfil} />
        <Route path={Routers.RELOAD} component={Reload} />
        <Route path={Routers.LOGIN} component={LoginGet} />
        <AdminRouter />
        <Route component={NotFound} />
      </Switch>
      <NotificationContainer />
    </main>
  );
}
