const api = (window as any).REACT_APP_BACKEND_API_BASE;
const wsUrl = (window as any).REACT_APP_WS_API_BASE;
const url = api ? api : "http://" + window.location.hostname + ":8080/regix";
// const url = "http://warsaw.heliant.rs:8080/regix";

const Endpoint = {
  AUTHENTICATE: url + "/api/oauth",
  WS_URL: wsUrl,
  HIJERARHIJA_ATRIBUTA: url + "/api/hijerarhijeAtributa",
  TIP_HIJERARHIJE_ATRIBUTA: url + "/api/tipoviHijerarhijeAtributa",
  KATEGORIJA_STATISTICKOG_IZVESTAJA: url + "/api/kategorijeStatistickogIzvestaja",
  STATISTICKI_IZVESTAJI: url + "/api/statistickiIzvestaji",
  STATISTICKI_IZVESTAJ: url + "/api/statistickiIzvestaj",
  TIP_PARAMETRA_STATISTICKOG_IZVESTAJA: url + "/api/tipoviParametraStatistickogIzvestaja",
  VEZA_STATISTICKI_IZVESTAJ_I_FORMAT: url + "/api/vezeStatistickihIzvestajaIFormata",
  VEZA_STATISTICKI_IZVESTAJ_I_FORMAT_BRISANJE: url + "/api/formatiStatistickihIzvestaja/delete",
  ZAKAZIVANJE_IZVESTAJA_PARAMETRI: url + "/api/zakazivanjeIzvestajaParametri",
  ZAKAZIVANJE_IZVESTAJA: url + "/api/zakazivaniIzvestaji",
  KONTAKT: url + "/api/kontakti",
  PARAMETAR_PO_RADNIKU: url + "/api/parametriPoRadniku",
  RADNIK_ULOGA: url + "/api/radnikUloge",
  RADNIK_ULOGA_BRISANJE: url + "/api/ulogeRadnika/delete",
  ULOGA_APLIKATIVNO_PRAVO: url + "/api/ulogaAplikativnaPrava",
  ULOGA_APLIKATIVNO_PRAVO_BRISANJE: url + "/api/ulogaAplikativnoPravo/delete",
  ULOGA: url + "/api/uloge",
  LOG: url + "/api/log",
  LOGOVI: url + "/api/logovi",
  LOGIN: url + "/api/login",
  PACIJENT_ARHIVA: url + "/api/pacijentiArhiva",
  PACIJENT: url + "/api/pacijenti",
  PACIJENT_PARAMETRI: url + "/api/pacijentParametri",
  PARAMETAR: url + "/api/parametri",
  PARAMETAR_NAZIV_APLIKACIJE: url + "/api/parametri/nazivAplikacije",
  RADNIK: url + "/api/radnici",
  RADNIK_WS: url + "/api/radnik",
  RADNIK_CREATE: url + "/api/radnik/create",
  RADNIK_USTANOVA: url + "/api/radniciUstanova",
  RADNIK_USTANOVA_BRISANJE: url + "/api/ustanoveRadnika/delete",
  REGISTAR: url + "/api/registri",
  AKCIJA: url + "/api/akcije",
  NAZIV_KLASE_ZA_LOG: url + "/api/nazivKlasaZaLog",
  FORMAT_STATISTICKIH_IZVESTAJA: url + "/api/formatiStatistickihIzvestaja",
  GENERISANI_IZVESTAJI: url + "/api/generisaniIzvestaji",
  PARAMETAR_STATISTICKI_IZVESTAJ: url + "/api/parametriStatistickiIzvestaja",
  RADNIK_STATISTICKI_IZVESTAJ: url + "/api/radnikStatistickiIzvestaji",
  SIFARNIK_TIPOVA_PARAMETRA_STATISTICKOG_IZVESTAJA: url + "/api/tipoviParametaraStatistickogIzvestaja",
  TIP_STATISTICKOG_IZVESTAJA: url + "/api/tipoviStatistickogIzvestaja",
  APLIKATIVNO_PRAVO: url + "/api/aplikativnaPrava",
  KATEGORIJA_HIJERARHIJE_ATRIBUTA: url + "/api/kategorijeHijerarhijeAtributa",
  TIP_VREDNOSTI_ATRIBUTA: url + "/api/tipoviVrednostiAtributa",
  ATRIBUT_U_TIPU_SKUPA: url + "/api/atributiUTipuSkupa",
  ENUM_STAVKA: url + "/api/enumStavke",
  SKUP_VREDNOSTI_ATRIBUTA: url + "/api/skupoviVrednostiAtributa",
  TIP_ATRIBUTA: url + "/api/tipoviAtributa",
  TIP_SKUPA_ATRIBUTA: url + "/api/tipoviSkupaAtributa",
  VREDNOST_ATRIBUTA_ARHIVA: url + "/api/vrednostiAtributaArhiva",
  VREDNOST_ATRIBUTA: url + "/api/vrednostiAtributa",
  USTANOVA: url + "/api/ustanova",
  PROFILE: url + "/profile",
  RELOAD_PARAMETAR: url + "/api/reloadParametri",
  KREIRANJE_PARAMETRA: url + "/api/kreiranjaParametra",
  RADNIK_PROMENA_LOZINKE: url + "/api/radnikPromenaLozinke",
  RELOAD: url + "/api/reload",
  RADNIK_ZA_USTANOVU: url + "/api/radniciZaUstanove",
  SNIMI_UPOZORENJE: url + "/api/snimiUpozorenje",
  OBRISI_UPOZORENJE: url + "/api/obrisiUpozorenje",
  GENERISANI_IZVESTAJ: url + "/api/generisaniIzvestaj",
  DODAJ_IZVESTAJ_U_NIZ: url + "/api/dodajIzvestajUNiz",
  OBRISI_IZVESTAJ: url + "/api/deleteRadnikStatistickiIzvestaj",
  PACIJENT_IMA_UNOSE: url + "/api/pacijentImaUnose",
  GET_POSLEDNJI_UNOS_PACIJENTA: url + "/api/getPoslednjiUnosPacijenta",
  BROJ_UNOSA_PO_PACIJENTU: url + "/api/brojUnosaPoPacijentu",
  GET_UNOSI_TABELA: url + "/api/getUnosiTabela",
  NOVI_UNOS_VREDNOSTI: url + "/api/noviUnosVrednosti",
  OBRISI_UNOS: url + "/api/obrisiUnos",
  GET_PODACI_ZA_PRIKAZ_H_A: url + "/api/getPodaciZaPrikazHA",
  PRIKAZ_UNOSA: url + "/api/prikazUnosa",
  GET_VREDNOST_ATRIBUTA_ARHIVA: url + "/api/getVrednostAtributaArhiva",
  GET_IZVESTAJ_RESPONSE: url + "/api/getIzvestajResponse/kategorijaStatistickogIzvestaja",
  EXECUTE_QUERY: url + "/api/executeQuery",
  GET_UPOZORENJE: url + "/api/getUpozorenje",
  PRETRAGA: url + "/api/pretraga",
  REPOZITORIJUM: url + "/api/repozitorijum",
  KATEGORIJA_STATISTICKOG_IZVESTAJA_TREE: url + "/api/kategorijeStatistickogIzvestajaTree",
  DELETE_RADNIK_STATISTICKI_IZVESTAJ_FUTURE: url + "/api/deleteRadnikStatistickiIzvestajFuture",
  DELETE_CURRENT: url + "/api/deleteCurrent",
  SPONZOR: url + "/api/sponzor/sponzorList/",
};

export default Endpoint;
