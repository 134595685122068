import React from "react";
import { NavLink } from "react-router-dom";
import ReloadRouter from "./ReloadRouter";

export default function Reload() {
  return (
    <div className="flex">
      <div className="nav-bar-left" style={{ minWidth: "150px" }}>
        <div>
          <NavLink className="nav-link" activeClassName="active" to="/reload/jasperi">
            <i className="fas fa-file-invoice" /> Jasperi
          </NavLink>
          <NavLink className="nav-link" activeClassName="active" to="/reload/konekcija">
            <i className="fas fa-wifi" /> Konekcija
          </NavLink>
          <NavLink className="nav-link" activeClassName="active" to="/reload/parametri">
            <i className="fas fa-cog" /> Parametri
          </NavLink>
        </div>
      </div>
      <div className="p-3">
        <ReloadRouter />
      </div>
    </div>
  );
}
