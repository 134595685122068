const Labels = {
  NEW_LINE: "\n",
  TAB: "\t",
  SPACE: " ",
  SLASH: "/",
  DASH: "-",

  // NAZIVI KOLONA
  COLUMN_HEADER_RADNIK: "Radnik",
  COLUMN_HEADER_NAZIV_KLASE: "Naziv klase",
  COLUMN_HEADER_AKCIJA: "Akcija",
  COLUMN_HEADER_VREME_PRISTUPA: "Vreme pristupa",
  COLUMN_HEADER_OPIS: "Opis",
  COLUMN_HEADER_NAZIV_POLJA: "Naziv polja",
  COLUMN_HEADER_STARA_VREDNOST: "Stara vrednost",
  COLUMN_HEADER_NOVA_VREDNOST: "Nova vrednost",
  COLUMN_HEADER_NAZIV: "Naziv",
  COLUMN_HEADER_NUMERICKA_VREDNOST: "Numerička vrednost",
  COLUMN_HEADER_REDOSLED_PRIKAZA: "Redosled prikaza",
  COLUMN_HEADER_JASPER: "Jasper",
  COLUMN_HEADER_PRIKAZUJE_SE: "Prikazuje se",
  COLUMN_HEADER_TIP_STATISTICKOG_IZVESTAJA: "Tip statističkog izveštaja",
  COLUMN_HEADER_KATEGORIJA_STATISTICKOG_IZVESTAJA: "Kategorija statističkog izveštaja",
  COLUMN_HEADER_PRESTANAK_VAZENJA: "Prestanak važenja",
  COLUMN_HEADER_IME: "Ime",
  COLUMN_HEADER_PREZIME: "Prezime",
  COLUMN_HEADER_IME_RODITELJA: "Ime roditelja",
  COLUMN_HEADER_DATUM_RODJENJA: "Datum rođenja",
  COLUMN_HEADER_JMBG: "JMBG",
  COLUMN_HEADER_LBO: "LBO",
  COLUMN_HEADER_USERNAME: "Username",
  COLUMN_HEADER_SIFRA: "Šifra",
  COLUMN_HEADER_USTANOVA: "Ustanova",
  COLUMN_HEADER_ADRESA: "Adresa",
  COLUMN_HEADER_BROJ_KARTONA: "Broj kartona",
  COLUMN_HEADER_KONTAKT_TELEFON: "Kontakt telefon",
  COLUMN_HEADER_EMAIL: "Email",
  COLUMN_HEADER_MOBILNI_TELEFON: "Mobilni telefon",
  COLUMN_HEADER_ZANIMANJE: "Zanimanje",
  COLUMN_HEADER_STRUCNA_SPREMA: "Stručna sprema",
  COLUMN_HEADER_RADNO_MESTO: "Radno mesto",
  COLUMN_HEADER_POL: "Pol",
  COLUMN_HEADER_POL_NA_RODJENJU: "Pol na rođenju",
  COLUMN_HEADER_MEDICINSKA_NAPOMENA: "Medicinska napomena",
  COLUMN_HEADER_ADMINISTRATIVNA_NAPOMENA: "Administartivna napomena",
  COLUMN_HEADER_VREME_KREIRANJA: "Vreme kreiranja",
  COLUMN_HEADER_VREME_POSLEDNJE_IZMENE: "Vreme poslednje izmene",
  COLUMN_HEADER_AKTIVAN: "Aktivan",
  COLUMN_HEADER_VREDNOST: "Vrednost",
  COLUMN_HEADER_NUM_VREDNOST: "Num vrednost",
  COLUMN_HEADER_SIFRA_VEZA: "Šifra veza",
  COLUMN_HEADER_TIP_VREDNOSTI_ATRIBUTA: "Tip vrednosti atributa",
  COLUMN_HEADER_TELEFON: "Telefon",
  COLUMN_HEADER_OZNAKA: "Oznaka",
  COLUMN_HEADER_DATUM_OD: "Datum od",
  COLUMN_HEADER_DATUM_DO: "Datum do",
  COLUMN_HEADER_KRATAK_OPIS: "Kratak opis",
  COLUMN_HEADER_TIKET: "Tiket",
  COLUMN_HEADER_NAPOMENA: "Napomena",
  COLUMN_HEADER_NAZIV_IZMENE: "Naziv izmene",
  COLUMN_HEADER_R_BR_: "R.br",
  COLUMN_HEADER_RB: "Rb",
  COLUMN_HEADER_UNETI_PACIJENTI: "Uneti pacijenti",
  COLUMN_HEADER_OSTALI_UNOSI: "Ostali unosi",
  COLUMN_HEADER_UNETI_ODGOVORI: "Uneti odgovori",
  COLUMN_HEADER_IME_I_PREZIME: "Ime i prezime",
  COLUMN_HEADER_KREIRANJE: "Kreiranje",
  COLUMN_HEADER_IZMENA: "Izmena",
  COLUMN_HEADER_BRISANJE: "Brisanje",
  COLUMN_HEADER_SUMA: "Suma",
  COLUMN_HEADER_POPUNJENI_FORMULARI: "Popunjeni formulari",
  COLUMN_HEADER_REDNI_BROJ: "Redni broj",
  COLUMN_HEADER_AKCIJE: "Akcije",
  COLUMN_HEADER_PRIKAZNI_REDNOSLED: "Prikazni redosled",
  COLUMN_HEADER_TIP_ATRIBUTA_NAZIV: "Tip atributa - naziv",
  COLUMN_HEADER_NADREDJENI_ATRIBUT_U_TIPU_SKUPA_NAZIV: "Nadređeni atribut u tipu skupa - naziv",
  COLUMN_HEADER_VREME_GENERISANJA: "Vreme generisanja",
  COLUMN_HEADER_NAZIV_IZVESTAJA: "Naziv izvestaja",
  COLUMN_HEADER_PARAMETRI: "Parametri",
  COLUMN_HEADER_PERIOD_PONAVLJANJA: "Period ponavljanja",
  COLUMN_HEADER_VREME: "Vreme",
  COLUMN_HEADER_PACIJENT: "Pacijent",
  COLUMN_HEADER_ODGOVORAN_ZA_SADRZAJ: "Odgovoran za sadržaj",
  COLUMN_HEADER_DATUM_KREIRANJA: "Datum kreiranja",
  COLUMN_HEADER_KREIRAO: "Kreirao",
  COLUMN_HEADER_POSLEDNJI_IZMENIO: "Poslednji izmenio",
  COLUMN_HEADER_KREIRANO: "Kreirano",
  COLUMN_HEADER_POSLEDNJA_PROMENA: "Poslednja promena",
  COLUMN_HEADER_NAZIV_ATRIBUTA: "Naziv atributa",
  COLUMN_HEADER_NABROJIVA_VREDNOST: "Nabrojiva vrednost",
  COLUMN_HEADER_LOGICKA_VREDNOST: "Logička vrednost",
  COLUMN_HEADER_TEKST_VREDNOST: "Tekst vrednost",
  COLUMN_HEADER_BROJNA_VREDNOST: "Brojna vrednost",
  COLUMN_HEADER_DATUMSKA_VREDNOST: "Datumska vrednost",
  COLUMN_HEADER_IDENTIFIKACIONI_BROJ: "Identifikacioni broj",
  COLUMN_HEADER_POSLEDNJI_UNOS: "Poslednji unos",
  COLUMN_HEADER_PRVI_UNOS: "Prvi unos",
  COLUMN_HEADER_NOVI_UNOS: "Novi unos",
  COLUMN_HEADER_CODE: "Kod",
  COLUMN_HEADER_NOTE: "Poruka",
  COLUMN_HEADER_LINK: "Website",
  COLUMN_HEADER_LOGO: "Logo",
  COLUMN_DISPLAY_ORDER: "Prikazni redosled",

  //BUTTONS
  BUTTON_SACUVAJ: "Sačuvaj",
  BUTTON_OBRISI: "Obriši",
  BUTTON_ODUSTANI: "Odustani",
  BUTTON_KREIRAJ: "Kreiraj",
  BUTTON_IZMENI: "Izmeni",
  BUTTON_DETALJI: "Detalji",
  BUTTON_OSVEZI: "Osveži",
  BUTTON_PRETRAGA: "Pretraga",
  BUTTON_PONISTI: "Poništi",
  BUTTON_PROMENA_LOZINKE: "Promena lozinke",
  BUTTON_SACUVAJ_NOVU_LOZINKU: "Sačuvaj novu lozinku",
  BUTTON_PRIKAZI: "Prikaži",
  BUTTON_PRIJAVI_SE: "Prijavi se",
  BUTTON_PACIJENT: "Pacijent",
  BUTTON_OTKAZI: "Otkaži",
  BUTTON_GENERISI_IZVESTAJ: "Generiši izveštaj",
  BUTTON_IZMENI_IZVESTAJ: "Izmeni izveštaj",
  BUTTON_NAZAD_NA_REGISTRE: "Nazad na registre",
  BUTTON_NOVI_UNOS: "Novi unos",
  BUTTON_NOVI_PACIJENT: "Novi pacijent",
  BUTTON_CSV: "CSV",
  BUTTON_SLEDECI: "sledeći",
  BUTTON_PRETHODNI: "prethodni",
  BUTTON_SNIMI: "Snimi",
  BUTTON_SNIMI_I_OSTANI: "Snimi i ostani",
  BUTTON_ISTORIJA_VERZIJA: "Istorija verzija",
  BUTTON_POTVRDI: "Potvrdi",
  BUTTON_ZATVORI: "Zatvori",
  BUTTON_UPLOAD_IMAGE: "Učitaj sliku",

  //PLACEHOLDERS
  PLACEHOLDER_DATUM_OD: "Datum od",
  PLACEHOLDER_DATUM_DO: "Datum do",
  PLACEHOLDER_RADNIK: "Radnik",
  PLACEHOLDER_NAZIV_KLASE: "Naziv klase",
  PLACEHOLDER_AKCIJE: "Akcije",
  PLACEHOLDER_REGISTAR: "Registar",
  PLACEHOLDER_USTANOVA: "Ustanova",
  PLACEHOLDER_SIFRA: "Šifra",
  PLACEHOLDER_KORISNICKO_IME: "Korisničko ime",
  PLACEHOLDER_IZABRANO_SVE: "Izabrano sve",
  PLACEHOLDER_VEZA: "Veza",
  PLACEHOLDER_ODABERITE_UNOS: "Odaberite unos",
  PLACEHOLDER_ODABERITE_STAVKU_UNOSA: "Odaberite stavku unosa",
  PLACEHOLDER_OPERATOR: "Operator",
  PLACEHOLDER_ODABERITE_VREDNOST: "Odaberite vrednost",
  PLACEHOLDER_IZABERITE_DAN: "Izaberite dan",
  PLACEHOLDER_IZABERITE_MESEC: "Izaberite mesec",
  PLACEHOLDER_IME: "Ime",
  PLACEHOLDER_PREZIME: "Prezime",
  PLACEHOLDER_JMBG: "JMBG",
  PLACEHOLDER_LBO: "LBO",
  PLACEHOLDER_GODINA_OD: "Godina od",
  PLACEHOLDER_GODINA_DO: "Godina do",
  PLACEHOLDER_POL: "Pol",

  //Labels
  LABEL_DA: "Da",
  LABEL_NE: "Ne",
  LABEL_USER: "Korisnik",
  LABEL_NUMERICKA_VREDNOST: "Numerička vrednost",
  LABEL_IME: "Ime",
  LABEL_PREZIME: "Prezime",
  LABEL_IME_I_PREZIME: "Ime i prezime",
  LABEL_USERNAME: "Username",
  LABEL_EMAIL: "Email",
  LABEL_TELEFON: "Telefon",
  LABEL_NAZIV: "Naziv",
  LABEL_NAZIV_ENGLESKI: "Naziv engleski",
  LABEL_OPIS: "Opis",
  LABEL_VREDNOST: "Vrednost",
  LABEL_PASSWORD: "Password",
  LABEL_PASSWORD_WS: "Password web service",
  LABEL_ADRESA: "Adresa",
  LABEL_REDOSLED_PRIKAZA: "Redosled prikaza",
  LABEL_SIFRA: "Šifra",
  LABEL_DATUM_RODJENJA: "Datum rođenja",
  LABEL_LBO: "Lbo",
  LABEL_JMBG: "Jmbg",
  LABEL_POL: "Pol",
  LABEL_MUSKI: "Muški",
  LABEL_ZENSKI: "Ženski",
  LABEL_THEME: "Theme",
  LABEL_USTANOVA: "Ustanova",
  LABEL_NOVA_LOZINKA: "Nova lozinka",
  LABEL_POTVRDA_LOZINKE: "Potvrda lozinke",
  LABEL_KATEGORIJA_STATISTICKOG_IZVESTAJA: "Kategorija statističkog izveštaja",
  LABEL_KATEGORIJA_IZVESTAJA: "Kategorija izveštaja",
  LABEL_TIP_PARAMETRA_STATISTICKOG_IZVESTAJA: "Tip parametra statističkog izveštaja",
  LABEL_UPIT: "Upit",
  LABEL_ADMINISTRATIVNA_NAPOMENA: "Administrativna napomena",
  LABEL_MEDICINSKA_NAPOMENA: "Medicinska napomena",
  LABEL_RADNO_MESTO: "Radno mesto",
  LABEL_ZANIMANJE: "Zanimanje",
  LABEL_STRUCNA_SPREMA: "Stručna sprema",
  LABEL_PRETRAGA_GODINA_OD: "Pretraga godina od",
  LABEL_PRETRAGA_GODINA_DO: "Pretraga godina do",
  LABEL_PRETRAGA_IME: "Pretraga ime",
  LABEL_PRETRAGA_PREZIME: "Pretraga prezime",
  LABEL_PRETRAGA_JMBG: "Pretraga JMBG",
  LABEL_PRETRAGA_LBO: "Pretraga LBO",
  LABEL_PRETRAGA_POL: "Pretraga pol",
  LABEL_ODABIR_DATUME_PRE_UNOSA: "Odabir datuma pre kreiranja unosa",
  LABEL_BROJ_REZULTATA_U_PRETRAZI: "Broj rezultata u pretrazi",
  LABEL_JASPER: "Jasper",
  LABEL_PRIKAZUJE_SE: "Prikazuje se",
  LABEL_TIP_STATISTICKOG_IZVESTAJA: "Tip statističkog izveštaja",
  LABEL_REQUEST_NAZIV: "Request naziv",
  LABEL_OPCIONI: "Opcioni",
  LABEL_MULTI_SELECT: "Multi Select",
  LABEL_TIP_VREDNOSTI_ATRIBUTA: "Tip vrednosti atributa",
  LABEL_TIP_ATRIBUTA: "Tip atributa",
  LABEL_NADREDJENA_STAVKA: "Nadređena stavka",
  LABEL_FORMULA_ZA_PRIKAZ: "Formula za prikaz",
  LABEL_FORMULA_ZA_RACUNANJE: "Formula za računanje",
  LABEL_DONJA_REFERENTNA_VREDNOST: "Donja referentna vrednost",
  LABEL_GORNJA_REFERENTNA_VREDNOST: "Gornja referentna vrednost",
  LABEL_RELEVANTNI: "Relevantni",
  LABEL_UPIT_INICIJALNA_VREDNOST: "Upit inicijalna vrednost",
  LABEL_INICIJALNA_VREDNOST: "Inicijalna vrednost",
  LABEL_RADIO_BUTTON: "Radio button",
  LABEL_MULTISELECT: "Multiselect",
  LABEL_POSLEDNJA_VREDNOST: "Poslednja vrednost",
  LABEL_ISPISUJE_SE_LABELA: "Ispisuje se labela",
  LABEL_CHECKBOX: "Checkbox",
  LABEL_VERTIKALNI_PRIKAZ: "Vertikalni prikaz",
  LABEL_PRETRAŽUJE_SE: "Pretražuje se",
  LABEL_HTML_INFO: "HTML info",
  LABEL_INFO: "Info",
  LABEL_NAPOMENA: "Napomena",
  LABEL_DECIMALE_BROJNE_VREDNOSTI: "Decimale brojne vrednosti",
  LABEL_DONJA_GRANICNA_VREDNOST: "Donja granična vrednost",
  LABEL_GORNJA_GRANICNA_VREDNOST: "Gornja granična vrednost",
  LABEL_JEDINICA: "Jedinica",
  LABEL_SIRINA: "Širina",
  LABEL_VISINA: "Visina",
  LABEL_SIFRA_VEZE: "Šifra veza",
  LABEL_NUM_VREDNOST: "Num vrednost",
  LABEL_REDOSLED: "Redosled",
  LABEL_MINIMALNA_BROJCANA_VREDNOST: "Minimalna brojčana vrednost",
  LABEL_MAKSIMALNA_BROJCANA_VREDNOST: "Maksimalna brojčana vrednost",
  LABEL_SIRINA_POLJA: "Širina polja",
  LABEL_VISINA_KOD_TEKSTUALNIH_POLJA: "Visina kod tekstualnih polja",
  LABEL_NEMA_PODATAKA: "Nema podataka",
  LABEL_PRVI_UNOS: "Prvi unos",
  LABEL_PODRAZUMEVANI_UNOS: "Podrazumevani unos",
  LABEL_KORISNICKO_IME: "Korisničko ime",
  LABEL_STARA_LOZINKA: "Stara lozinka",
  LABEL_MOZE_DA_SE_INSTANCIRA: "Može da se instancira",
  LABEL_MAX_INSTANCI_PO_KONTAKTU: "Maksimalan broj instanci po kontaktu",
  LABEL_MAX_INSTANCI_PO_PACIJENTU: "Maksimalan broj instanci po pacijentu",
  LABEL_BROJ_KARTONA: "Broj kartona",
  LABEL_MOBILNI_TELEFON: "Mobilni telefon",
  LABEL_IME_RODITELJA: "Ime roditelja",
  LABEL_POL_NA_RODJENJU: "Pol na rođenju",
  LABEL_IME_OBAVEZNO: "Ime *",
  LABEL_PREZIME_OBAVEZNO: "Prezime *",
  LABEL_DATUM_RODJENJA_OBAVEZNO: "Datum rođenja *",
  LABEL_JMBG_OBAVEZNO: "JMBG *",
  LABEL_LBO_OBAVEZNO: "LBO *",
  LABEL_POL_OBAVEZNO: "Pol *",
  LABEL_KONTAKT_TELEFON_OBAVEZNO: "Kontakt telefon *",
  LABEL_LOG_OUT: "Odjava",
  LABEL_ZAKAZIVANJE_ZA_BUDUCNOST: "Zakazivanje za budućnost",
  LABEL_PONAVLJANJE: "Ponavljanje",
  LABEL_DATUM_I_VREME: "Datum i vreme:",
  LABEL_PERIOD_VAZENJA: "Period važenja",
  LABEL_PERIOD: "Period",
  LABEL_DAN_U_NEDELJI: "Dan u nedelji:",
  LABEL_DAN_U_MESCU: "Dan u mesecu:",
  LABEL_VREME: "Vreme",
  LABEL_NE_POSTOJI_NIJEDNA_VREDNOST_STAVKE_U_ARHIVI: "Ne postoji nijedana vrednost stavke u arhivi.",
  LABEL_REGISTRI: "Registri",
  LABEL_DETALJI_O_FORMULARU: "Detalji o formularu ",
  LABEL_IZMENA_FORMULARA: "Izmena formulara ",
  LABEL_DETALJI_O_REGISTRU: "Detalji o registru ",
  LABEL_IZMENA_REGISTRA: "Izmena registra ",
  LABEL_KREIRANJE_REGISTRA: "Kreiranje registra ",
  LABEL_KREIRANJE_FORMULARA: "Kreiranje formulara ",
  LABEL_ODABERITE_POL: "-- Odaberite pol --",
  LABEL_UCITAVANJE: "Učitavanje...",
  LABEL_NOVI_PACIJENT: "Novi pacijent",
  LABEL_DATUM_PREGLEDA: "Datum pregleda",
  LABEL_DATUM_PREGLEDA_OBAVEZNO: "Datum pregleda*",
  LABEL_DODAJ: "Dodaj",
  LABEL_DODAJ_SLIKU: "Dodaj sliku",
  LABEL_PRECNIK: "Prečnik tačke",
  LABEL_DODAJ_KORDINATE: "Dodaj kordinate",
  LABEL_KORDINATA_X: "X kordinata",
  LABEL_KORDINATA_Y: "Y kordinata",
  LABEL_NAPUSTI_STRANICU: "Da li ste sigurni da želite da napustite stranicu?",
  LABEL_NASTAVI_BEZ_RELEVANTNIH_POLJA: "Da li ste sigurni da želite da nastavite bez popunjenih relevantnih polja?",
  LABEL_ORDINIRAJUCI_LEKAR_OBAVEZNO: "Ordinirajući lekar*",
  LABEL_BRISETE_UNOS: "Da li ste sigurni da želite da obrišete unos?",
  LABEL_CODE: "Kod",
  LABEL_DISPLAY_ORDER: "Prikazni redosled",
  LABEL_NOTE: "Poruka",
  LABEL_WEBSITE_URL: "Website",
  LABEL_SLIKA: "Logo",
  LABEL_BRISETE_PACIJENTA: "Da li ste sigurni da želite da obrišete pacijenta?",
  LABEL_PODACI_O_PACIJENTU: "Podaci o pacijentu",
  LABEL_IZABERITE_TIP_PREGLEDA: "Izaberite tip pregleda",
  LABEL_IZVESTAJ: "Izveštaj",
  LABEL_IZVESTAJ_NIJE_IZABRAN: "Izveštaj nije izabran",

  // NASLOVI PORUKA
  TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA: "Greška prilikom kreiranja ",
  TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE: "Greška prilikom izmene ",
  TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA: "Greška prilikom brisanja ",
  TITLE_MESSAGES_USPESNO_KREIRANO: "Uspešno kreirano ",
  TITLE_MESSAGES_USPESNO_IZMENJENO: "Uspešno izmenjeno ",
  TITLE_MESSAGES_USPESNO_OBRISANO: "Uspešno obrisano ",
  TITLE_MESSAGES_USPESNO_PROMENJENA_LOZINKA_ZA: "Uspešno promenjena lozinka za ",
  TITLE_MESSAGES_GRESKA_PRILIKOM_PROMENE_LOZINKE: "Greška prilikom promene lozinke ",
  TITLE_MESSAGES_GRESKA: "GREŠKA!  ",
  TITLE_MESSAGES_USPESNO_OSVEZENI_PARAMETRI: "Uspešno osveženi parametri ",
  TITLE_MESSAGES_GRESKA_PRILIKOM_OSVEZAVANJA_PARAMETARA: "Greška prilikom osvežavanja parametara ",
  TITLE_MESSAGES_ULOGA_POSTOJI: "Uloga sa ovim nazivom već postoji.",
  TITLE_MESSAGES_GRESKA_PRILIKOM_PRETRAGE: "Greska prilikom pretrage",
  TITLE_MESSAGES_USPESNO_SNIMLJEN_KORISNIK: "Uspešno snimljen korisnik",
  TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_KORISNIKA: "Greška prilikom snimanja korisnika",
  TITLE_MESSAGES_USPESNO_PONISTENI_PODACI: "Uspešno poništeni podaci",
  TITLE_MESSAGES_USPESNO_IZMENJENA_LOZINKA: "Uspešno izmenjena lozinka",
  TITLE_MESSAGES_USPESNO_SNIMLJEN_PACIJENT: "Uspešno snimljen pacijent",
  TITLE_MESSAGES_USPESNO_IZMENJEN_PACIJENT: "Uspešno izmenjen pacijent",
  TITLE_MESSAGES_USPESNO_OBRISAN_PACIJENT: "Uspešno obrisan pacijent",
  TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_PACIJENTA: "Greška prilikom snimanja pacijenta",
  TITLE_MESSAGES_NEISPRAVNO_KORISNICKO_IME_LOZINKA: "Neispravno korisničko ime i/ili lozinka.",
  TITLE_MESSAGES_NISTE_UNELI_KORISNICKO_IME: "Niste uneli korisničko ime.",
  TITLE_MESSAGES_NISTE_UNELI_SIFRU: "Niste uneli šifru.",
  TITLE_MESSAGES_GRESKA_PRILIKOM_DOHVATANJA_PODATAKA_O_UNOSU: "Greška prilikom dohvatanja podataka o unosu.",
  TITLE_MESSAGES_MORATE_ODABRATI_BAR_JEDNU_STAVKU_UNOSA: "Morate odabrati bar jednu stavku unosa.",
  TITLE_MESSAGES_GRESKA_PRILIKOM_DOHVATANJA_PODATAKA_O_ARHIVI_UNOSA: "Došlo je do greške prilikom dohvatanja podataka o arhivi unosa.",
  TITLE_MESSAGES_MORATE_ODABRATI_DATUM_PREGLEDA: "Morate odabrati datum pregleda",
  TITLE_MESSAGES_MORATE_ODABRATI_NEKI_OD_UNOSA: "Morate odabrati neki od unosa.",
  TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_UNOSA: "Došlo je do greške prilikom snimanja unosa.",
  TITLE_MESSAGES_NISTE_UNELI_DATUM_PREGLEDA: "Niste uneli datum pregleda.",
  TITLE_MESSAGES_NISTE_UNELI_ORDINIRAJUCEG_LEKARA: "Niste uneli ordinirajućeg lekara koji je odgovoran za kreiranje.",
  TITLE_MESSAGES_FORMULARI: "Formulari",
  TITLE_MESSAGES_NAPUSTATE_STRANU: "Napuštate stranu",
  TITLE_MESSAGES_POPUNITE_DODATNE_PARAMETRE_PRETRAGE: "Popunite dodatne parametre pretrage",

  // PORUKE
  MESSAGES_OBAVEZNA_POLJA: "Popunite obavezna polja.",
  MESSAGES_POLJE_PREZIME: "Morate uneti polje prezime",
  MESSAGES_POLJE_NAZIV: "Morate uneti polje naziv",
  MESSAGES_POLJE_PASSWORD: "Morate uneti polje password",
  MESSAGES_POLJE_IME: "Morate uneti polje ime",
  MESSAGES_POLJE_USERNAME: "Morate uneti polje username",
  MESSAGES_NOVA_LOZINKA: "Morate uneti novu lozinku.",
  MESSAGES_STARA_LOZINKA: "Morate uneti startu lozinku",
  MESSAGES_POTVRDA_NOVE_LOZINKE: "Morate uneti potvrdu nove lozinke.",
  MESSAGES_NUMERICKA_VREDNOST: "Morate uneti numeričku vrednost",
  MESSAGES_KATEGORIJA_STATISTICKOG_IZVESTAJA: "Morate uneti kategoriju statističkog izveštaja",
  MESSAGES_LOZINKE_NISU_ISTE: "Nova lozinka i potvrda lozinke nisu iste.",
  MESSAGES_UPOZORENJE: "Morate uneti upozorenje.",
  MESSAGES_POLJE_VREDNOST: "Popunite polje vrednost",
  MESSAGES_POLJE_JASPER: "Morate uneti polje jasper",
  MESSAGES_POLJE_TIP_STATISTICKOG_IZVESTAJA: "Morate uneti polje tip statističkog izveštaja",
  MESSAGES_POLJE_TIP_PARAMETRA_STATISTICKOG_IZVESTAJA: "Morate uneti polje tip parametra statističkog izveštaja",
  MESSAGES_POLJE_REQUEST_NAZIV: "Morate uneti polje Request naziv",
  MESSAGES_POLJE_REDOSLED_PRIKAZA: "Morate uneti polje redosled prikaza",
  MESSAGES_POLJE_TIP_VREDNOSTI_ATRIBUTA: "Morate uneti polje tip vrednosti atributa",
  MESSAGES_POLJE_NUM_VREDNOST: "Morate uneti polje num vrednost",
  MESSAGES_POLJE_DATUM_OD: "Morate uneti polje datum od",
  MESSAGES_POLJE_DATUM_DO: "Morate uneti polje datum do",
  MESSAGES_POLJE_CODE: "Morate uneti polje kod",
  MESSAGES_POLJE_DISPLAY_ORDER: "Morate uneti polje prikazni redosled.",
  MESSAGES_POLJE_LOGO: "Morate odabrati logo sliku.",
  MESSAGES_NIJE_PRONADJENA_STRANICA: "Nije pronađena stranica",
  MESSAGES_POLJE_REGISTAR: "Morate uneti polje registar",
  MESSAGES_POREDJENJE_DATUMA: "Drugi datum ne sme biti manji od prvog",
  MESSAGES_POLJE_TIP_ATRIBUTA: "Morate odabrati tip atributa",
  MESSAGES_IME_PACIJENTA: "Morate uneti ime pacijenta",
  MESSAGES_PREZIME_PACIJENTA: "Morate uneti prezime pacijenta",
  MESSAGES_DATUM_RODJENJA_PACIJENTA: "Morate uneti datum rođenja pacijenta",
  MESSAGES_JMBG_PACIJENTA: "Morate uneti JMBG pacijenta",
  MESSAGES_LBO_PACIJENTA: "Morate uneti LBO pacijenta",
  MESSAGES_POL_PACIJENTA: "Morate uneti pol pacijenta",
  MESSAGES_KONTAKT_TELEFON_PACIJENTA: "Morate uneti kontakt telefon pacijenta",
  MESSAGES_OBLIK_KONTAKT_TELEFONA: "Kontakt telefon mora imati oblik XXX/XXXXXXYY",
  MESSAGES_OBLIK_MOBILNOG_TELEFONA: "Mobilni telefon mora imati oblik XXX/XXXXXXYY",
  MESSAGES_USPESNO_ZAKAZAN_IZVESTAJ_ZA_BUDUCNOST: "Uspešno zakazan izveštaj za budućnost.",
  MESSAGES_USPESNO_GENERISAN_IZVESTAJ: "Uspešno generisan izveštaj.",
  MESSAGES_GRESKA_PRI_PREKIDU_GENERISANJA: "Došlo je do greške prilikom prekida generisanog izveštaja.",
  MESSAGES_USPESNO_OBRISAN_ZAKAZAN_IZVESTAJ_ZA_BUDUCNOST: "Uspešno obrisan zakazani izveštaj za budućnost.",
  MESSAGES_GRESKA_PRI_BRISANJU_ZAKAZANOG_IZVESTAJA: "Desila se greška prilikom brisanja zakazanog izveštaja za budućnost.",
  MESSAGES_USPENSO_OBRISAN_GENERISANI_IZVESTAJ: "Uspešno obrisan generisani izveštaj.",
  MESSAGES_GRESKA_PRILIKOM_BRISANJA_GENERISANOG_IZVESTAJA: "Došlo je do greške prilikom brisanja generisanog izveštaja.",
  MESSAGES_USPESNO_IZMENJEN_ZAKAZANI_IZVESTAJ_ZA_BUDUCNOST: "Uspešno izmenjen zakazani izveštaj za budućnost.",
  MESSAGES_NIJE_MOGUCE_KREIRATI_UNOS_JER_PREMASUJE_MAKSIMALAN_BROJ_MOGUCIH_KREIRANIH_PO_PACIJENTU: "Nije moguće kreirati unos jer premašuje maksimalan broj mogućih kreiraniih po pacijentu",
  MESSAGES_USPESNO_SNIMLJEN_UNOS: "Uspešno snimljen unos.",
  MESSAGES_PREMASEN_MAX_BROJ_PO_PACIJENTU: "Nije moguće kreirati unos jer premašuje maksimalan broj mogućih kreiranih po pacijentu",
  MESSAGES_PARAMETAR_OBAVEZAN: "Morate uneti obavezne parametre",

  // NASLOVI panela i dialoga
  TITLE_PANEL_CRUD_USTANOVA: "Pregled ustanova",
  TITLE_PANEL_CRUD_ULOGA: "Pregled uloga",
  TITLE_PANEL_CRUD_RADNIK: "Pregled radnika",
  TITLE_PANEL_CRUD_KATEGORIJA_IZVESTAJA: "Pregled kategorija izveštaja",
  TITLE_PANEL_CRUD_TIP_STATISTICKOG_IZVESTAJA: "Pregled tipova statističkog izveštaja",
  TITLE_PANEL_CRUD_TIP_PARAMETRA: "Pregled tipova parametara",
  TITLE_PANEL_CRUD_IZVESTAJ: "Pregled izveštaja",
  TITLE_PANEL_CRUD_SPONZOR: "Pregled sponzora",
  TITLE_PANEL_UPOZORENJE: "Upozorenje",
  TITLE_PANEL_CRUD_PARAMETAR: "Pregled parametara",
  TITLE_PANEL_CRUD_TIP_ATRIBUTA: "Pregled tipova atributa",
  TITLE_PANEL_CRUD_ENUM_STAVKA: "Pregled enum stavki",
  TITLE_PANEL_LOG: "Pregled loga",
  TITLE_PANEL_REGISTARA: "Pregled registara",
  TITLE_PANEL_UNOSA: "Pregled unosa",
  TITLE_PANEL_FORMULARA: "Pregled formulara",
  TITLE_PANEL_FRONTEND: "Frontend",
  TITLE_PANEL_BACKEND: "Backend",
  TITLE_PANEL_PACIJENT_ARHIVA: "Pacijent arhiva",
  TITLE_PANEL_STATISTIKA_UNOSA_PODATAKA: "Statistika unosa podataka",
  TITLE_PANEL_LICNI_PODACI_KORISNIKA: "Lični podaci korisnika",
  TITLE_PANEL_PROMENE_TEKUCEG_UNOSA: "Promene tekućeg unosa",
  TITLE_PANEL_PROMENA_VREDNOSTI: "Promena vrednosti",
  TITLE_DIALOG_KREIRANJE_USTANOVE: "Kreiranje ustanove",
  TITLE_DIALOG_BRISANJE_USTANOVE: "Brisanje ustanove: ",
  TITLE_DIALOG_IZMENA_USTANOVE: "Izmena ustanove: ",
  TITLE_DIALOG_DETALJI_USTANOVE: "Detalji ustanove: ",
  TITLE_DIALOG_KREIRANJE_RADNIKA: "Kreiranje radnika",
  TITLE_DIALOG_BRISANJE_RADNIKA: "Brisanje radnika: ",
  TITLE_DIALOG_DETALJI_RADNIKA: "Detalji radnika: ",
  TITLE_DIALOG_IZMENA_RADNIKA: "Izmena radnika: ",
  TITLE_DIALOG_KREIRANJE_ULOGE: "Kreiranje uloge",
  TITLE_DIALOG_BRISANJE_ULOGE: "Brisanje uloge: ",
  TITLE_DIALOG_IZMENA_ULOGE: "Izmena uloge: ",
  TITLE_DIALOG_DETALJI_ULOGE: "Detalji uloge: ",
  TITLE_DIALOG_KREIRANJE_KATEGORIJE_IZVESTAJA: "Kreiranje kategorije izveštaja",
  TITLE_DIALOG_DETALJI_KATEGORIJE_IZVESTAJA: "Detalji kategorije izveštaja: ",
  TITLE_DIALOG_IZMENA_KATEGORIJE_IZVESTAJA: "Izmena kategorije izveštaja: ",
  TITLE_DIALOG_BRISANJE_KATEGORIJE_IZVESTAJA: "Brisanje kategorije izveštaja: ",
  TITLE_DIALOG_KRAIRANJE_TIPA_STATISTICKOG_IZVESTAJA: "Kreiranje tipa statističkog izveštaja",
  TITLE_DIALOG_DETALJI_TIPA_STATISTICKOG_IZVESTAJA: "Detalji tipa statističkog izveštaja: ",
  TITLE_DIALOG_IZMENA_TIPA_STATISTICKOG_IZVESTAJA: "Izmena tipa statističkog izveštaja: ",
  TITLE_DIALOG_BRISANJE_TIPA_STATISTICKOG_IZVESTAJA: "Brisanje tipa statističkog izveštaja: ",
  TITLE_DIALOG_KREIRANJE_IZVESTAJA: "Kreiranje izveštaja",
  TITLE_DIALOG_DETALJI_IZVESTAJA: "Detalji izveštaja: ",
  TITLE_DIALOG_IZMENA_IZVESTAJA: "Izmena izveštaja: ",
  TITLE_DIALOG_BRISANJE_IZVESTAJA: "Brisanje izveštaja: ",
  TITLE_DIALOG_KREIRANJE_TIPA_PARAMETRA: "Kreiranje tipa parametra",
  TITLE_DIALOG_DETALJI_TIPA_PARAMETRA: "Detalji tipa parametra: ",
  TITLE_DIALOG_IZMENA_TIPA_PARAMETRA: "Izmena tipa parametra: ",
  TITLE_DIALOG_BRISANJE_TIPA_PARAMETRA: "Brisanje tipa parametra: ",
  TITLE_DIALOG_KREIRANJE_STAVKE_UNOSA: "Kreiranje stavke unosa:",
  TITLE_DIALOG_BRISANJE_STAVKE_UNOSA: "Brisanje stavke unosa: ",
  TITLE_DIALOG_DETALJI_STAVKE_UNOSA: "Detalji stavke unosa: ",
  TITLE_DIALOG_IZMENA_STAVKE_UNOSA: "Izmena stavke unosa: ",
  TITLE_DIALOG_KREIRANJE_POLJA_FORMULARA: "Kreiranje polja formulara:",
  TITLE_DIALOG_BRISANJE_POLJA_FORMULARA: "Brisanje polja formulara: ",
  TITLE_DIALOG_DETALJI_POLJA_FORMULARA: "Detalji polja formulara: ",
  TITLE_DIALOG_IZMENA_POLJA_FORMULARA: "Izmena polja formulara: ",
  TITLE_USTANOVE_KOJE_SU_VEZANE_ZA_RADNIKA: "Ustanove koje su vezane za radnika",
  TITLE_USTANOVE_KOJE_NISU_VEZANE_ZA_RADNIKA: "Ustanove koje nisu vezane za radnika",
  TITLE_DIALOG_DETALJI_PARAMETRA: "Detalji parametra: ",
  TITLE_DIALOG_KREIRANJE_PARAMETRA: "Kreiranje parametra",
  TITLE_DIALOG_BRISANJE_PARAMETRA: "Brisanje parametra: ",
  TITLE_DIALOG_IZMENA_PARAMETRA: "Izmena parametra: ",
  TITLE_FORMATI_KOJE_IMA_STATISTICKI_IZVESTAJ: "Formati koje ima statistički izveštaj",
  TITLE_FORMATI_KOJE_NEMA_STATISTICKI_IZVESTAJ: "Formati koje nema statistički izveštaj",
  TITLE_DIALOG_KREIRANJE_PARAMETRA_STATISTICKOG_IZVESTAJA: "Kreiranje parametra statističkog izveštaja",
  TITLE_DIALOG_DETALJI_PARAMETRA_STATISTICKOG_IZVESTAJA: "Detalji parametra statističkog izveštaja: ",
  TITLE_DIALOG_IZMENA_PARAMETRA_STATISTICKOG_IZVESTAJA: "Izmena parametra statističkog izveštaja: ",
  TITLE_DIALOG_BRISANJE_PARAMETRA_STATISTICKOG_IZVESTAJA: "Brisanje parametra statističkog izveštaja: ",
  TITLE_DIALOG_KREIRANJE_TIPA_ATRIBUTA: "Kreiranje tipa atributa",
  TITLE_DIALOG_DETALJI_TIPA_ATRIBUTA: "Detalji tipa atributa: ",
  TITLE_DIALOG_IZMENA_TIPA_ATRIBUTA: "Izmena tipa atributa: ",
  TITLE_DIALOG_BRISANJE_TIPA_ATRIBUTA: "Brisanje tipa atributa: ",
  TITLE_DIALOG_KREIRANJE_ENUM_STAVKE: "Kreiranje enum stavke",
  TITLE_DIALOG_DETALJI_ENUM_STAVKE: "Detalji enum stavke: ",
  TITLE_DIALOG_IZMENA_ENUM_STAVKE: "Izmena enum stavke: ",
  TITLE_DIALOG_BRISANJE_ENUM_STAVKE: "Brisanje enum stavke: ",
  TITLE_DIALOG_BRISANJE_PACIJENTA: "Brisanje pacijenta",
  TITLE_DIALOG_POLJA: "Polja",
  TITLE_DIALOG_PROMENA_LOZINKE: "Promena lozinke ",
  TITILE_DIALOG_KREIRANJE_UNOSA: "Kreiranje unosa",
  TITLE_DIALOG_KREIRANJE_SPONZORA: "Kreiranje sponzora",
  TITLE_DIALOG_BRISANJE_SPONZORA: "Brisanje sponzora: ",
  TITLE_DIALOG_DETALJI_SPONZORA: "Detalji sponzora: ",
  TITLE_DIALOG_IZMENA_SPONZORA: "Izmena sponzora: ",
  TITLE_SPONSORS: "Prijatelji projekta",
  TITLE_DIALOG_KREIRANJE_UNOSA: "Kreiranje unosa",
  TITLE_DIALOG_IZMENI_PACIJENTA: "Izmena pacijenta",

  PAGINATION_SHOWING: "Prikazano",
  PAGINATION_OF: "od",
  PAGINATION_ENTRIES: "unosa",
  PAGINATION_STRANICA: "Stranica",

  // MENU
  MENU_REGISTRI: "Registri",
  MENU_ADMINISTRACIJA: "Administracija",
  MENU_IZVESTAJI: "Izveštaji",

  // MENU ITEM
  MENU_ITEM_ULOGE: "Uloga",
  MENU_ITEM_USTANOVE: "Ustanove",
  MENU_ITEM_RADNICI: "Radnici",
  MENU_ITEM_REGISTRI: "Registri",
  MENU_ITEM_TIPOVI_ATRIBUTA: "Tipovi atributa",
  MENU_ITEM_PARAMETRI: "Parametri",
  MENU_ITEM_PREGLED_LOGA: "Pregled loga",
  MENU_ITEM_UPOZORENJA: "Upozorenja",
  MENU_ITEM_STATISTIKA_UNOSA: "Statistika unosa podataka",
  MENU_ITEM_VERZIJE: "Verzije",
  MENU_ITEM_IZVESTAJI: "Izveštaji",
  MENU_ITEM_KATEGORIJE_IZVESTAJA: "Kategorija izveštaja",
  MENU_ITEM_TIPOVI_IZVESTAJA: "Tipovi izveštaja",
  MENU_ITEM_AZURIRANJE_IZVESTAJA: "Ažuriranje izveštaja",
  MENU_ITEM_TIPOVI_PARAMETARA: "Tipovi parametara",
  MENU_ITEM_SPONZORI: "Sponzori",

  // TABS
  TAB_USTANOVA: "Ustanova",
  TAB_KATEGORIJA_IZVESTAJA: "Kategorija izveštaja",
  TAB_STATISTICKI_IZVESTAJ: "Statistički izveštaj",
  TAB_TIP_IZVESTAJA: "Tip izveštaja",
  TAB_TIP_PARAMETRA: "Tip parametra",
  TAB_ULOGA: "Uloga",
  TAB_ULOGE: "Uloge",
  TAB_PROMENA_LOZINKE: "Promeni lozinku",
  TAB_APLIKATIVNA_PRAVA: "Aplikativna prava",
  TAB_PREGLED_RADNIKA_SA_ULOGOM: "Pregled radnika sa ulogom",
  TAB_RADNIK: "Radnik",
  TAB_USTANOVE_RADNIKA: "Ustanove radnika",
  TAB_PARAMETAR: "Parametar",
  TAB_PACIJENT: "Pacijent",
  TAB_REGISTAR: "Registar",
  TAB_REGISTAR_PRETRAGA: "Registar pretraga",
  TAB_UNOSI: "Unosi",
  TAB_UNOS: "Unos",
  TAB_FORMAT_STATISTICKOG_IZVESTAJA: "Format statističkog izveštaja",
  TAB_PARAMETRI_STATISTICKOG_IZVESTAJA: "Parametri statističkog izveštaja",
  TAB_PARAMETAR_STATISTICKOG_IZVESTAJA: "Parametar statističkog izveštaja",
  TAB_TIP_ATRIBUTA: "Tip atributa",
  TAB_ENUM_STAVKA: "Enum stavka",
  TAB_STAVKE_UNOSA: "Stavke unosa",
  TAB_STAVKA_UNOSA: "Stavka unosa",
  TAB_POLJA_FORMULARA: "Polja formulara",
  TAB_POLJE_FORMULARA: "Polje formulara",
  TAB_PARAMETRI: "Parametri",
  TAB_GENERISANI_IZVESTAJI: "Generisani izveštaji",
  TAB_ZAKAZNI_IZVESTAJI: "Zakazani izveštaji",
  TAB_HTML_INFO: "HTML info",
  TAB_SPONZOR: "Sponzor",

  TAB_FORMULARI: "Formulari",
  TAB_FORMULAR: "Formular",

  //MULTISELECT_PLACEHOLDERS
  MULTISELECT_RADNIK: "Izabrano je {0} radnika",
  MULTISELECT_KLASA: "Izabrano je {0} klase(a)",
  MULTISELECT_USTANOVA: "Izabrano je {0} ustanova",

  //TOOLTIP
  TOOLTIP_BROJ_PACIJENT: "Broj pacijenata za pretražiti",
  TOOLTIP_DODATA_SLIKA: "Dodata slika",

  PAGINATION_SHOWING_TO_OF_ENTRIES: "Prikazano {first} do {last} od {totalRecords} rezultata",
  TABLE_EMPTY_MESSAGE: "Nema podataka",

  LABEL_EXPORT: "Preuzmi",
  LABEL_PDF: "PDF",
  LABEL_XLS: "XLS",
  LABEL_CSV: "CSV",
  LABEL_JSON: "JSON",
  DOWNLOAD_DATA_TABLE: "Preuzmite sadržaj tabele",
};

export default Labels;
