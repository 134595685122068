import React from "react";
import TipAtributaRouter2 from "./TipAtributaRouter2";
import EnumTipovaVrednostiAtributa from "../../utils/EnumTipovaVrednostiAtributa";
export default function Panel2(props: any) {
  if (!props.auts) {
    return null;
  }

  //   const changeTab = (value: any) => props.changeState("defaultTab", value);

  let autsi = <div></div>;
  if (!props.auts.vertikalniPrikaz) {
    if (props.autsiPodredjeni && props.autsiPodredjeni[props.auts.id]) {
      autsi = props.autsiPodredjeni[props.auts.id].map((podredjeni: any, p: any) => {
        if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.PANEL) {
          return (
            <Panel2
              key={podredjeni.id}
              auts={podredjeni}
              autsiPodredjeni={props.panelIPodredjeniObject}
              initValue={props.initValue}
              enumiPoAUTS={props.enumiPoAUTS}
              changeState={props.changeState}
              prikazAUTS={props.prikazAUTS}
              sifraAutsPoTipu={props.sifraAutsPoTipu}
              zaPretragu={false}
              prikazivanjeAutsa={props.prikazivanjeAutsa}
              prikazivanjeVertikalno={props.prikazivanjeVertikalno}
              showHtmlInfo={props.showHtmlInfo}
              hideHtmlInfo={props.hideHtmlInfo}
            />
          );
        }

        let prikaz = props.prikazivanjeAutsa(podredjeni);
        if (prikaz) {
          let className = "mb-3";
          className += podredjeni.ispisujeSeLabela ? " mt-2" : " mt-4";

          if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.IZBORNI) {
            className = "mt-2";
          } else if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.BROJNI) {
            className += " col-md-2";
          } else if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.DATUMSKI) {
            className += " ml-3";
          } else {
            className += " col-md-3";
          }

          let autsPolje = (
            <div className={className} key={podredjeni.id}>
              <div className={!podredjeni.ispisujeSeLabela ? "ml-3 mt-3" : podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.NASLOV ? "ml-3 mt-3 font-weight-bold" : "mt-2"}>
                {podredjeni.ispisujeSeLabela ? podredjeni.naziv : ""}
                {!podredjeni.opciono ? "*" : ""}
                {podredjeni.ispisujeSeLabela && podredjeni.relevantno ? <i className="fas fa-bell ml-2"> </i> : ""}
                {podredjeni.ispisujeSeLabela && podredjeni.htmlInfo && (
                  <button className="btn btn-outline-danger ml-1 mb-1 button_htmlInfo" onClick={() => props.showHtmlInfo(podredjeni.htmlInfo)}>
                    <i className="fas fa-info-circle"></i>
                  </button>
                )}
              </div>
              <TipAtributaRouter2
                tvaNumVrednost={podredjeni.tvaNumVrednost}
                initValue={props.initValue[podredjeni.id]}
                changeState={props.changeState}
                tipSkupaAtributaKey={podredjeni.id}
                atributUTipuSkupaID={podredjeni.id}
                auts={podredjeni}
                enumiPoAUTS={props.enumiPoAUTS}
                prikazAUTS={props.prikazAUTS}
                sifraAutsPoTipu={props.sifraAutsPoTipu}
                zaPretragu={false}
                smallFormat={true}
              />
            </div>
          );
          return <React.Fragment key={p}>{autsPolje}</React.Fragment>;
        }
      });
    }
    autsi = <div className="form-group autsDiv row card-body">{autsi}</div>;
  } else {
    if (props.autsiPodredjeni[props.auts.id]) {
      autsi = props.autsiPodredjeni[props.auts.id].map((podredjeni: any) => {
        if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.PANEL) {
          return (
            <Panel2
              key={podredjeni.id}
              auts={podredjeni}
              autsiPodredjeni={props.autsiPodredjeni}
              initValue={props.initValue}
              enumiPoAUTS={props.enumiPoAUTS}
              changeState={props.changeState}
              prikazAUTS={props.prikazAUTS}
              sifraAutsPoTipu={props.sifraAutsPoTipu}
              zaPretragu={false}
              prikazivanjeAutsa={props.prikazivanjeAutsa}
              prikazivanjeVertikalno={props.prikazivanjeVertikalno}
              showHtmlInfo={props.showHtmlInfo}
              hideHtmlInfo={props.hideHtmlInfo}
            />
          );
        }
        let prikaz = props.prikazivanjeAutsa(podredjeni);
        if (prikaz) {
          return (
            <div key={podredjeni.id} className={"form-group autsDiv row card-body"}>
              {props.prikazivanjeVertikalno(podredjeni)}
            </div>
          );
        }
      });
    }
  }
  return (
    <div className={props.auts.ispisujeSeLabela ? "card w-100" : "w-100"} key={props.auts.id}>
      <h5 className={props.auts.ispisujeSeLabela ? "card-header mb-2" : "mb-2"}>{props.auts.ispisujeSeLabela ? props.auts.naziv : ""}</h5>
      {autsi}
    </div>
  );
}
