import axios from "axios";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import EnumTipovaVrednostiAtributa from "../../../utils/EnumTipovaVrednostiAtributa";
import { axiosConfig, CALENDAR_DATE_FORMAT, isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import CrudStavkaUnosaSlikovni from "./CrudStavkaUnosaSlikovni";

interface CrudTipAtributaProps {
  stavkaUnosaOperation: string;
  stavkaUnosa?: any;
  onCreateStavkaUnosa: Function;
  onUpdateStavkaUnosa: Function;
  onDeleteStavkaUnosa: Function;
  onCancel: () => void;
}

export default function CrudStavkaUnosa(props: CrudTipAtributaProps) {
  const { stavkaUnosaOperation, stavkaUnosa, onCreateStavkaUnosa, onUpdateStavkaUnosa, onDeleteStavkaUnosa, onCancel } = props;
  const [stavkaUnosaChange, setStavkaUnosaChange] = useState<Partial<any>>(stavkaUnosa);
  const [tipoviAtributa, setTipoviAtributa] = useState();
  const [tipoviAtributaPaneli, setTipoviAtributaPaneli] = useState();
  const [enumStavke, setEnumStavke] = useState();
  const [nadredjeniAUTS, setNadredjeniAUTS] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);

  const { id } = useParams<Partial<any>>();

  useEffectOnce(() => {
    const requestTipoviAtributa = axios.get(
      Endpoint.TIP_ATRIBUTA + "/search/findByAktivan",
      axiosConfig(authData.token, {
        page: 0,
        size: 5000,
        aktivan: true,
      })
    );
    const requestTipAtributaPaneli = axios.get(
      Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findByTipSkupaAtributaAndListNumVredTipaVrednosti",
      axiosConfig(authData.token, {
        tipSkupaAtributa: Endpoint.TIP_SKUPA_ATRIBUTA + "/" + id,
        listaNumerickihVrednosti: [EnumTipovaVrednostiAtributa.PANEL, EnumTipovaVrednostiAtributa.SLIKOVNI, EnumTipovaVrednostiAtributa.TAB].toString(),
      })
    );
    if (prikazSkupovnog) {
      axios
        .get(
          Endpoint.ENUM_STAVKA + "/search/findByTipAtributaAndAktivanOrderByRedosled",
          axiosConfig(authData.token, { tipAtributa: Endpoint.TIP_ATRIBUTA + "/" + stavkaUnosa.tipAtributaID, aktivan: true })
        )
        .then((response) => {
          setEnumStavke(response.data._embedded.enumStavkas);
        });
    }
    if (prikazNadredjeniSlikovni) {
      axios
        .get(Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findNadredjeniAuts", axiosConfig(authData.token, { atributUTipuSkupa: Endpoint.ATRIBUT_U_TIPU_SKUPA + "/" + stavkaUnosa.id }))
        .then((response) => {
          setNadredjeniAUTS(response.data._embedded.atributUTipuSkupas[0]);
        });
    }

    axios.all([requestTipoviAtributa, requestTipAtributaPaneli]).then(
      axios.spread((responseTipoviAtributa, responseTipAtributaPaneli) => {
        setTipoviAtributa(responseTipoviAtributa.data._embedded.tipAtributas);
        setTipoviAtributaPaneli(responseTipAtributaPaneli.data._embedded.atributUTipuSkupas);
      })
    );
  });

  const validateInput = (message: string) => {
    if (!stavkaUnosaChange || !stavkaUnosaChange.tipAtributaNaziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_TIP_ATRIBUTA);
      return false;
    }

    if (!stavkaUnosaChange || !stavkaUnosaChange.naziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    if (!stavkaUnosaChange || !stavkaUnosaChange.redosled) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_REDOSLED_PRIKAZA);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
      return;
    }
    const stavkaUnosaChangeNew = stavkaUnosaChange;
    stavkaUnosaChangeNew.tipSkupaAtributa = Endpoint.TIP_SKUPA_ATRIBUTA + "/" + id;
    onCreateStavkaUnosa(stavkaUnosaChangeNew)
      .then(() => {})
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
      });
  };

  const onUpdate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
      return;
    }
    onUpdateStavkaUnosa(stavkaUnosaChange)
      .then(() => {})
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
      });
  };

  const onDelete = () => {
    onDeleteStavkaUnosa(stavkaUnosaChange)
      .then(() => {})
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
      });
  };

  const handleUpload = (event: any) => {
    const file = event.files[0];
    readFileDataAsBase64(file)
      .then((response: any) => {
        const source = response.split("base64,")[1];
        var image = new Image();
        image.src = response;
        image.onload = function (): any {
          setStavkaUnosaChange({
            ...stavkaUnosaChange,
            inicijalnaStringVrednost: file.name,
            gornjaReferentnaBrojnaVrednost: image.width,
            donjaReferentnaBrojnaVrednost: image.height,
            backgroundImage: source,
          });
        };
      })
      .catch((e) => console.log(e));
  };

  const readFileDataAsBase64 = (file: any) => {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  const prikazIzbornog = stavkaUnosa?.tipVrednostiAtributaNumVrednost === EnumTipovaVrednostiAtributa.IZBORNI;
  const prikazBrojnog = stavkaUnosa?.tipVrednostiAtributaNumVrednost === EnumTipovaVrednostiAtributa.BROJNI;
  const prikazTekstualnog = stavkaUnosa?.tipVrednostiAtributaNumVrednost === EnumTipovaVrednostiAtributa.TEKSTUALNI;
  const prikazSkupovnog = stavkaUnosa?.tipVrednostiAtributaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI;
  const prikazDatumskog = stavkaUnosa?.tipVrednostiAtributaNumVrednost === EnumTipovaVrednostiAtributa.DATUMSKI;
  const prikazPanel = stavkaUnosa?.tipVrednostiAtributaNumVrednost === EnumTipovaVrednostiAtributa.PANEL;
  const prikazSlikovni = stavkaUnosa?.tipVrednostiAtributaNumVrednost === EnumTipovaVrednostiAtributa.SLIKOVNI;
  const prikazNadredjeniSlikovni = stavkaUnosa?.nadredjeniAtributUTipuSkupaTVANumVr === EnumTipovaVrednostiAtributa.SLIKOVNI;

  const leftContents = () => (
    <React.Fragment>
      {stavkaUnosaOperation === EntityOperation.CREATE && <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-save" className="p-button-success" onClick={onCreate} />}
      {stavkaUnosaOperation === EntityOperation.UPDATE && <Button label={Labels.BUTTON_IZMENI} icon="pi pi-inbox" className="p-button-warning" onClick={onUpdate} />}
      {stavkaUnosaOperation === EntityOperation.DELETE && <Button label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={onDelete} />}
    </React.Fragment>
  );

  const rightContents = () => <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />;

  return (
    <div className="panel-attribute">
      <TabView renderActiveOnly={false}>
        <TabPanel header={Labels.TAB_POLJE_FORMULARA}>
          <div className="fluid">
            <div className="field grid align-items-center">
              <div className="col-2">{Labels.LABEL_TIP_ATRIBUTA}</div>
              <div className="col-4">
                <Dropdown
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  dataKey="id"
                  value={stavkaUnosaChange && stavkaUnosaChange.tipAtributaNaziv ? stavkaUnosaChange.tipAtributaNaziv : ""}
                  options={tipoviAtributa}
                  editable={true}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      tipAtributaNaziv: e.value.naziv,
                      tipAtributa: Endpoint.TIP_ATRIBUTA + "/" + e.value.id,
                    });
                  }}
                  optionLabel="naziv"
                  filter={true}
                  filterBy="naziv"
                  className="w-full"
                />
              </div>
              <div className="col-2">{Labels.LABEL_NADREDJENA_STAVKA}</div>
              <div className="col-4">
                <Dropdown
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  dataKey="id"
                  value={stavkaUnosaChange && stavkaUnosaChange.nadredjeniAtributUTipuSkupaNaziv ? stavkaUnosaChange.nadredjeniAtributUTipuSkupaNaziv : ""}
                  options={tipoviAtributaPaneli}
                  editable={true}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      nadredjeniAtributUTipuSkupaNaziv: e.value.naziv,
                      nadredjeniAtributUTipuSkupa: Endpoint.ATRIBUT_U_TIPU_SKUPA + "/" + e.value.id,
                    });
                  }}
                  optionLabel="naziv"
                  filter={true}
                  filterBy="naziv"
                  className="w-full"
                />
              </div>
            </div>
          </div>
          <div className="fluid">
            <div className="field grid align-items-center">
              <div className="col-2">{Labels.LABEL_NAZIV}</div>
              <div className="col-4">
                <InputText
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  value={stavkaUnosaChange ? stavkaUnosaChange.naziv : ""}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      naziv: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-2">{Labels.LABEL_REDOSLED_PRIKAZA}</div>
              <div className="col-4">
                <InputNumber
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  value={stavkaUnosaChange ? stavkaUnosaChange.redosled : null}
                  onValueChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      redosled: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="fluid">
            <div className="field grid align-items-center">
              <div className="col-2">{Labels.LABEL_SIFRA}</div>
              <div className="col-4">
                <InputText
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  value={stavkaUnosaChange && stavkaUnosaChange.sifra ? stavkaUnosaChange.sifra : null}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      sifra: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-2">{Labels.LABEL_SIFRA_VEZE}</div>
              <div className="col-4">
                <InputText
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  value={stavkaUnosaChange ? stavkaUnosaChange.sifraVeza : null}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      sifraVeza: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="fluid">
            <div className="field grid align-items-center">
              <div className="col-2">{Labels.LABEL_RELEVANTNI}</div>
              <div className="col-4">
                <InputSwitch
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  checked={stavkaUnosaChange && stavkaUnosaChange.relevantni ? stavkaUnosaChange.relevantni : false}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      relevantni: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-2">{Labels.LABEL_OPCIONI}</div>
              <div className="col-4">
                <InputSwitch
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  checked={stavkaUnosaChange && stavkaUnosaChange.opcioni ? stavkaUnosaChange.opcioni : false}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      opcioni: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="fluid">
            <div className="field grid align-items-center">
              <div className="col-2">{Labels.LABEL_POSLEDNJA_VREDNOST}</div>
              <div className="col-4">
                <InputSwitch
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  checked={stavkaUnosaChange && stavkaUnosaChange.inicijalnoPoslednjaPopunjenaVrednost ? stavkaUnosaChange.inicijalnoPoslednjaPopunjenaVrednost : false}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      inicijalnoPoslednjaPopunjenaVrednost: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-2">{Labels.LABEL_ISPISUJE_SE_LABELA}</div>
              <div className="col-4">
                <InputSwitch
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  checked={stavkaUnosaChange && stavkaUnosaChange.ispisujeSeLabela ? stavkaUnosaChange.ispisujeSeLabela : false}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      ispisujeSeLabela: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="fluid">
            <div className="field grid align-items-center">
              {prikazIzbornog && (
                <>
                  <div className="col-2">{Labels.LABEL_CHECKBOX}</div>
                  <div className="col-4">
                    <InputSwitch
                      disabled={isFormDisabled(stavkaUnosaOperation)}
                      checked={stavkaUnosaChange && stavkaUnosaChange.imaCekBox ? stavkaUnosaChange.imaCekBox : false}
                      onChange={(e: any) => {
                        setStavkaUnosaChange({
                          ...stavkaUnosaChange,
                          imaCekBox: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              )}
              {prikazPanel && (
                <>
                  <div className="col-2">{Labels.LABEL_VERTIKALNI_PRIKAZ}</div>
                  <div className="col-4">
                    <InputSwitch
                      disabled={isFormDisabled(stavkaUnosaOperation)}
                      checked={stavkaUnosaChange && stavkaUnosaChange.vertikalniPrikaz ? stavkaUnosaChange.vertikalniPrikaz : false}
                      onChange={(e: any) => {
                        setStavkaUnosaChange({
                          ...stavkaUnosaChange,
                          vertikalniPrikaz: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="fluid">
            <div className="field grid align-items-center">
              <div className="col-2">{Labels.LABEL_PRETRAŽUJE_SE}</div>
              <div className="col-4">
                <InputSwitch
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  checked={stavkaUnosaChange && stavkaUnosaChange.pretrazujeSe ? stavkaUnosaChange.pretrazujeSe : false}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      pretrazujeSe: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-2">{Labels.LABEL_PRIKAZUJE_SE}</div>
              <div className="col-4">
                <InputSwitch
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  checked={stavkaUnosaChange && stavkaUnosaChange.prikazujeSe ? stavkaUnosaChange.prikazujeSe : false}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      prikazujeSe: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {prikazSkupovnog && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_MULTISELECT}</div>
                <div className="col-4">
                  <InputSwitch
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    checked={stavkaUnosaChange && stavkaUnosaChange.multiselect ? stavkaUnosaChange.multiselect : false}
                    onChange={(e: any) => {
                      setStavkaUnosaChange({
                        ...stavkaUnosaChange,
                        multiselect: e.target.value,
                      });
                    }}
                  />
                </div>
                {prikazSkupovnog && stavkaUnosaChange && stavkaUnosaChange.multiselect && (
                  <>
                    <div className="col-2">{Labels.LABEL_RADIO_BUTTON}</div>
                    <div className="col-4">
                      <InputSwitch
                        disabled={isFormDisabled(stavkaUnosaOperation)}
                        checked={stavkaUnosaChange && stavkaUnosaChange.radioButton ? stavkaUnosaChange.radioButton : false}
                        onChange={(e: any) => {
                          setStavkaUnosaChange({
                            ...stavkaUnosaChange,
                            radioButton: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {prikazIzbornog && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_INICIJALNA_VREDNOST}</div>
                <div className="col-4">
                  <InputSwitch
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    checked={stavkaUnosaChange && stavkaUnosaChange.inicijalnaBooleanVrednost ? stavkaUnosaChange.inicijalnaBooleanVrednost : false}
                    onChange={(e: any) => {
                      setStavkaUnosaChange({
                        ...stavkaUnosaChange,
                        inicijalnaBooleanVrednost: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {prikazTekstualnog && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_INICIJALNA_VREDNOST}</div>
                <div className="col-4">
                  <InputText
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    value={stavkaUnosaChange && stavkaUnosaChange.inicijalnaStringVrednost ? stavkaUnosaChange.inicijalnaStringVrednost : null}
                    onChange={(e: any) => {
                      setStavkaUnosaChange({ ...stavkaUnosaChange, inicijalnaStringVrednost: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {prikazBrojnog && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_INICIJALNA_VREDNOST}</div>
                <div className="col-4">
                  <InputNumber
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    value={stavkaUnosaChange && stavkaUnosaChange.inicijalnaBrojnaVrednost ? stavkaUnosaChange.inicijalnaBrojnaVrednost : null}
                    onValueChange={(e: any) => {
                      setStavkaUnosaChange({ ...stavkaUnosaChange, inicijalnaBrojnaVrednost: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {prikazSkupovnog && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_INICIJALNA_VREDNOST}</div>
                <div className="col-4">
                  <Dropdown
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    dataKey="id"
                    value={stavkaUnosaChange && stavkaUnosaChange.inicijalnaEnumStavkaNaziv ? stavkaUnosaChange.inicijalnaEnumStavkaNaziv : ""}
                    options={enumStavke}
                    editable={true}
                    onChange={(e: any) => {
                      setStavkaUnosaChange({
                        ...stavkaUnosaChange,
                        inicijalnaEnumStavkaNaziv: e.value.naziv,
                        inicijalnaEnumStavka: Endpoint.ENUM_STAVKA + "/" + e.value.id,
                      });
                    }}
                    optionLabel="naziv"
                    filter={true}
                    filterBy="naziv"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          )}
          {prikazDatumskog && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_INICIJALNA_VREDNOST}</div>
                <div className="col-4">
                  <Calendar
                    dateFormat={CALENDAR_DATE_FORMAT}
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    value={stavkaUnosaChange && stavkaUnosaChange.inicijalnaBrojnaVrednost ? new Date(stavkaUnosaChange.inicijalnaBrojnaVrednost) : undefined}
                    onChange={(e: any) => {
                      const date = new Date(e.target.value);
                      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                      setStavkaUnosaChange({
                        ...stavkaUnosaChange,
                        inicijalnaBrojnaVrednost: e.target.value ? e.target.value.valueOf() : undefined,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {(prikazTekstualnog || prikazBrojnog || prikazSkupovnog) && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_UPIT_INICIJALNA_VREDNOST}</div>
                <div className="col-10">
                  <InputText
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    value={stavkaUnosaChange ? stavkaUnosaChange.upitInicijalnaVrednost : ""}
                    onChange={(e: any) => {
                      setStavkaUnosaChange({
                        ...stavkaUnosaChange,
                        upitInicijalnaVrednost: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="fluid">
            <div className="field grid align-items-center">
              <div className="col-2">{Labels.LABEL_FORMULA_ZA_PRIKAZ}</div>
              <div className="col-10">
                <InputText
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  value={stavkaUnosaChange && stavkaUnosaChange.formulaZaPrikaz}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      formulaZaPrikaz: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {(prikazBrojnog || prikazTekstualnog) && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_FORMULA_ZA_RACUNANJE}</div>
                <div className="col-10">
                  <InputText
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    value={stavkaUnosaChange && stavkaUnosaChange.formulaZaRacunanje}
                    onChange={(e: any) => {
                      setStavkaUnosaChange({
                        ...stavkaUnosaChange,
                        formulaZaRacunanje: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="fluid">
            <div className="field grid align-items-center">
              <div className="col-2">{Labels.LABEL_NAPOMENA}</div>
              <div className="col-10">
                <InputText
                  disabled={isFormDisabled(stavkaUnosaOperation)}
                  value={stavkaUnosaChange && stavkaUnosaChange.napomena}
                  onChange={(e: any) => {
                    setStavkaUnosaChange({
                      ...stavkaUnosaChange,
                      napomena: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {prikazBrojnog && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_DONJA_REFERENTNA_VREDNOST}</div>
                <div className="col-4">
                  <InputNumber
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    value={stavkaUnosaChange && stavkaUnosaChange.donjaReferentnaBrojnaVrednost}
                    onValueChange={(e: any) => {
                      setStavkaUnosaChange({
                        ...stavkaUnosaChange,
                        donjaReferentnaBrojnaVrednost: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-2">{Labels.LABEL_GORNJA_REFERENTNA_VREDNOST}</div>
                <div className="col-4">
                  <InputNumber
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    value={stavkaUnosaChange && stavkaUnosaChange.gornjaReferentnaBrojnaVrednost}
                    onValueChange={(e: any) => {
                      setStavkaUnosaChange({
                        ...stavkaUnosaChange,
                        gornjaReferentnaBrojnaVrednost: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {prikazSlikovni && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_DODAJ_SLIKU}</div>
                <div className="col-1">
                  <FileUpload auto onSelect={handleUpload} mode="basic" chooseLabel={Labels.LABEL_DODAJ} accept="image/*" maxFileSize={1000000} />
                </div>
                <div className="col-2">
                  {stavkaUnosaChange && stavkaUnosaChange.inicijalnaStringVrednost && (
                    <Button
                      onClick={() => {
                        setStavkaUnosaChange({ ...stavkaUnosaChange, backgroundImage: null, inicijalnaStringVrednost: "" });
                      }}
                      tooltip={`${Labels.TOOLTIP_DODATA_SLIKA}: ${stavkaUnosaChange ? stavkaUnosaChange.inicijalnaStringVrednost : ""}`}
                      tooltipOptions={{ position: "bottom" }}
                      label={`(1) ${Labels.BUTTON_PONISTI}`}
                      icon="pi pi-times"
                      className="p-button-danger"
                    />
                  )}
                </div>
              </div>
              {stavkaUnosaChange && stavkaUnosaChange.inicijalnaStringVrednost && (
                <div className="field grid align-items-center">
                  <div className="col-2">{Labels.LABEL_PRECNIK}</div>
                  <div className="col-1">
                    <InputNumber
                      disabled={isFormDisabled(stavkaUnosaOperation)}
                      value={stavkaUnosaChange?.inicijalnaBrojnaVrednost}
                      onValueChange={(e: any) => {
                        setStavkaUnosaChange({ ...stavkaUnosaChange, inicijalnaBrojnaVrednost: e.target.value });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {prikazNadredjeniSlikovni && nadredjeniAUTS && stavkaUnosaChange?.nadredjeniAtributUTipuSkupaNaziv && (
            <div className="fluid">
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_KORDINATA_X}</div>
                <div className="col-1">
                  <InputNumber
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    value={stavkaUnosaChange?.imageCoordinateX}
                    onValueChange={(e: any) => {
                      setStavkaUnosaChange({ ...stavkaUnosaChange, imageCoordinateX: e.target.value });
                    }}
                  />
                </div>
                <div className="col-3"></div>
                <div className="col-2">{Labels.LABEL_KORDINATA_Y}</div>
                <div className="col-1">
                  <InputNumber
                    disabled={isFormDisabled(stavkaUnosaOperation)}
                    value={stavkaUnosaChange?.imageCoordinateY}
                    onValueChange={(e: any) => {
                      setStavkaUnosaChange({ ...stavkaUnosaChange, imageCoordinateY: e.target.value });
                    }}
                  />
                </div>
                <div className="col-3"></div>
              </div>
              <div className="field grid align-items-center">
                <div className="col-2">{Labels.LABEL_DODAJ_KORDINATE}</div>
                <div className="col-10">
                  <CrudStavkaUnosaSlikovni nadredjeniAUTS={nadredjeniAUTS} stavkaUnosaChange={stavkaUnosaChange} setStavkaUnosaChange={setStavkaUnosaChange} />
                </div>
              </div>
            </div>
          )}
          <Toolbar left={leftContents} right={rightContents} />
        </TabPanel>
      </TabView>
    </div>
  );
}
