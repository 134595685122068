const EnumTipovaVrednostiAtributa = {
  IZBORNI: 1,
  BROJNI: 2,
  TEKSTUALNI: 3,
  SKUPOVNI: 4,
  DATUMSKI: 5,
  NASLOV: 6,
  SEPARATOR: 7,
  PANEL: 8,
  SLIKOVNI: 9,
  TAB: 11,
  GRAFIK: 12,
};

export default EnumTipovaVrednostiAtributa;
