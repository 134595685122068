import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useContext, useRef } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import CrudKategorijaIzvestaja from "./CrudKategorijeIzvestaja";
import MessageType from "../../../infrastructure/MessageType";
import { AppContext } from "../../Store";

export default function CrudKategorijeIzvestajaListO() {
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedKategorijaIzvestaja, setSelectedKategorijaIzvestaja] = useState();
  const [kategorijaIzvestajaList, setKategorijaIzvestajaList] = useState();
  const [entityOperation, setEntityOperation] = useState<string>("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [kategorijaIzvestajaToChange, setKategorijaIzvestajaToChange] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axios
      .get(
        Endpoint.KATEGORIJA_STATISTICKOG_IZVESTAJA + "/search/findByAktivanPageable",
        axiosConfig(authData.token, {
          page: 0,
          size: 50000000,
          aktivan: true,
        })
      )
      .then((kategorijeIzvestajaCollection) => {
        setKategorijaIzvestajaList(kategorijeIzvestajaCollection.data._embedded.kategorijaStatistickogIzvestajas);
      });
  };

  const createKategorijaIzvestaja = (kategorijaIzvestaja: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.KATEGORIJA_STATISTICKOG_IZVESTAJA, kategorijaIzvestaja, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO, kategorijaIzvestaja.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateKategorijaIzvestaja = (kategorijaIzvestaja: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(Endpoint.KATEGORIJA_STATISTICKOG_IZVESTAJA + `/${kategorijaIzvestaja.id}`, kategorijaIzvestaja, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, kategorijaIzvestaja.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteKategorijaIzvestaja = (kategorijaIzvestaja: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(
          Endpoint.KATEGORIJA_STATISTICKOG_IZVESTAJA + `/${kategorijaIzvestaja.id}`,
          {
            aktivan: false,
          },
          axiosConfig(authData.token)
        )
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO, kategorijaIzvestaja.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const dialogHeader = () => {
    const display = kategorijaIzvestajaToChange ? kategorijaIzvestajaToChange.naziv : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_KATEGORIJE_IZVESTAJA;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_KATEGORIJE_IZVESTAJA + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_KATEGORIJE_IZVESTAJA + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_KATEGORIJE_IZVESTAJA + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedKategorijaIzvestaja;
        break;
    }
    setEntityOperation(entityOperation);
    setKategorijaIzvestajaToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
    setSelectedKategorijaIzvestaja(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => closeDialog();

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-save" className="p-button-success" onClick={() => dialogRef.current.onCreate()} />}
      {entityOperation === EntityOperation.UPDATE && <Button label={Labels.BUTTON_IZMENI} icon="pi pi-inbox" className="p-button-warning" onClick={() => dialogRef.current.onUpdate()} />}
      {entityOperation === EntityOperation.DELETE && <Button label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => dialogRef.current.onDelete()} />}
    </React.Fragment>
  );

  const rightContentsDialog = () => <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />;

  const dialogFooter = () => <Toolbar left={leftContentsDialog} right={rightContentsDialog} />;

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
      <Button disabled={!selectedKategorijaIzvestaja} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openDialog(EntityOperation.UPDATE)} />
      <Button disabled={!selectedKategorijaIzvestaja} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => (
    <Button disabled={!selectedKategorijaIzvestaja} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openDialog(EntityOperation.DELETE)} />
  );

  return (
    <div className="layout-report">
      <Panel header={Labels.TITLE_PANEL_CRUD_KATEGORIJA_IZVESTAJA} className="panel-report-list">
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            filterDisplay="row"
            value={kategorijaIzvestajaList}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedKategorijaIzvestaja(e.data)}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column
              className="column-align-left column-width-200px"
              field={"numerickaVrednost"}
              header={Labels.COLUMN_HEADER_NUMERICKA_VREDNOST}
              filterMatchMode="contains"
              showFilterMenu={false}
              filter
              sortable
            />
          </DataTable>
          <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }} footer={dialogFooter()}>
            <CrudKategorijaIzvestaja
              kategorijaIzvestajaOperation={entityOperation}
              kategorijaIzvestaja={kategorijaIzvestajaToChange}
              onCreateKategorijaIzvestaja={createKategorijaIzvestaja}
              onUpdateKategorijaIzvestaja={updateKategorijaIzvestaja}
              onDeleteKategorijaIzvestaja={deleteKategorijaIzvestaja}
              onCancel={onCancel}
              setIndex={setIndex}
              index={index}
              dialogRef={dialogRef}
            />
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
