import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState, useContext } from "react";
import Endpoint from "../../../../endpoints/Endpoint";
import EntityOperation from "../../../../infrastructure/EnumEntityOperation";
import Labels from "../../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../../infrastructure/MessageType";
import { axiosConfig, useEffectOnce } from "../../../../utils/Utils";
import CrudParametar from "./CrudParametar";
import { AppContext } from "../../../Store";

export default function CrudParametriList() {
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedParametar, setSelectedParametar] = useState();
  const [parametarList, setParametarList] = useState();
  const [entityOperation, setEntityOperation] = useState<string>("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [parametarToChange, setParametarToChange] = useState<any>();
  const { authData } = useContext(AppContext);

  const messages = useRef<any>(null);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axios
      .get(
        Endpoint.PARAMETAR + "/search/findByAktivan",
        axiosConfig(authData.token, {
          aktivan: true,
        })
      )
      .then((parametriCollection) => {
        setParametarList(parametriCollection.data._embedded.parametars);
      });
  };

  const showMessage = (summary: string, severity: string, detail: string) => {
    messages.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const createParametar = (parametar: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.KREIRANJE_PARAMETRA, parametar, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showMessage(Labels.TITLE_MESSAGES_USPESNO_KREIRANO, MessageType.SUCCESS, parametar.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateParametar = (parametar: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.KREIRANJE_PARAMETRA, parametar, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showMessage(Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, MessageType.SUCCESS, parametar.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteParametar = (parametar: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.KREIRANJE_PARAMETRA, parametar, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showMessage(Labels.TITLE_MESSAGES_USPESNO_OBRISANO, MessageType.SUCCESS, parametar.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const reloadParametre = () => {
    axios
      .post(Endpoint.RELOAD_PARAMETAR, {}, axiosConfig(authData.token))
      .then(() => {
        showMessage(Labels.TITLE_MESSAGES_USPESNO_OSVEZENI_PARAMETRI, MessageType.SUCCESS, "");
      })
      .catch((error: any) => {
        showMessage(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_OSVEZAVANJA_PARAMETARA, MessageType.ERROR, "");
      });
  };

  const dialogHeader = () => {
    const display = parametarToChange ? parametarToChange.naziv : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_PARAMETRA;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_PARAMETRA + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_PARAMETRA + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_PARAMETRA + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedParametar;
        break;
    }
    setEntityOperation(entityOperation);
    setParametarToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setDisplayDialog(false);
    setSelectedParametar(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => {
    closeDialog();
  };

  return (
    <div className="layout-role">
      <div className="col-12">
        <Messages ref={messages} />
      </div>
      <Panel header={Labels.TITLE_PANEL_CRUD_PARAMETAR} className="panel-roles-list">
        <Toolbar>
          <div className="p-toolbar-group-left">
            <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
            <Button disabled={!selectedParametar} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openDialog(EntityOperation.UPDATE)} />
            <Button disabled={!selectedParametar} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
            <Button label={Labels.BUTTON_OSVEZI} icon="pi pi-refresh" className="p-button-info" onClick={reloadParametre} />
          </div>
          <div className="p-toolbar-group-right">
            <Button disabled={!selectedParametar} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openDialog(EntityOperation.DELETE)} />
          </div>
        </Toolbar>
        <div>
          <DataTable
            filterDisplay="row"
            value={parametarList}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedParametar(e.data)}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"vrednost"} header={Labels.COLUMN_HEADER_VREDNOST} filterMatchMode="contains" showFilterMenu={false} filter />
            <Column className="column-align-left" field={"opis"} header={Labels.COLUMN_HEADER_OPIS} filterMatchMode="contains" showFilterMenu={false} filter sortable />
          </DataTable>
          <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }}>
            <CrudParametar
              parametarOperation={entityOperation}
              parametar={parametarToChange}
              onCreateParametar={createParametar}
              onUpdateParametar={updateParametar}
              onDeleteParametar={deleteParametar}
              onCancel={onCancel}
            />
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
