let mapDataUNedelji = [ "2_Ponedeljak", "3_Utorak", "4_Sreda", "5_Četvrtak", "6_Petak",  "7_Subota", "1_Nedelja" ];

let objectDanUNedelji = {
		2 :"Ponedeljak",
		3 : "Utorak",
		4 : "Sreda",
		5 : "Četvrtak",
		6 : "Petak",
		7 : "Subota",
		1 : "Nedelja"
};

module.exports = {
	mapDataUNedelji : mapDataUNedelji,
	objectDanUNedelji : objectDanUNedelji
};
