import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState } from "react";
import EntityOperation from "../../../../infrastructure/EnumEntityOperation";
import Labels from "../../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../../infrastructure/MessageType";
import { isFormDisabled } from "../../../../utils/Utils";

interface CrudParametarProp {
  parametarOperation: string;
  parametar?: any;
  onCreateParametar: Function;
  onUpdateParametar: Function;
  onDeleteParametar: Function;
  onCancel: () => void;
}

export default function CrudParametar(prop: CrudParametarProp) {
  const { parametarOperation, parametar, onCreateParametar, onUpdateParametar, onDeleteParametar, onCancel } = prop;

  const messages = useRef<any>(null);
  const [parametarChange, setParametarChange] = useState<Partial<any>>(parametar);

  const showMessage = (summary: string, severity: string, detail: string) => {
    messages.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const validateInput = (message: string) => {
    if (!parametarChange || !parametarChange.naziv) {
      showMessage(message, MessageType.ERROR, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    } else if (!parametarChange || !parametarChange.vrednost) {
      showMessage(message, MessageType.ERROR, Labels.MESSAGES_POLJE_VREDNOST);
      return false;
    }

    return true;
  };

  const onCreate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
      return;
    }
    onCreateParametar(parametarChange)
      .then(() => {})
      .catch((error: any) => {
        showMessage(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, MessageType.ERROR, error);
      });
  };

  const onUpdate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
      return;
    }
    onUpdateParametar(parametarChange)
      .then(() => {})
      .catch((error: any) => {
        showMessage(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, MessageType.ERROR, error);
      });
  };

  const onDelete = () => {
    let parametarChangeObject = parametarChange;
    parametarChangeObject.vrednost = "";
    onDeleteParametar(parametarChangeObject)
      .then(() => {})
      .catch((error: any) => {
        showMessage(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, MessageType.ERROR, error);
      });
  };

  return (
    <div className="panel-role">
      <div className="col-12">
        <Messages ref={messages} />
      </div>
      <TabView renderActiveOnly={false}>
        <TabPanel header={Labels.TAB_PARAMETAR}>
          <div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NAZIV}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(parametarOperation)}
                    value={parametarChange && parametarChange.naziv}
                    onChange={(e: any) => {
                      setParametarChange({
                        ...parametarChange,
                        naziv: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_VREDNOST}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(parametarOperation)}
                    value={parametarChange && parametarChange.vrednost}
                    onChange={(e: any) => {
                      setParametarChange({
                        ...parametarChange,
                        vrednost: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_OPIS}</div>
                <div className="col-8">
                  <InputNumber
                    disabled={isFormDisabled(parametarOperation)}
                    value={parametarChange && parametarChange.opis}
                    onValueChange={(e: any) => {
                      setParametarChange({
                        ...parametarChange,
                        opis: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Toolbar>
            <div className="p-toolbar-group-left">
              {parametarOperation === EntityOperation.CREATE && <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-save" className="p-button-success" onClick={onCreate} />}
              {parametarOperation === EntityOperation.UPDATE && <Button label={Labels.BUTTON_IZMENI} icon="pi pi-inbox" className="p-button-warning" onClick={onUpdate} />}
              {parametarOperation === EntityOperation.DELETE && <Button label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={onDelete} />}
            </div>
            <div className="p-toolbar-group-right">
              <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />
            </div>
          </Toolbar>
        </TabPanel>
      </TabView>
    </div>
  );
}
