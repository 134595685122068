import React from "react";
import { Link } from "react-router-dom";

interface BreadcrubProp {
  registar: any;
  nazivUnosa: string;
}

export default function Breadcrumb(prop: BreadcrubProp) {
  const { registar, nazivUnosa } = prop;
  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/registri/" + registar.id}>{registar.naziv}</Link>
          </li>
          {nazivUnosa && (
            <li className="breadcrumb-item active" aria-current="page">
              {nazivUnosa}
            </li>
          )}
        </ol>
      </nav>
    </div>
  );
}
