import { Calendar } from "primereact/calendar";
import React from "react";
import { CALENDAR_DATE_FORMAT } from "./../../utils/Utils";

export default function Datumski({ initValue, auts, smallFormat, changeState, tipSkupaAtributaKey }) {
  const handleChange = (value) => {
    let stateArray = [
      ["initValue", tipSkupaAtributaKey, value],
      ["prikazAUTS", auts.sifra, value],
    ];
    changeState(stateArray);
  };

  return <Calendar showIcon dateFormat={CALENDAR_DATE_FORMAT} showMonthDropdown showYearDropdown value={initValue ? new Date(initValue) : undefined} onChange={(e) => handleChange(e.value ?? "")} />;
}
