import axios from "axios";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState, useContext } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import Parametri from "../../../infrastructure/Parametri";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import Izborni from "./tipovi/Izborni";
import Skupovni from "./tipovi/Skupovni";
import { AppContext } from "../../Store";

export default function RUParametri() {
  const [parametarList, setParametarList] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axios
      .get(
        Endpoint.PARAMETAR + "/search/findByAktivan",
        axiosConfig(authData.token, {
          aktivan: true,
        })
      )
      .then((parametriCollection) => {
        const parametriObject = {};
        parametriCollection.data._embedded.parametars.forEach(({ naziv, vrednost }: any) => {
          Object.assign(parametriObject, { [naziv]: vrednost });
        });
        setParametarList(parametriObject);
      });
  };

  const updateParametar = (parametar: any) => {
    axios
      .post(Endpoint.KREIRANJE_PARAMETRA, parametar, axiosConfig(authData.token))
      .then(() => {
        showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, parametar.label);
        fetchData();
      })
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, parametar.label);
      });
  };

  const booleanValue = (naziv: string) => {
    return parametarList[naziv] === "true";
  };

  if (!parametarList) {
    return null;
  }

  return (
    <div className="layout-parametri">
      <Panel header={Labels.TITLE_PANEL_CRUD_PARAMETAR} className="panel-parametri-list">
        <div>
          <TabView renderActiveOnly={false}>
            <TabPanel header={Labels.TAB_PACIJENT}>
              <Izborni label={Labels.LABEL_ZANIMANJE} naziv={Parametri.PACIJENT_ZANIMANJE} vrednost={booleanValue(Parametri.PACIJENT_ZANIMANJE)} onChange={updateParametar} />
              <Izborni label={Labels.LABEL_STRUCNA_SPREMA} naziv={Parametri.PACIJENT_STRUCNA_SPREMA} vrednost={booleanValue(Parametri.PACIJENT_STRUCNA_SPREMA)} onChange={updateParametar} />
              <Izborni label={Labels.LABEL_RADNO_MESTO} naziv={Parametri.PACIJENT_RADNO_MESTO} vrednost={booleanValue(Parametri.PACIJENT_RADNO_MESTO)} onChange={updateParametar} />
              <Izborni
                label={Labels.LABEL_MEDICINSKA_NAPOMENA}
                naziv={Parametri.PACIJENT_MEDICINSKA_NAPOMENA}
                vrednost={booleanValue(Parametri.PACIJENT_MEDICINSKA_NAPOMENA)}
                onChange={updateParametar}
              />
              <Izborni
                label={Labels.LABEL_ADMINISTRATIVNA_NAPOMENA}
                naziv={Parametri.PACIJENT_ADMINISTRATIVNA_NAPOMENA}
                vrednost={booleanValue(Parametri.PACIJENT_ADMINISTRATIVNA_NAPOMENA)}
                onChange={updateParametar}
              />
            </TabPanel>
            <TabPanel header={Labels.TAB_REGISTAR_PRETRAGA}>
              <Izborni
                label={Labels.LABEL_PRETRAGA_GODINA_OD}
                naziv={Parametri.REGISTAR_PRETRAGA_GODINA_OD}
                vrednost={booleanValue(Parametri.REGISTAR_PRETRAGA_GODINA_OD)}
                onChange={updateParametar}
              />
              <Izborni
                label={Labels.LABEL_PRETRAGA_GODINA_DO}
                naziv={Parametri.REGISTAR_PRETRAGA_GODINA_DO}
                vrednost={booleanValue(Parametri.REGISTAR_PRETRAGA_GODINA_DO)}
                onChange={updateParametar}
              />
              <Izborni label={Labels.LABEL_PRETRAGA_IME} naziv={Parametri.REGISTAR_PRETRAGA_IME} vrednost={booleanValue(Parametri.REGISTAR_PRETRAGA_IME)} onChange={updateParametar} />
              <Izborni label={Labels.LABEL_PRETRAGA_PREZIME} naziv={Parametri.REGISTAR_PRETRAGA_PREZIME} vrednost={booleanValue(Parametri.REGISTAR_PRETRAGA_PREZIME)} onChange={updateParametar} />
              <Izborni label={Labels.LABEL_PRETRAGA_JMBG} naziv={Parametri.REGISTAR_PRETRAGA_JMBG} vrednost={booleanValue(Parametri.REGISTAR_PRETRAGA_JMBG)} onChange={updateParametar} />
              <Izborni label={Labels.LABEL_PRETRAGA_LBO} naziv={Parametri.REGISTAR_PRETRAGA_LBO} vrednost={booleanValue(Parametri.REGISTAR_PRETRAGA_LBO)} onChange={updateParametar} />
              <Izborni label={Labels.LABEL_PRETRAGA_POL} naziv={Parametri.REGISTAR_PRETRAGA_POL} vrednost={booleanValue(Parametri.REGISTAR_PRETRAGA_POL)} onChange={updateParametar} />
              <Izborni
                label={Labels.LABEL_ODABIR_DATUME_PRE_UNOSA}
                naziv={Parametri.REGISTAR_ODABIR_DATUME_PRE_UNOSA}
                vrednost={booleanValue(Parametri.REGISTAR_ODABIR_DATUME_PRE_UNOSA)}
                onChange={updateParametar}
              />
              <Skupovni
                label={Labels.LABEL_BROJ_REZULTATA_U_PRETRAZI}
                naziv={Parametri.REGISTAR_BROJ_REZULTATA_U_PRETRAZI}
                vrednost={parametarList[Parametri.REGISTAR_BROJ_REZULTATA_U_PRETRAZI]}
                onChange={updateParametar}
              />
            </TabPanel>
          </TabView>
        </div>
      </Panel>
    </div>
  );
}
