import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState, useContext, useImperativeHandle } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { Dropdown } from "primereact/dropdown";
import Endpoint from "../../../endpoints/Endpoint";
import { axiosConfig } from "../../../utils/Utils";
import axios from "axios";
import { InputTextarea } from "primereact/inputtextarea";
import MessageType from "../../../infrastructure/MessageType";
import { AppContext } from "../../Store";

interface CrudTipParametraStatistickogIzvestajaProps {
  tipParametraStatistickogIzvestajaOperation: string;
  tipParametraStatistickogIzvestaja?: any;
  onCreateTipParametraStatistickogIzvestaja: Function;
  onUpdateTipParametraStatistickogIzvestaja: Function;
  onDeleteTipParametraStatistickogIzvestaja: Function;
  onCancel: Function;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudTipParametraStatistickogIzvestaja(props: CrudTipParametraStatistickogIzvestajaProps) {
  const {
    tipParametraStatistickogIzvestajaOperation,
    tipParametraStatistickogIzvestaja,
    onCreateTipParametraStatistickogIzvestaja,
    onUpdateTipParametraStatistickogIzvestaja,
    onDeleteTipParametraStatistickogIzvestaja,
    dialogRef,
    setIndex,
    index,
  } = props;
  const [tipParametraStatistickogIzvestajaChange, setTipParametraStatistickogIzvestajaChange] = useState<Partial<any>>(tipParametraStatistickogIzvestaja);
  const [sifarnikTipParametraStatistickogIzvestaja, setSifarnikTipParametraStatistickogIzvestaja] = useState();
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    const requestSifarnikTipParametraStatistickogIzvestaja = axios.get(
      Endpoint.SIFARNIK_TIPOVA_PARAMETRA_STATISTICKOG_IZVESTAJA + "/search/findByAktivan",
      axiosConfig(authData.token, { aktivan: true })
    );
    axios
      .all([requestSifarnikTipParametraStatistickogIzvestaja])
      .then(
        axios.spread((responseSifarnikTipParametraStatistickogIzvestaja) => {
          setSifarnikTipParametraStatistickogIzvestaja(responseSifarnikTipParametraStatistickogIzvestaja.data._embedded.sifarnikTipovaParametraStatistickogIzvestajas);
        })
      )
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, error);
      });
  });

  const validateInput = (message: string) => {
    if (!tipParametraStatistickogIzvestajaChange || !tipParametraStatistickogIzvestajaChange.naziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    if (!tipParametraStatistickogIzvestajaChange || !tipParametraStatistickogIzvestajaChange.nazivSifarnikaTipovaParametraStatistickogIzvestaja) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_TIP_PARAMETRA_STATISTICKOG_IZVESTAJA);
      return false;
    }
    return true;
  };

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
        return;
      }
      onCreateTipParametraStatistickogIzvestaja(tipParametraStatistickogIzvestajaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
        });
    },
    onUpdate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
        return;
      }
      onUpdateTipParametraStatistickogIzvestaja(tipParametraStatistickogIzvestajaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
        });
    },
    onDelete: () => {
      onDeleteTipParametraStatistickogIzvestaja(tipParametraStatistickogIzvestajaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    },
  }));

  return (
    <div className="panel-report">
      <TabView
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.TAB_TIP_PARAMETRA}>
          <div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NAZIV}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(tipParametraStatistickogIzvestajaOperation)}
                    value={tipParametraStatistickogIzvestajaChange && tipParametraStatistickogIzvestajaChange.naziv ? tipParametraStatistickogIzvestajaChange.naziv : ""}
                    onChange={(e: any) => {
                      setTipParametraStatistickogIzvestajaChange({
                        ...tipParametraStatistickogIzvestajaChange,
                        naziv: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_TIP_PARAMETRA_STATISTICKOG_IZVESTAJA}</div>
                <div className="col-8">
                  <Dropdown
                    disabled={isFormDisabled(tipParametraStatistickogIzvestajaOperation)}
                    dataKey="id"
                    value={
                      tipParametraStatistickogIzvestajaChange && tipParametraStatistickogIzvestajaChange.nazivSifarnikaTipovaParametraStatistickogIzvestaja
                        ? tipParametraStatistickogIzvestajaChange.nazivSifarnikaTipovaParametraStatistickogIzvestaja
                        : ""
                    }
                    options={sifarnikTipParametraStatistickogIzvestaja}
                    editable={true}
                    onChange={(e) => {
                      setTipParametraStatistickogIzvestajaChange({
                        ...tipParametraStatistickogIzvestajaChange,
                        nazivSifarnikaTipovaParametraStatistickogIzvestaja: e.value.naziv,
                        sifarnikTipovaParametraStatistickogIzvestaja: Endpoint.SIFARNIK_TIPOVA_PARAMETRA_STATISTICKOG_IZVESTAJA + "/" + e.value.id,
                      });
                    }}
                    optionLabel="naziv"
                    filter={true}
                    filterBy="naziv"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NUMERICKA_VREDNOST}</div>
                <div className="col-8">
                  <InputNumber
                    disabled={isFormDisabled(tipParametraStatistickogIzvestajaOperation)}
                    value={tipParametraStatistickogIzvestajaChange && tipParametraStatistickogIzvestajaChange.sqlNumVrednost ? tipParametraStatistickogIzvestajaChange.sqlNumVrednost : null}
                    onValueChange={(e: any) => {
                      setTipParametraStatistickogIzvestajaChange({
                        ...tipParametraStatistickogIzvestajaChange,
                        sqlNumVrednost: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_UPIT}</div>
                <div className="col-8">
                  <InputTextarea
                    disabled={isFormDisabled(tipParametraStatistickogIzvestajaOperation)}
                    value={tipParametraStatistickogIzvestajaChange && tipParametraStatistickogIzvestajaChange.upit ? tipParametraStatistickogIzvestajaChange.upit : ""}
                    onChange={(e: any) => {
                      setTipParametraStatistickogIzvestajaChange({
                        ...tipParametraStatistickogIzvestajaChange,
                        upit: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
