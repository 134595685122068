const Routers = {
  // main
  REGISTRI: "/registri",
  IZVESTAJI: "/izvestaji",
  PACIJENTI: "/pacijenti",
  PACIJENT_ARHIVA: "/pacijentArhiva",
  UNOSI: "/unosi",
  UNOS_ARHIVA: "/unosArhiva",
  UNOSI_NOVI: "/unosi/novi",
  KORISNICKI_PROFIL: "/korisnickiProfil",
  RELOAD: "/reload",
  LOGIN: "/login",

  // admin
  ADMIN_KATEGORIJE_IZVESTAJA: "/admin/kategorijeIzvestaja",
  ADMIN_IZVESTAJI: "/admin/izvestaji",
  ADMIN_TIPOVI_PARAMETARA_IZVESTAJA: "/admin/tipoviParametraIzvestaja",
  ADMIN_PARAMETRI: "/admin/parametri",
  ADMIN_RADNICI: "/admin/radnici",
  ADMIN_REGISTRI: "/admin/registri",
  ADMIN_REGISTAR: "/admin/registar",
  ADMIN_TIPOVI_IZVESTAJA: "/admin/tipoviStatistickogIzvestaja",
  ADMIN_ULOGE: "/admin/uloge",
  ADMIN_USTANOVE: "/admin/ustanove",
  ADMIN_FORMUALR: "/admin/formular",
  ADMIN_TIPOVI_ATRIBUTA: "/admin/tipoviAtributa",
  ADMIN_LOG: "/admin/log",
  ADMIN_STATISTIKA_UNOSA: "/admin/statistikaUnosa",
  ADMIN_UPOZORENJA: "/admin/upozorenja",
  ADMIN_VERZIJE: "/admin/verzije",
  ADMIN_SPONZORI: "/admin/sponzori",

  // reload
  RELOAD_JASPERI: "/reload/jasperi",
  RELOAD_KONEKCJIJA: "/reload/konekcija",
  RELOAD_PARAMETRI: "/reload/parametri",
};

export default Routers;
