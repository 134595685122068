import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Messages } from "primereact/messages";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import Routers from "../../../utils/Routers";

export default function CrudRegistarList() {
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRegistar, setSelectedRegistar] = useState<any>();
  const [registarList, setRegistarList] = useState();
  const { authData } = useContext(AppContext);

  let history = useHistory();
  const messages = useRef<any>(null);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axios
      .get(
        Endpoint.REGISTAR + "/search/findByAktivan",
        axiosConfig(authData.token, {
          page: 0,
          size: 50000000,
          aktivan: true,
        })
      )
      .then((registarCollection) => {
        setRegistarList(registarCollection.data._embedded.registars);
      });
  };

  const openRegistar = (entityOperation: string) => {
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        break;
    }

    const id = entityOperation !== EntityOperation.CREATE ? selectedRegistar.id : "";

    history.push({
      pathname: `${Routers.ADMIN_REGISTAR}/${id}`,
      state: { registarOperation: entityOperation },
    });
  };

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openRegistar(EntityOperation.CREATE)} />
      <Button disabled={!selectedRegistar} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openRegistar(EntityOperation.UPDATE)} />
      <Button disabled={!selectedRegistar} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openRegistar(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button disabled={!selectedRegistar} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openRegistar(EntityOperation.DELETE)} />
    </React.Fragment>
  );

  return (
    <div className="layout-role">
      <div className="col-12">
        <Messages ref={messages} />
      </div>
      <Panel header={Labels.TITLE_PANEL_REGISTARA} className="panel-roles-list">
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            filterDisplay="row"
            value={registarList}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedRegistar(e.data)}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="p-sortable-column" field={"sifra"} header={Labels.COLUMN_HEADER_SIFRA} filterMatchMode="contains" showFilterMenu={false} filter />
            <Column className="column-align-left" field={"opis"} header={Labels.COLUMN_HEADER_OPIS} filterMatchMode="contains" showFilterMenu={false} filter sortable />
          </DataTable>
        </div>
      </Panel>
    </div>
  );
}
