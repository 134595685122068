import axios from "axios";
import { useContext } from "react";
import { AppContext } from "../components/Store";
import Endpoint from "../endpoints/Endpoint";
import { axiosConfig } from "../utils/Utils";

interface RegistarControllerType {
  axiosRegistarById: Function;
  axiosParametarRegistarList: Function;
  axiosPretraga: Function;
  axiosTipSkupaAtributaByRegistar: Function;
  axisGetAtributUTipuSkupaByRegistar: Function;
  axiosBrojUnosaPoPacijentu: Function;
  axiosTipSkupaAtributaByTipSkupaAtributa: Function;
  axiosEnumStavkaByAUTS: Function;
  axiosAtributUTipuSkupaFindTVAByAUTS: Function;
}

export function RegistarController(): RegistarControllerType {
  const { authData } = useContext(AppContext);
  const { token } = authData;

  const registarID = JSON.parse(localStorage.getItem("odabraniRegistar") || "null");
  const registarParams = { registar: `${Endpoint.REGISTAR}/${registarID}` };

  const axiosRegistarById = () => axios.get(registarParams.registar, axiosConfig(token));

  const axiosParametarRegistarList = () => axios.get(Endpoint.PARAMETAR + "/registri", axiosConfig(token));

  const axiosPretraga = (paramsPretraga: any) => axios.post(Endpoint.PRETRAGA, { ...paramsPretraga, registarID: registarID }, axiosConfig(token));

  const axiosTipSkupaAtributaByRegistar = () => axios.get(Endpoint.TIP_SKUPA_ATRIBUTA + "/search/findTipSkupaAtributaByRegistar", axiosConfig(token, registarParams));

  const axisGetAtributUTipuSkupaByRegistar = () => axios.get(Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findAtributUTipuSkupaByRegistar", axiosConfig(token, registarParams));

  const axiosBrojUnosaPoPacijentu = (params: any) => axios.get(Endpoint.BROJ_UNOSA_PO_PACIJENTU, axiosConfig(token, params));

  const axiosTipSkupaAtributaByTipSkupaAtributa = (params: any) =>
    axios.get(Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findByTipSkupaAtributaAndAktivanZaPretraguOrderByRedosledAsc", axiosConfig(token, params));

  const axiosEnumStavkaByAUTS = (params: any) => axios.get(Endpoint.ENUM_STAVKA + "/search/findEnumStavkaByAUST", axiosConfig(token, params));

  const axiosAtributUTipuSkupaFindTVAByAUTS = (params: any) => axios.get(Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findTipVrednostiAtributaByAtributUTipuSkupa", axiosConfig(token, params));

  return {
    axiosRegistarById,
    axiosParametarRegistarList,
    axiosPretraga,
    axiosTipSkupaAtributaByRegistar,
    axisGetAtributUTipuSkupaByRegistar,
    axiosBrojUnosaPoPacijentu,
    axiosTipSkupaAtributaByTipSkupaAtributa,
    axiosEnumStavkaByAUTS,
    axiosAtributUTipuSkupaFindTVAByAUTS,
  };
}

// export type { RegistarControllerType };
