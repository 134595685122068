import React from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import Select from "react-select";
import { Dropdown } from "primereact/dropdown";

export default function Skupovni({ initValue, changeState, auts, tipSkupaAtributaKey, zaPretragu, enumiPoAUTS }) {
  const handleChange = (value) => {
    let stateArray = [
      ["initValue", tipSkupaAtributaKey, value],
      ["prikazAUTS", auts.sifra, value],
    ];
    changeState(stateArray);
  };
  const handleChangeDropdown = (value) => {
    let stateArray = [
      ["initValue", tipSkupaAtributaKey, value.value ? { label: value.target.name, value: value.target.value } : value.value],
      ["prikazAUTS", auts.sifra, value.target.value],
    ];
    changeState(stateArray);
  };
  const handleChangeMultiCheck = (selected) => {
    let initValueNew = initValue ? initValue : [];
    let postoji = false;
    if (!Array.isArray(initValueNew)) {
      initValueNew = [initValueNew];
    }

    initValueNew.forEach((init, i) => {
      if (init.value === selected.value) {
        postoji = true;
        return;
      }
    });
    if (postoji) {
      initValueNew = initValueNew.filter((init) => init.value !== selected.value);
    } else {
      initValueNew.push(selected);
    }
    handleChange(initValueNew);
  };

  let valueSkupovni = initValue ? initValue : null;
  let multi = auts.multiselect;
  if (zaPretragu) {
    multi = false;
  }
  let checkbox = false;
  let radio = false;
  if (multi) {
    checkbox = auts.imaCekBox;
  } else {
    radio = auts.radioButton;
  }

  if ((!checkbox && !radio) || zaPretragu) {
    const options = enumiPoAUTS?.map((autsEnum) => {
      return { label: autsEnum.naziv, value: autsEnum.id, numVrednost: autsEnum.numVrednost };
    });
    return (
      <div className="autsSkupovni">
        {zaPretragu ? (
          <Dropdown
            className="w-full"
            placeholder={Labels.PLACEHOLDER_ODABERITE_VREDNOST}
            filter
            showClear
            name="naziv"
            value={valueSkupovni ? valueSkupovni.value : undefined}
            onChange={(value) => handleChangeDropdown(value)}
            options={options}
          />
        ) : (
          <Select
            className="w-full"
            placeholder={Labels.PLACEHOLDER_ODABERITE_VREDNOST}
            isSearchable={true}
            isClearable={true}
            value={valueSkupovni}
            isMulti={multi}
            onChange={(value) => handleChange(value)}
            options={options}
          />
        )}
      </div>
    );
  } else if (multi && checkbox) {
    let initArray = valueSkupovni ? valueSkupovni : [];
    if (!Array.isArray(initArray)) {
      initArray = [initArray];
    }
    let multiCheckbox = <div></div>;
    if (enumiPoAUTS) {
      multiCheckbox = enumiPoAUTS.map((enumVre, e) => {
        let isChecked = initArray.length > 0 && initArray.find((init) => init.value === enumVre.id) && Object.keys(initArray.find((init) => init.value === enumVre.id)).length > 0;
        return (
          <div key={e}>
            <input type="checkbox" checked={isChecked} onChange={() => handleChangeMultiCheck({ label: enumVre.naziv, value: enumVre.id })} />
            <span className="cursor-pointer" onClick={() => handleChangeMultiCheck({ label: enumVre.naziv, value: enumVre.id })}>
              {enumVre.naziv}
            </span>
          </div>
        );
      });
    }
    return <div>{multiCheckbox}</div>;
  } else if (!multi && radio) {
    let multiRadio = <div></div>;
    if (enumiPoAUTS) {
      multiRadio = enumiPoAUTS.map((enumVre, e) => {
        return (
          <div key={e}>
            <input type="radio" checked={initValue && initValue[0] && initValue[0].value === enumVre.id} onChange={() => handleChange([{ label: enumVre.naziv, value: enumVre.id }])} />
            <span className="cursor-pointer" onClick={() => handleChange([{ label: enumVre.naziv, value: enumVre.id, numVrednost: enumVre.numVrednost }])}>
              {enumVre.naziv}
            </span>
          </div>
        );
      });
    }
    return <div>{multiRadio}</div>;
  }
}
