import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useContext } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import MessageType from "../../../infrastructure/MessageType";
import CrudParametriStatistickogIzvestaja from "./CrudParametriStatistickogIzvestaja";
import { isFormDisabled } from "../../../utils/Utils";
import { AppContext } from "../../Store";

interface CrudParametriStatistickogIzvestajaListProps {
  idStatistickiIzvestaj: string;
  statistickiIzvestajOperation: string;
}

export default function CrudParametriStatistickogIzvestajaList(props: CrudParametriStatistickogIzvestajaListProps) {
  const { idStatistickiIzvestaj, statistickiIzvestajOperation } = props;
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedParametarStatistickogIzvestaja, setSelectedParametarStatistickogIzvestaja] = useState();
  const [parametriStatistickogIzvestajaList, setParametriStatistickogIzvestajaList] = useState<any>();
  const [entityOperation, setEntityOperation] = useState<string>("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [parametarStatistickogIzvestajaToChange, setParametarStatistickogIzvestajaToChange] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let dataList: any = [];
    if (idStatistickiIzvestaj) {
      const requestParametriStatistickogIzvestaja = axios.get(
        Endpoint.PARAMETAR_STATISTICKI_IZVESTAJ + "/search/findByAktivanAndStatistickiIzvestaj",
        axiosConfig(authData.token, {
          statistickiIzvestaj: Endpoint.STATISTICKI_IZVESTAJ + "/" + idStatistickiIzvestaj,
          page: 0,
          size: 50000000,
          aktivan: true,
          projection: "getWithRelatedObjects",
        })
      );
      axios.all([requestParametriStatistickogIzvestaja]).then(
        axios.spread((responseParametriStatistickogIzvestaja) => {
          const parametriIzvestaja = responseParametriStatistickogIzvestaja.data._embedded.parametarStatistickiIzvestajs;
          parametriIzvestaja.forEach((parametar: any) => {
            const object = {
              naziv: parametar.parametarStatistickiIzvestaj.naziv,
              prikazniRedosled: parametar.parametarStatistickiIzvestaj.prikazniRedosled,
              multiSelect: parametar.parametarStatistickiIzvestaj.multiSelect,
              opcioni: parametar.parametarStatistickiIzvestaj.opcioni,
              requestNaziv: parametar.parametarStatistickiIzvestaj.requestNaziv,
              nazivTipaParametraStatistickogIzvestaja: parametar.tipParametraStatistickogIzvestaja.naziv,
              id: parametar.parametarStatistickiIzvestaj.id,
              statistickiIzvestaj: Endpoint.STATISTICKI_IZVESTAJ + "/" + idStatistickiIzvestaj,
            };
            dataList.push(object);
          });
          setParametriStatistickogIzvestajaList(dataList);
        })
      );
    }
  };

  const createParametarStatistickogIzvestaja = (parametarStatistickogIzvestaja: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.PARAMETAR_STATISTICKI_IZVESTAJ, parametarStatistickogIzvestaja, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO, parametarStatistickogIzvestaja.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateParametarStatistickogIzvestaja = (parametarStatistickogIzvestaja: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(Endpoint.PARAMETAR_STATISTICKI_IZVESTAJ + `/${parametarStatistickogIzvestaja.id}`, parametarStatistickogIzvestaja, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, parametarStatistickogIzvestaja.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteParametarStatistickogIzvestaja = (parametarStatistickogIzvestaja: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(Endpoint.PARAMETAR_STATISTICKI_IZVESTAJ + `/${parametarStatistickogIzvestaja.id}`, { aktivan: false }, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO, parametarStatistickogIzvestaja.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };
  const dialogHeader = () => {
    const display = parametarStatistickogIzvestajaToChange ? parametarStatistickogIzvestajaToChange.naziv : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_PARAMETRA_STATISTICKOG_IZVESTAJA;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_PARAMETRA_STATISTICKOG_IZVESTAJA + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_PARAMETRA_STATISTICKOG_IZVESTAJA + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_PARAMETRA_STATISTICKOG_IZVESTAJA + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedParametarStatistickogIzvestaja;
        break;
    }
    setEntityOperation(entityOperation);
    setParametarStatistickogIzvestajaToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setDisplayDialog(false);
    setSelectedParametarStatistickogIzvestaja(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => closeDialog();
  const toolbarLeftContent = () => {
    return (
      <>
        <Button
          disabled={isFormDisabled(statistickiIzvestajOperation)}
          label={Labels.BUTTON_KREIRAJ}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => openDialog(EntityOperation.CREATE)}
        />
        <Button
          disabled={!selectedParametarStatistickogIzvestaja || isFormDisabled(statistickiIzvestajOperation)}
          label={Labels.BUTTON_IZMENI}
          icon="pi pi-upload"
          className="p-button-warning"
          onClick={() => openDialog(EntityOperation.UPDATE)}
        />
        <Button disabled={!selectedParametarStatistickogIzvestaja} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
      </>
    );
  };
  const toolbarRightContent = () => {
    return (
      <>
        <Button
          disabled={!selectedParametarStatistickogIzvestaja || isFormDisabled(statistickiIzvestajOperation)}
          label={Labels.BUTTON_OBRISI}
          icon="pi pi-inbox"
          className="p-button-danger"
          onClick={() => openDialog(EntityOperation.DELETE)}
        />
        ;
      </>
    );
  };

  return (
    <div>
      <Toolbar left={toolbarLeftContent} right={toolbarRightContent} />
      <DataTable
        filterDisplay="row"
        value={parametriStatistickogIzvestajaList}
        paginator={true}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
        rows={20}
        rowsPerPageOptions={[5, 10, 20]}
        selectionMode="single"
        selection={selectedRow}
        onSelectionChange={(e) => setSelectedRow(e.value)}
        onRowSelect={(e) => {
          setSelectedParametarStatistickogIzvestaja(e.data);
        }}
        alwaysShowPaginator={false}
        emptyMessage={Labels.LABEL_NEMA_PODATAKA}
      >
        <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filterMatchMode="contains" showFilterMenu={false} filter sortable />
        <Column className="column-align-left" field={"prikazniRedosled"} header={Labels.COLUMN_HEADER_REDOSLED_PRIKAZA} filterMatchMode="contains" showFilterMenu={false} filter sortable />
      </DataTable>
      <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }}>
        <CrudParametriStatistickogIzvestaja
          parametarStatistickogIzvestajaOperation={entityOperation}
          parametarStatistickogIzvestaja={parametarStatistickogIzvestajaToChange}
          onCreateParametarStatistickogIzvestaja={createParametarStatistickogIzvestaja}
          onUpdateParametarStatistickogIzvestaja={updateParametarStatistickogIzvestaja}
          onDeleteParametarStatistickogIzvestaja={deleteParametarStatistickogIzvestaja}
          onCancel={onCancel}
          idStatistickogIzvestaja={idStatistickiIzvestaj}
        />
      </Dialog>
    </div>
  );
}
