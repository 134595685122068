import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/nova/theme.css";
import React from "react";
import ReactDOM from "react-dom";
import "react-notifications/lib/notifications.css";
import App from "./App";
import "./css/collapsible.css";
import "./css/izvestaj.css";
import "./css/layout/layout.scss";
import "./css/multiWrapper.css";
import "./fontawesome/css/all.css";
import "./index.css";
import "./main.css";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
