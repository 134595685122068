import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { Row } from "primereact/row";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useEffect, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig, CALENDAR_DATE_FORMAT, DATE_FORMAT } from "../../../utils/Utils";
import { AppContext } from "../../Store";

export default function StatistikaUnosa() {
  const [datumOd, setDatumOd] = useState();
  const [datumDo, setDatumDo] = useState<any>(null);
  const [radnikSelected, setRadnikSelected] = useState<any>([]);
  const [registarSelected, setRegistarSelected] = useState<any>();
  const [ustanovaSelected, setUstanovaSelected] = useState<any>([]);
  const [registarList, setRegistarList] = useState();
  const [radnikList, setRadnikList] = useState();
  const [ustanovaList, setUstanovaList] = useState();
  const [prviUnosNaziv, setPrviUnosNaziv] = useState("");
  const [columnsStatistikaUnosaData, setColumnsStatistikaUnosaData] = useState();
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffect(() => {
    const requestRegistar = axios.get(Endpoint.REGISTAR + "/search/findByAktivan", axiosConfig(authData.token, { page: 0, size: 50000000, aktivan: true }));
    const requestUstanova = axios.get(Endpoint.USTANOVA + "/search/findByAktivan", axiosConfig(authData.token, { page: 0, size: 50000000, aktivan: true }));
    const requestRadnik = axios.get(Endpoint.RADNIK + "/search/findByAktivan", axiosConfig(authData.token, { page: 0, size: 50000000, aktivan: true }));

    axios
      .all([requestRegistar, requestUstanova, requestRadnik])
      .then(
        axios.spread((responseRegistar: any, responseUstanova: any, responseRadnik: any) => {
          setRegistarList(responseRegistar.data._embedded.registars);
          setRadnikList(responseRadnik.data._embedded.radniks);
          setUstanovaList(responseUstanova.data._embedded.ustanovas);
        })
      )
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, error);
      });
  }, [authData.token]);

  const validateInput = () => {
    if (!datumOd) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_PRETRAGE, Labels.MESSAGES_POLJE_DATUM_OD);
      return false;
    }
    if (moment(datumOd).format("YYYY-MM-DD").localeCompare(moment(datumDo).format("YYYY-MM-DD")) === 1) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_PRETRAGE, Labels.MESSAGES_POREDJENJE_DATUMA);
      return false;
    }
    if (!registarSelected) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_PRETRAGE, Labels.MESSAGES_POLJE_REGISTAR);
      return false;
    }

    return true;
  };

  const pretraga = () => {
    if (!validateInput()) {
      return;
    }

    let ustanovaArray = ustanovaSelected.map((x: any) => x.id);
    let radniciArray = radnikSelected.map((x: any) => x.id);

    axios
      .get(
        Endpoint.LOG + "/statistikaUnosaPodataka/",
        axiosConfig(authData.token, {
          registarID: registarSelected.id,
          datumOd: moment(datumOd).format(DATE_FORMAT),
          datumDo: moment(datumDo).format(DATE_FORMAT),
          ustanovaArray: ustanovaArray.toString(),
          radniciArray: radniciArray.toString(),
        })
      )
      .then((response) => {
        setPrviUnosNaziv(response.data.prviUnos);
        setColumnsStatistikaUnosaData(response.data.vrednost);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let ustanovaArray = ustanovaSelected.map((x: any) => x.id);
    if (ustanovaArray.length > 0) {
      axios
        .get(
          Endpoint.RADNIK_ZA_USTANOVU,
          axiosConfig(authData.token, {
            ustanovaArray: ustanovaArray.toString(),
          })
        )
        .then((response) => {
          let radnikData = response.data.map((radnik: any) => {
            return {
              imePrezime: radnik.label,
              id: radnik.value,
            };
          });
          setRadnikList(radnikData);
          setRadnikSelected([]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [ustanovaSelected, authData.token]);

  const selectedInstitutionTemplate = (option: any) => {
    return <span>{option ? option.naziv + ", " : Labels.PLACEHOLDER_USTANOVA} </span>;
  };

  const selectedWorkerTemplate = (option: any) => {
    return <span>{option ? option.imePrezime + ", " : Labels.PLACEHOLDER_RADNIK} </span>;
  };

  let headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="#" colSpan={1} />
        <Column className="column-width-170px" header={Labels.COLUMN_HEADER_RADNIK} colSpan={1} />
        <Column header={Labels.COLUMN_HEADER_UNETI_PACIJENTI} colSpan={4} />
        {prviUnosNaziv !== "" && <Column header={prviUnosNaziv} colSpan={4} />}
        <Column header={prviUnosNaziv !== "" ? Labels.COLUMN_HEADER_OSTALI_UNOSI : Labels.COLUMN_HEADER_POPUNJENI_FORMULARI} colSpan={4} />
        <Column header={Labels.COLUMN_HEADER_UNETI_ODGOVORI} colSpan={4} />
      </Row>
      <Row>
        <Column header={Labels.COLUMN_HEADER_RB} sortable sortField="redniBroj" />
        <Column header={Labels.COLUMN_HEADER_IME_I_PREZIME} sortable sortField="radnik" />
        <Column header={Labels.COLUMN_HEADER_KREIRANJE} sortable sortField="kreiranjePacijenti" />
        <Column header={Labels.COLUMN_HEADER_IZMENA} sortable sortField="izmenaPacijenti" />
        <Column header={Labels.COLUMN_HEADER_BRISANJE} sortable sortField="brisanjePacijenti" />
        <Column header={Labels.COLUMN_HEADER_SUMA} sortable sortField="sumaPacijenti" />
        {prviUnosNaziv !== "" && <Column header={Labels.COLUMN_HEADER_KREIRANJE} sortable sortField="kreiranjePrvi" />}
        {prviUnosNaziv !== "" && <Column header={Labels.COLUMN_HEADER_IZMENA} sortable sortField="izmenaPrvi" />}
        {prviUnosNaziv !== "" && <Column header={Labels.COLUMN_HEADER_BRISANJE} sortable sortField="obrisaniPrvi" />}
        {prviUnosNaziv !== "" && <Column header={Labels.COLUMN_HEADER_SUMA} sortable sortField="sumaPrvi" />}
        <Column header={Labels.COLUMN_HEADER_KREIRANJE} sortable sortField="kreiranjeOstali" />
        <Column header={Labels.COLUMN_HEADER_IZMENA} sortable sortField="izmenaOstali" />
        <Column header={Labels.COLUMN_HEADER_BRISANJE} sortable sortField="brisanjeOstali" />
        <Column header={Labels.COLUMN_HEADER_SUMA} sortable sortField="sumaOstali" />
        <Column header={Labels.COLUMN_HEADER_KREIRANJE} sortable sortField="kreiranjeOdgovori" />
        <Column header={Labels.COLUMN_HEADER_IZMENA} sortable sortField="izmenaOdgovori" />
        <Column header={Labels.COLUMN_HEADER_BRISANJE} sortable sortField="brisanjeOdgovori" />
        <Column header={Labels.COLUMN_HEADER_SUMA} sortable sortField="sumaOdgovori" />
      </Row>
    </ColumnGroup>
  );

  const leftContents = () => (
    <React.Fragment>
      <Calendar
        showIcon
        placeholder={Labels.PLACEHOLDER_DATUM_OD}
        dateFormat={CALENDAR_DATE_FORMAT}
        value={datumOd}
        onChange={(e: any) => {
          setDatumOd(e.value);
        }}
      />
      &nbsp;
      <Calendar
        showIcon
        placeholder={Labels.PLACEHOLDER_DATUM_DO}
        dateFormat={CALENDAR_DATE_FORMAT}
        value={datumDo}
        onChange={(e: any) => {
          setDatumDo(e.value);
        }}
      />
      &nbsp;
      <Dropdown
        filter
        showClear
        placeholder={Labels.PLACEHOLDER_REGISTAR}
        optionLabel="naziv"
        dataKey="id"
        value={registarSelected}
        options={registarList}
        onChange={(e: any) => {
          setRegistarSelected(e.value);
        }}
      />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      &nbsp;
       <MultiSelect
          selectedItemTemplate={selectedInstitutionTemplate}
          filter
          optionLabel="naziv"
          dataKey="id"
          value={ustanovaSelected}
          options={ustanovaList}
          maxSelectedLabels={3}
          selectedItemsLabel={Labels.MULTISELECT_USTANOVA}
          onChange={(e: any) => {
            setUstanovaSelected(e.value);
          }}
        />
        &nbsp;
        <MultiSelect
          selectedItemTemplate={selectedWorkerTemplate}
          filter
          optionLabel="imePrezime"
          dataKey="id"
          value={radnikSelected}
          options={radnikList}
          maxSelectedLabels={3}
          selectedItemsLabel={Labels.MULTISELECT_RADNIK}
          onChange={(e: any) => {
            setRadnikSelected(e.value);
          }}
        />
        &nbsp;
        <Button className="p-button-danger" label={Labels.BUTTON_PRETRAGA} icon="pi pi-search" iconPos="left" onClick={pretraga} />
    </React.Fragment>
  );

  return (
    <div className="layout-log">
      <Panel header={Labels.TITLE_PANEL_STATISTIKA_UNOSA_PODATAKA} className="panel-web-service-call-log-list">
        <Toolbar left={leftContents} right={rightContents} />
        <DataTable
          headerColumnGroup={headerGroup}
          value={columnsStatistikaUnosaData}
          paginator={true}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
          rows={20}
          rowsPerPageOptions={[5, 10, 20]}
          selectionMode="single"
          alwaysShowPaginator={false}
          emptyMessage={Labels.LABEL_NEMA_PODATAKA}
        >
          <Column field={"redniBroj"} />
          <Column field={"radnik"} />
          <Column field={"kreiranjePacijenti"} />
          <Column field={"izmenaPacijenti"} />
          <Column field={"brisanjePacijenti"} />
          <Column field={"sumaPacijenti"} />
          {prviUnosNaziv !== "" && <Column field={"kreiranjePrvi"} />}
          {prviUnosNaziv !== "" && <Column field={"izmenaPrvi"} />}
          {prviUnosNaziv !== "" && <Column field={"obrisaniPrvi"} />}
          {prviUnosNaziv !== "" && <Column field={"sumaPrvi"} />}
          <Column field={"kreiranjeOstali"} />
          <Column field={"izmenaOstali"} />
          <Column field={"brisanjeOstali"} />
          <Column field={"sumaOstali"} />
          <Column field={"kreiranjeOdgovori"} />
          <Column field={"izmenaOdgovori"} />
          <Column field={"brisanjeOdgovori"} />
          <Column field={"sumaOdgovori"} />
        </DataTable>
      </Panel>
    </div>
  );
}
