import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Endpoint from "../endpoints/Endpoint";
import { axiosConfig, useEffectOnce } from "../utils/Utils";
import { AppContext } from "./Store";
import { Tree } from "primereact/tree";
import NewParametri from "./izvestaj/NewParametri";
import EnumTipovaParametraStatistickogIzvestaja from "../utils/EnumTipovaParametraStatistickogIzvestaja";

export default function NewIzvestaji() {
  const { authData } = useContext(AppContext);
  const [kategorijeIzvestajaTreeData, setKategorijeIzvestajaTreeData] = useState();
  const [displayTabView, setDisplayTabView] = useState(false);
  const [selectOptions, setSelectOptions] = useState({});
  const [parametriStatistickiIzvestaj, setParametriStatistickiIzvestaj] = useState();
  const [statistickiIzvestaj, setStatistickiIzvestaj] = useState();
  const [idKategorijaStatistickogIzvestaja, setIdKategorijaStatistickogIzvestaja] = useState<any>();

  useEffectOnce(() => {
    getData();
  });

  const getData = () => {
    axios
      .get(Endpoint.KATEGORIJA_STATISTICKOG_IZVESTAJA_TREE, axiosConfig(authData.token))
      .then((response) => {
        response.data.forEach((kat: any) => {
          kat.children.forEach((tipStatIzv: any) => {
            tipStatIzv.children.forEach((statIzvestaj: any) => {
              statIzvestaj.idKategorijaStatistickiIzvestaj = kat.key;
            });
            tipStatIzv.key = kat.key + "-" + tipStatIzv.key;
          });
        });
        setKategorijeIzvestajaTreeData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openStatistickiIzvestaj = (izvestaj: any) => {
    axios
      .get(
        Endpoint.PARAMETAR_STATISTICKI_IZVESTAJ + "/search/findByAktivanAndStatistickiIzvestaj",
        axiosConfig(authData.token, {
          statistickiIzvestaj: Endpoint.STATISTICKI_IZVESTAJI + "/" + (izvestaj.key ? izvestaj.key : izvestaj.id),
          aktivan: true,
          projection: "getWithRelatedObjects",
        })
      )
      .then((response) => {
        let paramStatIzve = response.data._embedded.parametarStatistickiIzvestajs;
        paramStatIzve.forEach((param: any, i: any) => {
          if (param.sifarnikTipovaParametraStatistickogIzvestaja.numerickaVrednost === EnumTipovaParametraStatistickogIzvestaja.SKUPOVNI && param.tipParametraStatistickogIzvestaja.upit !== "") {
            axios
              .get(Endpoint.EXECUTE_QUERY, axiosConfig(authData.token, { query: param.tipParametraStatistickogIzvestaja.upit }))
              .then((response) => {
                var object = response.data;
                var mapa: any = selectOptions;
                let options = object.map((obj: any, o: any) => {
                  let key = obj[0];
                  let naziv = obj[1];
                  let numVrednost = obj[2] ? obj[2] + " - " : "";
                  return { value: key, label: numVrednost + naziv };
                });
                mapa[param.parametarStatistickiIzvestaj.id] = options;
                setSelectOptions(mapa);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });

        setParametriStatistickiIzvestaj(paramStatIzve);
        setStatistickiIzvestaj(izvestaj);
      })
      .catch((error) => {
        console.log(error);
      });
    setDisplayTabView(true);
  };

  return (
    <div className="layout-report grid">
      <div className="col-3 fluid tree-view-report">
        <Tree
          value={kategorijeIzvestajaTreeData}
          selectionMode="single"
          onSelect={(e: any) => {
            if (!e.node.children) {
              setIdKategorijaStatistickogIzvestaja(e.node.idKategorijaStatistickiIzvestaj);
              openStatistickiIzvestaj(e.node);
            }
          }}
        />
      </div>
      {displayTabView && parametriStatistickiIzvestaj && (
        <div className="col-9 p-fluid">
          <NewParametri
            idKategorijaStatistickogIzvestaja={idKategorijaStatistickogIzvestaja}
            skupovniParametri={selectOptions}
            propsParametri={parametriStatistickiIzvestaj}
            statistickiIzvestaj={statistickiIzvestaj}
            openStatistickiIzvestaj={openStatistickiIzvestaj}
          />
        </div>
      )}
    </div>
  );
}
