import axios from "axios";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Endpoint from "../../endpoints/Endpoint";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { axiosConfig, useEffectOnce } from "../../utils/Utils";
import { AppContext } from "../Store";

export default function UnosArhiva() {
  const [unos, setUnos] = useState<any>();
  const [selectedAuts, setSelectedAuts] = useState([]);
  const [autsSvi, setAutsSvi] = useState([]);
  const [columnsUnosVrednosti, setColumnsUnosVrednosti] = useState([]);
  const { showGrowlMessage, authData } = useContext(AppContext);
  const { number } = useParams<any>();

  useEffectOnce(() => {
    axios
      .get(
        Endpoint.PRIKAZ_UNOSA,
        axiosConfig(authData.token, {
          unosID: number,
        })
      )
      .then((response) => {
        setUnos(response.data);
      })
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_DOHVATANJA_PODATAKA_O_UNOSU, error);
      });

    axios
      .get(
        Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findAtributUTipuSkupaBySkupVrednostiAtributa/",
        axiosConfig(authData.token, {
          skupVrednostiAtributa: Endpoint.SKUP_VREDNOSTI_ATRIBUTA + "/" + number,
        })
      )
      .then((response) => {
        let autsArray = response.data._embedded.atributUTipuSkupas.map((auts: { naziv: any; id: any }) => {
          return { label: auts.naziv, value: { label: auts.naziv, value: auts.id } };
        });
        setAutsSvi(autsArray);
      })
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_DOHVATANJA_PODATAKA_O_UNOSU, error);
      });
  });

  const pretraga = () => {
    if (selectedAuts.length === 0) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_MORATE_ODABRATI_BAR_JEDNU_STAVKU_UNOSA, "");
      return;
    }

    let nizAutsID = selectedAuts.map((obj: any) => {
      return obj.value;
    });

    axios
      .get(
        Endpoint.GET_VREDNOST_ATRIBUTA_ARHIVA,
        axiosConfig(authData.token, {
          unosID: number,
          nizAutsID: nizAutsID.toString(),
        })
      )
      .then((response) => {
        setColumnsUnosVrednosti(response.data);
      })
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_DOHVATANJA_PODATAKA_O_ARHIVI_UNOSA, error);
      });
  };

  const handleChange = (e: React.SetStateAction<never[]>) => {
    setSelectedAuts(e);
  };

  return (
    <div>
      <div className="layout-versions">
        {unos && (
          <Card title={unos.nazivUnosa}>
            <Link to={`/unosi/${number}`}>
              <Button icon="pi pi-undo" className="p-button-danger" label="Nazad na unos"></Button>
            </Link>
            <br />
            <br />
            <MultiSelect placeholder="Odaberite stavku unosa" options={autsSvi} value={selectedAuts} onChange={(e) => handleChange(e.value)} filter></MultiSelect>
            &nbsp;
            <Button icon="pi pi-search" className="p-button-danger" label="Pretraga" onClick={(e) => pretraga()}></Button>
          </Card>
        )}
      </div>
      <br />
      <div className="layout-versions">
        <Panel header={Labels.TITLE_PANEL_PROMENE_TEKUCEG_UNOSA} className="panel-versions">
          <DataTable value={[unos]}>
            <Column className="column-align-center" field={"pacijent"} header={Labels.COLUMN_HEADER_PACIJENT} />
            <Column className="column-align-center" field={"odgovoranZaSadrzaj"} header={Labels.COLUMN_HEADER_ODGOVORAN_ZA_SADRZAJ} />
            <Column className="column-align-center" field={"datumKreiranja"} header={Labels.COLUMN_HEADER_DATUM_KREIRANJA} />
            <Column className="column-align-center" field={"radnikKreirao"} header={Labels.COLUMN_HEADER_KREIRAO} />
            <Column className="column-align-center" field={"radnikPoslednjiIzmenio"} header={Labels.COLUMN_HEADER_POSLEDNJI_IZMENIO} />
            <Column className="column-align-center" field={"kreirano"} header={Labels.COLUMN_HEADER_KREIRANO} />
            <Column className="column-align-center" field={"vremePoslednjeIzmene"} header={Labels.COLUMN_HEADER_POSLEDNJA_PROMENA} />
            <Column className="column-align-center" field={"aktivan"} header={Labels.COLUMN_HEADER_AKTIVAN} />
          </DataTable>
        </Panel>
      </div>
      <br />
      <br />
      <div className="layout-versions">
        <Panel header={Labels.TITLE_PANEL_PROMENA_VREDNOSTI} className="panel-vrednosti">
          <DataTable
            value={columnsUnosVrednosti}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={10}
            rowsPerPageOptions={[5, 10, 20]}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NE_POSTOJI_NIJEDNA_VREDNOST_STAVKE_U_ARHIVI}
          >
            <Column className="column-align-center" field={"vremePrestankaVazenja"} header={Labels.COLUMN_HEADER_PRESTANAK_VAZENJA} />
            <Column className="column-align-center" field={"nazivAtributa"} header={Labels.COLUMN_HEADER_NAZIV_ATRIBUTA} />
            <Column className="column-align-center" field={"nabrojivaVrednost"} header={Labels.COLUMN_HEADER_NABROJIVA_VREDNOST} />
            <Column className="column-align-center" field={"logickaVrednost"} header={Labels.COLUMN_HEADER_LOGICKA_VREDNOST} />
            <Column className="column-align-center" field={"tekstVrednost"} header={Labels.COLUMN_HEADER_TEKST_VREDNOST} />
            <Column className="column-align-center" field={"brojnaVrednost"} header={Labels.COLUMN_HEADER_BROJNA_VREDNOST} />
            <Column className="column-align-center" field={"datumskaVrednost"} header={Labels.COLUMN_HEADER_DATUMSKA_VREDNOST} />
            <Column className="column-align-center" field={"vremeKreiranja"} header={Labels.COLUMN_HEADER_VREME_KREIRANJA} />
            <Column className="column-align-center" field={"vremePoslednjeIzmene"} header={Labels.COLUMN_HEADER_VREME_POSLEDNJE_IZMENE} />
          </DataTable>
        </Panel>
      </div>
    </div>
  );
}
