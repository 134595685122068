let objectBrojni = [
	{value: 1, label : ">"},
	{value: 2, label : "<"},
	{value: 3, label : "="},
	{value: 4, label : "nije ="},
	{value: 5, label : "<="},
	{value: 6, label : ">="}
];

let objectTekstualni = [
	{value: 1, label : "="},
	{value: 2, label : "nije ="},
	{value: 3, label : "sadrži"},
	{value: 4, label : "ne sadrži"},
	{value: 5, label : "počinje sa"},
	{value: 6, label : "završava se"}
];

let objectIzborni = [
	{value: 1, label : "izabrano"},
	{value: 2, label : "nije izabrano"}
];

let objectAndOr = [
	{value: true, label : 'I'},
	{value: false, label : 'ILI'}
];

let tipVrednostiAtributa = [
	{numericka_vrednost: 1, naziv: "Izborni", },
	{numericka_vrednost: 2, naziv: "Brojni"},
	{numericka_vrednost: 3, naziv: "Tektualni"},
	{numericka_vrednost: 4, naziv: "Skupovni"},
	{numericka_vrednost: 5, naziv: "Datumski"},
	{numericka_vrednost: 6, naziv: "Naslov"},
	{numericka_vrednost: 7, naziv: "Separator"},
	{numericka_vrednost: 8, naziv: "Panel"},
	{numericka_vrednost: 11, naziv: "Tab"},
	{numericka_vrednost: 12, naziv: "Grafik"},
]

function getObjectOperator(numerickaVrednost) {
	if ([1, 4].includes(numerickaVrednost)) {
		return objectIzborni;
	} else if ([2, 5].includes(numerickaVrednost)) {
		return objectBrojni;
	} else if ([3, 6].includes(numerickaVrednost)) {
		return objectTekstualni;
	}
}

module.exports = {
		getObjectOperator : getObjectOperator,
		tipVrednostiAtributa : tipVrednostiAtributa,
		objectAndOr : objectAndOr
};
