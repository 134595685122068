import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useContext } from "react";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { axiosConfig } from "../../../utils/Utils";
import Endpoint from "../../../endpoints/Endpoint";
import { InputSwitch } from "primereact/inputswitch";
import MessageType from "../../../infrastructure/MessageType";
import { AppContext } from "../../Store";

interface CrudParametriStatistickogIzvestajaProps {
  parametarStatistickogIzvestajaOperation: string;
  parametarStatistickogIzvestaja?: any;
  onCreateParametarStatistickogIzvestaja: Function;
  onUpdateParametarStatistickogIzvestaja: Function;
  onDeleteParametarStatistickogIzvestaja: Function;
  onCancel: () => void;
  idStatistickogIzvestaja: string;
}

export default function CrudParametriStatistickogIzvestaja(props: CrudParametriStatistickogIzvestajaProps) {
  const {
    parametarStatistickogIzvestajaOperation,
    parametarStatistickogIzvestaja,
    onCreateParametarStatistickogIzvestaja,
    onUpdateParametarStatistickogIzvestaja,
    onDeleteParametarStatistickogIzvestaja,
    onCancel,
    idStatistickogIzvestaja,
  } = props;
  const [parametarStatistickogIzvestajaChange, setParametarStatistickogIzvestajaChange] = useState<Partial<any>>(parametarStatistickogIzvestaja);
  const [tipoviParametraStatistickogIzvestaja, setTipoviParametraStatistickogIzvestaja] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    axios
      .get(
        Endpoint.TIP_PARAMETRA_STATISTICKOG_IZVESTAJA + "/search/findByAktivan",
        axiosConfig(authData.token, {
          page: 0,
          size: 50000000,
          aktivan: true,
        })
      )
      .then((tipoviParametaraStatistickogIzvestajaCollection) => {
        setTipoviParametraStatistickogIzvestaja(tipoviParametaraStatistickogIzvestajaCollection.data._embedded.tipParametraStatistickogIzvestajas);
      });
    if (!parametarStatistickogIzvestaja) {
      setParametarStatistickogIzvestajaChange({
        ...parametarStatistickogIzvestajaChange,
        statistickiIzvestaj: Endpoint.STATISTICKI_IZVESTAJI + "/" + idStatistickogIzvestaja,
        opcioni: false,
        multiSelect: false,
      });
    } else {
      setParametarStatistickogIzvestajaChange({
        ...parametarStatistickogIzvestajaChange,
        statistickiIzvestaj: Endpoint.STATISTICKI_IZVESTAJI + "/" + idStatistickogIzvestaja,
      });
    }
  });

  const validateInput = (message: string) => {
    if (!parametarStatistickogIzvestajaChange || !parametarStatistickogIzvestajaChange.naziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    if (!parametarStatistickogIzvestajaChange || !parametarStatistickogIzvestajaChange.nazivTipaParametraStatistickogIzvestaja) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_TIP_PARAMETRA_STATISTICKOG_IZVESTAJA);
      return false;
    }
    if (!parametarStatistickogIzvestajaChange || !parametarStatistickogIzvestajaChange.requestNaziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_REQUEST_NAZIV);
      return false;
    }
    if (!parametarStatistickogIzvestajaChange || !parametarStatistickogIzvestajaChange.prikazniRedosled) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_REDOSLED_PRIKAZA);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
      return;
    }
    onCreateParametarStatistickogIzvestaja(parametarStatistickogIzvestajaChange)
      .then(() => {})
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
      });
  };

  const onUpdate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
      return;
    }
    onUpdateParametarStatistickogIzvestaja(parametarStatistickogIzvestajaChange)
      .then(() => {})
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
      });
  };

  const onDelete = () => {
    onDeleteParametarStatistickogIzvestaja(parametarStatistickogIzvestajaChange)
      .then(() => {})
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
      });
  };

  const toolbarLeftContent = () => {
    return (
      <>
        {parametarStatistickogIzvestajaOperation === EntityOperation.CREATE && <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-save" className="p-button-success" onClick={onCreate} />}
        {parametarStatistickogIzvestajaOperation === EntityOperation.UPDATE && <Button label={Labels.BUTTON_IZMENI} icon="pi pi-inbox" className="p-button-warning" onClick={onUpdate} />}
        {parametarStatistickogIzvestajaOperation === EntityOperation.DELETE && <Button label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={onDelete} />}
      </>
    );
  };
  const toolbarRightContent = () => {
    return <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />;
  };

  return (
    <div className="panel-report">
      <TabView renderActiveOnly={false}>
        <TabPanel header={Labels.TAB_PARAMETAR_STATISTICKOG_IZVESTAJA}>
          <div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NAZIV}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(parametarStatistickogIzvestajaOperation)}
                    value={parametarStatistickogIzvestajaChange && parametarStatistickogIzvestajaChange.naziv ? parametarStatistickogIzvestajaChange.naziv : ""}
                    onChange={(e: any) => {
                      setParametarStatistickogIzvestajaChange({
                        ...parametarStatistickogIzvestajaChange,
                        naziv: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_TIP_PARAMETRA_STATISTICKOG_IZVESTAJA}</div>
                <div className="col-8">
                  <Dropdown
                    disabled={isFormDisabled(parametarStatistickogIzvestajaOperation)}
                    dataKey="id"
                    value={
                      parametarStatistickogIzvestajaChange && parametarStatistickogIzvestajaChange.nazivTipaParametraStatistickogIzvestaja
                        ? parametarStatistickogIzvestajaChange.nazivTipaParametraStatistickogIzvestaja
                        : ""
                    }
                    options={tipoviParametraStatistickogIzvestaja}
                    editable={true}
                    onChange={(e) => {
                      setParametarStatistickogIzvestajaChange({
                        ...parametarStatistickogIzvestajaChange,
                        nazivTipaParametraStatistickogIzvestaja: e.value.naziv,
                        tipParametraStatistickogIzvestaja: Endpoint.TIP_PARAMETRA_STATISTICKOG_IZVESTAJA + "/" + e.value.id,
                      });
                    }}
                    optionLabel="naziv"
                    filter={true}
                    filterBy="naziv"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_REQUEST_NAZIV}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(parametarStatistickogIzvestajaOperation)}
                    value={parametarStatistickogIzvestajaChange && parametarStatistickogIzvestajaChange.requestNaziv ? parametarStatistickogIzvestajaChange.requestNaziv : ""}
                    onChange={(e: any) => {
                      setParametarStatistickogIzvestajaChange({
                        ...parametarStatistickogIzvestajaChange,
                        requestNaziv: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_REDOSLED_PRIKAZA}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(parametarStatistickogIzvestajaOperation)}
                    value={parametarStatistickogIzvestajaChange && parametarStatistickogIzvestajaChange.prikazniRedosled ? parametarStatistickogIzvestajaChange.prikazniRedosled : ""}
                    onChange={(e: any) => {
                      setParametarStatistickogIzvestajaChange({
                        ...parametarStatistickogIzvestajaChange,
                        prikazniRedosled: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_OPCIONI}</div>
                <div className="col-8">
                  <InputSwitch
                    disabled={isFormDisabled(parametarStatistickogIzvestajaOperation)}
                    checked={parametarStatistickogIzvestajaChange && parametarStatistickogIzvestajaChange.opcioni ? parametarStatistickogIzvestajaChange.opcioni : false}
                    onChange={(e: any) => {
                      setParametarStatistickogIzvestajaChange({
                        ...parametarStatistickogIzvestajaChange,
                        opcioni: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_MULTI_SELECT}</div>
                <div className="col-8">
                  <InputSwitch
                    disabled={isFormDisabled(parametarStatistickogIzvestajaOperation)}
                    checked={parametarStatistickogIzvestajaChange && parametarStatistickogIzvestajaChange.multiSelect ? parametarStatistickogIzvestajaChange.multiSelect : false}
                    onChange={(e: any) => {
                      setParametarStatistickogIzvestajaChange({
                        ...parametarStatistickogIzvestajaChange,
                        multiSelect: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Toolbar left={toolbarLeftContent} right={toolbarRightContent} />
        </TabPanel>
      </TabView>
    </div>
  );
}
