import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useContext, useRef } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import CrudStatistickiIzvestaji from "./CrudStatistickiIzvestaji";
import MessageType from "../../../infrastructure/MessageType";
import { AppContext } from "../../Store";

export default function CrudStatistickiIzvestajiList() {
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedStatistickiIzvestaj, setSelectedStatistickiIzvestaj] = useState();
  const [statistickiIzvestajList, setStatistickiIzvestajList] = useState<any>();
  const [entityOperation, setEntityOperation] = useState<string>("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [statistickiIzvestajToChange, setStatistickiIzvestajToChange] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const statistickiIzvestajIndex: number = 0;

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let dataList: any = [];

    axios
      .get(
        Endpoint.STATISTICKI_IZVESTAJI + "/search/findByAktivan",
        axiosConfig(authData.token, {
          page: 0,
          size: 50000000,
          aktivan: true,
          projection: "getWithRelatedObjects",
        })
      )
      .then((statistickiIzvestajCollection) => {
        const statistickiIzvestaji = statistickiIzvestajCollection.data._embedded.statistickiIzvestajs;
        statistickiIzvestaji.forEach((statIzvestaj: any) => {
          const object = {
            naziv: statIzvestaj.statistickiIzvestaj.naziv,
            jasperFajl: statIzvestaj.statistickiIzvestaj.jasperFajl,
            opis: statIzvestaj.statistickiIzvestaj.opis,
            numerickaVrednost: statIzvestaj.statistickiIzvestaj.numerickaVrednost,
            prikazujeSe: statIzvestaj.statistickiIzvestaj.prikazujeSe,
            id: statIzvestaj.statistickiIzvestaj.id,
            nazivTipaStatistickogIzvestaja: statIzvestaj.tipStatistickogIzvestaja.naziv,
          };
          dataList.push(object);
        });
        setStatistickiIzvestajList(dataList);
      });
  };

  const createStatistickiIzvestaj = (statistickiIzvestaj: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.STATISTICKI_IZVESTAJI, statistickiIzvestaj, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO, statistickiIzvestaj.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateStatistickiIzvestaj = (statistickiIzvestaj: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(Endpoint.STATISTICKI_IZVESTAJI + `/${statistickiIzvestaj.id}`, statistickiIzvestaj, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, statistickiIzvestaj.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteStatistickiIzvestaj = (statistickiIzvestaj: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(
          Endpoint.STATISTICKI_IZVESTAJI + `/${statistickiIzvestaj.id}`,
          {
            aktivan: false,
          },
          axiosConfig(authData.token)
        )
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO, statistickiIzvestaj.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const dialogHeader = () => {
    const display = statistickiIzvestajToChange ? statistickiIzvestajToChange.naziv : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_IZVESTAJA;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_IZVESTAJA + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_IZVESTAJA + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_IZVESTAJA + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedStatistickiIzvestaj;
        break;
    }
    setEntityOperation(entityOperation);
    setStatistickiIzvestajToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
    setSelectedStatistickiIzvestaj(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => {
    closeDialog();
  };

  const parseShowing = (column: any) => {
    const { prikazujeSe } = column;
    return prikazujeSe ? Labels.LABEL_DA : Labels.LABEL_NE;
  };

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-save" className="p-button-success" onClick={() => dialogRef.current.onCreate()} />}
      {entityOperation === EntityOperation.UPDATE && index === statistickiIzvestajIndex && (
        <Button label={Labels.BUTTON_IZMENI} icon="pi pi-inbox" className="p-button-warning" onClick={() => dialogRef.current.onUpdate()} />
      )}
      {entityOperation === EntityOperation.DELETE && <Button label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => dialogRef.current.onDelete()} />}
    </React.Fragment>
  );

  const rightContentsDialog = () => <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />;

  const dialogFooter = () => <Toolbar left={leftContentsDialog} right={rightContentsDialog} />;

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
      <Button disabled={!selectedStatistickiIzvestaj} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openDialog(EntityOperation.UPDATE)} />
      <Button disabled={!selectedStatistickiIzvestaj} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button disabled={!selectedStatistickiIzvestaj} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openDialog(EntityOperation.DELETE)} />
    </React.Fragment>
  );

  return (
    <div className="layout-report">
      <Panel header={Labels.TITLE_PANEL_CRUD_IZVESTAJ} className="panel-report-list">
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            filterDisplay="row"
            value={statistickiIzvestajList}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => {
              setSelectedStatistickiIzvestaj(e.data);
            }}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"jasperFajl"} header={Labels.COLUMN_HEADER_JASPER} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"opis"} header={Labels.COLUMN_HEADER_OPIS} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column
              className="column-align-left column-width-200px"
              field={"numerickaVrednost"}
              header={Labels.COLUMN_HEADER_NUMERICKA_VREDNOST}
              filterMatchMode="contains"
              showFilterMenu={false}
              filter
              sortable
            />
            <Column
              className="column-align-left column-width-170px"
              body={parseShowing}
              field={"prikazujeSe"}
              header={Labels.COLUMN_HEADER_PRIKAZUJE_SE}
              filterMatchMode="contains"
              showFilterMenu={false}
              filter
              sortable
            />
            <Column
              className="column-align-left"
              field={"nazivTipaStatistickogIzvestaja"}
              header={Labels.COLUMN_HEADER_TIP_STATISTICKOG_IZVESTAJA}
              filterMatchMode="contains"
              showFilterMenu={false}
              filter
              sortable
            />
          </DataTable>
          <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }} footer={dialogFooter()}>
            <CrudStatistickiIzvestaji
              statistickiIzvestajOperation={entityOperation}
              statistickiIzvestaj={statistickiIzvestajToChange}
              onCreateStatistickiIzvestaj={createStatistickiIzvestaj}
              onUpdateStatistickiIzvestaj={updateStatistickiIzvestaj}
              onDeleteStatistickiIzvestaj={deleteStatistickiIzvestaj}
              onCancel={onCancel}
              setIndex={setIndex}
              index={index}
              dialogRef={dialogRef}
            />
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
