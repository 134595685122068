import React from "react";
import { Route, Switch } from "react-router-dom";
import Jasperi from "./jasperi/Jasperi";
import Konekcija from "./konekcija/Konekcija";
import CrudParametriList from "./parametri/CrudParametriList";
import Routers from "../../../utils/Routers";

export default function ReloadRouter() {
  return (
    <Switch>
      <Route exact path={Routers.RELOAD} component={Jasperi} />
      <Route exact path={Routers.RELOAD_JASPERI} component={Jasperi} />
      <Route exact path={Routers.RELOAD_KONEKCJIJA} component={Konekcija} />
      <Route exact path={Routers.RELOAD_PARAMETRI} component={CrudParametriList} />
    </Switch>
  );
}
