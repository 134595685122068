import React from "react";
import TipAtributaRouter from "./TipAtributaRouter";
import Panel from "./Panel";
import Slikovni from "./Slikovni";
import Tab from "./Tab";
import EnumTipovaVrednostiAtributa from "../../utils/EnumTipovaVrednostiAtributa";

class Auts extends React.Component {
  constructor(props) {
    super(props);
    this.changeTab = this.changeTab.bind(this);
    this.changePrikazTabova = this.changePrikazTabova.bind(this);
    this.addTabova = this.addTabova.bind(this);
    this.prikazivanjeJednogAutsa = this.prikazivanjeJednogAutsa.bind(this);
    this.prikazivanjeVertikalno = this.prikazivanjeVertikalno.bind(this);
    this.prikazivanjeHorizontalno = this.prikazivanjeHorizontalno.bind(this);
    this.state = {
      prikazTaba: true,
      poslednjiTab: [],
      panelTab: {},
    };
  }

  changeTab(value) {
    this.props.changeState("defaultTab", value);
  }

  changePrikazTabova() {
    this.setState({
      prikazTaba: !this.state.prikazTaba,
    });
  }

  addTabova(value) {
    let poslednjiTabArray = this.state.poslednjiTab;
    poslednjiTabArray.push(value);
    this.setState({
      poslednjiTab: poslednjiTabArray,
    });
    this.changeTab(value);
  }

  prikazivanjeHorizontalno(auts) {
    let bojenje = false;
    if (auts.relevantno && auts.prikazujeSe) {
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI && (this.props.initValue[auts.id] === undefined || this.props.initValue[auts.id].length === 0)) {
        bojenje = true;
      } else if (this.props.initValue[auts.id] === undefined || this.props.initValue[auts.id] === "") {
        bojenje = true;
      }
    }
    let classNaziv = "";
    if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.NASLOV) {
      classNaziv += "font-weight-bold";
    } else if (bojenje) {
      classNaziv += "bojenje_labele";
    }

    return (
      <div className="form-group autsDiv row card-body">
        <div className={classNaziv}>
          {auts.ispisujeSeLabela ? auts.naziv : ""}
          {auts.ispisujeSeLabela && !auts.opciono ? "* " : " "}
          {auts.ispisujeSeLabela && auts.relevantno ? <i class="fas fa-bell ml-2"></i> : ""}
          {auts.ispisujeSeLabela && auts.htmlInfo && (
            <button className="btn btn-outline-danger ml-1 mb-1 button_htmlInfo" onClick={() => this.props.showHtmlInfo(auts.htmlInfo)}>
              <i className="fas fa-info-circle"></i>
            </button>
          )}
        </div>

        <div className="col-md-3 mb-3">
          <TipAtributaRouter
            tvaNumVrednost={auts.tvaNumVrednost}
            initValue={this.props.initValue[auts.id]}
            changeState={this.props.changeState}
            tipSkupaAtributaKey={auts.id}
            atributUTipuSkupaID={auts.id}
            auts={auts}
            enumiPoAUTS={this.props.enumiPoAUTS !== undefined ? this.props.enumiPoAUTS[auts.id] : []}
            prikazAUTS={this.props.prikazAUTS}
            sifraAutsPoTipu={this.props.sifraAutsPoTipu}
            zaPretragu={false}
          />
        </div>
      </div>
    );
  }

  prikazivanjeJednogAutsa(auts, podredjeniObject, segmentID) {
    if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.PANEL) {
      return (
        <Panel
          auts={auts}
          autsiPodredjeni={podredjeniObject}
          initValue={this.props.initValue}
          enumiPoAUTS={this.props.enumiPoAUTS}
          changeState={this.props.changeState}
          prikazAUTS={this.props.prikazAUTS}
          sifraAutsPoTipu={this.props.sifraAutsPoTipu}
          zaPretragu={false}
          prikazivanjeAutsa={this.props.prikazivanjeAutsa}
          prikazivanjeVertikalno={this.prikazivanjeVertikalno}
          showHtmlInfo={this.props.showHtmlInfo}
          hideHtmlInfo={this.props.hideHtmlInfo}
        />
      );
    } else if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SLIKOVNI) {
      return (
        <Slikovni
          auts={auts}
          autsiPodredjeni={podredjeniObject}
          initValue={this.props.initValue}
          enumiPoAUTS={this.props.enumiPoAUTS}
          changeState={this.props.changeState}
          prikazAUTS={this.props.prikazAUTS}
          sifraAutsPoTipu={this.props.sifraAutsPoTipu}
          zaPretragu={false}
          prikazivanjeAutsa={this.props.prikazivanjeAutsa}
          prikazivanjeVertikalno={this.prikazivanjeVertikalno}
          showHtmlInfo={this.props.showHtmlInfo}
          hideHtmlInfo={this.props.hideHtmlInfo}
        />
      );
    } else if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.TAB) {
      return (
        <Tab
          auts={auts}
          autsiPodredjeni={podredjeniObject}
          initValue={this.props.initValue[auts.id]}
          enumiPoAUTS={this.props.enumiPoAUTS}
          changeState={this.props.changeState}
          prikazAUTS={this.props.prikazAUTS}
          defaultPodTab={this.props.defaultPodTab !== undefined ? this.props.defaultPodTab[segmentID] : ""}
          sifraAutsPoTipu={this.props.sifraAutsPoTipu}
          zaPretragu={false}
          prikazivanjeAutsa={this.props.prikazivanjeAutsa}
          prikazivanjeVertikalno={this.prikazivanjeVertikalno}
          prikazivanjeJednogAutsa={this.prikazivanjeJednogAutsa}
        />
      );
    } else {
      return this.prikazivanjeVertikalno(auts);
    }
  }

  prikazivanjeVertikalno(auts) {
    let bojenje = false;

    if (auts.relevantno && auts.prikazujeSe) {
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI && (this.props.initValue[auts.id] === undefined || this.props.initValue[auts.id].length === 0)) {
        bojenje = true;
      } else if (this.props.initValue[auts.id] === undefined || this.props.initValue[auts.id] === "") {
        bojenje = true;
      }
    }
    let classNaziv = "";
    if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.NASLOV) {
      classNaziv += "col-md-2 mb-3 font-weight-bold ";
    } else {
      classNaziv += "col-md-2 mb-3 ";
    }
    if (bojenje && this.props.oboji) {
      classNaziv += "bojenje_labele ";
    }
    return (
      <div className="form-group autsDiv row card-body" key={auts.id}>
        <div className={classNaziv}>
          {auts.ispisujeSeLabela ? auts.naziv : ""}
          {auts.ispisujeSeLabela && !auts.opciono ? "* " : " "}
          {auts.ispisujeSeLabela && auts.relevantno ? <i class="fas fa-bell ml-2"></i> : ""}
          {auts.ispisujeSeLabela && auts.htmlInfo && (
            <button className="btn btn-outline-danger ml-1 mb-1 button_htmlInfo" onClick={() => this.props.showHtmlInfo(auts.htmlInfo)}>
              <i className="fas fa-info-circle"></i>
            </button>
          )}
        </div>

        <div className="col-md-4 mb-3">
          <TipAtributaRouter
            tvaNumVrednost={auts.tvaNumVrednost}
            initValue={this.props.initValue[auts.id]}
            changeState={this.props.changeState}
            tipSkupaAtributaKey={auts.id}
            atributUTipuSkupaID={auts.id}
            auts={auts}
            enumiPoAUTS={this.props.enumiPoAUTS !== undefined ? this.props.enumiPoAUTS[auts.id] : []}
            prikazAUTS={this.props.prikazAUTS}
            sifraAutsPoTipu={this.props.sifraAutsPoTipu}
            zaPretragu={false}
          />
        </div>
      </div>
    );
  }

  render() {
    let autsiSeparatori = this.props.autsiSeparatori;
    let autsiSegmentiOrderNePrikazujeSe = [];
    Object.keys(this.props.autsiSegmentiOrder).map((id, a) => {
      if (this.props.autsiSegmentiOrder[id] !== null && !this.props.autsiSegmentiOrder[id].prikazujeSe && !this.state.poslednjiTab.includes(this.props.autsiSegmentiOrder[id].id)) {
        autsiSegmentiOrderNePrikazujeSe.push(this.props.autsiSegmentiOrder[id]);
      }
    });

    let tabMenu = Object.keys(this.props.autsiSegmentiOrder).map((id, a) => {
      /*if (autsiSegmentiOrderNePrikazujeSe.length > 0 && autsiSegmentiOrderNePrikazujeSe[0].id ===  this.props.autsiSegmentiOrder[id].id) {
				return (
						<a key={this.props.autsiSegmentiOrder[id].id}
							className= "btn nav-item nav-link"
							data-toggle="tab"
							role="tab"
							aria-controls="nav-home"
							aria-selected="true"
							onClick={() => this.addTabova(this.props.autsiSegmentiOrder[id].id)}>
							<i className="fas fa-plus"></i>
						</a>
					)
			} else {
				
			}*/

      if (!this.props.autsiSegmentiOrder[id].prikazujeSe) {
        return null;
      }
      return (
        <a
          key={this.props.autsiSegmentiOrder[id].id}
          className={this.props.defaultTab === this.props.autsiSegmentiOrder[id].id ? "btn nav-item nav-link tabBorderColor tab active" : "btn nav-item nav-link tabBorderColor tab"}
          data-toggle="tab"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          onClick={() => this.changeTab(this.props.autsiSegmentiOrder[id].id)}
        >
          {this.props.autsiSegmentiOrder[id].naziv}
        </a>
      );
    });
    if (Object.keys(autsiSeparatori).length === 0 && this.props.autsi.length > 0) {
      autsiSeparatori[0] = { autsi: this.props.autsi };
    }
    let sviAutsi = Object.keys(autsiSeparatori).map((id) => {
      if (autsiSeparatori[id]) {
        let autsi = autsiSeparatori[id].autsi;
        let autsiPanel = this.props.autsiPanel;
        let podredjeniObject = {};
        let podredjeniAutsi = [];
        autsiPanel.map((auts) => {
          let podredjeniArray = autsi.filter((x) => x.naredjeniAUTS === auts.id);
          podredjeniObject[auts.id] = podredjeniArray;
          podredjeniAutsi.push(podredjeniArray);
        });
        if (podredjeniAutsi.length > 0) {
          podredjeniAutsi.map((pod, a) => {
            autsi = autsi.filter((x) => !podredjeniAutsi[a].includes(x)).concat(podredjeniAutsi[a].filter((x) => !autsi.includes(x)));
          });
        }

        let podtaboviArray = autsi.filter((x) => x.tvaNumVrednost === EnumTipovaVrednostiAtributa.TAB);
        let podTabovi = podtaboviArray.map((podTab) => {
          return (
            <a
              key={podTab.id}
              className={
                this.props.defaultPodTab !== undefined && this.props.defaultPodTab[id] === podTab.id ? "btn nav-item nav-link tabBorderColor tab active" : "btn nav-item nav-link tabBorderColor tab"
              }
              data-toggle="tab"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              onClick={() => this.props.changePodTab(id, podTab.id)}
            >
              {podTab.naziv}
            </a>
          );
        });

        let grupaAuts = autsi.map((auts, a) => {
          let prikaz = this.props.prikazivanjeAutsa(auts);
          if (prikaz && auts.prikazujeSe) {
            return <div key={a}>{this.prikazivanjeJednogAutsa(auts, podredjeniObject, id)}</div>;
          }
        });

        if (!this.state.prikazTaba || !this.props.defaultTab || this.props.defaultTab.toString() !== id) {
          return null;
        }

        return (
          <div key={id} className={this.state.prikazTaba && this.props.defaultTab && this.props.defaultTab.toString() === id ? "" : "d-none"}>
            {podtaboviArray.length > 0 && (
              <nav className="mb-4">
                <div className="nav nav-tabs" role="tablist">
                  {podTabovi}
                </div>
              </nav>
            )}
            {grupaAuts}
          </div>
        );
      }
    });
    return (
      <div className="pt-2">
        {this.state.prikazTaba && (
          <nav>
            <div className="nav nav-tabs" role="tablist">
              {tabMenu}
            </div>
          </nav>
        )}
        <div className="pt-4 divScroll">{sviAutsi}</div>
      </div>
    );
  }
}

export default Auts;
