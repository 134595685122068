import React from "react";

export default function Tekstualni({ initValue, changeState, auts, tipSkupaAtributaKey }) {
  const handleChange = (value) => {
    let stateArray = [
      ["initValue", tipSkupaAtributaKey, value],
      ["prikazAUTS", auts.sifra, value],
    ];
    changeState(stateArray);
  };

  if (auts.tipAtributa.sirina && auts.tipAtributa.visina && auts.tipAtributa.visina > 1) {
    return (
      <div>
        <textarea className="form-control" onChange={(event) => handleChange(event.target.value)} cols={auts.tipAtributa.sirina} rows={auts.tipAtributa.visina} value={initValue} />
      </div>
    );
  } else {
    return (
      <div>
        <input type="text" className="form-control" onChange={(event) => handleChange(event.target.value)} value={initValue ? initValue : ""} disabled={auts.formulaZaRacunanje} />
      </div>
    );
  }
}
