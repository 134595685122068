import React from "react";
import { Route, Switch } from "react-router-dom";
import RegistarOld from "./RegistarOld";
import Registar from "./unos/registar/Registar";
import Registri from "./Registri";

export default function RegistarRouter() {
  return (
    <Switch>
      <Route exact path="/" component={Registri} />
      <Route exact path="/registri" component={Registri} />
      <Route path="/registri/:id" component={Registar} />
      <Route path="/registri2/:id" component={RegistarOld} />
    </Switch>
  );
}
