import React from "react";
import { Dropdown } from "primereact/dropdown";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { objectAndOr } from "../../../utils/AUTSOperator";
import TipAtributaRouter from "../../hijerarhija/TipAtributaRouter";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import EnumPol from "../../../utils/EnumPol";
import { brojRezultatePretrageOptions } from "../../../utils/EnumBrojRezultataPretrage";
import { PrikaziPoljaZaPretraguType } from "./RegistarLogical";

const optionsPol = [
  { name: Labels.LABEL_ODABERITE_POL, code: EnumPol.ODABERITE_POL },
  { name: Labels.LABEL_ZENSKI, code: EnumPol.ZENSKI },
  { name: Labels.LABEL_MUSKI, code: EnumPol.MUSKI },
];

export default function RegistarFilterPanel(props: any) {
  const {
    registar,
    state,
    podaci,
    tipoviSkupaAtributa,
    kriterijumZaPretragu,
    searchFieldOnChange,
    handleKeyPressLogIn,
    unosiPoljaZaPretragu,
    prikaziPoljaZaPretragu,
    pretraga,
    changeState,
    changeVeza,
    changeTipaSkupaAtributa,
    changeAtributUTipuSkupa,
    changeOperator,
    dodajRedPretrage,
    obrisiRedPretrage,
  } = props;

  let filterRedovi = Object.keys(state?.redPretrageElementi ?? []).map((key, counter) => {
    return (
      <div className="grid-nogutter flex align-items-center" key={key}>
        <div className="col-0 mr-2">
          <Button icon="pi pi-minus" className="p-button-danger p-button-outlined" onClick={() => obrisiRedPretrage(key)} />
        </div>
        <div className="p-col-fixed w-6rem mr-1">
          {counter > 0 && (
            <Dropdown
              className="w-full"
              placeholder={Labels.PLACEHOLDER_VEZA}
              onChange={(value: any) => changeVeza(key, value)}
              options={objectAndOr}
              value={state.redPretrageElementi[key]?.veza?.value}
            />
          )}
        </div>
        {kriterijumZaPretragu?.prikazTekucihUnosaKodPretrage && (
          <div className="col-2 mr-1">
            <Dropdown
              className="w-full"
              placeholder={Labels.PLACEHOLDER_ODABERITE_UNOS}
              onChange={(value: any) => changeTipaSkupaAtributa(key, value.value)}
              options={tipoviSkupaAtributa}
              value={state.redPretrageElementi[key]?.tipSkupaAtributa?.value}
              filter={true}
              editable={false}
            />
          </div>
        )}
        <div className={`${kriterijumZaPretragu?.prikazTekucihUnosaKodPretrage ? "col-2" : "col-4"} mr-1`}>
          <Dropdown
            className="w-full"
            placeholder={Labels.PLACEHOLDER_ODABERITE_STAVKU_UNOSA}
            onChange={(value: any) => changeAtributUTipuSkupa(key, value.value)}
            options={kriterijumZaPretragu?.prikazTekucihUnosaKodPretrage ? podaci.autsiPoTSA[key] : podaci.autsiPoTSA[0]}
            value={state.redPretrageElementi[key]?.atributUTipuSkupa?.value}
            filter={true}
            editable={false}
          />
        </div>
        <div className="col-1 mr-1">
          <Dropdown
            className="w-full"
            placeholder={Labels.PLACEHOLDER_OPERATOR}
            onChange={(value: any) => changeOperator(key, value)}
            value={state.redPretrageElementi[key]?.operator?.value}
            options={podaci.operatoriPoTSA[key]}
          />
        </div>
        <div className="col-2">
          <TipAtributaRouter
            tvaNumVrednost={state.redPretrageElementi[key].tipVrednosti !== undefined ? state.redPretrageElementi[key].tipVrednosti.numerickaVrednost : null}
            initValue={state.initValue[key]}
            changeState={changeState}
            tipSkupaAtributaKey={key}
            atributUTipuSkupaID={state.redPretrageElementi[key].atributUTipuSkupa !== undefined ? state.redPretrageElementi[key].atributUTipuSkupa.value : ""}
            auts={podaci.autsiPoTSAFull}
            enumiPoAUTS={podaci.enumiPoAUTS}
            zaPretragu={true}
          />
        </div>
      </div>
    );
  });

  const createSearchField = ({ type, name, placeholder }: PrikaziPoljaZaPretraguType) => {
    if (type === "text") {
      return <InputText name={name} className="w-full" placeholder={placeholder} onKeyPress={handleKeyPressLogIn} onChange={searchFieldOnChange} value={unosiPoljaZaPretragu[name]} />;
    } else if (type === "number") {
      return <InputNumber name={name} className="w-full" placeholder={placeholder} onKeyDown={handleKeyPressLogIn} onValueChange={searchFieldOnChange} value={unosiPoljaZaPretragu[name]} />;
    } else {
      return <Dropdown name={name} className="w-full" placeholder={placeholder} optionLabel="name" onChange={searchFieldOnChange} value={unosiPoljaZaPretragu[name]} options={optionsPol} />;
    }
  };

  return (
    <div className="col-12">
      <Panel className="h3" header={registar?.current?.naziv ?? ""} toggleable>
        <div className="grid">
          {unosiPoljaZaPretragu &&
            prikaziPoljaZaPretragu.map((polje: PrikaziPoljaZaPretraguType, i: number) => (
              <div key={i} className="col-2">
                {createSearchField(polje)}
              </div>
            ))}
          <div className="col-12">{filterRedovi}</div>
          <div className="col-12">
            <Button icon="pi pi-plus" className="p-button-danger p-button-outlined" onClick={dodajRedPretrage} />
          </div>
          <div className="col-12">
            <Button className="p-button-danger p-button-outlined mr-3" onClick={pretraga} label={Labels.BUTTON_PRETRAGA} icon="pi pi-search" />
            {unosiPoljaZaPretragu?.brojRezultatePretrage && (
              <Dropdown
                tooltip={Labels.TOOLTIP_BROJ_PACIJENT}
                tooltipOptions={{ position: "top" }}
                value={unosiPoljaZaPretragu.brojRezultatePretrage}
                name="brojRezultatePretrage"
                options={brojRezultatePretrageOptions}
                onChange={searchFieldOnChange}
                optionLabel="name"
              />
            )}
          </div>
        </div>
      </Panel>
    </div>
  );
}
