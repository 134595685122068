import axios from "axios";
import React, { useRef, useState, useReducer } from "react";
import Endpoint from "../endpoints/Endpoint";
import AuthData from "../model/AuthData";
import { useEffectOnce } from "../utils/Utils";

interface AppContextProps {
  authData: AuthData;
  updateAuthData: (data: AuthData) => void;
  deleteAuthData: () => void;
  upozorenje: string | undefined;
  setUpozorenje: (upozorenje: string) => void;
  showBlockUI: boolean;
  setShowBlockUI: (value: boolean) => void;
  growlMessage: any;
  showGrowlMessage: (severity: string, summary: string, detail: string) => void;
  nazivAplikacije: string | undefined;
  stateDispatch: any;
  dispatch: any;
}

export const AppContext = React.createContext({} as AppContextProps);

const Store = (props: any) => {
  const ulogovanRadnik = localStorage.hasOwnProperty("ulogovanRadnik") ? JSON.parse(localStorage.getItem("ulogovanRadnik") || "{}") : undefined;
  const [authData, setAuthData] = useState<AuthData>(ulogovanRadnik as AuthData);
  const [showBlockUI, setShowBlockUI] = useState<boolean>(false);
  const [upozorenje, setUpozorenje] = useState<string>();
  const [nazivAplikacije, setNazivAplikacije] = useState<string>("");
  const growlMessage = useRef<any>(null);
  const initialState = {
    pacijentPretragaRefrash: "",
  };
  const reducer = (state: any, action: { type: any; pacijentPretragaRefrash: any }) => {
    switch (action.type) {
      case "setPacijentPretragaRefrash":
        return {
          ...state,
          pacijentPretragaRefrash: action.pacijentPretragaRefrash,
        };
      default:
        return state;
    }
  };

  const [stateDispatch, dispatch] = useReducer(reducer, initialState);

  useEffectOnce(() => {
    axios
      .get(Endpoint.PARAMETAR_NAZIV_APLIKACIJE)
      .then((response) => {
        const nazivApl = response.data.nazivAplikacije;
        setNazivAplikacije(nazivApl ? nazivApl : "Regix");
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const showGrowlMessage = (severity: string, summary: string, detail: string) => {
    growlMessage.current.show({ severity: severity, summary: summary, detail: detail });
  };

  const updateAuthData = (data: AuthData) => {
    setAuthData(data);
    localStorage.setItem("ulogovanRadnik", JSON.stringify(data));
  };

  const deleteAuthData = () => {
    setAuthData(new AuthData("", 0, "", "", ""));
    localStorage.removeItem("ulogovanRadnik");
  };

  return (
    <AppContext.Provider
      value={{
        authData,
        updateAuthData,
        deleteAuthData,
        upozorenje,
        setUpozorenje,
        showBlockUI,
        setShowBlockUI,
        growlMessage,
        showGrowlMessage,
        nazivAplikacije,
        stateDispatch,
        dispatch,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default Store;
