import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import React, { useRef } from "react";
import { useHistory } from "react-router";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import ExportData from "../../export/ExportData";

const EXPORT_FILE_NAME = "rezultati_pretrage";

export default function RegistarDataTable(props: any) {
  const { dataTable, loading, tableRows, first, handleRowsAndFirst, tipoviSkupaAtributa, openDialogUnos, openDialogPacijent } = props;
  const history = useHistory();
  const dt = useRef<any>();
  const blurText = (rowData: any) => (rowData.blurPodataka ? "blur-text" : undefined);

  const pacijentTemplate = (rowData: any) =>
    !rowData.blurPodataka ? <Button label={rowData.imePrezime} onClick={() => history.push(`/pacijenti/${rowData.pacijentID}`)} className="p-button-outlined pac-icon w-full" /> : undefined;

  const jmbgTemplate = (rowData: any) => <div className={blurText(rowData)}>{rowData.jmbg}</div>;

  const lboTemplate = (rowData: any) => <div className={blurText(rowData)}>{rowData.lbo}</div>;

  const identifikacioniBrojTemplate = (rowData: any) => <div className={blurText(rowData)}>{rowData.identifikacioniBroj}</div>;

  const prviUnosTemplate = (rowData: any) =>
    !rowData.blurPodataka && rowData.prviUnos ? (
      <Button icon="pi pi-file" label={rowData.prviUnos} onClick={() => history.push(`/unosi/${rowData.prviUnosID}`)} className="p-button-outlined w-full pac-icon w-full" />
    ) : undefined;

  const poslednjiUnosTemplate = (rowData: any) =>
    !rowData.blurPodataka && rowData.poslednjiUnos ? (
      <Button icon="pi pi-file" label={rowData.poslednjiUnos} onClick={() => history.push(`/unosi/${rowData.poslednjiUnosID}`)} className="p-button-outlined w-full pac-icon w-full" />
    ) : undefined;

  const noviUnosTemplate = (rowData: any) =>
    !rowData.blurPodataka && tipoviSkupaAtributa?.length ? (
      <Button className="btn btn-outline-danger pac-icon w-full" icon="fas fa-plus" label={Labels.BUTTON_NOVI_UNOS} onClick={() => openDialogUnos(rowData.pacijentID)} />
    ) : undefined;

  const leftToolbarTemplate = () => <Button label={Labels.BUTTON_NOVI_PACIJENT} icon="pi pi-plus" className="p-button-outlined p-mr-2" onClick={openDialogPacijent} />;

  const rightToolbarTemplate = () => <ExportData data={dataTable} name={EXPORT_FILE_NAME} dt={dt} />;

  return (
    <>
      <div className="col-12">
        <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
      </div>
      <div className="col-12">
        <DataTable
          ref={dt}
          value={dataTable}
          loading={loading}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          first={first}
          rows={tableRows}
          rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
          onPage={(e) => handleRowsAndFirst(e.rows, e.first)}
          emptyMessage={Labels.TABLE_EMPTY_MESSAGE}
          currentPageReportTemplate={Labels.PAGINATION_SHOWING_TO_OF_ENTRIES}
          exportFilename={EXPORT_FILE_NAME}
        >
          <Column field="imePrezime" header={Labels.COLUMN_HEADER_PACIJENT} sortable body={pacijentTemplate} />
          <Column field="jmbg" header={Labels.COLUMN_HEADER_JMBG} sortable body={jmbgTemplate} />
          <Column field="lbo" header={Labels.COLUMN_HEADER_LBO} sortable body={lboTemplate} />
          <Column field="identifikacioniBroj" header={Labels.COLUMN_HEADER_IDENTIFIKACIONI_BROJ} sortable body={identifikacioniBrojTemplate} />
          <Column field="prviUnos" header={Labels.COLUMN_HEADER_PRVI_UNOS} sortable body={prviUnosTemplate} />
          <Column field="poslednjiUnos" header={Labels.COLUMN_HEADER_POSLEDNJI_UNOS} sortable body={poslednjiUnosTemplate} />
          <Column field="noviUnos" header={Labels.COLUMN_HEADER_NOVI_UNOS} sortable body={noviUnosTemplate} exportable={false} />
          <Column field="blurPodataka" hidden exportable={false} />
        </DataTable>
      </div>
      <div className="col">
        <Toolbar left={<Button icon="pi pi-undo" className="p-button-outlined p-button-danger" label={Labels.BUTTON_NAZAD_NA_REGISTRE} onClick={() => history.push("/registri")} />} />
      </div>
    </>
  );
}
