import React from "react";
import axios from "axios";
import { axiosConfig } from "../utils/Utils";
import Endpoint from "../endpoints/Endpoint";

const token = localStorage.hasOwnProperty("ulogovanRadnik") ? JSON.parse(localStorage.getItem("ulogovanRadnik")).token : "";;

class LoginGet extends React.Component {

  componentDidMount() {
    const params = new URL(window.location.href).searchParams;
    axios
      .post(Endpoint.LOGIN, {
        username: params.get("username"),
        password: params.get("password")
      }, axiosConfig(token))
      .then(response => {
        if (response.status === 200) {
          let radnik = response.data.ulogovaniRadnik;
          if (radnik !== undefined) {
            localStorage.setItem(
              "ulogovanRadnik",
              JSON.stringify(radnik).toString()
            );
            axios
              .get(Endpoint.REGISTAR + "/search/findByAktivan", axiosConfig(token, {
                aktivan: true
              }))
              .then(function (response) {
                if (response.status === 200) {
                  var registri = response.data._embedded.registars;
                  if (registri.length === 1) {
                    let id = registri[0].id;
                    return (window.location.href = "/registri/" + id);
                  } else {
                    return (window.location.href = "/registri");
                  }
                }
              });
          } else {
            return (window.location.href = "/");
          }
        } else if (response.status === 404) {
          return (window.location.href = "/");
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return <div></div>;
  }
}

export default LoginGet;
