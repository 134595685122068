import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import { axiosConfig, getImage, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";

interface CrudSlikovniProps {
  nadredjeniAUTS?: any;
  stavkaUnosaChange?: any;
  setStavkaUnosaChange?: any;
}

export default function CrudStavkaUnosaSlikovni(props: CrudSlikovniProps) {
  const { authData } = useContext(AppContext);
  const { nadredjeniAUTS, stavkaUnosaChange, setStavkaUnosaChange } = props;
  const { backgroundImage, inicijalnaBrojnaVrednost, gornjaReferentnaBrojnaVrednost, donjaReferentnaBrojnaVrednost } = nadredjeniAUTS;
  const { imageCoordinateX, imageCoordinateY } = stavkaUnosaChange;
  const [podredjeniAUTS, setPodredjeniAUTS] = useState<any>([]);

  const DIAMETER_DOT = useRef(inicijalnaBrojnaVrednost || 20);
  const getDiameter = () => DIAMETER_DOT.current;
  const WIDTH_CANVAS = useRef(gornjaReferentnaBrojnaVrednost);
  const HEIGHT_CANVAS = useRef(donjaReferentnaBrojnaVrednost);

  const handleDot = (x: any, y: any) => {
    setStavkaUnosaChange({ ...stavkaUnosaChange, imageCoordinateX: x, imageCoordinateY: y });
  };

  useEffectOnce(() => {
    axios
      .get(Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findPodredjeniByNadredjeniAuts", axiosConfig(authData.token, { atributUTipuSkupa: Endpoint.ATRIBUT_U_TIPU_SKUPA + "/" + nadredjeniAUTS.id }))
      .then((response) => {
        const sviPodredjeni = response.data._embedded.atributUTipuSkupas;
        setPodredjeniAUTS(sviPodredjeni.filter((p: any) => p.id !== stavkaUnosaChange.id && p.imageCoordinateX && p.imageCoordinateY));
      });
  });

  const setDot = (diameter: any, x: any, y: any) => {
    return {
      width: `${diameter}px`,
      height: `${diameter}px`,
      marginTop: `${y}px`,
      marginLeft: `${x}px`,
    };
  };

  const imageDiv = {
    backgroundImage: `url(${getImage(backgroundImage, null)})`,
    width: WIDTH_CANVAS.current,
    height: HEIGHT_CANVAS.current,
  };

  return (
    <React.Fragment>
      <div className="imageDiv" style={imageDiv} onClick={(e) => handleDot(e.nativeEvent.offsetX - getDiameter() / 2, e.nativeEvent.offsetY - getDiameter() / 2)}>
        {imageCoordinateX && imageCoordinateY && (
          <div
            onClick={(e) => {
              handleDot(null, null);
              e.stopPropagation();
            }}
            className="btn-primary dotDiv"
            style={setDot(getDiameter(), imageCoordinateX, imageCoordinateY)}
          />
        )}
        {podredjeniAUTS.map((p: any) => {
          return <div className="bg-white dotDiv" style={setDot(getDiameter(), p.imageCoordinateX, p.imageCoordinateY)} />;
        })}
      </div>
    </React.Fragment>
  );
}
