import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Messages } from "primereact/messages";
import { Panel } from "primereact/panel";
import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Endpoint from "../endpoints/Endpoint";
import Labels from "../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../infrastructure/MessageType";
import { axiosConfig, useEffectOnce } from "../utils/Utils";
import { AppContext } from "./Store";
import Routers from "../utils/Routers";

export default function Registri() {
  const { authData } = useContext(AppContext);
  const [tableDataRegistri, setTableDataRegistri] = useState<any>();
  const [putanjaDoLoga, setPutanjaDoLoga] = useState();

  const history = useHistory();
  const messages = useRef<any>(null);

  const showMessage = (summary: string, severity: string, detail: string) => {
    messages.current.replace({
      severity: severity,
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  useEffectOnce(() => {
    const requestPutDoLogoa = axios.get(Endpoint.PARAMETAR + "/search/findByNazivAndAktivan/", axiosConfig(authData.token, { naziv: "putanjaDoLogoa", aktivan: true }));
    const requestRegistri = axios.get(Endpoint.REGISTAR + "/search/findByAktivan", axiosConfig(authData.token, { aktivan: true }));

    axios
      .all([requestPutDoLogoa, requestRegistri])
      .then(
        axios.spread((responsePutDoLogoa: any, responseRegistri: any) => {
          if (responsePutDoLogoa.data._embedded.parametars.length > 0) {
            setPutanjaDoLoga(responsePutDoLogoa.data._embedded.parametars[0].vrednost);
          }
          const dataRegistri = responseRegistri.data._embedded.registars.map((registar: any) => {
            return {
              naziv: registar.naziv,
              opis: registar.opis,
              akcija: <Button label={Labels.BUTTON_PRIKAZI} icon="pi pi-bookmark" className="button-info" onClick={() => history.push(`${Routers.REGISTRI}/${registar.id}`)} />,
            };
          });
          setTableDataRegistri(dataRegistri);
        })
      )
      .catch((error: any) => {
        showMessage(Labels.TITLE_MESSAGES_GRESKA, MessageType.ERROR, error);
      });
  });

  const images: any[] = [];
  if (putanjaDoLoga) {
    const imagesPath = require.context("./../images");
    imagesPath.keys().forEach((image: any) => {
      if (image.replace("./", "").startsWith(putanjaDoLoga)) {
        images.push(require(`./../images/${image.replace("./", "")}`));
      }
    });
  }

  const rowExpansionIndexTemplate = (rowData: any, column: { rowIndex: number }) => column.rowIndex + 1 + ".";

  return (
    <div>
      <div className="layout-registri">
        <Panel header={Labels.TITLE_PANEL_REGISTARA} className="panel-registri">
          <Messages ref={messages} />
          <DataTable
            value={tableDataRegistri}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
            className="p-datatable-striped"
          >
            <Column bodyClassName="text-center w-1" body={rowExpansionIndexTemplate} header={Labels.COLUMN_HEADER_REDNI_BROJ} />
            <Column bodyClassName="text-center w-6" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} />
            <Column bodyClassName="text-center w-3" field={"opis"} header={Labels.COLUMN_HEADER_OPIS} />
            <Column bodyClassName="text-center w-2" field={"akcija"} header={Labels.COLUMN_HEADER_AKCIJE} />
          </DataTable>
        </Panel>
      </div>
      <div className="p-log-container">
        {images.map((img, i) => (
          <img key={i} className="p-logo-image" src={img} alt={img.toString()} />
        ))}
      </div>
    </div>
  );
}
