import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useRef, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import CrudTipAtributa from "./CrudTipAtributa";

export default function CrudTipAtributaList() {
  const [tipAtributaList, setTipAtributaList] = useState();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [tipAtributaToChange, setTipAtributaToChange] = useState<any>();
  const [selectedTipAtributa, setSelectedTipAtributa] = useState();
  const [entityOperation, setEntityOperation] = useState<string>("");
  const { showGrowlMessage, authData } = useContext(AppContext);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const tipAtributaIndex: number = 0;

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    let dataList: any = [];

    axios
      .get(
        Endpoint.TIP_ATRIBUTA + "/search/findByAktivan",
        axiosConfig(authData.token, {
          page: 0,
          size: 50000000,
          aktivan: true,
          projection: "getWithRelatedObjects",
        })
      )
      .then((tipAtributaCollection) => {
        const tipoviAtributa = tipAtributaCollection.data._embedded.tipAtributas;
        tipoviAtributa.forEach((tipAtr: any) => {
          const object = {
            naziv: tipAtr.tipAtributa.naziv,
            nazivTipaVrednostiAtributa: tipAtr.tipVrednostiAtributa.naziv,
            napomena: tipAtr.tipAtributa.napomena,
            decimaleBrojneVrednosti: tipAtr.tipAtributa.decimaleBrojneVrednosti,
            donjaGranicaBrojneVrednosti: tipAtr.tipAtributa.donjaGranicaBrojneVrednosti,
            gornjaGranicaBrojneVrednosti: tipAtr.tipAtributa.gornjaGranicaBrojneVrednosti,
            jedinica: tipAtr.tipAtributa.jedinica,
            sirina: tipAtr.tipAtributa.sirina,
            visina: tipAtr.tipAtributa.visina,
            id: tipAtr.tipAtributa.id,
          };
          dataList.push(object);
        });
        setTipAtributaList(dataList);
      });
  };

  const createTipAtributa = (tipAtributa: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.TIP_ATRIBUTA, tipAtributa, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO, tipAtributa.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateTipAtributa = (tipAtributa: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(Endpoint.TIP_ATRIBUTA + `/${tipAtributa.id}`, tipAtributa, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, tipAtributa.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteTipAtributa = (tipAtributa: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(
          Endpoint.TIP_ATRIBUTA + `/${tipAtributa.id}`,
          {
            aktivan: false,
          },
          axiosConfig(authData.token)
        )
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO, tipAtributa.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const dialogHeader = () => {
    const display = tipAtributaToChange ? tipAtributaToChange.naziv : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_TIPA_ATRIBUTA;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_TIPA_ATRIBUTA + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_TIPA_ATRIBUTA + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_TIPA_ATRIBUTA + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedTipAtributa;
        break;
    }
    setEntityOperation(entityOperation);
    setTipAtributaToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
    setSelectedTipAtributa(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => closeDialog();

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_KREIRAJ}
          icon="pi pi-save"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && index === tipAtributaIndex && (
        <Button
          label={Labels.BUTTON_IZMENI}
          icon="pi pi-inbox"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_OBRISI}
          icon="pi pi-inbox"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />;

  const dialogFooter = () => <Toolbar left={leftContentsDialog} right={rightContentsDialog} />;

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
      <Button disabled={!selectedTipAtributa} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openDialog(EntityOperation.UPDATE)} />
      <Button disabled={!selectedTipAtributa} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => <Button disabled={!selectedTipAtributa} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openDialog(EntityOperation.DELETE)} />;

  return (
    <div className="layout-attribute">
      <Panel header={Labels.TITLE_PANEL_CRUD_TIP_ATRIBUTA} className="panel-attribute-list">
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            filterDisplay="row"
            value={tipAtributaList}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => {
              setSelectedTipAtributa(e.data);
            }}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column field={"nazivTipaVrednostiAtributa"} header={Labels.COLUMN_HEADER_TIP_VREDNOSTI_ATRIBUTA} filterMatchMode="contains" showFilterMenu={false} filter sortable />
          </DataTable>
          <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }} footer={dialogFooter()}>
            <CrudTipAtributa
              setIndex={setIndex}
              index={index}
              dialogRef={dialogRef}
              tipAtributaOperation={entityOperation}
              tipAtributa={tipAtributaToChange}
              onCreateTipAtributa={createTipAtributa}
              onUpdateTipAtributa={updateTipAtributa}
              onDeleteTipAtributa={deleteTipAtributa}
              onCancel={onCancel}
            />
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
