import axios from "axios";
import { Button } from "primereact/button";
import React, { useContext, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import Repozitorijum from "./Repozitorijum";
import frontendRepozitorijumJson from "./repozitorijum.json";
import RepozitorijumDto from "./RepozitorijumDto";

export default function Verzija() {
  const [backendRepozitorijum, setBackendRepozitorijum] = useState<RepozitorijumDto>();
  const [frontendRepozitorijum, setFrontendRepozitorijum] = useState<any>();
  const { authData } = useContext(AppContext);

  useEffectOnce(() => {
    setFrontendRepozitorijum(frontendRepozitorijumJson);

    axios.get(Endpoint.REPOZITORIJUM, axiosConfig(authData.token)).then((repozitorijumCollection) => {
      setBackendRepozitorijum(repozitorijumCollection.data);
    });
  });

  return (
    <div>
      <Button label={(window as any).REACT_APP_ENVIRONMENT} disabled />
      <Repozitorijum panelHeader={Labels.TITLE_PANEL_FRONTEND} panelCollapsed={false} repozitorijum={frontendRepozitorijum} />
      <Repozitorijum panelHeader={Labels.TITLE_PANEL_BACKEND} panelCollapsed={true} repozitorijum={backendRepozitorijum} />
    </div>
  );
}
