import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState, useContext, useImperativeHandle } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import Endpoint from "../../../endpoints/Endpoint";
import { InputSwitch } from "primereact/inputswitch";
import MessageType from "../../../infrastructure/MessageType";
import { InputTextarea } from "primereact/inputtextarea";
import { AppContext } from "../../Store";

interface CrudEnumStavkaProps {
  enumStavkaOperation: string;
  enumStavka?: any;
  onCreateEnumStavka: Function;
  onUpdateEnumStavka: Function;
  onDeleteEnumStavka: Function;
  onCancel: Function;
  tipAtributaId: string;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudEnumStavka(props: CrudEnumStavkaProps) {
  const { enumStavkaOperation, enumStavka, onCreateEnumStavka, onUpdateEnumStavka, onDeleteEnumStavka, tipAtributaId, dialogRef, setIndex, index } = props;
  const [enumStavkaChange, setEnumStavkaChange] = useState<Partial<any>>(enumStavka);
  const { showGrowlMessage } = useContext(AppContext);

  useEffectOnce(() => {
    if (!enumStavka) {
      setEnumStavkaChange({
        ...enumStavkaChange,
        tipAtributa: Endpoint.TIP_ATRIBUTA + "/" + tipAtributaId,
        opcioni: false,
      });
    }
  });

  const validateInput = (message: string) => {
    if (!enumStavkaChange || !enumStavkaChange.naziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    if (!enumStavkaChange || !enumStavkaChange.numVrednost) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NUM_VREDNOST);
      return false;
    }
    return true;
  };

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
        return;
      }
      onCreateEnumStavka(enumStavkaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
        });
    },
    onUpdate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
        return;
      }
      onUpdateEnumStavka(enumStavkaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
        });
    },
    onDelte: () => {
      onDeleteEnumStavka(enumStavkaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    },
  }));

  return (
    <div className="panel-attribute">
      <TabView
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.TAB_ENUM_STAVKA}>
          <div className="col-8 xl:col-8 lg:col-8 md:col-6 sm:col-12">
            <div className="grid align-items-center">
              <div className="col-4 mb-3">{Labels.LABEL_NAZIV}</div>
              <div className="col-8 p-fluid p-0">
                <InputTextarea
                  disabled={isFormDisabled(enumStavkaOperation)}
                  value={enumStavkaChange && enumStavkaChange.naziv ? enumStavkaChange.naziv : ""}
                  onChange={(e: any) => {
                    setEnumStavkaChange({
                      ...enumStavkaChange,
                      naziv: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_NUM_VREDNOST}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(enumStavkaOperation)}
                  value={enumStavkaChange && enumStavkaChange.numVrednost ? enumStavkaChange.numVrednost : ""}
                  onChange={(e: any) => {
                    setEnumStavkaChange({
                      ...enumStavkaChange,
                      numVrednost: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_OPIS}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(enumStavkaOperation)}
                  value={enumStavkaChange && enumStavkaChange.opis ? enumStavkaChange.opis : ""}
                  onChange={(e: any) => {
                    setEnumStavkaChange({
                      ...enumStavkaChange,
                      opis: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_OPCIONI}</div>
              <div className="col-8 p-fluid p-0">
                <InputSwitch
                  disabled={isFormDisabled(enumStavkaOperation)}
                  checked={enumStavkaChange && enumStavkaChange.opcioni ? enumStavkaChange.opcioni : false}
                  onChange={(e: any) => {
                    setEnumStavkaChange({
                      ...enumStavkaChange,
                      opcioni: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_REDOSLED}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(enumStavkaOperation)}
                  value={enumStavkaChange && enumStavkaChange.redosled ? enumStavkaChange.redosled : ""}
                  onChange={(e: any) => {
                    setEnumStavkaChange({
                      ...enumStavkaChange,
                      redosled: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_SIFRA_VEZE}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(enumStavkaOperation)}
                  value={enumStavkaChange && enumStavkaChange.sifraVeza ? enumStavkaChange.sifraVeza : ""}
                  onChange={(e: any) => {
                    setEnumStavkaChange({
                      ...enumStavkaChange,
                      sifraVeza: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
