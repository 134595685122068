import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState, useContext, useImperativeHandle, useRef } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { AppContext } from "../../Store";
import MessageType from "../../../infrastructure/MessageType";
import { isFormDisabled, getImage } from "../../../utils/Utils";
import noImageIcon from "./../../../images/no-image-icon.png";

interface CrudSponzorProps {
  sponzorOperation: string;
  sponzor?: any;
  onCreateSponzor: Function;
  onUpdateSponzor: Function;
  onDeleteSponzor: Function;
  dialogRef?: any;
}

export default function CrudSponzor(props: CrudSponzorProps) {
  const { sponzorOperation, sponzor, onCreateSponzor, onUpdateSponzor, onDeleteSponzor, dialogRef } = props;
  const [image, setImage] = useState<any>(sponzor?.logo);
  const fileInput = useRef<any>();

  const [sponzorChange, setSponzorChange] = useState<Partial<any>>(sponzor);
  const { showGrowlMessage } = useContext(AppContext);
  const readFileDataAsBase64 = (file: any) => {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  const _handleFileChange = (e: any) => {
    const file = e.target.files[0];
    readFileDataAsBase64(file)
      .then((response: any) => {
        const source = response.split("base64,")[1];
        const image = new Image();
        image.src = response;
        image.onload = function (): any {
          setSponzorChange({
            ...sponzorChange,
            logo: source,
          });

          setImage(source);
        };
      })
      .catch((err) => console.log(err));
  };

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
        return;
      }
      onCreateSponzor(sponzorChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
        });
    },

    onUpdate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
        return;
      }
      onUpdateSponzor(sponzorChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
        });
    },

    onDelete: () => {
      onDeleteSponzor(sponzorChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    },
  }));

  const validateInput = (titleMessage: string) => {
    if (!sponzorChange) {
      showGrowlMessage(MessageType.ERROR, titleMessage, Labels.MESSAGES_OBAVEZNA_POLJA);
      return false;
    }
    if (sponzorChange && !sponzorChange.name) {
      showGrowlMessage(MessageType.ERROR, titleMessage, Labels.MESSAGES_POLJE_IME);
      return false;
    }
    if (sponzorChange && !sponzorChange.code) {
      showGrowlMessage(MessageType.ERROR, titleMessage, Labels.MESSAGES_POLJE_CODE);
      return false;
    }
    if (sponzorChange && !sponzorChange.displayOrder) {
      showGrowlMessage(MessageType.ERROR, titleMessage, Labels.MESSAGES_POLJE_DISPLAY_ORDER);
      return false;
    }
    return true;
  };

  return (
    <div className="panel-user">
      <TabView renderActiveOnly={false} activeIndex={0}>
        <TabPanel header={Labels.TAB_SPONZOR}>
          <div className="grid ">
            <div className="col-8">
              <div className="grid align-items-center">
                <div className="xl-6 lg-6 md-6 sm-12">
                  <div className="grid dir-col">
                    <div className="col-12">
                      <div className="grid align-items-center">
                        <div className="col-4">{Labels.LABEL_IME}</div>
                        <div className="col-8">
                          <InputText
                            disabled={isFormDisabled(sponzorOperation)}
                            value={sponzorChange && sponzorChange.name ? sponzorChange.name : ""}
                            onChange={(e: any) => {
                              setSponzorChange({
                                ...sponzorChange,
                                name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="grid align-items-center">
                        <div className="col-4">{Labels.LABEL_CODE}</div>
                        <div className="col-8">
                          <InputText
                            disabled={isFormDisabled(sponzorOperation)}
                            value={sponzorChange && sponzorChange.code ? sponzorChange.code : ""}
                            onChange={(e: any) => {
                              setSponzorChange({
                                ...sponzorChange,
                                code: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="grid align-items-center">
                        <div className="col-4">{Labels.LABEL_DISPLAY_ORDER}</div>
                        <div className="col-8">
                          <InputText
                            disabled={isFormDisabled(sponzorOperation)}
                            value={sponzorChange && sponzorChange.displayOrder ? sponzorChange.displayOrder : ""}
                            onChange={(e: any) => {
                              setSponzorChange({
                                ...sponzorChange,
                                displayOrder: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="grid align-items-center">
                        <div className="col-4">{Labels.LABEL_OPIS}</div>
                        <div className="col-8">
                          <InputText
                            disabled={isFormDisabled(sponzorOperation)}
                            value={sponzorChange && sponzorChange.description ? sponzorChange.description : ""}
                            onChange={(e: any) => {
                              setSponzorChange({
                                ...sponzorChange,
                                description: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="grid align-items-center">
                        <div className="col-4">{Labels.LABEL_NOTE}</div>
                        <div className="col-8">
                          <InputText
                            disabled={isFormDisabled(sponzorOperation)}
                            value={sponzorChange && sponzorChange.note ? sponzorChange.note : ""}
                            onChange={(e: any) => {
                              setSponzorChange({
                                ...sponzorChange,
                                note: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="grid align-items-center">
                        <div className="col-4">{Labels.LABEL_ADRESA}</div>
                        <div className="col-8">
                          <InputText
                            disabled={isFormDisabled(sponzorOperation)}
                            value={sponzorChange && sponzorChange.address ? sponzorChange.address : ""}
                            onChange={(e: any) => {
                              setSponzorChange({
                                ...sponzorChange,
                                address: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl-6 lg-6 md-6 sm-12">
                  <div className="grid dir-col">
                    <div className="col-12">
                      <div className="grid align-items-center">
                        <div className="col-4">{Labels.LABEL_TELEFON}</div>
                        <div className="col-8">
                          <InputText
                            disabled={isFormDisabled(sponzorOperation)}
                            value={sponzorChange && sponzorChange.phone ? sponzorChange.phone : ""}
                            onChange={(e: any) => {
                              setSponzorChange({
                                ...sponzorChange,
                                phone: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="grid align-items-center">
                        <div className="col-4">{Labels.LABEL_WEBSITE_URL}</div>
                        <div className="col-8">
                          <InputText
                            disabled={isFormDisabled(sponzorOperation)}
                            value={sponzorChange && sponzorChange.websiteUrl ? sponzorChange.websiteUrl : ""}
                            onChange={(e: any) => {
                              setSponzorChange({
                                ...sponzorChange,
                                websiteUrl: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="grid align-items-center">
                        <div className="col-4">{Labels.LABEL_SLIKA}</div>
                        <div className="col-8" onClick={() => fileInput.current.click()}>
                          <img alt="L" className="sponsor-logo big-logo" src={getImage(image, noImageIcon)} />
                          <input hidden id="file" type="file" ref={fileInput} accept="image/*" onChange={_handleFileChange} />
                          {!image && <p className="text-align-items-center"> Izaberite</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
