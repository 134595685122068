import React from "react";
import TipAtributaRouter from "./TipAtributaRouter";
import EnumTipovaVrednostiAtributa from "../../utils/EnumTipovaVrednostiAtributa";

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.changeTab = this.changeTab.bind(this);
    this.state = {
      prikazTaba: true,
      defaultTab: "",
    };
  }

  changeTab(value) {
    this.props.changeState("defaultTab", value);
  }

  render() {
    if (!this.props.auts) {
      return null;
    }
    let autsi = <div></div>;
    if (!this.props.auts.vertikalniPrikaz) {
      if (this.props.autsiPodredjeni && this.props.autsiPodredjeni[this.props.auts.id]) {
        autsi = this.props.autsiPodredjeni[this.props.auts.id].map((podredjeni, p) => {
          if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.PANEL) {
            return (
              <Panel
                key={podredjeni.id}
                auts={podredjeni}
                autsiPodredjeni={this.props.panelIPodredjeniObject}
                initValue={this.props.initValue}
                enumiPoAUTS={this.props.enumiPoAUTS}
                changeState={this.props.changeState}
                prikazAUTS={this.props.prikazAUTS}
                sifraAutsPoTipu={this.props.sifraAutsPoTipu}
                zaPretragu={false}
                prikazivanjeAutsa={this.props.prikazivanjeAutsa}
                prikazivanjeVertikalno={this.props.prikazivanjeVertikalno}
                showHtmlInfo={this.props.showHtmlInfo}
                hideHtmlInfo={this.props.hideHtmlInfo}
              />
            );
          }

          let prikaz = this.props.prikazivanjeAutsa(podredjeni);
          if (prikaz) {
            let className = "mb-3";
            className += podredjeni.ispisujeSeLabela ? " mt-2" : " mt-4";

            if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.IZBORNI) {
              className = "mt-2";
            } else if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.BROJNI) {
              className += " col-md-2";
            } else if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.DATUMSKI) {
              className += " ml-3";
            } else {
              className += " col-md-3";
            }

            let autsPolje = (
              <div className={className} key={podredjeni.id}>
                <div className={!podredjeni.ispisujeSeLabela ? "ml-3 mt-3" : podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.NASLOV ? "ml-3 mt-3 font-weight-bold" : "mt-2"}>
                  {podredjeni.ispisujeSeLabela ? podredjeni.naziv : ""}
                  {!podredjeni.opciono ? "*" : ""}
                  {podredjeni.ispisujeSeLabela && podredjeni.relevantno ? <i class="fas fa-bell ml-2"> </i> : ""}
                  {podredjeni.ispisujeSeLabela && podredjeni.htmlInfo && (
                    <button className="btn btn-outline-danger ml-1 mb-1 button_htmlInfo" onClick={() => this.props.showHtmlInfo(podredjeni.htmlInfo)}>
                      <i className="fas fa-info-circle"></i>
                    </button>
                  )}
                </div>
                <TipAtributaRouter
                  tvaNumVrednost={podredjeni.tvaNumVrednost}
                  initValue={this.props.initValue[podredjeni.id]}
                  changeState={this.props.changeState}
                  tipSkupaAtributaKey={podredjeni.id}
                  atributUTipuSkupaID={podredjeni.id}
                  auts={podredjeni}
                  enumiPoAUTS={this.props.enumiPoAUTS ? this.props.enumiPoAUTS[podredjeni.id] : []}
                  prikazAUTS={this.props.prikazAUTS}
                  sifraAutsPoTipu={this.props.sifraAutsPoTipu}
                  zaPretragu={false}
                  smallFormat={true}
                />
              </div>
            );
            return <React.Fragment key={p}>{autsPolje}</React.Fragment>;
          }
        });
      }
      autsi = <div className="form-group autsDiv row card-body">{autsi}</div>;
    } else {
      if (this.props.autsiPodredjeni[this.props.auts.id]) {
        autsi = this.props.autsiPodredjeni[this.props.auts.id].map((podredjeni) => {
          if (podredjeni.tvaNumVrednost === EnumTipovaVrednostiAtributa.PANEL) {
            return (
              <Panel
                key={podredjeni.id}
                auts={podredjeni}
                autsiPodredjeni={this.props.autsiPodredjeni}
                initValue={this.props.initValue}
                enumiPoAUTS={this.props.enumiPoAUTS}
                changeState={this.props.changeState}
                prikazAUTS={this.props.prikazAUTS}
                sifraAutsPoTipu={this.props.sifraAutsPoTipu}
                zaPretragu={false}
                prikazivanjeAutsa={this.props.prikazivanjeAutsa}
                prikazivanjeVertikalno={this.props.prikazivanjeVertikalno}
                showHtmlInfo={this.props.showHtmlInfo}
                hideHtmlInfo={this.props.hideHtmlInfo}
              />
            );
          }
          let prikaz = this.props.prikazivanjeAutsa(podredjeni);
          if (prikaz) {
            return (
              <div key={podredjeni.id} className={"form-group autsDiv row card-body"}>
                {this.props.prikazivanjeVertikalno(podredjeni)}
              </div>
            );
          }
        });
      }
    }
    return (
      <div className={this.props.auts.ispisujeSeLabela ? "card w-100" : "w-100"} key={this.props.auts.id}>
        <h5 className={this.props.auts.ispisujeSeLabela ? "card-header mb-2" : "mb-2"}>{this.props.auts.ispisujeSeLabela ? this.props.auts.naziv : ""}</h5>
        {autsi}
      </div>
    );
  }
}

export default Panel;
