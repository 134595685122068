import "jspdf-autotable";
import "./arial-normal";

interface ExportDataLogicalType {
  exportPdf: (data: any, name: any) => void;
  exportXls: (data: any, name: any) => void;
  exportCsv: (data: any, dt: any, name: any) => void;
  exportJson: (data: any, name: any) => void;
}

export default function ExportDataLogical(): ExportDataLogicalType {
  const jsPDF = require("jspdf");

  const exportPdf = (data: any, name: any) => {
    let propertyArray = data && data[0] ? Object.getOwnPropertyNames(data[0]) : [];

    let exportColumns = new Array<any>();
    let columnStyles = new Array<any>();

    propertyArray.forEach((property: any) => {
      exportColumns.push({ title: property, dataKey: property });
      columnStyles.push({ columnWidth: "auto", font: "arial", cellWidth: "auto" });
    });

    const options = {
      columnStyles: columnStyles,
      headStyles: { font: "arial" },
      style: { font: "arial" },
      showHead: "firstPage",
      startY: 50,
    };

    const doc = new jsPDF.default("landscape", "pt", "a4");

    doc.autoTable(exportColumns, data, options);

    doc.save(name);
  };

  const exportXls = (data: any, name: any) => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
      saveAsExcelFile(excelBuffer, name);
    });
  };

  const saveAsExcelFile = (buffer: any, fileName: any) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const exportCsv = (data: any, dt: any, name: any) => {
    dt.current.exportCSV({ data });
  };

  const exportJson = (data: any, name: any) => {
    let dataStr = JSON.stringify(data);
    let dataUri = "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

    let exportFileDefaultName = name + ".json";

    let linkElement = document.createElement("a");
    linkElement.setAttribute("href", dataUri);
    linkElement.setAttribute("download", exportFileDefaultName);
    linkElement.click();
  };

  return { exportPdf, exportXls, exportCsv, exportJson };
}

export type { ExportDataLogicalType };
