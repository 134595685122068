const Parametri = {
  // pacijnet
  PACIJENT_ADMINISTRATIVNA_NAPOMENA: "pacijent.administrativnaNapomena",
  PACIJENT_MEDICINSKA_NAPOMENA: "pacijent.medicinskaNapomena",
  PACIJENT_RADNO_MESTO: "pacijent.prikazRadnoMesto",
  PACIJENT_ZANIMANJE: "pacijent.prikazZanimanja",
  PACIJENT_STRUCNA_SPREMA: "pacijent.strucnaSprema",
  //registar
  REGISTAR_PRETRAGA_GODINA_OD: "registar.kriterijumZaPretraguGodinaOd",
  REGISTAR_PRETRAGA_GODINA_DO: "registar.kriterijumZaPretraguGodinaDo",
  REGISTAR_PRETRAGA_IME: "registar.kriterijumZaPretraguIme",
  REGISTAR_PRETRAGA_PREZIME: "registar.kriterijumZaPretraguPrezime",
  REGISTAR_PRETRAGA_JMBG: "registar.kriterijumZaPretraguJMBG",
  REGISTAR_PRETRAGA_LBO: "registar.kriterijumZaPretraguLBO",
  REGISTAR_PRETRAGA_POL: "registar.kriterijumZaPretraguPol",
  REGISTAR_ODABIR_DATUME_PRE_UNOSA: "registar.odabirDatumaPregledaPreUnosa",
  REGISTAR_BROJ_REZULTATA_U_PRETRAZI: "registar.brojRezultataUPretrazi",
};

export default Parametri;
