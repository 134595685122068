

import React from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { axiosConfig } from "../../../../utils/Utils";
import Endpoint from '../../../../endpoints/Endpoint';

const token = localStorage.hasOwnProperty("ulogovanRadnik") ? JSON.parse(localStorage.getItem("ulogovanRadnik")).token : "";;

class Konekcija extends React.Component {
	constructor(props) {
		super(props);
		this.ciscenjePulaKonekcija = this.ciscenjePulaKonekcija.bind(this);
		this.state = {
			aktivnihKonekcija: 0,
			dostupnihKonekcija: 0,
			dataSource: "RegiXDS"
		};
	}

	ciscenjePulaKonekcija() {
		axios.post(Endpoint.RELOAD + '/ciscenjePulaKonekcija', {
			dataSource: this.state.dataSource
		}, axiosConfig(token))
			.then((response) => {
				NotificationManager.success('Uspešno očišćen pull konekcija.');
			})
			.catch((error) => {
				NotificationManager.error('Došlo je do greške prilikom čišćenja pulla konekcija.');
				console.log(error);
			});
	}

	render() {
		return (
			<div>
				<div className="card">
					<div className="card-header">
						Konekcije
					</div>
					<div className="card-body">
						Data source:  <input
							type="text"
							className="form-control"
							onChange={(event) => this.setState({ "dataSource": event.target.value })}
							value={this.state.dataSource}
						/>
						<p className="card-text mt-3">
							Broj zauzetih konekcija je <span className="text-primary">{this.state.aktivnihKonekcija}</span> od maksimalno <span className="text-danger">{this.state.dostupnihKonekcija}</span>.</p>
						<button className="btn btn-primary" onClick={this.ciscenjePulaKonekcija.bind(this)}>Čišćenje pula konekcija</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Konekcija;
