import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import React, { useState } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import RepozitorijumDto from "./RepozitorijumDto";

interface RepozitorijumProps {
  panelHeader: string;
  panelCollapsed: boolean;
  repozitorijum?: RepozitorijumDto;
}

export default function Repozitorijum(props: RepozitorijumProps) {
  const { panelHeader, repozitorijum } = props;
  const [panelCollapsed, setPanelCollapsed] = useState(props.panelCollapsed);
  const [expandedRows, setExpandedRows] = useState<any>();

  const rowExpansionIndexTemplate = (rowData: any, column: { rowIndex: number }) => {
    return column.rowIndex + 1 + "";
  };

  const rowExpansionTemplate = (data: any) => (
    <DataTable value={data.izmena}>
      <Column className="column-width-percent-5 column-align-center" body={rowExpansionIndexTemplate} header={Labels.COLUMN_HEADER_R_BR_} />
      <Column className="column-width-percent-10" field="naslov" header={Labels.COLUMN_HEADER_NAZIV_IZMENE} />
      <Column field="opis" header={Labels.COLUMN_HEADER_OPIS} />
      <Column className="column-width-percent-20" field="napomena" header={Labels.COLUMN_HEADER_NAPOMENA} />
      <Column className="column-width-percent-10" field="tiket" header={Labels.COLUMN_HEADER_TIKET} />
    </DataTable>
  );

  return (
    <Panel header={panelHeader} toggleable collapsed={panelCollapsed} onToggle={(e) => setPanelCollapsed(e.value)}>
      <DataTable
        value={repozitorijum ? repozitorijum.verzija : []}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="oznaka"
      >
        <Column expander={true} style={{ width: "3em" }} />
        <Column className="column-width-percent-10 column-align-center" field="oznaka" header={Labels.COLUMN_HEADER_OZNAKA} />
        <Column className="column-width-percent-10" field="datumOd" header={Labels.COLUMN_HEADER_DATUM_OD} />
        <Column className="column-width-percent-10" field="datumDo" header={Labels.COLUMN_HEADER_DATUM_DO} />
        <Column field="kratakOpis" header={Labels.COLUMN_HEADER_KRATAK_OPIS} />
      </DataTable>
    </Panel>
  );
}
