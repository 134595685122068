import axios from "axios";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVLink } from "react-csv";
import { Link, withRouter } from "react-router-dom";
// import Select from "react-select";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Endpoint from "../endpoints/Endpoint";
import { getObjectOperator, objectAndOr } from "../utils/AUTSOperator";
import { axiosConfig, CALENDAR_DATE_FORMAT, registarID } from "../utils/Utils";
import TipAtributaRouter from "./hijerarhija/TipAtributaRouter";
import CUDialogPacijent from "./pacijent/CUDialogPacijent";
import Routers from "../utils/Routers";
import Labels from "../infrastructure/Labels_sr_Latn_RS";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { brojRezultatePretrageOptions, findBrojRezultataByCode } from "../utils/EnumBrojRezultataPretrage";

const token = localStorage.hasOwnProperty("ulogovanRadnik") ? JSON.parse(localStorage.getItem("ulogovanRadnik")).token : "";

const headersCSV = [
  { label: Labels.COLUMN_HEADER_PACIJENT, key: "pacijent" },
  { label: Labels.COLUMN_HEADER_JMBG, key: "jmbg" },
  { label: Labels.COLUMN_HEADER_LBO, key: "lbo" },
  { label: Labels.COLUMN_HEADER_IDENTIFIKACIONI_BROJ, key: "identifikacioniBroj" },
  { label: Labels.COLUMN_HEADER_POSLEDNJI_UNOS, key: "poslednjiUnos" },
];

class Registar extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.dodajRedPretrage = this.dodajRedPretrage.bind(this);
    this.obrisiRedPretrage = this.obrisiRedPretrage.bind(this);
    this.changeTipaSkupaAtributa = this.changeTipaSkupaAtributa.bind(this);
    this.changeOperator = this.changeOperator.bind(this);
    this.changeVeza = this.changeVeza.bind(this);
    this.changeAtributUTipuSkupa = this.changeAtributUTipuSkupa.bind(this);
    this.pretraga = this.pretraga.bind(this);
    this.changeState = this.changeState.bind(this);
    this.noviUnos = this.noviUnos.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.alertCancel = this.alertCancel.bind(this);
    this.onChangeUni = this.onChangeUni.bind(this);
    this.handleKeyPressLogIn = this.handleKeyPressLogIn.bind(this);
    this.afterSubmitPacijent = this.afterSubmitPacijent.bind(this);
    this.getAllTipSkupaAtributa = this.getAllTipSkupaAtributa.bind(this);
    this.getAllAtributUTipuSkupa = this.getAllAtributUTipuSkupa.bind(this);
    this.responseAUTS = this.responseAUTS.bind(this);
    this.csvDownload = this.csvDownload.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.closeBasicDialog = this.closeBasicDialog.bind(this);
    this.csvLink = React.createRef();
    this.calendarRef = React.createRef();
    this.state = {
      displayBasic: false,
      displayDialog: false,
      ucitano: false,
      registarID: registarID,
      ime: "",
      prezime: "",
      godinaOd: "",
      godinaDo: "",
      pol: "",
      jmbg: "",
      lbo: "",
      brojPrikazaPretrage: 20,
      brojRezultatePretrage: 200,
      kriterijumZaPretraguGodinaDo: false,
      kriterijumZaPretraguGodinaOd: false,
      kriterijumZaPretraguIme: false,
      kriterijumZaPretraguJMBG: false,
      kriterijumZaPretraguLBO: false,
      kriterijumZaPretraguPol: false,
      kriterijumZaPretraguPrezime: false,
      prikazTekucihUnosaKodPretrage: false,
      registar: {},
      redPretrageElementi: {},
      brojRedova: 0,
      tipoviSkupaAtributa: [],
      autsiPoTSA: {},
      autsiPoTSAFull: {},
      enumiPoAUTS: {},
      operatoriPoTSA: {},
      poljePoTSA: {},
      initValue: {},
      tableDataPretraga: [],
      dataCSV: [],
      show: false,
      showDialogPacijent: false,
      selectTipSkupAtributa: "",
      selectPacijent: "",
      prikazAlerta: false,
      alertText: "",
      vratiNaPocetnu: false,
      strana: 0,
      odabirDatumaPreUnosa: false,
      vremeUnosa: undefined,
    };
  }

  loadFromServer() {
    this._isMounted = true;
    axios
      .get(Endpoint.PARAMETAR + "/registri", axiosConfig(token))
      .then((response) => {
        if (this._isMounted) {
          const { brojRezultata, odabirDatumaPregledaPreUnosa, brojRezultataUPretrazi } = response.data;
          this.setState({
            brojPrikazaPretrage: brojRezultata ?? 0,
            odabirDatumaPreUnosa: odabirDatumaPregledaPreUnosa,
            brojRezultatePretrage: findBrojRezultataByCode(brojRezultataUPretrazi),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(Endpoint.REGISTAR + "/" + this.state.registarID, axiosConfig(token))
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            registar: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showPopup(show, pacijentId) {
    this.setState({
      displayBasic: true,
      show: show,
      selectPacijent: pacijentId,
    });
  }

  closeDialog() {
    this.setState({
      displayDialog: false,
    });
  }

  closeBasicDialog() {
    this.setState({
      displayBasic: false,
    });
  }

  alertCancel() {
    this.setState({
      prikazAlerta: false,
    });
  }

  handleKeyPressLogIn(event) {
    if (event.key === "Enter") {
      this.pretraga(false, false);
    }
  }

  getAllTipSkupaAtributa() {
    axios
      .get(
        Endpoint.TIP_SKUPA_ATRIBUTA + "/search/findTipSkupaAtributaByRegistar",
        axiosConfig(token, {
          registar: Endpoint.REGISTAR + "/" + this.state.registarID,
        })
      )
      .then((response) => {
        if (response.data._embedded.tipHijerarhijeAtributas) {
          let optionsTHA = response.data._embedded.tipHijerarhijeAtributas.map((tipHijerarhijeAtributa, tha) => {
            let key = tipHijerarhijeAtributa.id;
            let naziv = tipHijerarhijeAtributa.naziv;
            return { value: key, label: naziv };
          });
          if (this._isMounted) {
            this.setState({
              tipoviSkupaAtributa: optionsTHA,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllAtributUTipuSkupa() {
    axios
      .get(
        Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findAtributUTipuSkupaByRegistar",
        axiosConfig(token, {
          registar: Endpoint.REGISTAR + "/" + this.state.registarID,
        })
      )
      .then((response) => {
        this.responseAUTS(response, 0, {});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeState(stateName, stateKey, stateValue) {
    if (Array.isArray(stateName)) {
      let stateSvi = {};
      stateName.map((elem) => {
        let stateOne = this.state[elem[0]];
        if (stateOne !== undefined) {
          Object.assign(stateOne, { [elem[1]]: elem[2] });
          stateSvi[elem[0]] = stateOne;
        }
      });
      this.setState({
        stateSvi,
      });
    } else {
      let stateSvi = this.state[stateName];
      if (stateSvi !== undefined) {
        let key = parseInt(stateKey);
        Object.assign(stateSvi, { [key]: stateValue });
        this.setState({
          [stateName]: stateSvi,
        });
      }
    }
  }

  onChangeUni(attr, val) {
    this.setState({
      [attr]: val,
    });
  }

  afterSubmitPacijent(pacijentID) {
    this.props.history.push(`${Routers.PACIJENTI}/${pacijentID}`);
  }

  noviUnos() {
    const { odabirDatumaPreUnosa, vremeUnosa } = this.state;
    let unosID = this.state.selectTipSkupAtributa;
    if (odabirDatumaPreUnosa && !vremeUnosa) {
      this.setState({
        prikazAlerta: true,
        alertText: Labels.TITLE_MESSAGES_MORATE_ODABRATI_DATUM_PREGLEDA,
      });
      return;
    }
    if (unosID === "") {
      this.setState({
        prikazAlerta: true,
        alertText: Labels.TITLE_MESSAGES_MORATE_ODABRATI_NEKI_OD_UNOSA,
      });
      return;
    }
    axios
      .get(
        Endpoint.BROJ_UNOSA_PO_PACIJENTU,
        axiosConfig(token, {
          thaID: this.state.selectTipSkupAtributa,
          pacijentID: this.state.selectPacijent,
        })
      )
      .then((response) => {
        if (response.data.isValid) {
          this.props.history.push({
            pathname: Routers.UNOSI_NOVI,
            state: {
              tsaID: this.state.selectTipSkupAtributa,
              pacijentID: this.state.selectPacijent,
              vremeUnosa: odabirDatumaPreUnosa ? vremeUnosa.toString() : undefined,
            },
          });
        } else {
          this.setState({
            prikazAlerta: true,
            alertText: Labels.MESSAGES_NIJE_MOGUCE_KREIRATI_UNOS_JER_PREMASUJE_MAKSIMALAN_BROJ_MOGUCIH_KREIRANIH_PO_PACIJENTU + "(" + response.data.maxInstanci + ")",
          });
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadFromServer();
    this.pretraga(false, true);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  dodajRedPretrage() {
    var brReda = this.state.brojRedova + 1;
    var redoviPretrage = this.state.redPretrageElementi;
    Object.assign(redoviPretrage, { [brReda]: {} });
    this.setState({
      redPretrageElementi: redoviPretrage,
      brojRedova: brReda,
    });
  }

  obrisiRedPretrage(key) {
    delete this.state.redPretrageElementi[key];
    this.setState(this.state);
  }

  async csvDownload() {
    this.pretraga(true, false);
  }

  responseAUTS(response, key, redoviPretrage) {
    let autsiPoTSASvi = this.state.autsiPoTSA;
    let autsiPoTSAFullSvi = this.state.autsiPoTSAFull;
    let filterOption = response.data._embedded.atributUTipuSkupas.filter((x) => x.tipVrednostiAtributaNumVrednost <= 5);
    let optionsAUTS = filterOption.map((atributUTipuSkupa, auts) => {
      let key = atributUTipuSkupa.id;
      let naziv = atributUTipuSkupa.naziv + (!this.state.prikazTekucihUnosaKodPretrage ? " (" + atributUTipuSkupa.tipSkupaAtributaNaziv + ")" : "");
      return { value: key, label: naziv };
    });
    Object.assign(autsiPoTSASvi, { [key]: optionsAUTS });
    Object.assign(autsiPoTSAFullSvi, { [key]: response.data._embedded.atributUTipuSkupas });
    if (this._isMounted) {
      this.setState({
        redPretrageElementi: redoviPretrage,
        autsiPoTSA: autsiPoTSASvi,
        autsiPoTSAFull: autsiPoTSAFullSvi,
      });
    }
  }

  changeTipaSkupaAtributa(key, value) {
    let redoviPretrage = this.state.redPretrageElementi;
    let redPretrage = redoviPretrage[key];
    Object.assign(redPretrage, { tipSkupaAtributa: value });
    redoviPretrage[key] = redPretrage;
    axios
      .get(
        Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findByTipSkupaAtributaAndAktivanZaPretraguOrderByRedosledAsc",
        axiosConfig(token, {
          tipSkupaAtributa: Endpoint.TIP_SKUPA_ATRIBUTA + "/" + value.value,
        })
      )
      .then((response) => {
        this.responseAUTS(response, key, redoviPretrage);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeOperator(key, value) {
    let redoviPretrage = this.state.redPretrageElementi;
    let redPretrage = redoviPretrage[key];
    Object.assign(redPretrage, { operator: value });
    this.setState({
      redPretrageElementi: redoviPretrage,
    });
  }

  changeVeza = (key, value) => {
    const label = value.originalEvent.target.innerText;
    let redoviPretrage = this.state.redPretrageElementi;
    Object.assign(redoviPretrage[key], { veza: { label: label, value: value.value } });
    this.setState({
      redPretrageElementi: redoviPretrage,
    });
  };

  changeAtributUTipuSkupa(key, value) {
    let redoviPretrage = this.state.redPretrageElementi;
    let redPretrage = redoviPretrage[key];
    Object.assign(redPretrage, { atributUTipuSkupa: value });
    redoviPretrage[key] = redPretrage;
    axios
      .get(
        Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findTipVrednostiAtributaByAtributUTipuSkupa",
        axiosConfig(token, {
          atributUTipuSkupa: Endpoint.ATRIBUT_U_TIPU_SKUPA + "/" + value.value,
        })
      )
      .then((response) => {
        let tipVrednosti = response.data._embedded.tipVrednostiAtributas[0];
        Object.assign(redPretrage, { tipVrednosti: tipVrednosti });
        let objectOperator = getObjectOperator(tipVrednosti.numerickaVrednost);
        let operatoriPoTSASvi = this.state.operatoriPoTSA;
        Object.assign(operatoriPoTSASvi, { [key]: objectOperator });
        let initValueSvi = this.state.initValue;
        Object.assign(initValueSvi, { [key]: "" });
        axios
          .get(
            Endpoint.ENUM_STAVKA + "/search/findEnumStavkaByAUST",
            axiosConfig(token, {
              auts: Endpoint.ATRIBUT_U_TIPU_SKUPA + "/" + value.value,
            })
          )
          .then((response) => {
            this.setState({
              operatoriPoTSA: operatoriPoTSASvi,
              initValue: initValueSvi,
              enumiPoAUTS: response.data._embedded.enumStavkas,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  pretraga(sviRezultati, ucitavanjePodataka) {
    if (this._isMounted) {
      this.setState({
        ucitano: false,
      });
    }
    if (ucitavanjePodataka) {
      this.getAllTipSkupaAtributa();
      this.getAllAtributUTipuSkupa();
    }
    const redPretrageFilter = this.state.redPretrageElementi;
    Object.keys(redPretrageFilter).forEach((key) => {
      if (Object.keys(redPretrageFilter[key]).length === 0) {
        delete redPretrageFilter[key];
      }
    });

    const paramsPretraga = {
      registarID: this.state.registarID,
      ime: this.state.ime,
      prezime: this.state.prezime,
      godinaOd: this.state.godinaOd,
      godinaDo: this.state.godinaDo,
      pol: this.state.pol,
      jmbg: this.state.jmbg,
      lbo: this.state.lbo,
      redPretrage: redPretrageFilter,
      initValue: this.state.initValue,
      sviRezultati: sviRezultati,
      brojRezultatePretrage: this.state.brojRezultatePretrage?.code,
    };

    axios
      .post(Endpoint.PRETRAGA, paramsPretraga, axiosConfig(token))
      .then((response) => {
        let dataPretraga = [];
        let dataPretragaCSV = [];
        if (response.data.unosi) {
          response.data.unosi.map((red, r) => {
            let object = {};
            let objectCSV = {};
            if (red.prviUnosID) {
              let putanjaPrviTUDugme = "/unosi/" + red.prviUnosID;
              let prikaziDugmePrviTU = (
                <Link className="btn btn-outline-danger" to={putanjaPrviTUDugme}>
                  <i className="fas fa-file"></i> {red.prviUnosNaziv} ({red.prviUnosDatum})
                </Link>
              );
              object["prviUnos"] = !red.blurPodataka && <div sortvalue={red.prviUnosDatum}>{prikaziDugmePrviTU}</div>;
              objectCSV["prviUnos"] = red.prviUnosNaziv + "(" + red.prviUnosDatum + ")";
            }
            if (red.poslednjiUnosID) {
              let putanjaPoslednjiTUDugme = "/unosi/" + red.poslednjiUnosID;
              let prikaziDugmePoslednjiTU = (
                <Link className="btn btn-outline-danger" to={putanjaPoslednjiTUDugme}>
                  <i className="fas fa-file"></i> {red.poslednjiUnosNaziv} ({red.poslednjiUnosDatum})
                </Link>
              );
              object["poslednjiUnos"] = !red.blurPodataka && <div sortvalue={red.poslednjiUnosDatum}>{prikaziDugmePoslednjiTU}</div>;
              objectCSV["poslednjiUnos"] = red.poslednjiUnosNaziv + "(" + red.poslednjiUnosDatum + ")";
            }
            let prikazNoviUnosDugme = this.state.tipoviSkupaAtributa.length > 0 && (
              <button className="btn btn-outline-danger" onClick={this.showPopup.bind(this, true, red.pacijentID)}>
                <i className="fas fa-plus"></i> {Labels.BUTTON_NOVI_UNOS}
              </button>
            );
            let putanjaPacijentDugme = "/pacijenti/" + red.pacijentID;
            let prikaziDugmePacijent = (
              <Link className="btn btn-outline-danger" to={putanjaPacijentDugme}>
                {red.imePrezime}
              </Link>
            );
            if (sviRezultati) {
              objectCSV["pacijent"] = red.imePrezime;
              objectCSV["jmbg"] = red.jmbg;
              objectCSV["lbo"] = red.lbo;
              objectCSV["identifikacioniBroj"] = red.identifikacioniBroj;
              dataPretragaCSV.push(objectCSV);
            } else {
              object["pacijent"] = !red.blurPodataka && <div>{prikaziDugmePacijent}</div>;
              object["jmbg"] = (
                <div jmbg={red.jmbg} className={red.blurPodataka ? "blur-text" : undefined}>
                  {red.jmbg}
                </div>
              );
              object["lbo"] = (
                <div lbo={red.lbo} className={red.blurPodataka ? "blur-text" : undefined}>
                  {red.lbo}
                </div>
              );
              object["identifikacioniBroj"] = <div className={red.blurPodataka ? "blur-text" : undefined}>{red.identifikacioniBroj}</div>;
              object["noviUnos"] = !red.blurPodataka && <div>{prikazNoviUnosDugme}</div>;
              dataPretraga.push(object);
            }
          });
        }
        if (sviRezultati) {
          if (this._isMounted) {
            this.setState({
              dataCSV: dataPretragaCSV,
            });
          }
        } else {
          if (this._isMounted) {
            this.setState({
              tableDataPretraga: dataPretraga,
            });
          }
        }

        if (this._isMounted) {
          this.setState({
            kriterijumZaPretraguGodinaDo: response.data.parametri.kriterijumZaPretraguGodinaDo,
            kriterijumZaPretraguGodinaOd: response.data.parametri.kriterijumZaPretraguGodinaOd,
            kriterijumZaPretraguIme: response.data.parametri.kriterijumZaPretraguIme,
            kriterijumZaPretraguJMBG: response.data.parametri.kriterijumZaPretraguJMBG,
            kriterijumZaPretraguLBO: response.data.parametri.kriterijumZaPretraguLBO,
            kriterijumZaPretraguPol: response.data.parametri.kriterijumZaPretraguPol,
            kriterijumZaPretraguPrezime: response.data.parametri.kriterijumZaPretraguPrezime,
            prikazTekucihUnosaKodPretrage: response.data.parametri.prikazTekucihUnosaKodPretrage,
            ucitano: true,
            vratiNaPocetnu: true,
          });
        }
        if (sviRezultati) {
          this.csvLink.current.link.click();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  prvaStrana() {
    this.setState({
      vratiNaPocetnu: false,
      strana: 0,
    });
    return 0;
  }

  render() {
    let red = Object.keys(this.state.redPretrageElementi).map((key) => {
      return (
        <div className="form-row" key={key}>
          <div className="col-sd-1 mb-3">
            <button type="button" className="btn btn-outline-danger" onClick={() => this.obrisiRedPretrage(key)}>
              <i className="fas fa-minus"></i>
            </button>
          </div>
          <div className="col-md-1 mb-3">
            <Dropdown
              className={Object.keys(this.state.redPretrageElementi)[0] !== key ? "col vrednost" : "d-none"}
              placeholder={Labels.PLACEHOLDER_VEZA}
              onChange={(value) => this.changeVeza(key, value)}
              options={objectAndOr}
              value={this.state.redPretrageElementi[key]?.veza?.value}
            />
          </div>
          <div className={this.state.prikazTekucihUnosaKodPretrage ? "col-md-2 mb-3" : "d-none"}>
            {/* <Select
              className="col vrednost"
              placeholder={Labels.PLACEHOLDER_ODABERITE_UNOS}
              isSearchable={true}
              onChange={(value) => this.changeTipaSkupaAtributa(key, value)}
              options={this.state.tipoviSkupaAtributa}
            /> */}
          </div>
          <div className={this.state.prikazTekucihUnosaKodPretrage ? "col-md-2 mb-3" : "col-md-4 mb-3"}>
            {/* <Select
              className="col vrednost"
              placeholder={Labels.PLACEHOLDER_ODABERITE_STAVKU_UNOSA}
              isSearchable={true}
              value={this.state.redPretrageElementi[key].atributUTipuSkupa}
              onChange={(value) => this.changeAtributUTipuSkupa(key, value)}
              options={this.state.prikazTekucihUnosaKodPretrage ? this.state.autsiPoTSA[key] : this.state.autsiPoTSA[0]}
            /> */}
          </div>
          <div className="col-md-2 mb-3">
            {/* <Select
              className="col vrednost"
              placeholder={Labels.PLACEHOLDER_OPERATOR}
              isSearchable={true}
              value={this.state.redPretrageElementi[key].operator}
              onChange={(value) => this.changeOperator(key, value)}
              options={this.state.operatoriPoTSA[key]}
            /> */}
          </div>
          <div className="col-md-2 mb-3">
            <TipAtributaRouter
              tvaNumVrednost={this.state.redPretrageElementi[key].tipVrednosti !== undefined ? this.state.redPretrageElementi[key].tipVrednosti.numerickaVrednost : null}
              initValue={this.state.initValue[key]}
              changeState={this.changeState}
              tipSkupaAtributaKey={key}
              atributUTipuSkupaID={this.state.redPretrageElementi[key].atributUTipuSkupa !== undefined ? this.state.redPretrageElementi[key].atributUTipuSkupa.value : ""}
              auts={this.state.autsiPoTSAFull}
              enumiPoAUTS={this.state.enumiPoAUTS}
              zaPretragu={true}
            />
          </div>
        </div>
      );
    });

    let renderFooter = () => {
      return (
        <>
          <Button label={Labels.BUTTON_ODUSTANI} onClick={this.closeBasicDialog} className="p-button-warning" />
          <Button label={Labels.BUTTON_KREIRAJ} onClick={(e) => this.noviUnos(e)} className="p-button-primary" autoFocus />
        </>
      );
    };

    let kreiranjeUnosa = this.state.tipoviSkupaAtributa.map((tsa, t) => {
      return (
        <div key={tsa.value} className="form-group row">
          <button
            type="button"
            className={
              this.state.selectTipSkupAtributa !== "" && this.state.selectTipSkupAtributa === tsa.value ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"
            }
            onClick={() => this.setState({ selectTipSkupAtributa: tsa.value })}
          >
            {tsa.label}
          </button>
        </div>
      );
    });

    return (
      <div className="px-5">
        <h1>{this.state.registar.naziv}</h1>
        <button className="btn btn-outline-success md-2" onClick={this.pretraga.bind(this, true)}>
          <i className="fas fa-file-csv"></i> {Labels.BUTTON_CSV}
        </button>
        <CSVLink data={this.state.dataCSV} headers={headersCSV} ref={this.csvLink} filename={"rezultati_pretrage.csv"} className="d-none">
          <i className="fas fa-file-csv"></i> {Labels.BUTTON_CSV}
        </CSVLink>
        <button type="button" className="btn btn-outline-info ml-2" onClick={() => this.setState({ displayDialog: true })}>
          <i className="fas fa-plus"></i> {Labels.BUTTON_NOVI_PACIJENT}
        </button>
        <div className="form-row mt-3">
          <div className={this.state.kriterijumZaPretraguIme ? "col-md-2 mb-3" : "d-none"}>
            <input type="text" className="form-control" placeholder={Labels.PLACEHOLDER_IME} onKeyPress={this.handleKeyPressLogIn} onChange={(event) => this.setState({ ime: event.target.value })} />
          </div>
          <div className={this.state.kriterijumZaPretraguPrezime ? "col-md-2 mb-3" : "d-none"}>
            <input
              type="text"
              className="form-control"
              placeholder={Labels.PLACEHOLDER_PREZIME}
              onKeyPress={this.handleKeyPressLogIn}
              onChange={(event) => this.setState({ prezime: event.target.value })}
            />
          </div>
          <div className={this.state.kriterijumZaPretraguJMBG ? "col-md-2 mb-3" : "d-none"}>
            <input type="text" className="form-control" placeholder={Labels.PLACEHOLDER_JMBG} onKeyPress={this.handleKeyPressLogIn} onChange={(event) => this.setState({ jmbg: event.target.value })} />
          </div>
          <div className={this.state.kriterijumZaPretraguLBO ? "col-md-2 mb-3" : "d-none"}>
            <input type="text" className="form-control" placeholder={Labels.PLACEHOLDER_LBO} onKeyPress={this.handleKeyPressLogIn} onChange={(event) => this.setState({ lbo: event.target.value })} />
          </div>
          <div className={this.state.kriterijumZaPretraguGodinaOd ? "col-md-1 mb-3" : "d-none"}>
            <input
              type="number"
              className="form-control"
              placeholder={Labels.PLACEHOLDER_GODINA_OD}
              onKeyPress={this.handleKeyPressLogIn}
              onChange={(event) => this.setState({ godinaOd: event.target.value })}
            />
          </div>
          <div className={this.state.kriterijumZaPretraguGodinaDo ? "col-md-1 mb-3" : "d-none"}>
            <input
              type="number"
              className="form-control"
              placeholder={Labels.PLACEHOLDER_GODINA_DO}
              onKeyPress={this.handleKeyPressLogIn}
              onChange={(event) => this.setState({ godinaDo: event.target.value })}
            />
          </div>
          <div className={this.state.kriterijumZaPretraguPol ? "col-md-2 mb-3" : "d-none"}>
            <select className="form-control" onKeyPress={this.handleKeyPressLogIn} onChange={(event) => this.setState({ pol: event.target.value })}>
              <option value="-1">{Labels.LABEL_ODABERITE_POL}</option>
              <option value="1">{Labels.LABEL_MUSKI}</option>
              <option value="0">{Labels.LABEL_ZENSKI}</option>
            </select>
          </div>
          <div className={this.state.brojRezultatePretrage ? "col-md-0 mb-3" : "d-none"}>
            <Dropdown
              tooltip={Labels.TOOLTIP_BROJ_PACIJENT}
              tooltipOptions={{ position: "top" }}
              value={this.state.brojRezultatePretrage}
              options={brojRezultatePretrageOptions}
              onChange={(event) => this.setState({ brojRezultatePretrage: event.target.value })}
              optionLabel="name"
            />
          </div>
          <div className="col-md-1 mb-3">
            <button type="button" className="btn btn-outline-danger" onClick={this.pretraga.bind(this, false, false)}>
              <i className="fas fa-search"></i> {Labels.BUTTON_PRETRAGA}
            </button>
          </div>
          <div className={this.state.ucitano ? "d-none" : "spinner-border text-secondary"} role="status">
            <span className="sr-only">{Labels.LABEL_UCITAVANJE}</span>
          </div>
        </div>
        {red}
        <div className="form-row">
          <div className="col-md-1 mb-3">
            <button type="button" className="btn btn-outline-danger" onClick={this.dodajRedPretrage}>
              <i className="fas fa-plus"></i>
            </button>
          </div>
        </div>
        <div className={this.state.tableDataPretraga.length === 0 ? "d-none" : ""}>
          <ReactTable
            previousText={Labels.BUTTON_PRETHODNI}
            nextText={Labels.BUTTON_SLEDECI}
            pageText={Labels.PAGINATION_STRANICA}
            ofText={Labels.PAGINATION_OF}
            pageSize={this.state.brojPrikazaPretrage}
            onPageSizeChange={(pageSize, pageIndex) => {
              this.setState({ brojPrikazaPretrage: pageSize });
            }}
            data={this.state.tableDataPretraga}
            columns={[
              {
                Header: Labels.COLUMN_HEADER_PACIJENT,
                accessor: "pacijent",
                width: "auto",
                className: "registriTabletext",
              },
              {
                Header: Labels.COLUMN_HEADER_JMBG,
                accessor: "jmbg",
                sortMethod: (a, b) => {
                  if (a.props.jmbg < b.props.jmbg) {
                    return -1;
                  }
                  if (a.props.jmbg > b.props.jmbg) {
                    return 1;
                  }
                  return 0;
                },
                width: "150",
                className: "registriTabletext",
              },
              {
                Header: Labels.COLUMN_HEADER_LBO,
                accessor: "lbo",
                sortMethod: (a, b) => {
                  if (!a.props.lbo) {
                    return -1;
                  }
                  if (!b.props.lbo) {
                    return 1;
                  }
                  if (a.props.lbo < b.props.lbo) {
                    return -1;
                  }
                  if (a.props.lbo > b.props.lbo) {
                    return 1;
                  }
                  return 0;
                },
                width: "150",
                className: "registriTabletext",
              },
              {
                Header: Labels.COLUMN_HEADER_IDENTIFIKACIONI_BROJ,
                accessor: "identifikacioniBroj",
                width: "250",
                className: "registriTabletext",
              },
              {
                Header: Labels.COLUMN_HEADER_PRVI_UNOS,
                accessor: "prviUnos",
                sortMethod: (a, b) => {
                  if (!a) {
                    return -1;
                  }
                  if (!b) {
                    return 1;
                  }
                  let splitDateA = a.props.sortvalue.split(".");
                  let splitDateB = b.props.sortvalue.split(".");
                  let dateA = moment(splitDateA[2] + "-" + splitDateA[1] + "-" + splitDateA[0], "YYYY-MM-DD");
                  let dateB = moment(splitDateB[2] + "-" + splitDateB[1] + "-" + splitDateB[0], "YYYY-MM-DD");
                  if (dateA < dateB) {
                    return -1;
                  }
                  if (dateA > dateB) {
                    return 1;
                  }
                  return 0;
                },
                width: "auto",
                className: "registriTabletext",
              },
              {
                Header: Labels.COLUMN_HEADER_POSLEDNJI_UNOS,
                accessor: "poslednjiUnos",
                sortMethod: (a, b) => {
                  if (!a) {
                    return -1;
                  }
                  if (!b) {
                    return 1;
                  }
                  let splitDateA = a.props.sortvalue.split(".");
                  let splitDateB = b.props.sortvalue.split(".");
                  let dateA = moment(splitDateA[2] + "-" + splitDateA[1] + "-" + splitDateA[0], "YYYY-MM-DD");
                  let dateB = moment(splitDateB[2] + "-" + splitDateB[1] + "-" + splitDateB[0], "YYYY-MM-DD");
                  if (dateA < dateB) {
                    return -1;
                  }
                  if (dateA > dateB) {
                    return 1;
                  }
                  return 0;
                },
                width: "auto",
                className: "registriTabletext",
              },
              {
                Header: Labels.COLUMN_HEADER_NOVI_UNOS,
                accessor: "noviUnos",
                width: "150",
                className: "registriTabletext",
              },
            ]}
            onPageChange={(pageIndex) => {
              this.setState({ strana: pageIndex });
            }}
            page={this.state.vratiNaPocetnu ? this.prvaStrana() : this.state.strana}
          />
        </div>
        <Link className="btn btn-outline-danger mt-2" to="/registri">
          <i className="fas fa-undo-alt"></i> {Labels.BUTTON_NAZAD_NA_REGISTRE}
        </Link>

        <Dialog header={Labels.TITLE_DIALOG_KREIRANJE_UNOSA} className="w-3" visible={this.state.displayBasic} footer={renderFooter} onHide={this.closeBasicDialog}>
          <div className="grid">
            {this.state.odabirDatumaPreUnosa && (
              <>
                <label className="col-12">{Labels.LABEL_DATUM_PREGLEDA}:</label>
                <Calendar className="col-12" showIcon dateFormat={CALENDAR_DATE_FORMAT} value={this.state.vremeUnosa} onChange={(e) => this.setState({ ...this.state, vremeUnosa: e.value })} />
              </>
            )}

            <label className="col-12">{Labels.LABEL_IZABERITE_TIP_PREGLEDA}:</label>
            {this.state.tipoviSkupaAtributa.map(({ value, label }) => {
              return (
                <div key={value} className="field-radiobutton col-12 py-0">
                  <RadioButton
                    inputId={value}
                    name="category"
                    value={this.state.selectTipSkupAtributa}
                    onChange={(e) => this.setState({ ...this.state, selectTipSkupAtributa: value })}
                    checked={this.state.selectTipSkupAtributa === value}
                  />
                  <label className="p-2" htmlFor={value}>
                    {label}
                  </label>
                </div>
              );
            })}
          </div>
        </Dialog>
        <SweetAlert show={this.state.prikazAlerta} info title="" onConfirm={this.alertCancel}>
          {" "}
          {this.state.alertText}
        </SweetAlert>
        {/* <CreateDialogPacijent novi={true} show={this.state.showDialogPacijent} onChangeUni={this.onChangeUni} afterSubmit={this.afterSubmitPacijent} /> */}
        <div className="layout-pacijent">
          <Dialog visible={this.state.displayDialog} header={Labels.LABEL_NOVI_PACIJENT} onHide={this.closeDialog} className="w-5">
            <CUDialogPacijent pacijentOperation="CREATE" onCancel={this.closeDialog} />
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withRouter(Registar);
