import axios from "axios";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Endpoint from "../../endpoints/Endpoint";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import Routers from "../../utils/Routers";
import { axiosConfig, CALENDAR_DATE_FORMAT, useEffectOnce } from "../../utils/Utils";
import { AppContext } from "../Store";

interface CUDialogPacijentProps {
  pacijentOperation: string;
  pacijent?: any;
  onCancel: Function;
  afterSubmit?: Function;
}

export default function CUDialogPacijent(props: CUDialogPacijentProps) {
  const { pacijentOperation, pacijent, onCancel, afterSubmit } = props;
  const [pacijentChange, setPacijentChange] = useState<Partial<any>>(pacijent);
  const [prikazPacijentAdministrativnaNapomena, setPrikazPacijentAdministrativnaNapomena] = useState();
  const [prikazPacijentImeRoditelja, setPrikazPacijentImeRoditelja] = useState();
  const [prikazPacijentMedicinskaNapomena, setPrikazPacijentMedicinskaNapomena] = useState();
  const [prikazPacijentPrikazJednogPola, setPrikazPacijentPrikazJednogPola] = useState();
  const [prikazPacijentRadnoMesto, setPrikazPacijentRadnoMesto] = useState();
  const [prikazPacijentStrucnaSprema, setPrikazPacijentStrucnaSprema] = useState();
  const [prikazPacijentZanimanje, setPrikazPacijentZanimanje] = useState();
  const { showGrowlMessage, authData } = useContext(AppContext);

  const history = useHistory();

  useEffectOnce(() => {
    axios
      .get(Endpoint.PACIJENT_PARAMETRI, axiosConfig(authData.token))
      .then((response) => {
        setPrikazPacijentAdministrativnaNapomena(response.data.prikazPacijentAdministrativnaNapomena);
        setPrikazPacijentImeRoditelja(response.data.prikazPacijentImeRoditelja);
        setPrikazPacijentMedicinskaNapomena(response.data.prikazPacijentMedicinskaNapomena);
        setPrikazPacijentPrikazJednogPola(response.data.prikazPacijentPrikazJednogPola);
        setPrikazPacijentRadnoMesto(response.data.prikazPacijentRadnoMesto);
        setPrikazPacijentStrucnaSprema(response.data.prikazPacijentStrucnaSprema);
        setPrikazPacijentZanimanje(response.data.prikazPacijentZanimanje);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const validationInput = () => {
    if (!pacijentChange) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_OBAVEZNA_POLJA);
      return false;
    }
    if (!pacijentChange.ime) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_IME_PACIJENTA);
      return false;
    }
    if (!pacijentChange.prezime) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_PREZIME_PACIJENTA);
      return false;
    }
    if (!pacijentChange.datumRodjenja) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_DATUM_RODJENJA_PACIJENTA);
      return false;
    }
    if (!pacijentChange.jmbg) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_JMBG_PACIJENTA);
      return false;
    }
    if (!pacijentChange.lbo) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_LBO_PACIJENTA);
      return false;
    }
    if (!pacijentChange.polTrenutni && pacijentChange.polTrenutni !== true && pacijentChange.polTrenutni !== false) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_POL_PACIJENTA);
      return false;
    }

    if (!pacijentChange.kontaktTelefon) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_KONTAKT_TELEFON_PACIJENTA);
      return false;
    }

    let pattern = new RegExp("^([0-9]{3}/[0-9]{6,8})$");
    if (!pattern.test(pacijentChange.kontaktTelefon.trim())) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_OBLIK_KONTAKT_TELEFONA);
      return false;
    }

    if (pacijentChange.mobilniTelefon && !pattern.test(pacijentChange.mobilniTelefon)) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_OBLIK_MOBILNOG_TELEFONA);
      return false;
    }

    return true;
  };

  const kreirajPacijenta = () => {
    if (!validationInput()) {
      return;
    }

    axios
      .post(Endpoint.PACIJENT, pacijentChange, axiosConfig(authData.token))
      .then((response) => {
        showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_SNIMLJEN_PACIJENT, "");
        onCancel();
        history.push(`${Routers.PACIJENTI}/${response.data.id}`);
      })
      .catch((error) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_PACIJENTA, "");
        console.log(error);
      });
  };

  const izmeniPacijenta = async () => {
    if (!validationInput()) {
      return;
    }

    await axios
      .patch(Endpoint.PACIJENT + "/" + pacijent.id, pacijentChange, axiosConfig(authData.token))
      .then((response) => {
        showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJEN_PACIJENT, "");
        afterSubmit && afterSubmit(pacijent.id);
        onCancel();
      })
      .catch((error) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_PACIJENTA, "");
      });
  };

  return (
    <div className="col-12">
      <div className="grid align-items-center">
        <div className="col-4 mb-3">{Labels.LABEL_IME_OBAVEZNO}</div>
        <div className="col-8 p-fluid p-0">
          <InputText
            value={pacijentChange && pacijentChange.ime ? pacijentChange.ime : ""}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                ime: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-4 mb-3">{Labels.LABEL_PREZIME_OBAVEZNO}</div>
        <div className="col-8 p-fluid p-0">
          <InputText
            value={pacijentChange && pacijentChange.prezime ? pacijentChange.prezime : ""}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                prezime: e.target.value,
              });
            }}
          />
        </div>
        {prikazPacijentImeRoditelja && (
          <>
            <div className="col-4 mb-3">{Labels.LABEL_IME_RODITELJA}</div>
            <div className="col-8 p-fluid p-0">
              <InputText
                value={pacijentChange && pacijentChange.imeRoditelja ? pacijentChange.imeRoditelja : ""}
                onChange={(e: any) => {
                  setPacijentChange({
                    ...pacijentChange,
                    imeRoditelja: e.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
        <div className="col-4 mb-3">{Labels.LABEL_DATUM_RODJENJA_OBAVEZNO}</div>
        <div className="col-8 p-fluid p-0">
          <Calendar
            monthNavigator={true}
            yearNavigator={true}
            yearRange="1920:2030"
            dateFormat={CALENDAR_DATE_FORMAT}
            showIcon
            value={pacijentChange && pacijentChange.datumRodjenja ? new Date(pacijentChange.datumRodjenja) : undefined}
            onChange={(e: any) => {
              const date = new Date(e.target.value);
              date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
              setPacijentChange({
                ...pacijentChange,
                datumRodjenja: date,
              });
            }}
          />
        </div>
        <div className="col-4 mb-3">{Labels.LABEL_JMBG_OBAVEZNO}</div>
        <div className="col-8 p-fluid p-0">
          <InputText
            value={pacijentChange && pacijentChange.jmbg ? pacijentChange.jmbg : ""}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                jmbg: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-4 mb-3">{Labels.LABEL_LBO_OBAVEZNO}</div>
        <div className="col-8 p-fluid p-0">
          <InputText
            value={pacijentChange && pacijentChange.lbo ? pacijentChange.lbo : ""}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                lbo: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-4 mb-3">{Labels.LABEL_POL_OBAVEZNO}</div>
        <div className="col-8 p-fluid p-0">
          <RadioButton
            inputId="rb1"
            name="gender"
            checked={pacijentChange && pacijentChange.polTrenutni === true ? pacijentChange.polTrenutni : false}
            value={pacijentChange && pacijentChange.polTrenutni === true ? pacijentChange.polTrenutni : true}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                polTrenutni: e.target.value,
              });
            }}
          />
          <label htmlFor="rb1" className="p-radiobutton-label">
            {Labels.LABEL_MUSKI}
          </label>
          <RadioButton
            inputId="rb2"
            name="gender"
            checked={pacijentChange && pacijentChange.polTrenutni === false ? !pacijentChange.polTrenutni : false}
            value={pacijentChange && pacijentChange.polTrenutni === false ? pacijentChange.polTrenutni : false}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                polTrenutni: e.target.value,
              });
            }}
          />
          <label htmlFor="rb2" className="p-radiobutton-label">
            {Labels.LABEL_ZENSKI}
          </label>
        </div>
        {prikazPacijentPrikazJednogPola && (
          <>
            <div className="col-4 mb-3">{Labels.LABEL_POL_NA_RODJENJU}</div>
            <div className="col-8 p-fluid p-0">
              <RadioButton
                name="pol"
                checked={pacijentChange && pacijentChange.polNaRodjenju === true ? pacijentChange.polNaRodjenju : false}
                value={pacijentChange && pacijentChange.polNaRodjenju === true ? pacijentChange.polNaRodjenju : true}
                onChange={(e: any) => {
                  setPacijentChange({
                    ...pacijentChange,
                    polNaRodjenju: e.target.value,
                  });
                }}
              />
              <label className="p-radiobutton-label">{Labels.LABEL_MUSKI}</label>
              <RadioButton
                name="pol"
                checked={pacijentChange && pacijentChange.polNaRodjenju === false ? !pacijentChange.polNaRodjenju : false}
                value={pacijentChange && pacijentChange.polNaRodjenju === false ? pacijentChange.polNaRodjenju : false}
                onChange={(e: any) => {
                  setPacijentChange({
                    ...pacijentChange,
                    polNaRodjenju: e.target.value,
                  });
                }}
              />
              <label className="p-radiobutton-label">{Labels.LABEL_ZENSKI}</label>
            </div>
          </>
        )}
        <div className="col-4 mb-3">{Labels.LABEL_ADRESA}</div>
        <div className="col-8 p-fluid p-0">
          <InputText
            value={pacijentChange && pacijentChange.adresa ? pacijentChange.adresa : ""}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                adresa: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-4 mb-3">{Labels.LABEL_BROJ_KARTONA}</div>
        <div className="col-8 p-fluid p-0">
          <InputText
            value={pacijentChange && pacijentChange.brojKartona ? pacijentChange.brojKartona : ""}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                brojKartona: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-4 mb-3">{Labels.LABEL_KONTAKT_TELEFON_OBAVEZNO}</div>
        <div className="col-8 p-fluid p-0">
          <InputText
            value={pacijentChange && pacijentChange.kontaktTelefon ? pacijentChange.kontaktTelefon : ""}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                kontaktTelefon: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-4 mb-3">{Labels.LABEL_EMAIL}</div>
        <div className="col-8 p-fluid p-0">
          <InputText
            value={pacijentChange && pacijentChange.email ? pacijentChange.email : ""}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                email: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-4 mb-3">{Labels.LABEL_MOBILNI_TELEFON}</div>
        <div className="col-8 p-fluid p-0">
          <InputText
            value={pacijentChange && pacijentChange.mobilniTelefon ? pacijentChange.mobilniTelefon : ""}
            onChange={(e: any) => {
              setPacijentChange({
                ...pacijentChange,
                mobilniTelefon: e.target.value,
              });
            }}
          />
        </div>
        {prikazPacijentZanimanje && (
          <>
            <div className="col-4 mb-3">{Labels.LABEL_ZANIMANJE}</div>
            <div className="col-8 p-fluid p-0">
              <InputText
                value={pacijentChange && pacijentChange.zanimanje ? pacijentChange.zanimanje : ""}
                onChange={(e: any) => {
                  setPacijentChange({
                    ...pacijentChange,
                    zanimanje: e.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
        {prikazPacijentStrucnaSprema && (
          <>
            <div className="col-4 mb-3">{Labels.LABEL_STRUCNA_SPREMA}</div>
            <div className="col-8 p-fluid p-0">
              <InputText
                value={pacijentChange && pacijentChange.strucnaSprema ? pacijentChange.strucnaSprema : ""}
                onChange={(e: any) => {
                  setPacijentChange({
                    ...pacijentChange,
                    strucnaSprema: e.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
        {prikazPacijentRadnoMesto && (
          <>
            <div className="col-4 mb-3">{Labels.LABEL_RADNO_MESTO}</div>
            <div className="col-8 p-fluid p-0">
              <InputText
                value={pacijentChange && pacijentChange.radnoMesto ? pacijentChange.radnoMesto : ""}
                onChange={(e: any) => {
                  setPacijentChange({
                    ...pacijentChange,
                    radnoMesto: e.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
        {prikazPacijentMedicinskaNapomena && (
          <>
            <div className="col-4 mb-3">{Labels.LABEL_MEDICINSKA_NAPOMENA}</div>
            <div className="col-8 p-fluid p-0">
              <InputText
                value={pacijentChange && pacijentChange.medicinskaNapomena ? pacijentChange.medicinskaNapomena : ""}
                onChange={(e: any) => {
                  setPacijentChange({
                    ...pacijentChange,
                    medicinskaNapomena: e.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
        {prikazPacijentAdministrativnaNapomena && (
          <>
            <div className="col-4 mb-3">{Labels.LABEL_ADMINISTRATIVNA_NAPOMENA}</div>
            <div className="col-8 p-fluid p-0">
              <InputText
                value={pacijentChange && pacijentChange.administrativnaNapomena ? pacijentChange.administrativnaNapomena : ""}
                onChange={(e: any) => {
                  setPacijentChange({
                    ...pacijentChange,
                    administrativnaNapomena: e.target.value,
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="p-toolbar-group-left">
        <Button label={Labels.BUTTON_SACUVAJ} icon="pi pi-save" className="p-button-info" onClick={pacijentOperation === EntityOperation.CREATE ? kreirajPacijenta : izmeniPacijenta} />
      </div>
    </div>
  );
}
