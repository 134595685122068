import React from "react";
import { Dropdown } from "primereact/dropdown";
import Brojni from "./Brojni";
import Tekstualni from "./Tekstualni";
import Datumski from "./Datumski";
import Skupovni2 from "./Skupovni2";
import Izborni from "./Izborni";
import Grafikon from "./Grafikon";
import EnumTipovaVrednostiAtributa from "../../utils/EnumTipovaVrednostiAtributa";

export default function TipAtributaRouter2(props: any) {
  const numVr = props.tvaNumVrednost;
  return (
    <>
      {numVr === EnumTipovaVrednostiAtributa.IZBORNI && props.zaPretragu && (
        <Dropdown
          className="w-full"
          placeholder="Izaberite vrednost"
          editable={false}
          value={props.initValue}
          onChange={(e) => props.changeState("initValue", props.tipSkupaAtributaKey, e.target.value)}
          options={[
            { label: "Da", value: 1 },
            { label: "Ne", value: 0 },
          ]}
        />
      )}
      {numVr === EnumTipovaVrednostiAtributa.IZBORNI && !props.zaPretragu && (
        <Izborni initValue={props.initValue} changeState={props.changeState} tipSkupaAtributaKey={props.tipSkupaAtributaKey} auts={props.auts} />
      )}
      {numVr === EnumTipovaVrednostiAtributa.BROJNI && <Brojni initValue={props.initValue} changeState={props.changeState} tipSkupaAtributaKey={props.tipSkupaAtributaKey} auts={props.auts} />}
      {numVr === EnumTipovaVrednostiAtributa.TEKSTUALNI && props.zaPretragu && (
        <input type="text" className="form-control" onChange={(event) => props.changeState("initValue", props.tipSkupaAtributaKey, event.target.value)} value={props.initValue} />
      )}
      {numVr === EnumTipovaVrednostiAtributa.TEKSTUALNI && !props.zaPretragu && (
        <Tekstualni initValue={props.initValue} changeState={props.changeState} tipSkupaAtributaKey={props.tipSkupaAtributaKey} auts={props.auts} />
      )}
      {numVr === EnumTipovaVrednostiAtributa.SKUPOVNI && (
        <Skupovni2
          initValue={props.initValue}
          changeState={props.changeState}
          tipSkupaAtributaKey={props.tipSkupaAtributaKey}
          zaPretragu={props.zaPretragu}
          auts={props.auts}
          enumiPoAUTS={props.enumiPoAUTS}
        />
      )}
      {numVr === EnumTipovaVrednostiAtributa.DATUMSKI && (
        <Datumski
          initValue={props.initValue}
          changeState={props.changeState}
          tipSkupaAtributaKey={props.tipSkupaAtributaKey}
          auts={props.auts}
          smallFormat={props.smallFormat !== undefined && props.smallFormat}
        />
      )}
      {numVr === EnumTipovaVrednostiAtributa.GRAFIK && <Grafikon initValue={props.initValue} />}
    </>
  );
}
