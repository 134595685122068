import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Endpoint from "../../endpoints/Endpoint";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import Routers from "../../utils/Routers";
import { axiosConfig, DATE_FORMAT, DATE_TIME_FORMAT, useEffectOnce } from "../../utils/Utils";
import { AppContext } from "../Store";

export default function PacijentArhiva() {
  const { authData } = useContext(AppContext);

  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const [pacijentiList, setPacijentiList] = useState<any>();
  const [pacijentParametri, setPacijentParametri] = useState({
    prikazPacijentImeRoditelja: false,
    prikazPacijentPrikazJednogPola: false,
    prikazPacijentRadnoMesto: false,
    prikazPacijentStrucnaSprema: false,
    prikazPacijentZanimanje: false,
    prikazPacijentAdministrativnaNapomena: false,
    prikazPacijentMedicinskaNapomena: false,
  });

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    const requestPacijentParametri = axios.get(Endpoint.PACIJENT_PARAMETRI, axiosConfig(authData.token));
    const requestPacijent = axios.get(`${Endpoint.PACIJENT}/${id}`, axiosConfig(authData.token));
    const requestPacijentArhiva = axios.get(
      Endpoint.PACIJENT_ARHIVA + "/search/findByPacijentOrderByVremePrestankaVazenjaDesc",
      axiosConfig(authData.token, {
        pacijent: `${Endpoint.PACIJENT}/${id}`,
      })
    );
    axios.all([requestPacijentParametri, requestPacijent, requestPacijentArhiva]).then(
      axios.spread((responsePacijentParametri: any, responsePacijent: any, responsePacijentArhiva: any) => {
        setPacijentParametri({
          prikazPacijentImeRoditelja: responsePacijentParametri.data.prikazPacijentImeRoditelja,
          prikazPacijentPrikazJednogPola: responsePacijentParametri.data.prikazPacijentPrikazJednogPola,
          prikazPacijentRadnoMesto: responsePacijentParametri.data.prikazPacijentRadnoMesto,
          prikazPacijentStrucnaSprema: responsePacijentParametri.data.prikazPacijentStrucnaSprema,
          prikazPacijentZanimanje: responsePacijentParametri.data.prikazPacijentZanimanje,
          prikazPacijentAdministrativnaNapomena: responsePacijentParametri.data.prikazPacijentAdministrativnaNapomena,
          prikazPacijentMedicinskaNapomena: responsePacijentParametri.data.prikazPacijentMedicinskaNapomena,
        });
        setPacijentiList([responsePacijent.data, ...responsePacijentArhiva.data._embedded.pacijentArhivas]);
      })
    );
  };

  const parseDateDatumRodjenja = (column: any) => {
    const { datumRodjenja } = column;
    return datumRodjenja ? moment(new Date(datumRodjenja)).format(DATE_FORMAT) : null;
  };

  const parseVremePrestanka = (column: any) => {
    const { vremePrestankaVazenja } = column;
    return vremePrestankaVazenja ? moment(new Date(vremePrestankaVazenja)).format(DATE_TIME_FORMAT) : "-";
  };

  const parseVremeKreiranja = (column: any) => {
    const { vremeKreiranja } = column;
    return vremeKreiranja ? moment(new Date(vremeKreiranja)).format(DATE_TIME_FORMAT) : null;
  };

  const parseVremePoslednjeIzmene = (column: any) => {
    const { vremePoslednjeIzmene } = column;
    return vremePoslednjeIzmene ? moment(new Date(vremePoslednjeIzmene)).format(DATE_TIME_FORMAT) : null;
  };

  const parsePol = (column: any) => {
    const { polTrenutni } = column;
    return polTrenutni ? "M" : "Ž";
  };

  const parseAktivan = (column: any) => {
    const { aktivan } = column;
    return aktivan ? "Da" : "Ne";
  };

  const leftComponent = () => <Button label={Labels.BUTTON_PACIJENT} icon="pi pi-undo" className="p-button-info" onClick={() => history.push(`${Routers.PACIJENTI}/${id}`)} />;

  return (
    <div className="layout-pacijent-arhiva">
      <Panel header={Labels.TITLE_PANEL_PACIJENT_ARHIVA} className="panel-pacijent-arhiva-list">
        <Toolbar left={leftComponent} />
        <DataTable
          value={pacijentiList}
          paginator={true}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
          rows={10}
          rowsPerPageOptions={[5, 10, 15, 20]}
          alwaysShowPaginator={false}
          emptyMessage={Labels.LABEL_NEMA_PODATAKA}
        >
          <Column className="column-align-center" field={"vremePrestankaVazenja"} body={parseVremePrestanka} header={Labels.COLUMN_HEADER_PRESTANAK_VAZENJA} />
          <Column className="column-align-center" field={"ime"} header={Labels.COLUMN_HEADER_IME} />
          <Column className="column-align-center" field={"prezime"} header={Labels.COLUMN_HEADER_PREZIME} />
          <Column className="column-align-center" field={"polTrenutni"} body={parsePol} header={Labels.COLUMN_HEADER_POL} />
          {pacijentParametri.prikazPacijentPrikazJednogPola && <Column className="column-align-center" field={"polNaRodjenju"} header={Labels.COLUMN_HEADER_POL_NA_RODJENJU} />}
          {pacijentParametri.prikazPacijentImeRoditelja && <Column className="column-align-center" field={"imeRoditelja"} header={Labels.COLUMN_HEADER_IME_RODITELJA} />}
          <Column className="column-align-center" field={"datumRodjenja"} body={parseDateDatumRodjenja} header={Labels.COLUMN_HEADER_DATUM_RODJENJA} />
          <Column className="column-align-center" field={"jmbg"} header={Labels.COLUMN_HEADER_JMBG} />
          <Column className="column-align-center" field={"lbo"} header={Labels.COLUMN_HEADER_LBO} />
          <Column className="column-align-center" field={"adresa"} header={Labels.COLUMN_HEADER_ADRESA} />
          <Column className="column-align-center" field={"brojKartona"} header={Labels.COLUMN_HEADER_BROJ_KARTONA} />
          <Column className="column-align-center" field={"kontaktTelefon"} header={Labels.COLUMN_HEADER_KONTAKT_TELEFON} />
          <Column className="column-align-center" field={"email"} header={Labels.COLUMN_HEADER_EMAIL} />
          <Column className="column-align-center" field={"mobilniTelefon"} header={Labels.COLUMN_HEADER_MOBILNI_TELEFON} />
          {pacijentParametri.prikazPacijentZanimanje && <Column className="column-align-center" field={"zanimanje"} header={Labels.COLUMN_HEADER_ZANIMANJE} />}
          {pacijentParametri.prikazPacijentStrucnaSprema && <Column className="column-align-center" field={"strucnaSprema"} header={Labels.COLUMN_HEADER_STRUCNA_SPREMA} />}
          {pacijentParametri.prikazPacijentRadnoMesto && <Column className="column-align-center" field={"radnoMesto"} header={Labels.COLUMN_HEADER_RADNO_MESTO} />}
          {pacijentParametri.prikazPacijentMedicinskaNapomena && <Column className="column-align-center" field={"medicinskaNapomena"} header={Labels.COLUMN_HEADER_MEDICINSKA_NAPOMENA} />}
          {pacijentParametri.prikazPacijentAdministrativnaNapomena && (
            <Column className="column-align-center" field={"administrativnaNapomena"} header={Labels.COLUMN_HEADER_ADMINISTRATIVNA_NAPOMENA} />
          )}
          <Column className="column-align-center" field={"vremeKreiranja"} body={parseVremeKreiranja} header={Labels.COLUMN_HEADER_VREME_KREIRANJA} />
          <Column className="column-align-center" field={"vremePoslednjeIzmene"} body={parseVremePoslednjeIzmene} header={Labels.COLUMN_HEADER_VREME_POSLEDNJE_IZMENE} />
          <Column className="column-align-center" field={"aktivan"} body={parseAktivan} header={Labels.COLUMN_HEADER_AKTIVAN} />
        </DataTable>
      </Panel>
    </div>
  );
}
