import React from "react";
import Labels from "../infrastructure/Labels_sr_Latn_RS";
import { Card } from "primereact/card";

export default function NotFound() {
  return (
    <div className="layout-not-found">
      <Card>
        <div className="p-code">404</div>
        <div className="p-error-message">{Labels.MESSAGES_NIJE_PRONADJENA_STRANICA}</div>
      </Card>
    </div>
  );
}
