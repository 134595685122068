import React from "react";
import Favicon from "react-favicon";
import { BrowserRouter } from "react-router-dom";
import logo from "./images/logo.png";
import SecuredRoute from "./components/SecuredRoute";
import Store from "./components/Store";
import ThemeSwitcher from "./components/themeSwitcher/ThemeSwitcher";
import GrowlMessage from "./components/message/GrowlMessage";
import BlockUI from "./BlockUI";

export default function App() {
  return (
    <BrowserRouter>
      <ThemeSwitcher>
        <Store>
          <Favicon url={logo} />
          <GrowlMessage />
          <SecuredRoute />
          <BlockUI />
        </Store>
      </ThemeSwitcher>
    </BrowserRouter>
  );
}
