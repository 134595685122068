import axios from "axios";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import Routers from "../../../utils/Routers";
import { axiosConfig, isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import CrudStavkeUnosaList from "../atributUTipuSkupa/CrudStavkeUnosaList";

interface CrudUnosProp {
  unosOperation: string;
  registarID: number;
  registarOperation: string;
}

export default function CrudUnos() {
  const { showGrowlMessage, authData } = useContext(AppContext);

  const location = useLocation<CrudUnosProp>();
  const history = useHistory();

  const { id } = useParams<{ id?: string }>();

  const unosOperation = location.state ? location.state.unosOperation : EntityOperation.UPDATE;
  const registarOperation = location.state ? location.state.registarOperation : EntityOperation.UPDATE;
  let registarID = location.state ? location.state.registarID : undefined;

  const [unosChange, setUnosChange] = useState<Partial<any>>();
  const [registarChange, setRegistarChange] = useState<Partial<any>>();

  const home = { icon: "pi pi-home", url: "/" };

  const items = [
    { label: Labels.LABEL_REGISTRI, url: "/admin/registri" },
    {
      label: registarOperation === EntityOperation.READ ? Labels.LABEL_DETALJI_O_REGISTRU + registarChange?.naziv : Labels.LABEL_IZMENA_REGISTRA + registarChange?.naziv,
      command: () => {
        history.push({
          pathname: `/admin/registar/${registarID}`,
          state: { registarOperation: registarOperation },
        });
      },
    },
    {
      label:
        unosOperation === EntityOperation.READ
          ? Labels.LABEL_DETALJI_O_FORMULARU
          : unosOperation === EntityOperation.UPDATE
          ? Labels.LABEL_IZMENA_FORMULARA + unosChange?.naziv
          : Labels.LABEL_KREIRANJE_FORMULARA,
    },
  ];

  useEffectOnce(() => {
    const requestRegistar = axios.get(`${Endpoint.REGISTAR}/${registarID}`, axiosConfig(authData.token));
    axios
      .all([requestRegistar])
      .then(
        axios.spread((requestRegistar: any) => {
          setRegistarChange(requestRegistar.data);
        })
      )
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, error);
      });

    if (id) {
      const requestUnos = axios.get(`${Endpoint.TIP_HIJERARHIJE_ATRIBUTA}/${id}`, axiosConfig(authData.token));
      axios
        .all([requestUnos])
        .then(
          axios.spread((responseUnos: any, requestRegistar: any) => {
            if (!registarID) {
              registarID = responseUnos.data.registarID;
            }
            setUnosChange(responseUnos.data);
          })
        )
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, error);
        });
    }
  });

  const validateInput = (message: string) => {
    if (!unosChange || !unosChange.naziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    return true;
  };

  const onCreate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
      return;
    }
    let unosChangeNew = unosChange ? unosChange : {};
    unosChangeNew.registar = Endpoint.REGISTAR + "/" + registarID;
    axios
      .post(Endpoint.TIP_HIJERARHIJE_ATRIBUTA, unosChangeNew, axiosConfig(authData.token))
      .then(() => {
        showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO, unosChange?.naziv);
        history.push(`${Routers.ADMIN_REGISTAR}/${registarID}`);
      })
      .catch((error: any) => {
        showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
      });
  };

  const onUpdate = () => {
    if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
      return;
    }
    axios
      .patch(Endpoint.TIP_HIJERARHIJE_ATRIBUTA + `/${unosChange?.id}`, unosChange, axiosConfig(authData.token))
      .then(() => {
        showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, unosChange?.naziv);
      })
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
      });
  };

  const onDelete = () => {
    axios
      .patch(
        Endpoint.TIP_HIJERARHIJE_ATRIBUTA + `/${unosChange?.id}`,
        {
          aktivan: false,
        },
        axiosConfig(authData.token)
      )
      .then(() => {
        showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO, unosChange?.naziv);
        history.push(`${Routers.ADMIN_REGISTAR}/${registarID}`);
      })
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
      });
  };

  const onCancel = () => {
    history.push({
      pathname: `${Routers.ADMIN_REGISTAR}/${registarID}`,
      state: { registarOperation: EntityOperation.UPDATE },
    });
  };

  if (!id && !location.state) {
    return <Redirect to="/admin/registri" />;
  }

  const leftContents = () => (
    <React.Fragment>
      {unosOperation === EntityOperation.CREATE && <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-save" className="p-button-success" onClick={onCreate} />}
      {unosOperation === EntityOperation.UPDATE && <Button label={Labels.BUTTON_IZMENI} icon="pi pi-inbox" className="p-button-warning" onClick={onUpdate} />}
      {unosOperation === EntityOperation.DELETE && <Button label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={onDelete} />}
    </React.Fragment>
  );

  const rightContents = () => <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />;

  return (
    <div className="layout-registri">
      <Panel header={Labels.TITLE_PANEL_FORMULARA} className="panel-registri-list">
        <BreadCrumb model={items} home={home} />
        <TabView renderActiveOnly={false}>
          <TabPanel header={Labels.TAB_FORMULAR}>
            <div className="col-8 xl:col-8 lg:col-8 md:col-6 sm:col-12">
              <div className="grid align-items-center">
                <div className="col-4 mb-3">{Labels.LABEL_NAZIV}</div>
                <div className="col-8 p-fluid p-0">
                  <InputText
                    disabled={isFormDisabled(unosOperation)}
                    value={unosChange && unosChange.naziv}
                    onChange={(e: any) => {
                      setUnosChange({
                        ...unosChange,
                        naziv: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4 mb-3">{Labels.LABEL_NAZIV_ENGLESKI}</div>
                <div className="col-8 p-fluid p-0">
                  <InputText
                    disabled={isFormDisabled(unosOperation)}
                    value={unosChange && unosChange.nazivEngleski}
                    onChange={(e: any) => {
                      setUnosChange({
                        ...unosChange,
                        nazivEngleski: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4 mb-3">{Labels.LABEL_REDOSLED_PRIKAZA}</div>
                <div className="col-8 p-fluid p-0">
                  <InputNumber
                    disabled={isFormDisabled(unosOperation)}
                    value={unosChange && unosChange.prikazniRedosled}
                    onValueChange={(e: any) => {
                      setUnosChange({
                        ...unosChange,
                        prikazniRedosled: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4 mb-3">{Labels.LABEL_MOZE_DA_SE_INSTANCIRA}</div>
                <div className="col-8 p-fluid p-0">
                  <InputSwitch
                    disabled={isFormDisabled(unosOperation)}
                    checked={unosChange && unosChange.mozeSeInstancirati ? unosChange.mozeSeInstancirati : false}
                    onChange={(e: any) => {
                      setUnosChange({
                        ...unosChange,
                        mozeSeInstancirati: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4 mb-3">{Labels.LABEL_SIFRA_VEZE}</div>
                <div className="col-8 p-fluid p-0">
                  <InputText
                    disabled={isFormDisabled(unosOperation)}
                    value={unosChange && unosChange.sifraVeza}
                    onChange={(e: any) => {
                      setUnosChange({
                        ...unosChange,
                        sifraVeza: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4 mb-3">{Labels.LABEL_NAPOMENA}</div>
                <div className="col-8 p-fluid p-0">
                  <InputText
                    disabled={isFormDisabled(unosOperation)}
                    value={unosChange && unosChange.napomena}
                    onChange={(e: any) => {
                      setUnosChange({
                        ...unosChange,
                        napomena: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4 mb-3">{Labels.LABEL_OPIS}</div>
                <div className="col-8 p-fluid p-0">
                  <InputText
                    disabled={isFormDisabled(unosOperation)}
                    value={unosChange && unosChange.opis}
                    onChange={(e: any) => {
                      setUnosChange({
                        ...unosChange,
                        opis: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4 mb-3">{Labels.LABEL_MAX_INSTANCI_PO_KONTAKTU}</div>
                <div className="col-8 p-fluid p-0">
                  <InputNumber
                    disabled={isFormDisabled(unosOperation)}
                    value={unosChange && unosChange.maksimalanBrojInstanciPoKontaktu}
                    onValueChange={(e: any) => {
                      setUnosChange({
                        ...unosChange,
                        maksimalanBrojInstanciPoKontaktu: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4 mb-3">{Labels.LABEL_MAX_INSTANCI_PO_PACIJENTU}</div>
                <div className="col-8 p-fluid p-0">
                  <InputNumber
                    disabled={isFormDisabled(unosOperation)}
                    value={unosChange && unosChange.maksimalanBrojInstanciPoPacijentu}
                    onValueChange={(e: any) => {
                      setUnosChange({
                        ...unosChange,
                        maksimalanBrojInstanciPoPacijentu: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <Toolbar left={leftContents} right={rightContents} />
          </TabPanel>
          <TabPanel header={Labels.TAB_POLJA_FORMULARA} disabled={unosOperation !== EntityOperation.UPDATE}>
            <CrudStavkeUnosaList />
          </TabPanel>
        </TabView>
      </Panel>
    </div>
  );
}
