import axios from "axios";
import moment from "moment";
import { BreadCrumb } from "primereact/breadcrumb";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Endpoint from "../../endpoints/Endpoint";
import Routers from "../../utils/Routers";
import { axiosConfig, DATE_FORMAT, registarID, useEffectOnce } from "../../utils/Utils";
import { AppContext } from "../Store";
import PacijentFormulari from "./PacijentFormulari";
import PacijentInfo from "./PacijentInfo";
import PoslednjiOdabraniUnos from "./PoslednjiOdabraniUnos";

export default function Pacijent() {
  const { id } = useParams<any>();
  const history = useHistory();
  const { authData } = useContext(AppContext);
  const { token } = authData;

  const [poslednjiUnos, setPoslednjiUnos] = useState<any>();
  const [dosije, setDosije] = useState({
    unosi: {},
    registar: { naziv: "" },
    selectTipSkupAtributa: "",
    prikazAlerta: false,
    prikazPacijentAdministrativnaNapomena: false,
    prikazPacijentImeRoditelja: false,
    prikazPacijentMedicinskaNapomena: false,
    prikazPacijentPrikazJednogPola: false,
    prikazPacijentRadnoMesto: false,
    prikazPacijentStrucnaSprema: false,
    prikazPacijentZanimanje: false,
    showDialogPacijent: false,
    imaUnose: true,
    blurPodataka: true,
    odabirDatumaPreUnosa: false,
  });

  const getSvaFromHa = (res: any) => {
    let unosiDatum: { [index: string]: any } = {};
    let unosiOrder: { [index: string]: any } = {};
    res.data._embedded.hijerarhijaAtributas.forEach((ha: any) => {
      axios
        .get(Endpoint.TIP_SKUPA_ATRIBUTA + "/search/findTipSkupaAtributaBySkupVrednostiAtributa", axiosConfig(token, { keySkupVrednostiAtributa: ha.id }))
        .then((response) => {
          let tsa = response.data;
          let datum = moment(ha.vremeKreiranja).format(DATE_FORMAT);
          if (Object.keys(unosiDatum).includes(datum)) {
            let unosi = unosiDatum[datum];
            unosi.push({ id: ha.id, naziv: tsa.naziv, prikazniRedosled: tsa.prikazniRedosled, vremeKreiranja: tsa.vremeKreiranja });
            unosi.sort(function (a: any, b: any) {
              return a.naziv > b.naziv;
            });
            Object.assign(unosiDatum[datum], unosi);
          } else {
            unosiDatum[datum] = [{ id: ha.id, naziv: tsa.naziv, prikazniRedosled: tsa.prikazniRedosled, vremeKreiranja: tsa.vremeKreiranja }];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
    Object.keys(unosiDatum)
      .sort(function (a: string, b: string) {
        return moment(b, DATE_FORMAT).toDate().getTime() - moment(a, DATE_FORMAT).toDate().getTime();
      })
      .forEach(function (key) {
        unosiOrder[key] = unosiDatum[key];
      });
    setDosije({ ...dosije, unosi: unosiOrder });
  };

  const loadFromServer = () => {
    if (registarID === null) {
      history.push(Routers.REGISTRI);
    }
    const reqTSA = axios.get(Endpoint.TIP_SKUPA_ATRIBUTA + "/search/findTipSkupaAtributaByRegistar", axiosConfig(token, { registar: Endpoint.REGISTAR + "/" + registarID }));
    const reqRegistar = axios.get(Endpoint.REGISTAR + "/" + registarID, axiosConfig(token));
    const reqPacijentParam = axios.get(Endpoint.PACIJENT_PARAMETRI, axiosConfig(token, { pacijentID: id }));
    const reqPacijentUnosi = axios.get(Endpoint.PACIJENT_IMA_UNOSE, axiosConfig(token, { pacijentID: id }));
    const reqHA = axios.get(Endpoint.HIJERARHIJA_ATRIBUTA + "/search/findByPacijentAndAktivan", axiosConfig(token, { pacijent: Endpoint.PACIJENT + "/" + id }));

    axios
      .all([reqTSA, reqRegistar, reqPacijentParam, reqPacijentUnosi, reqHA])
      .then(
        axios.spread((resTSA: any, resRegistar: any, resPacijentParam: any, resPacijentUnosi: any, resHA: any) => {
          getSvaFromHa(resHA);
          setDosije({
            ...dosije,
            registar: resRegistar.data,
            prikazPacijentAdministrativnaNapomena: resPacijentParam.data.prikazPacijentAdministrativnaNapomena,
            prikazPacijentImeRoditelja: resPacijentParam.data.prikazPacijentImeRoditelja,
            prikazPacijentMedicinskaNapomena: resPacijentParam.data.prikazPacijentMedicinskaNapomena,
            prikazPacijentPrikazJednogPola: resPacijentParam.data.prikazPacijentPrikazJednogPola,
            prikazPacijentRadnoMesto: resPacijentParam.data.prikazPacijentRadnoMesto,
            prikazPacijentStrucnaSprema: resPacijentParam.data.prikazPacijentStrucnaSprema,
            prikazPacijentZanimanje: resPacijentParam.data.prikazPacijentZanimanje,
            blurPodataka: resPacijentParam.data.blurPodataka,
            odabirDatumaPreUnosa: resPacijentParam.data.odabirDatumaPreUnosa,
            imaUnose: resPacijentUnosi.data.imaUnose,
          });
        })
      )
      .catch((error: any) => {
        console.log(error);
      });
  };

  const loadPoslednjiUnos = () => {
    axios
      .get(Endpoint.GET_POSLEDNJI_UNOS_PACIJENTA, axiosConfig(token, { pacijentID: id, registarID: registarID }))
      .then((response) => {
        setPoslednjiUnos(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffectOnce(() => {
    loadFromServer();
    loadPoslednjiUnos();
  });

  const home = { icon: "pi pi-home", url: "/" };
  const items = [{ label: dosije.registar.naziv, url: "/registri/" + registarID }];

  return (
    <React.Fragment>
      <BreadCrumb model={items} home={home} />
      <div className="grid p-4 m-0">
        <div className="col-4 mr-4">
          <div className="grid flex-column">
            <div className="col">
              <PacijentInfo dosije={dosije} />
            </div>
            <div className="col evenBackground">{poslednjiUnos?.autsi && <PoslednjiOdabraniUnos poslednjiUnos={poslednjiUnos} />}</div>
          </div>
        </div>
        <div className="col-4">
          <PacijentFormulari odabirDatumaPreUnosa={dosije.odabirDatumaPreUnosa} />
        </div>
      </div>
    </React.Fragment>
  );
}
