import React from "react";
import Brojni from "./Brojni";
import Tekstualni from "./Tekstualni";
import Datumski from "./Datumski";
import Skupovni from "./Skupovni";
import Izborni from "./Izborni";
import Grafikon from "./Grafikon";
import EnumTipovaVrednostiAtributa from "../../utils/EnumTipovaVrednostiAtributa";
import Select from "react-select";

class TipAtributaRouter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let tip = <div></div>;
    if (this.props.tvaNumVrednost) {
      let numerickaVrednost = this.props.tvaNumVrednost;
      if (numerickaVrednost === EnumTipovaVrednostiAtributa.IZBORNI) {
        if (this.props.zaPretragu) {
          tip = (
            <div>
              <Select
                className="col vrednost"
                placeholder="Izaberite vrednost"
                isSearchable={true}
                onChange={(value) => this.props.changeState("initValue", this.props.tipSkupaAtributaKey, value)}
                options={[
                  { label: "Da", value: 1 },
                  { label: "Ne", value: 0 },
                ]}
              />
            </div>
          );
        } else {
          tip = (
            <Izborni
              initValue={this.props.initValue}
              changeState={this.props.changeState}
              tipSkupaAtributaKey={this.props.tipSkupaAtributaKey}
              atributUTipuSkupaID={this.props.atributUTipuSkupaID}
              auts={this.props.auts}
            />
          );
        }
      } else if (numerickaVrednost === EnumTipovaVrednostiAtributa.BROJNI) {
        tip = (
          <Brojni
            initValue={this.props.initValue}
            changeState={this.props.changeState}
            tipSkupaAtributaKey={this.props.tipSkupaAtributaKey}
            atributUTipuSkupaID={this.props.atributUTipuSkupaID}
            auts={this.props.auts}
          />
        );
      } else if (numerickaVrednost === EnumTipovaVrednostiAtributa.TEKSTUALNI) {
        if (this.props.zaPretragu) {
          tip = (
            <div>
              <input type="text" className="form-control" onChange={(event) => this.props.changeState("initValue", this.props.tipSkupaAtributaKey, event.target.value)} value={this.props.initValue} />
            </div>
          );
        } else {
          tip = (
            <Tekstualni
              initValue={this.props.initValue}
              changeState={this.props.changeState}
              tipSkupaAtributaKey={this.props.tipSkupaAtributaKey}
              atributUTipuSkupaID={this.props.atributUTipuSkupaID}
              auts={this.props.auts}
            />
          );
        }
      } else if (numerickaVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI) {
        tip = (
          <Skupovni
            initValue={this.props.initValue}
            changeState={this.props.changeState}
            tipSkupaAtributaKey={this.props.tipSkupaAtributaKey}
            atributUTipuSkupaID={this.props.atributUTipuSkupaID}
            zaPretragu={this.props.zaPretragu}
            auts={this.props.auts}
            enumiPoAUTS={this.props.enumiPoAUTS}
          />
        );
      } else if (numerickaVrednost === EnumTipovaVrednostiAtributa.DATUMSKI) {
        tip = (
          <Datumski
            initValue={this.props.initValue}
            changeState={this.props.changeState}
            tipSkupaAtributaKey={this.props.tipSkupaAtributaKey}
            atributUTipuSkupaID={this.props.atributUTipuSkupaID}
            auts={this.props.auts}
            smallFormat={this.props.smallFormat !== undefined && this.props.smallFormat}
          />
        );
      } else if (numerickaVrednost === EnumTipovaVrednostiAtributa.GRAFIK) {
        tip = <Grafikon initValue={this.props.initValue} />;
      }
    }
    return <div>{tip}</div>;
  }
}

export default TipAtributaRouter;
