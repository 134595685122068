import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useContext, useRef } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, getImage, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import MessageType from "../../../infrastructure/MessageType";
import CrudSponzor from "./CrudSponzor";
import noImageIcon from "./../../../images/no-image-icon.png";

export default function CrudSponzorLista() {
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedSponzor, setSelectedSponzor] = useState();
  const [sponzoriList, setSponzoriList] = useState([]);
  const [entityOperation, setEntityOperation] = useState<string>("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [sponzorToChange, setSponzorToChange] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);
  const dialogRef = useRef<any>();

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axios
      .get(
        Endpoint.SPONZOR,
        axiosConfig(authData.token, {
          page: 0,
          size: 50000000,
        })
      )
      .then((sponzorCollection) => {
        setSponzoriList(sponzorCollection.data.data);
      });
  };

  const createSponzor = (sponzor: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.SPONZOR, sponzor, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO, sponzor.name + Labels.SPACE);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateSponzor = (sponzor: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .put(Endpoint.SPONZOR + `/${sponzor.id}`, sponzor, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, sponzor.name);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteSponzor = (sponzor: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(Endpoint.SPONZOR + `/${sponzor.id}`, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO, sponzor.name);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const dialogHeader = () => {
    const display = sponzorToChange ? sponzorToChange.name : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_SPONZORA;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_SPONZORA + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_SPONZORA + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_SPONZORA + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let s;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        s = selectedSponzor;
        break;
    }
    setEntityOperation(entityOperation);
    setSponzorToChange(s);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setDisplayDialog(false);
    setSelectedSponzor(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => {
    closeDialog();
  };

  const dialogFooter = () => {
    return (
      <Toolbar>
        <div className="p-toolbar-group-left">
          {entityOperation === EntityOperation.CREATE && (
            <Button
              label={Labels.BUTTON_KREIRAJ}
              icon="pi pi-save"
              className="p-button-success"
              onClick={() => {
                dialogRef.current.onCreate();
              }}
            />
          )}
          {entityOperation === EntityOperation.UPDATE && (
            <Button
              label={Labels.BUTTON_IZMENI}
              icon="pi pi-inbox"
              className="p-button-warning"
              onClick={() => {
                dialogRef.current.onUpdate();
              }}
            />
          )}
          {entityOperation === EntityOperation.DELETE && (
            <Button
              label={Labels.BUTTON_OBRISI}
              icon="pi pi-inbox"
              className="p-button-danger"
              onClick={() => {
                dialogRef.current.onDelete();
              }}
            />
          )}
        </div>
        <div className="p-toolbar-group-right">
          <Button
            label={Labels.BUTTON_ODUSTANI}
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              onCancel();
            }}
          />
        </div>
      </Toolbar>
    );
  };

  return (
    <div className="layout-user layout-sponsor">
      <Panel header={Labels.TITLE_PANEL_CRUD_SPONZOR} className="panel-user-list">
        <Toolbar>
          <div className="p-toolbar-group-left">
            <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
            <Button disabled={!selectedSponzor} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openDialog(EntityOperation.UPDATE)} />
            <Button disabled={!selectedSponzor} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
          </div>
          <div className="p-toolbar-group-right">
            <Button disabled={!selectedSponzor} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openDialog(EntityOperation.DELETE)} />
          </div>
        </Toolbar>
        <div>
          <DataTable
            filterDisplay="row"
            value={sponzoriList.sort((a: any, b: any) => (Number(a.displayOrder) > Number(b.displayOrder) ? 1 : -1))}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => {
              setSelectedSponzor(e.data);
            }}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left" field={"displayOrder"} header={Labels.COLUMN_DISPLAY_ORDER} sortable={true} />
            <Column className="column-align-left" field={"name"} header={Labels.COLUMN_HEADER_IME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"code"} header={Labels.COLUMN_HEADER_CODE} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"description"} header={Labels.COLUMN_HEADER_OPIS} filterMatchMode="contains" showFilterMenu={false} filter />
            <Column className="column-align-left" field={"note"} header={Labels.COLUMN_HEADER_NOTE} filterMatchMode="contains" showFilterMenu={false} filter />
            <Column className="column-align-left" field={"address"} header={Labels.COLUMN_HEADER_ADRESA} filterMatchMode="contains" showFilterMenu={false} filter />
            <Column className="column-align-left" field={"phone"} header={Labels.COLUMN_HEADER_TELEFON} filterMatchMode="contains" showFilterMenu={false} filter />
            <Column className="column-align-left" field={"websiteUrl"} header={Labels.COLUMN_HEADER_LINK} filterMatchMode="contains" showFilterMenu={false} filter />
            <Column
              className="column-align-left"
              field={"logo"}
              header={Labels.COLUMN_HEADER_LOGO}
              body={(e: any) => <img alt="L" className="sponsor-logo small-logo" src={getImage(e.logo, noImageIcon)} />}
            />
          </DataTable>
          <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} maximized={true} footer={dialogFooter()}>
            <CrudSponzor
              dialogRef={dialogRef}
              sponzorOperation={entityOperation}
              sponzor={sponzorToChange}
              onCreateSponzor={createSponzor}
              onUpdateSponzor={updateSponzor}
              onDeleteSponzor={deleteSponzor}
            />
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
