import moment from 'moment';
import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

export default function Grafikon({ initValue }) {
	const podaciSort = initValue ? initValue.podaci : [];
	podaciSort.sort(function (a, b) {
		return moment(a.naziv, 'DD.MM.YYYY.').toDate() - moment(b.naziv, 'DD.MM.YYYY/').toDate();
	});

	return (
		<div>
			<LineChart
				width={800}
				height={400}
				data={podaciSort}
				margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="naziv"
					padding={{ left: 30, right: 30 }}
				/>
				<YAxis />
				<Tooltip />
				<Legend verticalAlign="top" />
				<Line type="monotone" dataKey="HAQ" stroke="#8884d8" activeDot={{ r: 8 }} />
				<Line type="monotone" dataKey="DAS28" stroke="#82ca9d" />
				<Line type="monotone" dataKey="CDAI" stroke="#d6ab13" />
				<Line type="monotone" dataKey="SDAI" stroke="#9c58d3" />
				{initValue.dodatniParametri.map((parametar, i) => {
					return (
						<Line key={i} type="monotone" dataKey={parametar.oznaka} stroke={"#" + parametar.boja} />
					)
				})}
			</LineChart>
		</div>
	)

}