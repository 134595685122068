import React from "react";
import DisplaySponsorList from "./administracija/sponzor/DisplaySponzorList";
import Header from "./Header";
import Main from "./Main";
import Message from "./Message";

export default function Home() {

  return (
    <div>
      <Header />
      <Message />
      <Main />
      <DisplaySponsorList />
    </div>
  );
}
