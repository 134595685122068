import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { PickList } from "primereact/picklist";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useImperativeHandle, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";

interface CrudUlogaProp {
  ulogaOperation: string;
  uloga?: any;
  onCreateUloga: Function;
  onUpdateUloga: Function;
  onDeleteUloga: Function;
  onCancel: Function;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudUloga(prop: CrudUlogaProp) {
  const { ulogaOperation, uloga, onCreateUloga, onUpdateUloga, onDeleteUloga, dialogRef, setIndex, index } = prop;

  const [imaPrava, setImaPrava] = useState([]);
  const [nemaPrava, setNemaPrava] = useState([]);

  const [spisakRadnika, setSpisakRadnika] = useState([]);

  let [messages, setMessages] = useState<Messages>();
  const [ulogaChange, setUlogaChange] = useState<Partial<any>>(uloga);
  const { authData } = useContext(AppContext);

  useEffectOnce(() => {
    if (uloga) {
      const requestImaPrava = axios.get(
        Endpoint.ULOGA_APLIKATIVNO_PRAVO + "/search/findAplikativnaPravaByUloga",
        axiosConfig(authData.token, { uloga: Endpoint.ULOGA + "/" + uloga.id, aktivan: true })
      );
      const requestSvaPrava = axios.get(Endpoint.APLIKATIVNO_PRAVO, axiosConfig(authData.token));
      const requestSpisakRadnika = axios.get(Endpoint.RADNIK_ULOGA + "/search/findRadnikByUloga", axiosConfig(authData.token, { uloga: Endpoint.ULOGA + "/" + uloga.id }));

      axios
        .all([requestImaPrava, requestSvaPrava, requestSpisakRadnika])
        .then(
          axios.spread((responseImaPrava: any, responseSvaPrava: any, responseSpisakRadnika: any) => {
            const svaPravoArray = responseSvaPrava.data._embedded.aplikativnoPravoes;
            const imaPravoArray = responseImaPrava.data._embedded.aplikativnoPravoes;
            const imaPravoID = imaPravoArray.map((p: any) => {
              return p.id;
            });
            const nemaPravoArray = svaPravoArray.filter((p: any) => !imaPravoID.includes(p.id));
            setImaPrava(imaPravoArray);
            setNemaPrava(nemaPravoArray);
            setSpisakRadnika(responseSpisakRadnika.data._embedded.radniks);
          })
        )
        .catch((error: any) => {
          showError(Labels.TITLE_MESSAGES_GRESKA, error);
        });
    }
  });

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
        return;
      }
      if (!uloga) {
        axios
          .get(
            Endpoint.ULOGA + "/search/findByIme",
            axiosConfig(authData.token, {
              ime: ulogaChange.ime,
            })
          )
          .then((res: any) => {
            if (res.data._embedded.ulogas.length > 0) {
              showError(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, Labels.TITLE_MESSAGES_ULOGA_POSTOJI);
            } else {
              onCreateUloga(ulogaChange)
                .then(() => {})
                .catch((error: any) => {
                  showError(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
                });
            }
          });
      } else {
        onCreateUloga(ulogaChange)
          .then(() => {})
          .catch((error: any) => {
            showError(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
          });
      }
    },

    onUpdate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
        return;
      }
      onUpdateUloga(ulogaChange)
        .then(() => {})
        .catch((error: any) => {
          showError(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
        });
    },

    onDelete: () => {
      onDeleteUloga(ulogaChange)
        .then(() => {})
        .catch((error: any) => {
          showError(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    },
  }));

  const showError = (summary: string, detail: string) => {
    if (messages) {
      messages.replace({
        severity: "error",
        summary: summary,
        detail: detail,
        closable: true,
        sticky: true,
      });
    }
  };

  const validateInput = (message: string) => {
    if (!ulogaChange || !ulogaChange.ime) {
      showError(message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    return true;
  };

  const onChangePrava = (event: any) => {
    if (imaPrava.length < event.target.length) {
      const imaPravoID = imaPrava.map((p: any) => p.id);
      event.target.forEach((aplPravo: any) => {
        if (!imaPravoID.includes(aplPravo.id)) {
          const ulogaAplPravo = {
            uloga: uloga._links.self.href,
            aplikativnoPravo: aplPravo._links.self.href,
          };
          axios.post(Endpoint.ULOGA_APLIKATIVNO_PRAVO, ulogaAplPravo, axiosConfig(authData.token));
        }
      });
      setImaPrava(event.target);
      setNemaPrava(event.source);
    } else if (nemaPrava.length < event.source.length) {
      const nemaPravoID = nemaPrava.map((aplPravo: any) => aplPravo.id);
      const ulogaAppPravoDelete: any[] = [];
      event.source.forEach((aplPravo: any) => {
        if (!nemaPravoID.includes(aplPravo.id)) {
          ulogaAppPravoDelete.push(aplPravo.id);
        }
      });
      axios
        .post(
          Endpoint.ULOGA_APLIKATIVNO_PRAVO_BRISANJE,
          {
            ulogaID: uloga.id,
            aplikativnaPravaID: ulogaAppPravoDelete,
          },
          axiosConfig(authData.token)
        )
        .then((res: any) => {
          setImaPrava(event.target);
          setNemaPrava(event.source);
        })
        .catch((error: any) => {
          showError(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    }
  };

  const pravaTemplete = (object: any) => (
    <div className="p-clearfix">
      <div>{object.naziv}</div>
    </div>
  );

  return (
    <div className="panel-role">
      <div className="col-12">
        <Messages ref={(el: any) => setMessages(el)} />
      </div>
      <TabView renderActiveOnly={false} activeIndex={index} onTabChange={(e) => setIndex(e.index)}>
        <TabPanel header={Labels.TAB_ULOGA}>
          <div className="col-8 xl:col-8 lg:col-8 md:col-6 sm:col-12">
            <div className="grid align-items-center">
              <div className="col-4 mb-3">{Labels.LABEL_NAZIV}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(ulogaOperation)}
                  value={ulogaChange && ulogaChange.ime}
                  onChange={(e: any) => {
                    setUlogaChange({
                      ...ulogaChange,
                      ime: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_OPIS}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(ulogaOperation)}
                  value={ulogaChange && ulogaChange.opis}
                  onChange={(e: any) => {
                    setUlogaChange({
                      ...ulogaChange,
                      opis: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_NUMERICKA_VREDNOST}</div>
              <div className="col-8 p-fluid p-0">
                <InputNumber
                  disabled={isFormDisabled(ulogaOperation)}
                  value={ulogaChange && ulogaChange.numerickaVrednost}
                  onValueChange={(e: any) => {
                    setUlogaChange({
                      ...ulogaChange,
                      numerickaVrednost: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.TAB_APLIKATIVNA_PRAVA} disabled={ulogaOperation === EntityOperation.CREATE}>
          <PickList
            source={nemaPrava}
            target={imaPrava}
            itemTemplate={pravaTemplete}
            sourceHeader="Prava koje nema"
            targetHeader="Prava koja ima"
            sourceStyle={{ height: "650px" }}
            targetStyle={{ height: "650px" }}
            onChange={onChangePrava}
            showSourceControls={false}
            showTargetControls={false}
            style={{ pointerEvents: isFormDisabled(ulogaOperation) ? "none" : "" }}
          />
        </TabPanel>
        <TabPanel header={Labels.TAB_PREGLED_RADNIKA_SA_ULOGOM} disabled={ulogaOperation === EntityOperation.CREATE}>
          <DataTable
            filterDisplay="row"
            value={spisakRadnika}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left" field={"ime"} header={Labels.COLUMN_HEADER_IME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"prezime"} header={Labels.COLUMN_HEADER_PREZIME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  );
}
