import axios from "axios";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig } from "../../../utils/Utils";
import { AppContext } from "../../Store";

interface PromenaLozinkeProps {
  onCancel: Function;
  ulogovanRadnikId: string;
}

export default function PromenaLozinke(props: PromenaLozinkeProps) {
  const { onCancel, ulogovanRadnikId } = props;
  const [staraLozinka, setStaraLozinka] = useState<any>("");
  const [novaLozinka, setNovaLozinka] = useState("");
  const [potvrdaLozinke, setPotvrdaLozinke] = useState("");
  const { showGrowlMessage, authData } = useContext(AppContext);

  const validateInput = () => {
    if (staraLozinka === "") {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_PROMENE_LOZINKE, Labels.MESSAGES_STARA_LOZINKA);
      return false;
    }
    if (novaLozinka === "") {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_PROMENE_LOZINKE, Labels.MESSAGES_NOVA_LOZINKA);
      return false;
    }
    if (potvrdaLozinke === "") {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_PROMENE_LOZINKE, Labels.MESSAGES_POTVRDA_NOVE_LOZINKE);
      return false;
    }
    if (potvrdaLozinke !== novaLozinka) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_PROMENE_LOZINKE, Labels.MESSAGES_LOZINKE_NISU_ISTE);
      return false;
    }
    return true;
  };
  const promenaLozinke = () => {
    if (!validateInput()) {
      return;
    }

    axios
      .post(
        Endpoint.RADNIK_PROMENA_LOZINKE,
        {
          radnikID: ulogovanRadnikId,
          staraLozinka: staraLozinka,
          novaLozinka: novaLozinka,
          potvrdaLozinke: potvrdaLozinke,
          isAdmin: false,
        },
        axiosConfig(authData.token)
      )
      .then((response) => {
        showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENA_LOZINKA, "");
        onCancel();
      })
      .catch((error) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_PROMENE_LOZINKE, error.response.data.message);
      });
  };

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_SACUVAJ_NOVU_LOZINKU} icon="pi pi-save" className="p-button-primary" onClick={promenaLozinke} />
      <Button label={Labels.BUTTON_ODUSTANI} className="p-button-warning" onClick={() => onCancel()} />
    </React.Fragment>
  );

  return (
    <div>
      <div>
        <div className="xl-9 lg-9 md-9 sm-12">
          <div className="grid fluid align-items-center">
            <div className="col-4">{Labels.LABEL_STARA_LOZINKA}</div>
            <div className="col-8">
              <Password
                feedback={false}
                value={staraLozinka}
                autoComplete="new-password"
                onChange={(e: any) => {
                  setStaraLozinka(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="xl-9 lg-9 md-9 sm-12">
          <div className="grid fluid align-items-center">
            <div className="col-4">{Labels.LABEL_NOVA_LOZINKA}</div>
            <div className="col-8">
              <Password
                value={novaLozinka}
                autoComplete="new-password"
                onChange={(e: any) => {
                  setNovaLozinka(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="xl-9 lg-9 md-9 sm-12">
          <div className="grid fluid align-items-center">
            <div className="col-4">{Labels.LABEL_POTVRDA_LOZINKE}</div>
            <div className="col-8">
              <Password
                feedback={false}
                value={potvrdaLozinke}
                autoComplete="new-password"
                onChange={(e: any) => {
                  setPotvrdaLozinke(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Toolbar left={leftContents} />
    </div>
  );
}
