import React from "react";
import { CALENDAR_DATE_FORMAT } from "../../../utils/Utils";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import CUDialogPacijent from "../../pacijent/CUDialogPacijent";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import RegistarLogical from "./RegistarLogical";
import RegistarDataTable from "./RegistarDataTable";
import RegistarFilterPanel from "./RegistarFilterPanel";
import { Button } from "primereact/button";

export default function Registar() {
  const {
    registar,
    state,
    setState,
    podaci,
    dataTable,
    loading,
    tableRows,
    first,
    handleRowsAndFirst,
    tipoviSkupaAtributa,
    displayPacijent,
    displayUnos,
    openDialogUnos,
    openDialogPacijent,
    closeDialogUnos,
    closeDialogPacijent,
    kriterijumZaPretragu,
    searchFieldOnChange,
    handleKeyPressLogIn,
    unosiPoljaZaPretragu,
    prikaziPoljaZaPretragu,
    pretraga,
    noviUnos,
    changeState,
    changeVeza,
    changeTipaSkupaAtributa,
    changeAtributUTipuSkupa,
    changeOperator,
    dodajRedPretrage,
    obrisiRedPretrage,
  } = RegistarLogical();

  const renderFooter = () => {
    return (
      <>
        <Button label={Labels.BUTTON_ODUSTANI} onClick={closeDialogUnos} className="p-button-warning" />
        <Button label={Labels.BUTTON_KREIRAJ} onClick={noviUnos} className="p-button-primary" autoFocus />
      </>
    );
  };

  const createExaminationList = tipoviSkupaAtributa?.map(({ value, label }: any) => {
    return (
      <div key={value} className="field-radiobutton col-12 py-0">
        <RadioButton
          inputId={value}
          name="category"
          value={state.selectTipSkupAtributa}
          onChange={(e) => setState({ ...state, selectTipSkupAtributa: value })}
          checked={state.selectTipSkupAtributa === value}
        />
        <label className="p-2" htmlFor={value}>
          {label}
        </label>
      </div>
    );
  });

  return (
    <div className="grid px-3">
      <RegistarFilterPanel
        registar={registar}
        state={state}
        podaci={podaci}
        tipoviSkupaAtributa={tipoviSkupaAtributa}
        kriterijumZaPretragu={kriterijumZaPretragu}
        searchFieldOnChange={searchFieldOnChange}
        handleKeyPressLogIn={handleKeyPressLogIn}
        unosiPoljaZaPretragu={unosiPoljaZaPretragu}
        prikaziPoljaZaPretragu={prikaziPoljaZaPretragu}
        pretraga={pretraga}
        changeState={changeState}
        changeVeza={changeVeza}
        changeTipaSkupaAtributa={changeTipaSkupaAtributa}
        changeAtributUTipuSkupa={changeAtributUTipuSkupa}
        changeOperator={changeOperator}
        dodajRedPretrage={dodajRedPretrage}
        obrisiRedPretrage={obrisiRedPretrage}
      />
      <RegistarDataTable
        dataTable={dataTable}
        loading={loading}
        tableRows={tableRows}
        first={first}
        handleRowsAndFirst={handleRowsAndFirst}
        tipoviSkupaAtributa={tipoviSkupaAtributa}
        openDialogUnos={openDialogUnos}
        openDialogPacijent={openDialogPacijent}
      />

      <Dialog header={Labels.TITLE_DIALOG_KREIRANJE_UNOSA} className="w-3" visible={displayUnos} footer={renderFooter} onHide={closeDialogUnos}>
        <div className="grid">
          {state.odabirDatumaPreUnosa && (
            <>
              <label className="col-12">{Labels.LABEL_DATUM_PREGLEDA}:</label>
              <Calendar className="col-12" showIcon dateFormat={CALENDAR_DATE_FORMAT} value={state.vremeUnosa} onChange={(e: any) => setState({ ...state, vremeUnosa: e.value })} />
            </>
          )}
          <label className="col-12">{Labels.LABEL_IZABERITE_TIP_PREGLEDA}:</label>
          {createExaminationList}
        </div>
      </Dialog>
      <Dialog header={Labels.LABEL_NOVI_PACIJENT} className="w-5" visible={displayPacijent} onHide={closeDialogPacijent}>
        <CUDialogPacijent pacijentOperation={EntityOperation.CREATE} onCancel={closeDialogPacijent} />
      </Dialog>
    </div>
  );
}
