import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState, useContext, useImperativeHandle } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { Dropdown } from "primereact/dropdown";
import Endpoint from "../../../endpoints/Endpoint";
import { axiosConfig } from "../../../utils/Utils";
import axios from "axios";
import MessageType from "../../../infrastructure/MessageType";
import { AppContext } from "../../Store";

interface CrudTipoviStatistickogIzvestajaProps {
  tipStatistickogIzvestajaOperation: string;
  tipStatistickogIzvestaja?: any;
  onCreateTipStatistickogIzvestaja: Function;
  onUpdateTipStatistickogIzvestaja: Function;
  onDeleteTipStatistickogIzvestaja: Function;
  onCancel: Function;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudTipoviStatistickogIzvestaja(props: CrudTipoviStatistickogIzvestajaProps) {
  const {
    tipStatistickogIzvestajaOperation,
    tipStatistickogIzvestaja,
    onCreateTipStatistickogIzvestaja,
    onUpdateTipStatistickogIzvestaja,
    onDeleteTipStatistickogIzvestaja,
    dialogRef,
    setIndex,
    index,
  } = props;
  const [tipStatistickogIzvestajaChange, setTipStatistickogIzvestajaChange] = useState<Partial<any>>(tipStatistickogIzvestaja);
  const [kategorijeStatistickogIzvestaja, setKategorijeStatistickogIzvestaja] = useState();
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    const requestKategorijeStatistickogIzvestaja = axios.get(
      Endpoint.KATEGORIJA_STATISTICKOG_IZVESTAJA + "/search/findByAktivan",
      axiosConfig(authData.token, { aktivan: true, page: 0, size: 50000000 })
    );
    axios
      .all([requestKategorijeStatistickogIzvestaja])
      .then(
        axios.spread((responseKategorijeStatistickogIzvestaja) => {
          setKategorijeStatistickogIzvestaja(responseKategorijeStatistickogIzvestaja.data._embedded.kategorijaStatistickogIzvestajas);
        })
      )
      .catch((error: any) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, error);
      });
  });

  const validateInput = (message: string) => {
    if (!tipStatistickogIzvestajaChange || !tipStatistickogIzvestajaChange.naziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    if (!tipStatistickogIzvestajaChange || !tipStatistickogIzvestajaChange.numerickaVrednost) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_NUMERICKA_VREDNOST);
      return false;
    }
    if (!tipStatistickogIzvestajaChange || !tipStatistickogIzvestajaChange.nazivKategorijeStatistickogIzvestaja) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_KATEGORIJA_STATISTICKOG_IZVESTAJA);
      return false;
    }
    return true;
  };

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
        return;
      }
      onCreateTipStatistickogIzvestaja(tipStatistickogIzvestajaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
        });
    },
    onUpdate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
        return;
      }
      onUpdateTipStatistickogIzvestaja(tipStatistickogIzvestajaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
        });
    },
    onDelete: () => {
      onDeleteTipStatistickogIzvestaja(tipStatistickogIzvestajaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    },
  }));

  return (
    <div className="panel-report">
      <TabView
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.TAB_TIP_IZVESTAJA}>
          <div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_KATEGORIJA_IZVESTAJA}</div>
                <div className="col-8">
                  <Dropdown
                    disabled={isFormDisabled(tipStatistickogIzvestajaOperation)}
                    dataKey="id"
                    value={
                      tipStatistickogIzvestajaChange && tipStatistickogIzvestajaChange.nazivKategorijeStatistickogIzvestaja ? tipStatistickogIzvestajaChange.nazivKategorijeStatistickogIzvestaja : ""
                    }
                    options={kategorijeStatistickogIzvestaja}
                    editable={true}
                    onChange={(e) => {
                      setTipStatistickogIzvestajaChange({
                        ...tipStatistickogIzvestajaChange,
                        nazivKategorijeStatistickogIzvestaja: e.value.naziv,
                        kategorijaStatistickogIzvestaja: Endpoint.KATEGORIJA_STATISTICKOG_IZVESTAJA + "/" + e.value.id,
                      });
                    }}
                    optionLabel="naziv"
                    filter={true}
                    filterBy="naziv"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NAZIV}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(tipStatistickogIzvestajaOperation)}
                    value={tipStatistickogIzvestajaChange && tipStatistickogIzvestajaChange.naziv ? tipStatistickogIzvestajaChange.naziv : ""}
                    onChange={(e: any) => {
                      setTipStatistickogIzvestajaChange({
                        ...tipStatistickogIzvestajaChange,
                        naziv: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NUMERICKA_VREDNOST}</div>
                <div className="col-8">
                  <InputNumber
                    disabled={isFormDisabled(tipStatistickogIzvestajaOperation)}
                    value={tipStatistickogIzvestajaChange && tipStatistickogIzvestajaChange.numerickaVrednost ? tipStatistickogIzvestajaChange.numerickaVrednost : null}
                    onValueChange={(e: any) => {
                      setTipStatistickogIzvestajaChange({
                        ...tipStatistickogIzvestajaChange,
                        numerickaVrednost: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
