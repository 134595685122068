const EnumBrojRezultataPretrage = {
  "50": "50",
  "100": "100",
  "200": "200",
  "1000": "1000",
  Svi: "-1",
};

const brojRezultatePretrageOptions = Object.entries(EnumBrojRezultataPretrage).map((x: any) => {
  return { name: x[0], code: x[1] };
});

const findBrojRezultataByCode = (code: any) => {
  const temp = code ? code.toString() : EnumBrojRezultataPretrage[200];
  return brojRezultatePretrageOptions.find((x: any) => x.code === temp);
};

export { EnumBrojRezultataPretrage, brojRezultatePretrageOptions, findBrojRezultataByCode };
