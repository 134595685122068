// import React, { useState } from "react";
// export default function Unos2=()=> {
//   return <div>"aaaa"</div>;
// }
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import BeforeLeave from "react-before-leave";
// import SweetAlert from "react-bootstrap-sweetalert";
// import { NotificationManager } from "react-notifications";
// import Select from "react-select";
import Endpoint from "../../endpoints/Endpoint";
import Routers from "../../utils/Routers";
import { axiosConfig, CALENDAR_DATE_FORMAT, DATE_FORMAT, registarID, useEffectOnce } from "../../utils/Utils";
import Breadcrumb from "./../Breadcrumb";
import Auts from "./../hijerarhija/Auts";
import EnumTipovaVrednostiAtributa from "../../utils/EnumTipovaVrednostiAtributa";
// import ReactHtmlParser from "react-html-parser";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { Calendar } from "primereact/calendar";
import { useParams, useLocation, useHistory, NavLink, Link } from "react-router-dom";
import { setTimeout } from "timers";
import Auts2 from "../hijerarhija/Auts2";

const ulogovaniRadnik = JSON.parse(localStorage.getItem("ulogovanRadnik") || "null");
const token = localStorage.hasOwnProperty("ulogovanRadnik") ? ulogovaniRadnik.token : ""; //TODO authData?.token

interface UnosLocationType {
  registarOperation: string;
}
export default function Unos2({ unosID }: any) {
  const history = useHistory();
  const location = useLocation<UnosLocationType>();
  const { id } = useParams<{ id?: string }>();
  const emptyString: any = "";
  const emptyObj: any = {};
  const emptyArray: any = [];

  const [loading, setLoading] = useState<boolean>(false);
  const [registarObj, setRegistarObj] = useState<any>();
  const [pacijentObj, setPacijentObj] = useState<any>();
  const [radnikOdgovoranOptions, setRadnikOdgovoranOptions] = useState<any>();
  const [unosObj, setUnosObj] = useState<any>();
  const [autsiPanel, setAutsiPanel] = useState<any>([]);
  // const [defaultTab, setDefaultTab] = useState<any>("");
  // const [defaultPodTab, setDefaultPodTab] = useState<any>({});
  // const [initValue, setInitValue] = useState<any>({});
  // const [prikazAUTS, setPrikazAUTS] = useState<any>({});
  const [sifraAutsPoTipu, setSifraAutsPoTipu] = useState<any>({});
  const [autsi, setAutsi] = useState<any>({});
  const [autsiSegmentiOrder, setAutsiSegmentiOrder] = useState<any>({});
  const [enumiSvi, setEnumiSvi] = useState<any>({});
  // const [enumiPoAUTS, setEnumiPoAUTS] = useState<any>({});
  const [autsiSeparatori, setAutsiSeparatori] = useState<any>({});
  const [autsiSifraZavisnost, setAutsiSifraZavisnost] = useState<any>({});
  const [snimljenUnosID, setSnimljenUnosID] = useState<any>(id ?? "");

  const [prikazAlerta, setPrikazAlerta] = useState<boolean>(false);
  const [prikazSnimiAlerta, setPrikazSnimiAlerta] = useState<boolean>(false);
  const [pravoIzmeneUnosa, setPravoIzmeneUnosa] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [beforeLeaveEnabled, setBeforeLeaveEnabled] = useState<boolean>(false);
  const [greska, setGreska] = useState<boolean>(false);
  const [ostani, setOstani] = useState<boolean>(false);
  const [oboji, setOboji] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [popUpHtmlInfo, setPopUpHtmlInfo] = useState<any>("");

  const [state, setState] = useState<any>({
    defaultTab: "",
    defaultPodTab: {},
    initValue: {},
    prikazAUTS: {},
    enumiPoAUTS: {},
    // snimljenUnosID: !isNaN(id) ? id : "",
    // registar: {},
    // pacijent: {},
    // unos: {},
    // autsi: [],
    // autsiSeparatori: {},
    // autsiSegmentiOrder: {},
    // sifraAutsPoTipu: {},
    // enumiSvi: {},
    // autsiPanel: [],
    // autsiSifraZavisnost: {},
    // datumKreiranja: "",
    // radnikOdgovoranOptions: [],
    // radnikOdgovoranSelect: null,
    // datumUnosa: moment(),
    // ucitano: false,
    // prikazAlerta: false,
    // prikazSnimiAlerta: false,
    // pravoIzmeneUnosa: false,
    // disabledButton: false,
    // beforeLeaveEnabled: true,
    // greska: false,
    // ostani: false,
    // oboji: false,
    // showPopUp: false,
    popUpHtmlInfo: "",
  });

  useEffect(() => {
    console.log(state);
  }, [state]);

  const changeState = (stateName: any, stateKey: any, stateValue: any) => {
    console.log(stateName, stateKey, stateValue);
    if (Array.isArray(stateName)) {
      let stateSvi = emptyObj;
      stateName.forEach((elem: any) => {
        let stateOne = state[elem[0]];
        Object.assign(stateOne, { [elem[1]]: elem[2] });
        stateSvi[elem[0]] = stateOne;
      });
      setState({ ...state, initValue: stateSvi["initValue"] });
      // setState(
      //   stateSvi
      //   // () => changeRacunanjeAutsa()
      // );
      // changeRacunanjeAutsa();
    } else {
      let stateSvi = state[stateName];
      if (stateSvi !== undefined) {
        if (stateValue !== undefined) {
          Object.assign(stateSvi, { [stateKey]: stateValue });
          setState(
            { ...state, [stateName]: stateSvi }
            // () => changeRacunanjeAutsa()
          );
          changeRacunanjeAutsa();
        } else {
          setState(
            { ...state, [stateName]: stateKey }
            // () => changeRacunanjeAutsa()
          );
          changeRacunanjeAutsa();
        }
      }
    }
  };

  const yearDiff = (date1: any, date2: any) => {
    //koristi se u formuli za izracunavanje
    if (date1 === "0" || date2 === "0") {
      return 0;
    }

    var months = monthDiff(date1, date2);
    return months <= 0 ? 0 : Math.ceil(months / 12);
  };

  const monthDiff = (date1: any, date2: any) => {
    //koristi se u formuli za izracunavanje
    if (date1 === "0" || date2 === "0") {
      return 0;
    }

    let months = 0;
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  const dayDiff = (date1: any, date2: any) => {
    if (date1 === "0" || date2 === "0") {
      return 0;
    }
    let datum1 = new Date(date1);
    let datum2 = new Date(date2);
    // if (datum2 - datum1 <= 0) {
    //   return 0;
    // }
    var timeDiff = Math.abs(datum2.getTime() - datum1.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  };

  const changeRacunanjeAutsa = () => {
    let initValueObject = state.initValue;
    let prikazAUTSObject = state.prikazAUTS;
    let autsSaFormulom = autsi.filter((x: any) => x.formulaZaRacunanje && x.formulaZaRacunanje !== "");
    if (autsSaFormulom.length > 0) {
      autsSaFormulom.forEach((auts: any) => {
        let vrednost = racunanjeInitAutsa(auts, state.prikazAUTS);
        Object.assign(initValueObject, { [auts.id]: vrednost });
        Object.assign(prikazAUTSObject, { [auts.id]: vrednost });
        // initValueObject[auts.id] = vrednost;
        // prikazAUTSObject[auts.id] = vrednost;
      });
      setState({ ...state, initValue: initValueObject, prikazAUTS: prikazAUTSObject });
      // setInitValue(initValueObject);
      // setPrikazAUTS(prikazAUTSObject);
    }
  };

  const obrisUnosConfirm = () => {
    setPrikazAlerta(true);
    // setState({
    //   prikazAlerta: true,
    // });
  };

  const obrisiUnosCancel = () => {
    setPrikazAlerta(false);
    // setState({
    //   prikazAlerta: false,
    // });
  };

  const popuniPonovo = () => {
    setPrikazSnimiAlerta(false);
    setOboji(true);
    // setState({
    //   prikazSnimiAlerta: false,
    //   oboji: true,
    // });
  };

  const prihvatiUpozorenje = () => {
    setPrikazSnimiAlerta(false);
    // setState({
    //   prikazSnimiAlerta: false,
    // });
    preSnimanjaUnosa();
    axios
      .post(
        Endpoint.NOVI_UNOS_VREDNOSTI,
        {
          unosID: snimljenUnosID,
          tsaID: unosObj.id,
          registarID: registarObj.id,
          pacijentID: pacijentObj?.id,
          vrednosti: state.initValue,
          //   radnikOdgovoran: state.radnikOdgovoranSelect !== undefined ? state.radnikOdgovoranSelect.value : ulogovaniRadnik.id,
          radnikOdgovoran: unosObj?.radnikOdgovoran !== undefined ? unosObj.radnikOdgovoran.value : ulogovaniRadnik.id,
          datumUnosa: moment(unosObj.datumUnosa).format(DATE_FORMAT),
        },
        axiosConfig(token)
      )
      .then((response: any) => {
        // if (!state.ostani) {
        if (!ostani) {
          history.push(`${Routers.PACIJENTI}/${pacijentObj?.id}`);
        }
        // setState({
        //   disabledButton: false,
        //   beforeLeaveEnabled: false,
        //   snimljenUnosID: response.data.snimljenUnosID,
        // });
        setDisabledButton(false);
        setBeforeLeaveEnabled(false);
        setSnimljenUnosID(response.data.snimljenUnosID);
        window.history.pushState("", "RegiX", `${Routers.UNOSI}/${response.data.snimljenUnosID}`);
        // NotificationManager.success("Uspešno snimljen unos.");
      })
      .catch((error: any) => {
        setState({
          disabledButton: false,
          beforeLeaveEnabled: false,
        });
        // NotificationManager.error("Došlo je do greške prilikom snimanja unos.");
      });
  };

  // const zavisniAUTS = () => {
  //   let autsiSifraZavisnostTemp = emptyObj;
  //   autsi.forEach((auts: any) => {
  //     if (auts.formulaZaPrikaz != null) {
  //       let zavisnostAUTSI = emptyArray;
  //       auts.formulaZaPrikaz.split(" ").forEach((elem: any, e: any) => {
  //         if (elem.startsWith("$(")) {
  //           elem = elem.split("$(")[1].split(")")[0];
  //           let autsZavisan = state.autsi.filter((x: any) => x.sifra === elem && x.formulaZaPrikaz !== undefined)[0];
  //           if (autsZavisan !== undefined) {
  //             zavisnostAUTSI.push(autsZavisan);
  //           }
  //         }
  //       });
  //       autsiSifraZavisnostTemp[auts.sifra] = zavisnostAUTSI;
  //     }
  //   });
  //   setAutsiSifraZavisnost(autsiSifraZavisnostTemp);
  // setState({
  //   autsiSifraZavisnost,
  // });
  // };

  const prikazivanjeZavisnihAutsa = (auts: any) => {
    let prikaz = true;
    if (autsiSifraZavisnost[auts.sifra] !== undefined) {
      autsiSifraZavisnost[auts.sifra].map((autsZavisni: any) => {
        if (!prikazivanjeAutsaZavisnih(autsZavisni) && prikaz) {
          prikaz = false;
        }
      });
    }
    return prikaz;
  };

  const prikazivanjeAutsaZavisnih = (auts: any) => {
    let prikaz = true;
    if (auts.formulaZaPrikaz != null) {
      let formulaZaPrikaz = auts.formulaZaPrikaz;
      let formulaArray = formulaZaPrikaz.split(" ");
      let vrednost = emptyString;
      formulaArray.forEach((elem: any, e: any) => {
        if (elem.startsWith("$(")) {
          elem = elem.split("$(")[1].split(")")[0];
          if (state.prikazAUTS) {
            if (state.prikazAUTS[elem]) {
              vrednost = state.prikazAUTS[elem];
              if (sifraAutsPoTipu !== undefined && sifraAutsPoTipu[elem] === 4) {
                let vrednostArray = emptyArray;
                if (!Array.isArray(vrednost)) {
                  vrednost = [vrednost];
                }
                vrednost.forEach((vre: any, v: any) => {
                  vrednostArray.push(enumiSvi[vre.value]);
                });
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", "[" + vrednostArray + "]");
              } else {
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", vrednost);
              }
            } else {
              prikaz = false;
            }
          }
        }
      });
      if (vrednost !== "" && !formulaZaPrikaz.includes("$")) {
        prikaz = eval(formulaZaPrikaz);
      }
    }
    return prikaz;
  };

  const prikazivanjeAutsa = (auts: any) => {
    let prikaz = prikazivanjeZavisnihAutsa(auts);
    if (auts.formulaZaPrikaz != null && prikaz) {
      let formulaZaPrikaz = auts.formulaZaPrikaz;
      let formulaArray = formulaZaPrikaz.split(" ");
      let vrednost = emptyString;
      formulaArray.map((elem: any, e: any) => {
        if (elem.startsWith("$(")) {
          elem = elem.split("$(")[1].split(")")[0];
          if (state.prikazAUTS) {
            if (state.prikazAUTS[elem]) {
              vrednost = state.prikazAUTS[elem];
              if (sifraAutsPoTipu && sifraAutsPoTipu[elem] === EnumTipovaVrednostiAtributa.SKUPOVNI) {
                let vrednostArray = emptyArray;
                if (!Array.isArray(vrednost)) {
                  vrednost = [vrednost];
                }
                vrednost.map((vre: any, v: any) => {
                  vrednostArray.push(enumiSvi[vre.value]);
                });
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", "[" + vrednostArray + "]");
              } else {
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", vrednost);
              }
            } else {
              prikaz = false;
            }
          }
        }
      });
      if (vrednost !== "" && !formulaZaPrikaz.includes("$")) {
        prikaz = eval(formulaZaPrikaz);
      }
    }
    return prikaz;
  };

  const racunanjeInitAutsa = (auts: any, vrednostAUTS: any) => {
    let vrednost: any = 0;
    if (auts.formulaZaRacunanje != null && auts.formulaZaRacunanje !== "") {
      let formulaZaRacunanje = auts.formulaZaRacunanje;
      //TODO za develop samo
      if (formulaZaRacunanje == "this.yearDiff(this.state.pacijent.datumRodjenja, this.state.datumUnosa)") {
        formulaZaRacunanje = "yearDiff(pacijentObj.datumRodjenja, unosObj.datumUnosa)";
      }
      if (formulaZaRacunanje.startsWith("this.")) {
        formulaZaRacunanje = formulaZaRacunanje.replace("this.", "");
      }

      let formulaArray = formulaZaRacunanje.split(" ");
      formulaArray.forEach((elem: any, e: any) => {
        if (elem.includes("$(")) {
          elem = elem.split("$(")[1].split(")")[0];
          vrednost = vrednostAUTS[elem] !== "" && !isNaN(vrednostAUTS[elem]) ? vrednostAUTS[elem] : 0;
          formulaZaRacunanje = formulaZaRacunanje.replace("$(" + elem + ")", vrednost);
        }
      });
      if (!formulaZaRacunanje.includes("$")) {
        vrednost = eval(formulaZaRacunanje);
      }
    }
    return Math.abs(vrednost) !== Infinity ? parseFloat(vrednost).toFixed(2) : 0;
  };

  const changePodTab = (segmentID: any, value: any) => {
    // let podTab = defaultPodTab;
    // podTab[segmentID] = value;
    // setDefaultPodTab(podTab);
    let podTab = state.defaultPodTab;
    podTab[segmentID] = value;
    setState({ ...state, defaultPodTab: podTab });
  };

  const obrisiUnos = () => {
    axios
      .post(Endpoint.OBRISI_UNOS, { id: id }, axiosConfig(token))
      .then((res: any) => {
        setState({ beforeLeaveEnabled: false });
        history.push(`${Routers.PACIJENTI}/${pacijentObj?.id}`);
        // NotificationManager.success("Uspešno obrisan unos");
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const preSnimanjaUnosa = () => {
    let initValue = { ...state.initValue };
    state.autsi.forEach((auts: any) => {
      if (!prikazivanjeAutsa(auts)) {
        initValue[auts.id] = auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI ? [] : "";
      }
    });
  };

  const snimiUnos = (ostani: any) => {
    const { initValue } = state;
    let greska = false;
    // setState({
    //   ostani: ostani,
    //   disabledButton: true,
    //   beforeLeaveEnabled: false,
    //   greska: false,
    // });
    setOstani(ostani);
    setDisabledButton(true);
    setBeforeLeaveEnabled(false);
    setGreska(false);
    let autsiObavezni = autsi.filter((x: any) => !x.opciono && x.prikazujeSe && x.tvaNumVrednost <= EnumTipovaVrednostiAtributa.DATUMSKI && x.tvaNumVrednost > EnumTipovaVrednostiAtributa.IZBORNI);
    autsiObavezni.map((auts: any) => {
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI && (!initValue[auts.id] || initValue[auts.id].length === 0)) {
        // NotificationManager.error("Niste uneli stavku sa nazivom: " + auts.naziv + ".");
        greska = true;
        setState({
          greska: true,
          disabledButton: false,
          beforeLeaveEnabled: true,
        });
        return false;
      } else if (!initValue[auts.id] || initValue[auts.id] === "") {
        // NotificationManager.error("Niste uneli stavku sa nazivom: " + auts.naziv + ".");
        greska = true;
        setState({
          greska: true,
          disabledButton: false,
          beforeLeaveEnabled: true,
        });
        return false;
      }
    });
    let autsiRelevantni = autsi.filter((x: any) => x.relevantno && x.prikazujeSe && x.tvaNumVrednost <= EnumTipovaVrednostiAtributa.DATUMSKI && x.tvaNumVrednost > EnumTipovaVrednostiAtributa.IZBORNI);
    autsiRelevantni.map((auts: any) => {
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI && (!initValue[auts.id] || initValue[auts.id].length === 0)) {
        greska = true;
        // setState({
        //   prikazSnimiAlerta: true,
        //   disabledButton: false,
        //   beforeLeaveEnabled: false,
        // });
        setPrikazSnimiAlerta(true);
        setDisabledButton(false);
        setBeforeLeaveEnabled(false);
        return false;
      } else if (!initValue[auts.id] || initValue[auts.id] === "") {
        greska = true;
        setState({
          prikazSnimiAlerta: true,
          disabledButton: false,
          beforeLeaveEnabled: false,
        });
        return false;
      }
    });
    autsi.map((auts: any) => {
      const { donjaGranicaBrojneVrednosti, gornjaGranicaBrojneVrednosti } = auts.tipAtributa;
      const value = initValue[auts.id];
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.BROJNI && value && value !== "" && donjaGranicaBrojneVrednosti !== null && gornjaGranicaBrojneVrednosti !== null) {
        if (donjaGranicaBrojneVrednosti > value || gornjaGranicaBrojneVrednosti < value) {
          //   NotificationManager.error("Stavka sa nazivom: " + auts.naziv + " nije u opsegu " + donjaGranicaBrojneVrednosti + " - " + gornjaGranicaBrojneVrednosti);
          greska = true;
          // setState({
          //   greska: true,
          //   disabledButton: false,
          //   beforeLeaveEnabled: true,
          // });
          setGreska(true);
          setDisabledButton(false);
          setBeforeLeaveEnabled(true);
          return false;
        }
      }
    });

    if (!unosObj.datumUnosa) {
      //   NotificationManager.error(Labels.TITLE_MESSAGES_NISTE_UNELI_DATUM_PREGLEDA);
      // setState({
      //   greska: true,
      //   disabledButton: false,
      //   beforeLeaveEnabled: true,
      // });
      setGreska(true);
      setDisabledButton(false);
      setBeforeLeaveEnabled(true);
      return false;
    }
    if (radnikOdgovoranOptions.length > 0 && !unosObj.radnikOdgovoran) {
      //   NotificationManager.error(Labels.TITLE_MESSAGES_NISTE_UNELI_ORDINIRAJUCEG_LEKARA);
      // setState({
      //   greska: true,
      //   disabledButton: false,
      //   beforeLeaveEnabled: true,
      // });
      setGreska(true);
      setDisabledButton(false);
      setBeforeLeaveEnabled(true);
      return false;
    }
    if (!greska) {
      preSnimanjaUnosa();
      axios
        .post(
          Endpoint.NOVI_UNOS_VREDNOSTI,
          {
            unosID: snimljenUnosID,
            tsaID: unosObj.id,
            registarID: registarObj.id,
            pacijentID: pacijentObj?.id,
            vrednosti: initValue,
            // radnikOdgovoran: state.radnikOdgovoranSelect ? state.radnikOdgovoranSelect.value : ulogovaniRadnik.id,
            radnikOdgovoran: unosObj?.radnikOdgovoran !== undefined ? unosObj.radnikOdgovoran.value : ulogovaniRadnik.id,
            datumUnosa: moment(unosObj.datumUnosa).format(DATE_FORMAT),
          },
          axiosConfig(token)
        )
        .then((response: any) => {
          if (!ostani) {
            history.push(`${Routers.PACIJENTI}/${pacijentObj?.id}`);
          }
          setState({
            disabledButton: false,
            beforeLeaveEnabled: true,
            snimljenUnosID: response.data.snimljenUnosID,
          });
          window.history.pushState("", "RegiX", `${Routers.UNOSI}/${response.data.snimljenUnosID}`);
          //   NotificationManager.success(Labels.MESSAGES_USPESNO_SNIMLJEN_UNOS);
        })
        .catch((error: any) => {
          setState({
            disabledButton: false,
            beforeLeaveEnabled: true,
          });
          //   NotificationManager.error(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_UNOSA);
        });
    }
  };

  const loadFromServer = () => {
    setLoading(true);
    // let registarObject = {};
    // let pacijentObject = {};
    // let unosObject = emptyObj;
    // let autsiSegmentiOrderObject = {};
    let initValueObject = emptyObj;
    // let sifraAutsPoTipuObject = emptyObj;
    let prikazAUTSObject = emptyObj;
    let enumiSviObject = emptyObj;
    let enumiPoAUTSObject = emptyObj;
    // let autsiPanelArray = {};
    // let defaultTabInit = "";
    // let defaultPodTabInit = emptyObj;
    // let radniciOdgovorniArray = emptyArray;
    // let radnikOdgovoranSelect = null;
    // TODO
    // let datumUnosa = location.state && location.state?.vremeUnosa ? moment(location.state.vremeUnosa) : moment();
    // let datumUnosa = moment();

    axios
      .get(
        Endpoint.GET_PODACI_ZA_PRIKAZ_H_A,
        axiosConfig(token, {
          // TODO
          //   haID: props.match !== undefined && !isNaN(id) ? id : unosID !== undefined ? unosID : null,
          //   tsaID: location !== undefined && location.state !== undefined ? location.state.tsaID : null,
          haID: 118888,
          // haID: 4319,
          registarID: registarID,
          //   pacijentID: location !== undefined && location.state !== undefined ? location.pacijentObjID : null,
          radnikID: ulogovaniRadnik.id,
        })
      )
      .then((response: any) => {
        // const { registar, pacijent, tsa, panelAutsiArray, autsSegmenti, autsSegmenti, getAUTSPoSegmentima, radniciOdgovorni, radniciOdgovorni, autsiArray } = response.data.data;
        let data = response.data;
        setRegistarObj(data.registar);
        setPacijentObj(data.pacijent);
        setPravoIzmeneUnosa(data.pravoIzmene);
        setAutsiSegmentiOrder(data.autsSegmenti);

        let unosObject = data.tsa;
        if (unosObject.datumUnosa) {
          setUnosObj(moment(unosObject.datumUnosa, "DD.MM.YYYY."));
        }
        // registarObject = data.registar;
        // pacijentObject = data.pacijent;
        // unosObject = data.tsa;
        // if (unosObject.datumUnosa) {
        //   datumUnosa = moment(unosObject.datumUnosa, "DD.MM.YYYY.");
        // }
        // radnikOdgovoranSelect = unosObject.radnikOdgovoran ? unosObject.radnikOdgovoran : null;
        // autsiPanelArray = data.panelAutsiArray.sort((a: any, b: any) => (a.redosled > b.redosled ? 1 : -1));
        const autsiPanelArray = data.panelAutsiArray.sort((a: any, b: any) => (a.redosled > b.redosled ? 1 : -1));
        setAutsiPanel(autsiPanelArray);
        // if (data.radniciOdgovorni) {
        //   radniciOdgovorniArray = data.radniciOdgovorni.map((radnik: any) => {
        //     return { value: radnik.id, label: radnik.imePrezime };
        //   });
        // }
        const parseRadniciOdgovorni = data.radniciOdgovorni?.map((radnik: any) => {
          return { value: radnik.id, label: radnik.imePrezime };
        });
        setRadnikOdgovoranOptions(parseRadniciOdgovorni);

        let autsSegmenti = [];
        autsSegmenti = data.autsSegmenti;
        let autsPoSegmentima = data.getAUTSPoSegmentima;
        let autsiArray = emptyArray;
        let defaultPodTabInit = emptyObj;

        autsSegmenti.forEach((segment: any, s: any) => {
          const autsPoSegmentimaVal = autsPoSegmentima[segment.id];
          let podredjenTab = autsPoSegmentimaVal.autsi.filter((x: any) => x.tvaNumVrednost === EnumTipovaVrednostiAtributa.TAB);
          if (podredjenTab.length > 0) {
            defaultPodTabInit[segment.id] = podredjenTab[0].id;
          }
          autsPoSegmentimaVal.autsi.map((auts: any, a: any) => {
            autsiArray.push(auts);
          });
        });
        setAutsiSeparatori(autsPoSegmentima);

        // defaultTabInit = autsSegmenti[0] ? autsSegmenti[0].id : "0";
        const defaultTabInit = autsSegmenti[0] ? autsSegmenti[0].id : "0";
        // setDefaultTab(defaultTabInit);

        if (autsSegmenti.length === 0) {
          autsiArray = data.autsiArray;
          let podredjenTab = autsiArray.filter((x: any) => x.tvaNumVrednost === EnumTipovaVrednostiAtributa.TAB);
          if (podredjenTab.length > 0) {
            defaultPodTabInit[defaultTabInit] = podredjenTab[0].id;
          }
        }
        // setDefaultPodTab(defaultPodTabInit);
        setAutsi(autsiArray);

        let sifraAutsPoTipuObject = {};
        autsiArray.map((auts: any, a: any) => {
          let { tvaNumVrednost, vrednost } = auts;
          Object.assign(sifraAutsPoTipuObject, { [auts.sifra]: tvaNumVrednost });
          if (tvaNumVrednost === EnumTipovaVrednostiAtributa.IZBORNI) {
            Object.assign(initValueObject, { [auts.id]: vrednost === true ? vrednost : false });
            Object.assign(prikazAUTSObject, { [auts.sifra]: vrednost });
            // initValueObject[auts.id] = vrednost === true ? vrednost : false;
            // prikazAUTSObject[auts.sifra] = vrednost;
          }
          if (tvaNumVrednost === EnumTipovaVrednostiAtributa.BROJNI) {
            Object.assign(initValueObject, { [auts.id]: vrednost ? vrednost : "" });
            Object.assign(prikazAUTSObject, { [auts.sifra]: vrednost });
            // initValueObject[auts.id] = vrednost ? vrednost : "";
            // prikazAUTSObject[auts.sifra] = vrednost;
          } else if (tvaNumVrednost === EnumTipovaVrednostiAtributa.TEKSTUALNI) {
            Object.assign(initValueObject, { [auts.id]: vrednost });
            Object.assign(prikazAUTSObject, { [auts.sifra]: vrednost });
            // initValueObject[auts.id] = vrednost;
            // prikazAUTSObject[auts.sifra] = vrednost;
          } else if (tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI) {
            Object.assign(enumiPoAUTSObject, { [auts.id]: auts.enumStavkeSve });
            // enumiPoAUTSObject[auts.id] = auts.enumStavkeSve;
            auts.enumStavkeSve.map((enumS: any, s: any) => {
              Object.assign(enumiSviObject, { [enumS.id]: enumS.numVrednost });
              // enumiSviObject[enumS.id] = enumS.numVrednost;
            });
            if (auts.enumStavkeInit) {
              if (auts.enumStavkeInit === 1) {
                let enumVre = auts.enumStavkeInit[0];
                let enumVrednostiLista = { value: enumVre.id, label: enumVre.naziv };
                Object.assign(initValueObject, { [auts.id]: enumVrednostiLista });
                Object.assign(prikazAUTSObject, { [auts.sifra]: enumVrednostiLista });
                // initValueObject[auts.id] = enumVrednostiLista;
                // prikazAUTSObject[auts.sifra] = enumVrednostiLista;
              } else {
                let enumVrednostiLista = auts.enumStavkeInit.map((enumVre: any, ev: any) => {
                  let key = enumVre.id;
                  let naziv = enumVre.naziv;
                  return { value: key, label: naziv };
                });
                Object.assign(initValueObject, { [auts.id]: enumVrednostiLista });
                Object.assign(prikazAUTSObject, { [auts.sifra]: enumVrednostiLista });
                // initValueObject[auts.id] = enumVrednostiLista;
                // prikazAUTSObject[auts.sifra] = enumVrednostiLista;
              }
            }
          } else if (tvaNumVrednost === EnumTipovaVrednostiAtributa.DATUMSKI) {
            Object.assign(initValueObject, { [auts.id]: vrednost ? moment(vrednost) : "" });
            Object.assign(prikazAUTSObject, { [auts.sifra]: vrednost ? moment(vrednost) : "" });
            // initValueObject[auts.id] = vrednost ? moment(vrednost) : "";
            // prikazAUTSObject[auts.sifra] = vrednost ? moment(vrednost) : "";
          } else if (tvaNumVrednost === EnumTipovaVrednostiAtributa.GRAFIK) {
            Object.assign(initValueObject, { [auts.id]: vrednost ? vrednost : [] });
            Object.assign(prikazAUTSObject, { [auts.sifra]: vrednost });
            // initValueObject[auts.id] = vrednost ? vrednost : [];
            // prikazAUTSObject[auts.sifra] = vrednost;
          }
        });
        console.log(enumiPoAUTSObject);
        setSifraAutsPoTipu(sifraAutsPoTipuObject);
        // setInitValue(initValueObject);
        // setPrikazAUTS(prikazAUTSObject);
        setEnumiSvi(enumiSviObject);
        // setEnumiPoAUTS(enumiPoAUTSObject);
        setState({
          ...state,
          defaultTab: defaultTabInit,
          defaultPodTab: defaultPodTabInit,
          initValue: initValueObject,
          prikazAUTS: prikazAUTSObject,
          enumiPoAUTS: enumiPoAUTSObject,
          //   pacijent: pacijentObject,
          //   unos: unosObject,
          // autsi: autsiArray,
          //   registar: registarObject,
          // sifraAutsPoTipu: sifraAutsPoTipuObject,
          // autsiSegmentiOrder: autsiSegmentiOrderObject,
          // enumiSvi: enumiSviObject,
          //   autsiPanel: autsiPanelArray,
          // autsiSeparatori: autsiSeparatoriObject,
          // pravoIzmeneUnosa: response.data.pravoIzmene,
          //   radnikOdgovoranOptions: radniciOdgovorniArray,
          //   radnikOdgovoranSelect,
          //   datumUnosa,
          //   ucitano: true,
        });
        // ZAVISNI AUTSI
        // let autsiSifraZavisnostTemp = emptyObj;
        const autsiSifraZavisnostTemp = autsiArray.map((auts: any) => {
          if (auts.formulaZaPrikaz != null) {
            let zavisnostAUTSI = emptyArray;
            auts.formulaZaPrikaz.split(" ").forEach((elem: any, e: any) => {
              if (elem.startsWith("$(")) {
                elem = elem.split("$(")[1].split(")")[0];
                let autsZavisan = autsiArray.filter((x: any) => x.sifra === elem && x.formulaZaPrikaz !== undefined)[0];
                if (autsZavisan !== undefined) {
                  zavisnostAUTSI.push(autsZavisan);
                }
              }
            });
            return { [auts.sifra]: zavisnostAUTSI };
          }
        });
        setAutsiSifraZavisnost(autsiSifraZavisnostTemp);
        // setState({
        //   autsiSifraZavisnost,
        // });
        // zavisniAUTS();
        //ZAVISNI AUTSI
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffectOnce(() => {
    loadFromServer();
  });

  const showHtmlInfo = (htmlInfo: any) => {
    // setState({
    //   showPopUp: true,
    //   popUpHtmlInfo: htmlInfo,
    // });
    setShowPopUp(true);
    setPopUpHtmlInfo(htmlInfo);
  };

  const hideHtmlInfo = () => {
    // setState({
    //   showPopUp: false,
    //   popUpHtmlInfo: "",
    // });
    setShowPopUp(false);
    setPopUpHtmlInfo("");
  };

  return (
    <div>
      {loading ? (
        <div>Ucitavanje</div>
      ) : (
        <div>
          {/*  <Breadcrumb nazivUnosa={unosObj?.naziv} registar={registarObj} /> */}
          {registarObj?.id && <Breadcrumb nazivUnosa={unosObj?.naziv} registar={registarObj} />}
          <Link to={"/pacijenti/" + pacijentObj?.id} className="btn btn-outline-danger ml-4">
            <i className="fas fa-user-injured"></i> {pacijentObj?.ime + " " + pacijentObj?.prezime}
          </Link>
          <button
            className={pravoIzmeneUnosa ? "btn btn-outline-danger ml-4" : "d-none"}
            // onClick={snimiUnos.bind(this, false)}
            disabled={disabledButton}
          >
            <i className={!disabledButton ? "fas fa-save" : "fas fa-spinner fa-pulse"}></i> {Labels.BUTTON_SNIMI}
          </button>
          <button
            className={pravoIzmeneUnosa ? "btn btn-outline-danger ml-4" : "d-none"}
            // onClick={snimiUnos.bind(this, true)}
            disabled={disabledButton}
          >
            <i className={!disabledButton ? "fas fa-save" : "fas fa-spinner fa-pulse"}></i> {Labels.BUTTON_SNIMI_I_OSTANI}
          </button>
          <button
            // className={!isNaN(props.match?.params.number) && state.pravoIzmeneUnosa ? "btn btn-outline-danger ml-4" : "d-none"}
            onClick={obrisUnosConfirm}
          >
            <i className="fas fa-trash-alt"></i> {Labels.BUTTON_OBRISI}
          </button>
          <Link
            to={`/unosArhiva/${id}`}
            // className={!isNaN(id) ? "btn btn-outline-danger ml-4" : "d-none"}
          >
            <i className="fas fa-archive"></i> {Labels.BUTTON_ISTORIJA_VERZIJA}
          </Link>
          {/* show={state.prikazAlerta} */}
          {/* <SweetAlert
          show={prikazAlerta}
          warning
          title=""
          showCancel
          cancelBtnText={Labels.BUTTON_ODUSTANI}
          confirmBtnText={Labels.BUTTON_POTVRDI}
          onCancel={obrisiUnosCancel}
          onConfirm={obrisiUnos}
        >
          {Labels.LABEL_BRISETE_UNOS}
        </SweetAlert>
        <SweetAlert
          show={state.prikazSnimiAlerta && !state.greska}
          warning
          title=""
          showCancel
          cancelBtnText="Odustani"
          confirmBtnText="Potvrdi"
          onCancel={popuniPonovo}
          onConfirm={prihvatiUpozorenje}
        >
          {Labels.LABEL_NASTAVI_BEZ_RELEVANTNIH_POLJA}
        </SweetAlert> */}
          <div className="card autsCard mt-2 ml-4">
            <div className="card-header text-center mb-4">
              <strong>{unosObj?.naziv}</strong> <br />
              {unosObj?.doktorKreirao ? "Lekar kreirao: " + unosObj?.doktorKreirao + ", " : ""}
              {unosObj?.datumKreiranja}
              <br />
              {unosObj?.doktorIzmenio ? "Lekar izmenio: " + unosObj?.doktorIzmenio + ", " : ""}
              {unosObj?.datumIzmene}
            </div>
            <div className="form-group row ml-2">
              <div className={radnikOdgovoranOptions?.length > 0 ? "col-md-2 mb-3" : "d-none"}>{Labels?.LABEL_ORDINIRAJUCI_LEKAR_OBAVEZNO}</div>
              <div className={radnikOdgovoranOptions?.length > 0 ? "col-md-3 mb-3" : "d-none"}>
                {/* <Select
                className="col "
                placeholder="Odaberite radnika"
                isSearchable={true}
                isClearable={true}
                value={state.radnikOdgovoranSelect}
                value={unosObj.radnikOdgovoran}
                // onChange={(value) => changeState("radnikOdgovoranSelect", value)}
                onChange={(value:any) => setUnosObj({...unosObj,radnikOdgovoran : value)}
                options={state.radnikOdgovoranOptions}
                options={radnikOdgovoranOptions}
            /> */}
              </div>
              <div className="col-md-2 mb-3">{Labels.LABEL_DATUM_PREGLEDA_OBAVEZNO}</div>
              <div className="col-md-4 mb-3 ml-3">
                {/* <Calendar
                disabled={location.state?.vremeUnosa}
                showIcon
                dateFormat={CALENDAR_DATE_FORMAT}
                showMonthDropdown
                showYearDropdown
                value={state.datumUnosa ? new Date(state.datumUnosa) : undefined}
                value={unosObj.datumUnosa ? new Date(unosObj.datumUnosa) : undefined}
                onChange={(e) => changeState("datumUnosa", e.value)}
                onChange={(value:any) => setUnosObj({...unosObj,datumUnosa : e.value)}
              /> */}
              </div>
            </div>
            {!loading && (
              <Auts2
                autsi={autsi}
                autsiSeparatori={autsiSeparatori}
                defaultTab={state.defaultTab}
                autsiSegmentiOrder={autsiSegmentiOrder}
                enumiSvi={enumiSvi}
                enumiPoAUTS={state.enumiPoAUTS}
                initValue={state.initValue}
                prikazAUTS={state.prikazAUTS}
                sifraAutsPoTipu={sifraAutsPoTipu}
                changeState={changeState}
                autsiPanel={autsiPanel}
                defaultPodTab={state.defaultPodTab}
                changePodTab={changePodTab}
                prikazivanjeAutsa={prikazivanjeAutsa}
                oboji={oboji}
                showHtmlInfo={showHtmlInfo}
                hideHtmlInfo={hideHtmlInfo}
              />
            )}
          </div>
        </div>
      )}
      {loading && (
        <div className="d-flex justify-content-center" style={{ marginTop: "20%" }}>
          <div className="spinner-border text-secondary" style={{ width: "4rem", height: "4rem" }} role="status">
            <span className="sr-only">{Labels.LABEL_UCITAVANJE}</span>
          </div>
        </div>
      )}
      <div className="htmlInfoDialog">
        <div className={"modal fade" + (showPopUp ? " show modalShow" : "")}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header modal-header-1">
                <h5 className="modal-title">{Labels.LABEL_INFO}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hideHtmlInfo()}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                {/* <div className="reactHtmlParser">{ReactHtmlParser(state.popUpHtmlInfo)}</div> */}

                <div className="modal-footer">
                  <button type="button" className="btn btn-primary btn-primary-light" onClick={() => hideHtmlInfo()}>
                    {Labels.BUTTON_ZATVORI}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopUp && <div id="page-mask" />}
      </div>
      {/* <BeforeLeave message={Labels.LABEL_NAPUSTI_STRANICU} enableUnload={state.beforeLeaveEnabled} enableRouter={state.beforeLeaveEnabled} exact={true} /> */}
    </div>
  );
}
