import React from "react";

export default function Izborni({ initValue, changeState, auts, tipSkupaAtributaKey }) {
  const handleChange = (value) => {
    let stateArray = [
      ["initValue", tipSkupaAtributaKey, value],
      ["prikazAUTS", auts.sifra, value],
    ];
    changeState(stateArray);
  };

  if (auts.imaCekBox) {
    return (
      <div>
        <input type="checkbox" checked={initValue} onChange={() => handleChange(!initValue)} />
      </div>
    );
  } else {
    return (
      <div>
        <div className="d-inline col-md-3 align-middle">
          <input type="radio" className="align-middle" checked={initValue === true} onChange={() => handleChange(true)} />
          <span className="cursor-pointer align-middle" onClick={() => handleChange(true)}>
            Da {initValue}
          </span>
        </div>
        <div className="d-inline col-md-3 align-middle">
          <input type="radio" className="align-middle" checked={initValue !== true} onChange={() => handleChange(false)} />
          <span className="cursor-pointer align-middle" onClick={() => handleChange(false)}>
            Ne
          </span>
        </div>
      </div>
    );
  }
}
