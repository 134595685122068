import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export default function Skupovni2({ initValue, changeState, auts, tipSkupaAtributaKey, zaPretragu, enumiPoAUTS }: any) {
  let valueSkupovni = initValue ? initValue : null;
  let multi = auts.multiselect;
  if (zaPretragu) {
    multi = false;
  }
  let checkbox = false;
  let radio = false;
  if (multi) {
    checkbox = auts.imaCekBox;
  } else {
    radio = auts.radioButton;
  }

  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (auts.id == "3186") {
      console.log("set: " + auts.naziv + "  " + auts.id);
      console.log(enumiPoAUTS);
      console.log(enumiPoAUTS[auts.id]);
      console.log(enumiPoAUTS && enumiPoAUTS[auts.id] ? enumiPoAUTS[auts.id] : []);
    }
    setOptions(enumiPoAUTS && enumiPoAUTS[auts.id] ? enumiPoAUTS[auts.id] : []);
  }, []);

  const handleChange = (value: any) => {
    let stateArray = [
      ["initValue", tipSkupaAtributaKey, value],
      ["prikazAUTS", auts.sifra, value],
    ];
    changeState(stateArray);
  };

  const handleChangeMultiCheck = (selected: any) => {
    let initValueNew = initValue ? initValue : [];
    let postoji = false;
    if (!Array.isArray(initValueNew)) {
      initValueNew = [initValueNew];
    }

    initValueNew.forEach((init: any) => {
      if (init.value === selected.value) {
        postoji = true;
        return;
      }
    });
    if (postoji) {
      initValueNew = initValueNew.filter((init: any) => init.value !== selected.value);
    } else {
      initValueNew.push(selected);
    }
    handleChange(initValueNew);
  };

  const createDropDown = () => {
    const dropDownOptions = options.map((autsEnum: any) => {
      return { label: autsEnum.naziv, value: autsEnum.id };
    });
    return (
      <div className="autsSkupovni">
        <Dropdown className="w-full" onChange={(e) => handleChange(e.value)} optionLabel="label" options={dropDownOptions} value={valueSkupovni} placeholder={Labels.PLACEHOLDER_ODABERITE_VREDNOST} />
      </div>
    );
  };
  const createSecond = () => {
    let initArray = valueSkupovni ? valueSkupovni : [];
    if (!Array.isArray(initArray)) {
      initArray = [initArray];
    }
    return options.map((enumVre: any, e) => {
      let isChecked = initArray.length > 0 && initArray.find((init: any) => init.value === enumVre.id) && Object.keys(initArray.find((init: any) => init.value === enumVre.id)).length > 0;
      return (
        <div key={e}>
          <input type="checkbox" checked={isChecked} onChange={() => handleChangeMultiCheck({ label: enumVre.naziv, value: enumVre.id })} />
          <span className="cursor-pointer" onClick={() => handleChangeMultiCheck({ label: enumVre.naziv, value: enumVre.id })}>
            {enumVre.naziv}
          </span>
        </div>
      );
    });
  };

  const createThird = () => {
    return options.map((enumVre: any, e) => {
      return (
        <div key={e}>
          <input type="radio" checked={initValue && initValue[0] && initValue[0].value === enumVre.id} onChange={() => handleChange([{ label: enumVre.naziv, value: enumVre.id }])} />
          <span className="cursor-pointer" onClick={() => handleChange([{ label: enumVre.naziv, value: enumVre.id }])}>
            {enumVre.naziv}
          </span>
        </div>
      );
    });
  };

  return <>{(!checkbox && !radio) || zaPretragu ? createDropDown() : multi && checkbox ? createSecond() : !multi && radio ? createThird() : <div></div>}</>;
}
