

import React from 'react';
import ReactTable from "react-table";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { axiosConfig } from "../../../../utils/Utils";
import Endpoint from '../../../../endpoints/Endpoint';

const token = localStorage.hasOwnProperty("ulogovanRadnik") ? JSON.parse(localStorage.getItem("ulogovanRadnik")).token : "";;

const columnsJasperi = [{
    Header: '#',
    width: '50',
    accessor: 'redniBroj',
    className: "registriTabletext"
}, {
    Header: 'Naziv',
    width: 'auto',
    accessor: 'naziv',
    className: "registriTabletext"
}, {
    Header: 'Akcije',
    width: '150',
    accessor: 'akcije',
    className: "registriTabletext"
}];


class Jasperi extends React.Component {
    constructor(props) {
        super(props);
        this.reloadJasperi = this.reloadJasperi.bind(this);
        this.obrisiJasper = this.obrisiJasper.bind(this);
        this.state = {
            dataJasperi: []
        };
    }

    componentDidMount() {
        this.reloadJasperi();
    }

    obrisiJasper(jasper) {
        axios.post(Endpoint.RELOAD + '/obrisiJasper', {
            putanjaJaspera: jasper
        }, axiosConfig(token))
            .then((response) => {
                this.reloadJasperi();
                NotificationManager.success(jasper != null ? 'Uspešno obrisan japser.' : 'Uspešno obrisani japseri.');
            }).catch((error) => {
                NotificationManager.error('Došlo je do greške prilikom brisanja jaspera.');
                console.log(error);
            });
    }

    reloadJasperi() {
        let dataJasperi = []
        axios.get(Endpoint.RELOAD + '/jasperi', axiosConfig(token))
            .then((response) => {
                let broj = 1;
                response.data.forEach(jasper => {
                    let object = {};
                    let obrisiDugme = <button className="btn btn-outline-danger" onClick={() => this.obrisiJasper(jasper)}><i className="fas fa-trash-alt"></i> Obriši</button>;
                    object["redniBroj"] = <div>{broj}.</div>;
                    object["naziv"] = <div><span>{jasper}</span></div>;
                    object["akcije"] = <div>{obrisiDugme}</div>;
                    dataJasperi.push(object);
                    broj++;
                })
                this.setState({
                    dataJasperi
                });
            }).catch((error) => {
                NotificationManager.error('Došlo je do greške prilikom učitananja jaspera.');
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                <button className="btn btn-outline-success md-2 mb-3" onClick={this.obrisiJasper.bind(this, "")}><i className="fas fa-trash-alt"></i> Obriši sve jaspere</button>
                <ReactTable
                    previousText="prethodni"
                    nextText="sledeći"
                    pageText="Stranica"
                    ofText="od"
                    rowsText="redova"
                    noDataText="Ne postoji nijedan jasper"
                    defaultPageSize={10}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                    }
                    data={this.state.dataJasperi}
                    columns={columnsJasperi}
                />
            </div>
        );
    }
}

export default Jasperi;
