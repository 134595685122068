import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useContext, useRef } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import CrudRadnik from "./CrudRadnik";
import moment from "moment";
import { DATE_FORMAT } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import MessageType from "../../../infrastructure/MessageType";

export default function CrudRadnikList() {
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRadnik, setSelectedRadnik] = useState();
  const [radniciList, setRadniciList] = useState();
  const [entityOperation, setEntityOperation] = useState<string>("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [radnikToChange, setRadnikToChange] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);
  const radnikIndex: number = 0;
  // const ulogeIndex: number = 1;
  const promeniLozinkukIndex: number = 2;
  // const ustanoveRadnikaIndex: number = 3;

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axios
      .get(
        Endpoint.RADNIK + "/search/findByAktivan",
        axiosConfig(authData.token, {
          page: 0,
          size: 50000000,
          aktivan: true,
        })
      )
      .then((radnikCollection) => {
        setRadniciList(radnikCollection.data._embedded.radniks);
      });
  };

  const createRadnik = (radnik: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.RADNIK_CREATE, radnik, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO, radnik.ime + Labels.SPACE + radnik.prezime);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateRadnik = (radnik: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(Endpoint.RADNIK_WS + `/${radnik.id}`, radnik, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, radnik.imePrezime);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteRadnik = (radnik: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(
          Endpoint.RADNIK + `/${radnik.id}`,
          {
            aktivan: false,
          },
          axiosConfig(authData.token)
        )
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO, radnik.imePrezime);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const changePassword = (radnik: any, novaLozinka: string, potvrdaLozinke: string) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(
          Endpoint.RADNIK_PROMENA_LOZINKE,
          {
            radnikID: radnik.id,
            novaLozinka: novaLozinka,
            potvrdaLozinke: potvrdaLozinke,
            isAdmin: true,
          },
          axiosConfig(authData.token)
        )
        .then((response) => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_PROMENJENA_LOZINKA_ZA, radnik.imePrezime);
          fetchData();
          resolve();
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  };

  const dialogHeader = () => {
    const display = radnikToChange ? radnikToChange.imePrezime : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_RADNIKA;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_RADNIKA + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_RADNIKA + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_RADNIKA + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedRadnik;
        break;
    }
    setEntityOperation(entityOperation);
    setRadnikToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
    setSelectedRadnik(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => {
    closeDialog();
  };

  const parseDateOfBirth = (column: any) => {
    const { datumRodjenja } = column;
    return datumRodjenja ? moment(new Date(datumRodjenja)).format(DATE_FORMAT) : null;
  };

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_KREIRAJ}
          icon="pi pi-save"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (index === radnikIndex || index === promeniLozinkukIndex) && (
        <Button
          label={Labels.BUTTON_IZMENI}
          icon="pi pi-inbox"
          className="p-button-warning"
          onClick={() => {
            index === radnikIndex ? dialogRef.current.onUpdate() : dialogRef.current.changePassword();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_OBRISI}
          icon="pi pi-inbox"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => (
    <React.Fragment>
      <Button
        label={Labels.BUTTON_ODUSTANI}
        icon="pi pi-times"
        className="p-button-danger"
        onClick={() => {
          onCancel();
        }}
      />
    </React.Fragment>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
      <Button disabled={!selectedRadnik} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openDialog(EntityOperation.UPDATE)} />
      <Button disabled={!selectedRadnik} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button disabled={!selectedRadnik} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openDialog(EntityOperation.DELETE)} />
    </React.Fragment>
  );

  return (
    <div className="layout-user">
      <Panel header={Labels.TITLE_PANEL_CRUD_RADNIK} className="panel-user-list">
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            filterDisplay="row"
            value={radniciList}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => {
              setSelectedRadnik(e.data);
            }}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left" field={"ime"} header={Labels.COLUMN_HEADER_IME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"prezime"} header={Labels.COLUMN_HEADER_PREZIME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column
              className="column-align-left column-width-170px"
              field={"datumRodjenja"}
              body={parseDateOfBirth}
              header={Labels.COLUMN_HEADER_DATUM_RODJENJA}
              showFilterMenu={false}
              filter
              sortable
            />
            <Column className="column-align-left" field={"username"} header={Labels.COLUMN_HEADER_USERNAME} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"ustanovaNaziv"} header={Labels.COLUMN_HEADER_USTANOVA} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"sifra"} header={Labels.COLUMN_HEADER_SIFRA} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="p-sortable-column" field={"adresa"} header={Labels.COLUMN_HEADER_ADRESA} filter showFilterMenu={false} />
          </DataTable>
          <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }} footer={dialogFooter()}>
            <CrudRadnik
              setIndex={setIndex}
              index={index}
              dialogRef={dialogRef}
              radnikOperation={entityOperation}
              radnik={radnikToChange}
              onChangePassword={changePassword}
              onCreateRadnik={createRadnik}
              onUpdateRadnik={updateRadnik}
              onDeleteRadnik={deleteRadnik}
              onCancel={onCancel}
            />
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
