import axios from "axios";
import moment from "moment";
import React from "react";
import BeforeLeave from "react-before-leave";
import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Endpoint from "../../endpoints/Endpoint";
import Routers from "../../utils/Routers";
import { axiosConfig, CALENDAR_DATE_FORMAT, DATE_FORMAT, registarID } from "../../utils/Utils";
import Breadcrumb from "./../Breadcrumb";
import Auts from "./../hijerarhija/Auts";
import EnumTipovaVrednostiAtributa from "../../utils/EnumTipovaVrednostiAtributa";
import ReactHtmlParser from "react-html-parser";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

const token = localStorage.hasOwnProperty("ulogovanRadnik") ? JSON.parse(localStorage.getItem("ulogovanRadnik")).token : "";

class Unos extends React.Component {
  constructor(props) {
    super(props);
    this.loadFromServer = this.loadFromServer.bind(this);
    this.changeState = this.changeState.bind(this);
    this.snimiUnos = this.snimiUnos.bind(this);
    this.obrisiUnos = this.obrisiUnos.bind(this);
    this.obrisUnosConfirm = this.obrisUnosConfirm.bind(this);
    this.obrisiUnosCancel = this.obrisiUnosCancel.bind(this);
    this.prihvatiUpozorenje = this.prihvatiUpozorenje.bind(this);
    this.popuniPonovo = this.popuniPonovo.bind(this);
    this.changePodTab = this.changePodTab.bind(this);
    this.racunanjeInitAutsa = this.racunanjeInitAutsa.bind(this);
    this.changeRacunanjeAutsa = this.changeRacunanjeAutsa.bind(this);
    this.prikazivanjeAutsa = this.prikazivanjeAutsa.bind(this);
    this.prikazivanjeZavisnihAutsa = this.prikazivanjeZavisnihAutsa.bind(this);
    this.prikazivanjeAutsaZavisnih = this.prikazivanjeAutsaZavisnih.bind(this);
    this.preSnimanjaUnosa = this.preSnimanjaUnosa.bind(this);
    this.zavisniAUTS = this.zavisniAUTS.bind(this);
    this.monthDiff = this.monthDiff.bind(this);
    this.dayDiff = this.dayDiff.bind(this);
    this.datumKreiranjaRef = React.createRef();
    this.showHtmlInfo = this.showHtmlInfo.bind(this);
    this.hideHtmlInfo = this.hideHtmlInfo.bind(this);

    this.state = {
      snimljenUnosID: !isNaN(this.props.match.params.number) ? this.props.match.params.number : "",
      registar: {},
      pacijent: {},
      unos: {},
      autsi: [],
      autsiSeparatori: {},
      autsiSegmentiOrder: {},
      initValue: {},
      sifraAutsPoTipu: {},
      prikazAUTS: {},
      enumiSvi: {},
      enumiPoAUTS: {},
      autsiPanel: [],
      autsiSifraZavisnost: {},
      defaultTab: "",
      defaultPodTab: {},
      datumKreiranja: "",
      radnikOdgovoranOptions: [],
      radnikOdgovoranSelect: null,
      datumUnosa: moment(),
      ucitano: false,
      prikazAlerta: false,
      prikazSnimiAlerta: false,
      pravoIzmeneUnosa: false,
      disabledButton: false,
      beforeLeaveEnabled: true,
      greska: false,
      ostani: false,
      oboji: false,
      showPopUp: false,
      popUpHtmlInfo: "",
    };
  }

  changeState(stateName, stateKey, stateValue) {
    if (Array.isArray(stateName)) {
      let stateSvi = {};
      stateName.forEach((elem) => {
        let stateOne = this.state[elem[0]];
        Object.assign(stateOne, { [elem[1]]: elem[2] });
        stateSvi[elem[0]] = stateOne;
      });
      this.setState(
        {
          stateSvi,
        },
        () => this.changeRacunanjeAutsa()
      );
    } else {
      let stateSvi = this.state[stateName];
      if (stateSvi !== undefined) {
        if (stateValue !== undefined) {
          Object.assign(stateSvi, { [stateKey]: stateValue });
          this.setState(
            {
              [stateName]: stateSvi,
            },
            () => this.changeRacunanjeAutsa()
          );
        } else {
          this.setState(
            {
              [stateName]: stateKey,
            },
            () => this.changeRacunanjeAutsa()
          );
        }
      }
    }
  }

  yearDiff(date1, date2) {
    if (date1 === "0" || date2 === "0") {
      return 0;
    }

    var months = this.monthDiff(date1, date2);
    return months <= 0 ? 0 : Math.ceil(months / 12);
  }

  monthDiff(date1, date2) {
    if (date1 === "0" || date2 === "0") {
      return 0;
    }

    let months = 0;
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  dayDiff(date1, date2) {
    if (date1 === "0" || date2 === "0") {
      return 0;
    }
    let datum1 = new Date(date1);
    let datum2 = new Date(date2);
    if (datum2 - datum1 <= 0) {
      return 0;
    }
    var timeDiff = Math.abs(datum2.getTime() - datum1.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

  changeRacunanjeAutsa() {
    let initValueObject = this.state.initValue;
    let prikazAUTSObject = this.state.prikazAUTS;
    let autsSaFormulom = this.state.autsi.filter((x) => x.formulaZaRacunanje && x.formulaZaRacunanje !== "");
    if (autsSaFormulom.length > 0) {
      autsSaFormulom.forEach((auts) => {
        let vrednost = this.racunanjeInitAutsa(auts, this.state.prikazAUTS);
        initValueObject[auts.id] = vrednost;
        prikazAUTSObject[auts.id] = vrednost;
      });
      this.setState({
        initValue: initValueObject,
        prikazAUTS: prikazAUTSObject,
      });
    }
  }

  obrisUnosConfirm() {
    this.setState({
      prikazAlerta: true,
    });
  }

  obrisiUnosCancel() {
    this.setState({
      prikazAlerta: false,
    });
  }

  popuniPonovo() {
    this.setState({
      prikazSnimiAlerta: false,
      oboji: true,
    });
  }

  prihvatiUpozorenje() {
    this.setState({
      prikazSnimiAlerta: false,
    });
    this.preSnimanjaUnosa();
    axios
      .post(
        Endpoint.NOVI_UNOS_VREDNOSTI,
        {
          unosID: this.state.snimljenUnosID,
          tsaID: this.state.unos.id,
          registarID: this.state.registar.id,
          pacijentID: this.state.pacijent.id,
          vrednosti: this.state.initValue,
          radnikOdgovoran: this.state.radnikOdgovoranSelect ?? JSON.parse(localStorage.getItem("ulogovanRadnik")).id,
          datumUnosa: moment(this.state.datumUnosa).format(DATE_FORMAT),
        },
        axiosConfig(token)
      )
      .then((response) => {
        if (!this.state.ostani) {
          this.props.history.push(`${Routers.PACIJENTI}/${this.state.pacijent.id}`);
        }
        this.setState({
          disabledButton: false,
          beforeLeaveEnabled: false,
          snimljenUnosID: response.data.snimljenUnosID,
        });
        window.history.pushState("", "RegiX", `${Routers.UNOSI}/${response.data.snimljenUnosID}`);
        NotificationManager.success("Uspešno snimljen unos.");
      })
      .catch((error) => {
        this.setState({
          disabledButton: false,
          beforeLeaveEnabled: false,
        });
        NotificationManager.error("Došlo je do greške prilikom snimanja unos.");
      });
  }

  zavisniAUTS() {
    let autsiSifraZavisnost = {};
    this.state.autsi.map((auts) => {
      if (auts.formulaZaPrikaz != null) {
        let zavisnostAUTSI = [];
        auts.formulaZaPrikaz.split(" ").forEach((elem, e) => {
          if (elem.startsWith("$(")) {
            elem = elem.split("$(")[1].split(")")[0];
            let autsZavisan = this.state.autsi.filter((x) => x.sifra === elem && x.formulaZaPrikaz !== undefined)[0];
            if (autsZavisan !== undefined) {
              zavisnostAUTSI.push(autsZavisan);
            }
          }
        });
        autsiSifraZavisnost[auts.sifra] = zavisnostAUTSI;
      }
    });
    this.setState({
      autsiSifraZavisnost,
    });
  }

  prikazivanjeZavisnihAutsa(auts) {
    let prikaz = true;
    if (this.state.autsiSifraZavisnost[auts.sifra] !== undefined) {
      this.state.autsiSifraZavisnost[auts.sifra].map((autsZavisni) => {
        if (!this.prikazivanjeAutsaZavisnih(autsZavisni) && prikaz) {
          prikaz = false;
        }
      });
    }
    return prikaz;
  }

  prikazivanjeAutsaZavisnih(auts) {
    let prikaz = true;
    if (auts.formulaZaPrikaz != null) {
      let formulaZaPrikaz = auts.formulaZaPrikaz;
      let formulaArray = formulaZaPrikaz.split(" ");
      let vrednost = "";
      formulaArray.forEach((elem, e) => {
        if (elem.startsWith("$(")) {
          elem = elem.split("$(")[1].split(")")[0];
          if (this.state.prikazAUTS) {
            if (this.state.prikazAUTS[elem]) {
              vrednost = this.state.prikazAUTS[elem];
              if (this.state.sifraAutsPoTipu !== undefined && this.state.sifraAutsPoTipu[elem] === 4) {
                let vrednostArray = [];
                if (!Array.isArray(vrednost)) {
                  vrednost = [vrednost];
                }
                vrednost.forEach((vre, v) => {
                  vrednostArray.push(this.state.enumiSvi[vre.value]);
                });
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", "[" + vrednostArray + "]");
              } else {
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", vrednost);
              }
            } else {
              prikaz = false;
            }
          }
        }
      });
      if (vrednost !== "" && !formulaZaPrikaz.includes("$")) {
        prikaz = eval(formulaZaPrikaz);
      }
    }
    return prikaz;
  }

  prikazivanjeAutsa(auts) {
    let prikaz = this.prikazivanjeZavisnihAutsa(auts);
    if (auts.formulaZaPrikaz != null && prikaz) {
      let formulaZaPrikaz = auts.formulaZaPrikaz;
      let formulaArray = formulaZaPrikaz.split(" ");
      let vrednost = "";
      formulaArray.map((elem, e) => {
        if (elem.startsWith("$(")) {
          elem = elem.split("$(")[1].split(")")[0];
          if (this.state.prikazAUTS) {
            if (this.state.prikazAUTS[elem]) {
              vrednost = this.state.prikazAUTS[elem];
              if (this.state.sifraAutsPoTipu && this.state.sifraAutsPoTipu[elem] === EnumTipovaVrednostiAtributa.SKUPOVNI) {
                let vrednostArray = [];
                if (!Array.isArray(vrednost)) {
                  vrednost = [vrednost];
                }
                vrednost.map((vre, v) => {
                  vrednostArray.push(this.state.enumiSvi[vre.value]);
                });
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", "[" + vrednostArray + "]");
              } else {
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", vrednost);
              }
            } else {
              prikaz = false;
            }
          }
        }
      });
      if (vrednost !== "" && !formulaZaPrikaz.includes("$")) {
        prikaz = eval(formulaZaPrikaz);
      }
    }
    return prikaz;
  }

  racunanjeInitAutsa(auts, vrednostAUTS) {
    let vrednost = 0;
    if (auts.formulaZaRacunanje != null && auts.formulaZaRacunanje !== "") {
      let formulaZaRacunanje = auts.formulaZaRacunanje;
      let formulaArray = formulaZaRacunanje.split(" ");
      formulaArray.forEach((elem, e) => {
        if (elem.includes("$(")) {
          elem = elem.split("$(")[1].split(")")[0];
          vrednost = vrednostAUTS[elem] !== "" && !isNaN(vrednostAUTS[elem]) ? vrednostAUTS[elem] : 0;
          formulaZaRacunanje = formulaZaRacunanje.replace("$(" + elem + ")", vrednost);
        }
        if (elem.includes("$!(")) {
          elem = elem.split("$!(")[1].split(")")[0];
          let vrednostTemp = "";
          if (vrednostAUTS[elem]) {
            if (Array.isArray(vrednostAUTS[elem])) {
              vrednostTemp = vrednostAUTS[elem][0].numVrednost;
            } else {
              vrednostTemp = vrednostAUTS[elem].numVrednost;
            }
          }
          vrednost = vrednostTemp !== "" && !isNaN(vrednostTemp) ? vrednostTemp : 0;
          formulaZaRacunanje = formulaZaRacunanje.replace("$!(" + elem + ")", vrednost);
        }
      });
      if (!formulaZaRacunanje.includes("$")) {
        vrednost = eval(formulaZaRacunanje);
      }
    }
    return Math.abs(vrednost) !== Infinity ? parseFloat(vrednost).toFixed(2) : 0;
  }

  changePodTab(segmentID, value) {
    let podTab = this.state.defaultPodTab;
    podTab[segmentID] = value;
    this.setState({
      defaultPodTab: podTab,
    });
  }

  obrisiUnos() {
    axios
      .post(
        Endpoint.OBRISI_UNOS,
        {
          id: this.props.match.params.number,
        },
        axiosConfig(token)
      )
      .then((response) => {
        this.setState({
          beforeLeaveEnabled: false,
        });
        this.props.history.push(`${Routers.PACIJENTI}/${this.state.pacijent.id}`);
        NotificationManager.success("Uspešno obrisan unos");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  preSnimanjaUnosa() {
    let initValue = this.state.initValue;
    this.state.autsi.forEach((auts) => {
      if (!this.prikazivanjeAutsa(auts)) {
        initValue[auts.id] = auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI ? [] : "";
      }
    });
  }

  snimiUnos(ostani) {
    const { initValue } = this.state;
    let greska = false;
    this.setState({
      ostani: ostani,
      disabledButton: true,
      beforeLeaveEnabled: false,
      greska: false,
    });
    let autsiObavezni = this.state.autsi.filter(
      (x) => !x.opciono && x.prikazujeSe && x.tvaNumVrednost <= EnumTipovaVrednostiAtributa.DATUMSKI && x.tvaNumVrednost > EnumTipovaVrednostiAtributa.IZBORNI
    );
    autsiObavezni.map((auts) => {
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI && (!this.state.initValue[auts.id] || this.state.initValue[auts.id].length === 0)) {
        NotificationManager.error("Niste uneli stavku sa nazivom: " + auts.naziv + ".");
        greska = true;
        this.setState({
          greska: true,
          disabledButton: false,
          beforeLeaveEnabled: true,
        });
        return false;
      } else if (!this.state.initValue[auts.id] || this.state.initValue[auts.id] === "") {
        NotificationManager.error("Niste uneli stavku sa nazivom: " + auts.naziv + ".");
        greska = true;
        this.setState({
          greska: true,
          disabledButton: false,
          beforeLeaveEnabled: true,
        });
        return false;
      }
    });
    let autsiRelevantni = this.state.autsi.filter(
      (x) => x.relevantno && x.prikazujeSe && x.tvaNumVrednost <= EnumTipovaVrednostiAtributa.DATUMSKI && x.tvaNumVrednost > EnumTipovaVrednostiAtributa.IZBORNI
    );
    autsiRelevantni.map((auts) => {
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI && (!this.state.initValue[auts.id] || this.state.initValue[auts.id].length === 0)) {
        greska = true;
        this.setState({
          prikazSnimiAlerta: true,
          disabledButton: false,
          beforeLeaveEnabled: false,
        });
        return false;
      } else if (!this.state.initValue[auts.id] || this.state.initValue[auts.id] === "") {
        greska = true;
        this.setState({
          prikazSnimiAlerta: true,
          disabledButton: false,
          beforeLeaveEnabled: false,
        });
        return false;
      }
    });
    this.state.autsi.map((auts) => {
      const { donjaGranicaBrojneVrednosti, gornjaGranicaBrojneVrednosti } = auts.tipAtributa;
      const value = initValue[auts.id];
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.BROJNI && value && value !== "" && donjaGranicaBrojneVrednosti !== null && gornjaGranicaBrojneVrednosti !== null) {
        if (donjaGranicaBrojneVrednosti > value || gornjaGranicaBrojneVrednosti < value) {
          NotificationManager.error("Stavka sa nazivom: " + auts.naziv + " nije u opsegu " + donjaGranicaBrojneVrednosti + " - " + gornjaGranicaBrojneVrednosti);
          greska = true;
          this.setState({
            greska: true,
            disabledButton: false,
            beforeLeaveEnabled: true,
          });
          return false;
        }
      }
    });

    if (!this.state.datumUnosa) {
      NotificationManager.error(Labels.TITLE_MESSAGES_NISTE_UNELI_DATUM_PREGLEDA);
      this.setState({
        greska: true,
        disabledButton: false,
        beforeLeaveEnabled: true,
      });
      return false;
    }
    if (this.state.radnikOdgovoranOptions.length > 0 && !this.state.radnikOdgovoranSelect) {
      NotificationManager.error(Labels.TITLE_MESSAGES_NISTE_UNELI_ORDINIRAJUCEG_LEKARA);
      this.setState({
        greska: true,
        disabledButton: false,
        beforeLeaveEnabled: true,
      });
      return false;
    }
    if (!greska) {
      this.preSnimanjaUnosa();
      axios
        .post(
          Endpoint.NOVI_UNOS_VREDNOSTI,
          {
            unosID: this.state.snimljenUnosID,
            tsaID: this.state.unos.id,
            registarID: this.state.registar.id,
            pacijentID: this.state.pacijent.id,
            vrednosti: this.state.initValue,
            radnikOdgovoran: this.state.radnikOdgovoranSelect ?? JSON.parse(localStorage.getItem("ulogovanRadnik")).id,
            datumUnosa: moment(this.state.datumUnosa).format(DATE_FORMAT),
          },
          axiosConfig(token)
        )
        .then((response) => {
          if (!ostani) {
            this.props.history.push(`${Routers.PACIJENTI}/${this.state.pacijent.id}`);
          }
          this.setState({
            disabledButton: false,
            beforeLeaveEnabled: true,
            snimljenUnosID: response.data.snimljenUnosID,
          });
          window.history.pushState("", "RegiX", `${Routers.UNOSI}/${response.data.snimljenUnosID}`);
          NotificationManager.success(Labels.MESSAGES_USPESNO_SNIMLJEN_UNOS);
        })
        .catch((error) => {
          this.setState({
            disabledButton: false,
            beforeLeaveEnabled: true,
          });
          NotificationManager.error(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_UNOSA);
        });
    }
  }

  loadFromServer() {
    let registarObject = {};
    let pacijentObject = {};
    let unosObject = {};
    let autsiSeparatoriObject = {};
    let autsiSegmentiOrderObject = {};
    let initValueObject = {};
    let sifraAutsPoTipuObject = {};
    let prikazAUTSObject = {};
    let enumiSviObject = {};
    let enumiPoAUTSObject = {};
    let autsiPanelArray = {};
    let defaultTabInit = "";
    let defaultPodTabInit = {};
    let radniciOdgovorniArray = [];
    let radnikOdgovoranSelect = null;
    let datumUnosa = this.props.location.state && this.props.location.state.vremeUnosa ? moment(this.props.location.state.vremeUnosa) : moment();

    axios
      .get(
        Endpoint.GET_PODACI_ZA_PRIKAZ_H_A,
        axiosConfig(token, {
          haID: this.props.match !== undefined && !isNaN(this.props.match.params.number) ? this.props.match.params.number : this.props.unosID !== undefined ? this.props.unosID : null,
          tsaID: this.props.location !== undefined && this.props.location.state !== undefined ? this.props.location.state.tsaID : null,
          registarID: registarID,
          pacijentID: this.props.location !== undefined && this.props.location.state !== undefined ? this.props.location.state.pacijentID : null,
          radnikID: JSON.parse(localStorage.getItem("ulogovanRadnik")).id,
        })
      )
      .then((response) => {
        let data = response.data;
        registarObject = data.registar;
        pacijentObject = data.pacijent;
        unosObject = data.tsa;
        if (unosObject.datumUnosa) {
          datumUnosa = moment(unosObject.datumUnosa, "DD.MM.YYYY.");
        }
        radnikOdgovoranSelect = unosObject.radnikOdgovoran?.value ?? JSON.parse(localStorage.getItem("ulogovanRadnik"));
        autsiPanelArray = data.panelAutsiArray.sort((a, b) => (a.redosled > b.redosled ? 1 : -1));
        let autsSegmenti = [];
        autsSegmenti = data.autsSegmenti;
        autsiSegmentiOrderObject = data.autsSegmenti;
        let getAUTSPoSegmentima = data.getAUTSPoSegmentima;
        let autsiArray = [];
        if (data.radniciOdgovorni) {
          radniciOdgovorniArray = data.radniciOdgovorni.map((radnik) => {
            return { value: radnik.id, label: radnik.imePrezime };
          });
        }

        autsSegmenti.map((segment, s) => {
          autsiSeparatoriObject[segment.id] = getAUTSPoSegmentima[segment.id];
          let podredjenTab = getAUTSPoSegmentima[segment.id].autsi.filter((x) => x.tvaNumVrednost === EnumTipovaVrednostiAtributa.TAB);
          if (podredjenTab.length > 0) {
            defaultPodTabInit[segment.id] = podredjenTab[0].id;
          }
          getAUTSPoSegmentima[segment.id].autsi.map((auts, a) => {
            autsiArray.push(auts);
          });
        });
        defaultTabInit = autsSegmenti[0] ? autsSegmenti[0].id : "0";

        if (autsSegmenti.length === 0) {
          autsiArray = data.autsiArray;
          let podredjenTab = autsiArray.filter((x) => x.tvaNumVrednost === EnumTipovaVrednostiAtributa.TAB);
          if (podredjenTab.length > 0) {
            defaultPodTabInit[defaultTabInit] = podredjenTab[0].id;
          }
        }

        autsiArray.map((auts, a) => {
          let numerickaVrednost = auts.tvaNumVrednost;
          let vrednost = auts.vrednost;
          sifraAutsPoTipuObject[auts.sifra] = numerickaVrednost;
          if (numerickaVrednost === EnumTipovaVrednostiAtributa.IZBORNI) {
            initValueObject[auts.id] = vrednost && vrednost === true ? vrednost : false;
            prikazAUTSObject[auts.sifra] = vrednost;
          }
          if (numerickaVrednost === EnumTipovaVrednostiAtributa.BROJNI) {
            initValueObject[auts.id] = vrednost ? vrednost : "";
            prikazAUTSObject[auts.sifra] = vrednost;
          } else if (numerickaVrednost === EnumTipovaVrednostiAtributa.TEKSTUALNI) {
            initValueObject[auts.id] = vrednost;
            prikazAUTSObject[auts.sifra] = vrednost;
          } else if (numerickaVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI) {
            enumiPoAUTSObject[auts.id] = auts.enumStavkeSve;
            auts.enumStavkeSve.map((enumS, s) => {
              enumiSviObject[enumS.id] = enumS.numVrednost;
            });
            if (auts.enumStavkeInit) {
              if (auts.enumStavkeInit === 1) {
                let enumVre = auts.enumStavkeInit[0];
                let enumVrednostiLista = { value: enumVre.id, label: enumVre.naziv, numVrednost: enumVre.numVrednost };
                initValueObject[auts.id] = enumVrednostiLista;
                prikazAUTSObject[auts.sifra] = enumVrednostiLista;
              } else {
                let enumVrednostiLista = auts.enumStavkeInit.map((enumVre, ev) => {
                  let key = enumVre.id;
                  let naziv = enumVre.naziv;
                  let numVrednost = enumVre.numVrednost;
                  return { value: key, label: naziv, numVrednost: numVrednost };
                });
                initValueObject[auts.id] = enumVrednostiLista;
                prikazAUTSObject[auts.sifra] = enumVrednostiLista;
              }
            }
          } else if (numerickaVrednost === EnumTipovaVrednostiAtributa.DATUMSKI) {
            initValueObject[auts.id] = vrednost ? moment(vrednost) : "";
            prikazAUTSObject[auts.sifra] = vrednost ? moment(vrednost) : "";
          } else if (numerickaVrednost === EnumTipovaVrednostiAtributa.GRAFIK) {
            initValueObject[auts.id] = vrednost ? vrednost : [];
            prikazAUTSObject[auts.sifra] = vrednost;
          }
        });

        this.setState({
          pacijent: pacijentObject,
          unos: unosObject,
          autsi: autsiArray,
          initValue: initValueObject,
          registar: registarObject,
          prikazAUTS: prikazAUTSObject,
          sifraAutsPoTipu: sifraAutsPoTipuObject,
          autsiSegmentiOrder: autsiSegmentiOrderObject,
          enumiSvi: enumiSviObject,
          enumiPoAUTS: enumiPoAUTSObject,
          defaultTab: defaultTabInit,
          autsiPanel: autsiPanelArray,
          autsiSeparatori: autsiSeparatoriObject,
          pravoIzmeneUnosa: response.data.pravoIzmene,
          defaultPodTab: defaultPodTabInit,
          radnikOdgovoranOptions: radniciOdgovorniArray,
          radnikOdgovoranSelect,
          datumUnosa,
          ucitano: true,
        });
        this.changeRacunanjeAutsa();
        this.zavisniAUTS();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadFromServer();
  }

  showHtmlInfo(htmlInfo) {
    this.setState({
      showPopUp: true,
      popUpHtmlInfo: htmlInfo,
    });
  }

  hideHtmlInfo() {
    this.setState({
      showPopUp: false,
      popUpHtmlInfo: "",
    });
  }

  render() {
    return (
      <div>
        <div className={!this.state.ucitano ? "d-none" : ""}>
          <Breadcrumb nazivUnosa={this.state.unos.naziv} registar={this.state.registar} />
          <Link to={"/pacijenti/" + this.state.pacijent.id} className="btn btn-outline-danger ml-4">
            <i className="fas fa-user-injured"></i> {this.state.pacijent.ime + " " + this.state.pacijent.prezime}
          </Link>
          <button className={this.state.pravoIzmeneUnosa ? "btn btn-outline-danger ml-4" : "d-none"} onClick={this.snimiUnos.bind(this, false)} disabled={this.state.disabledButton}>
            <i className={!this.state.disabledButton ? "fas fa-save" : "fas fa-spinner fa-pulse"}></i> {Labels.BUTTON_SNIMI}
          </button>
          <button className={this.state.pravoIzmeneUnosa ? "btn btn-outline-danger ml-4" : "d-none"} onClick={this.snimiUnos.bind(this, true)} disabled={this.state.disabledButton}>
            <i className={!this.state.disabledButton ? "fas fa-save" : "fas fa-spinner fa-pulse"}></i> {Labels.BUTTON_SNIMI_I_OSTANI}
          </button>
          <button className={!isNaN(this.props.match?.params.number) && this.state.pravoIzmeneUnosa ? "btn btn-outline-danger ml-4" : "d-none"} onClick={this.obrisUnosConfirm}>
            <i className="fas fa-trash-alt"></i> {Labels.BUTTON_OBRISI}
          </button>
          <Link to={`/unosArhiva/${this.props.match.params.number}`} className={!isNaN(this.props.match.params.number) ? "btn btn-outline-danger ml-4" : "d-none"}>
            <i className="fas fa-archive"></i> {Labels.BUTTON_ISTORIJA_VERZIJA}
          </Link>
          <SweetAlert
            show={this.state.prikazAlerta}
            warning
            title=""
            showCancel
            cancelBtnText={Labels.BUTTON_ODUSTANI}
            confirmBtnText={Labels.BUTTON_POTVRDI}
            onCancel={this.obrisiUnosCancel}
            onConfirm={this.obrisiUnos}
          >
            {Labels.LABEL_BRISETE_UNOS}
          </SweetAlert>
          <SweetAlert
            show={this.state.prikazSnimiAlerta && !this.state.greska}
            warning
            title=""
            showCancel
            cancelBtnText="Odustani"
            confirmBtnText="Potvrdi"
            onCancel={this.popuniPonovo}
            onConfirm={this.prihvatiUpozorenje}
          >
            {Labels.LABEL_NASTAVI_BEZ_RELEVANTNIH_POLJA}
          </SweetAlert>
          <div className="card autsCard mt-2 ml-4">
            <div className="card-header text-center mb-4">
              <strong>{this.state.unos.naziv}</strong> <br />
              {this.state.unos.doktorKreirao ? "Lekar kreirao: " + this.state.unos.doktorKreirao + ", " : ""}
              {this.state.unos.datumKreiranja}
              <br />
              {this.state.unos.doktorIzmenio ? "Lekar izmenio: " + this.state.unos.doktorIzmenio + ", " : ""}
              {this.state.unos.datumIzmene}
            </div>
            <div className="form-group row ml-2">
              <div className={this.state.radnikOdgovoranOptions.length > 0 ? "col-md-2 mb-3" : "d-none"}>{Labels.LABEL_ORDINIRAJUCI_LEKAR_OBAVEZNO}</div>
              <div className={this.state.radnikOdgovoranOptions.length > 0 ? "col-md-3 mb-3" : "d-none"}>
                <Dropdown
                  className="w-full"
                  placeholder="Odaberite radnika"
                  editable={false}
                  filter={true}
                  value={this.state.radnikOdgovoranSelect}
                  onChange={(e) => this.changeState("radnikOdgovoranSelect", e.target.value)}
                  options={this.state.radnikOdgovoranOptions}
                />
              </div>
              <div className="col-md-2 mb-3">{Labels.LABEL_DATUM_PREGLEDA_OBAVEZNO}</div>
              <div className="col-md-4 mb-3 ml-3">
                <Calendar
                  disabled={this.props.location.state?.vremeUnosa}
                  showIcon
                  dateFormat={CALENDAR_DATE_FORMAT}
                  showMonthDropdown
                  showYearDropdown
                  value={this.state.datumUnosa ? new Date(this.state.datumUnosa) : undefined}
                  onChange={(e) => this.changeState("datumUnosa", e.value)}
                />
              </div>
            </div>
            <Auts
              autsi={this.state.autsi}
              autsiSeparatori={this.state.autsiSeparatori}
              defaultTab={this.state.defaultTab}
              autsiSegmentiOrder={this.state.autsiSegmentiOrder}
              enumiSvi={this.state.enumiSvi}
              enumiPoAUTS={this.state.enumiPoAUTS}
              initValue={this.state.initValue}
              prikazAUTS={this.state.prikazAUTS}
              sifraAutsPoTipu={this.state.sifraAutsPoTipu}
              changeState={this.changeState}
              autsiPanel={this.state.autsiPanel}
              defaultPodTab={this.state.defaultPodTab}
              changePodTab={this.changePodTab}
              prikazivanjeAutsa={this.prikazivanjeAutsa}
              oboji={this.state.oboji}
              showHtmlInfo={this.showHtmlInfo}
              hideHtmlInfo={this.hideHtmlInfo}
            />
          </div>
        </div>
        {!this.state.ucitano && (
          <div className="d-flex justify-content-center" style={{ marginTop: "20%" }}>
            <div className="spinner-border text-secondary" style={{ width: "4rem", height: "4rem" }} role="status">
              <span className="sr-only">{Labels.LABEL_UCITAVANJE}</span>
            </div>
          </div>
        )}
        <div className="htmlInfoDialog">
          <div className={"modal fade" + (this.state.showPopUp ? " show modalShow" : "")}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header modal-header-1">
                  <h5 className="modal-title">{Labels.LABEL_INFO}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.hideHtmlInfo()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="reactHtmlParser">{ReactHtmlParser(this.state.popUpHtmlInfo)}</div>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary btn-primary-light" onClick={() => this.hideHtmlInfo()}>
                      {Labels.BUTTON_ZATVORI}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showPopUp && <div id="page-mask" />}
        </div>
        <BeforeLeave message={Labels.LABEL_NAPUSTI_STRANICU} enableUnload={this.state.beforeLeaveEnabled} enableRouter={this.state.beforeLeaveEnabled} exact={true} />
      </div>
    );
  }
}

export default Unos;
