/* eslint no-eval: 0 */
import moment from "moment";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import EnumTipovaVrednostiAtributa from "../../utils/EnumTipovaVrednostiAtributa";
import Routers from "../../utils/Routers";
import { DATE_FORMAT } from "../../utils/Utils";

export default function PoslednjiOdabraniUnos({ poslednjiUnos }: any) {
  const { id } = useParams<any>();
  const history = useHistory();

  const prikazivanjeAutsa = (auts: any, prikazAUTS: any, sifraAutsPoTipu: any) => {
    let prikaz = true;
    if (auts.formulaZaPrikaz) {
      let formulaZaPrikaz = auts.formulaZaPrikaz;
      let formulaArray = formulaZaPrikaz.split(" ");
      let vrednost = "";
      formulaArray.forEach((elem: any, e: any) => {
        if (elem.startsWith("$(")) {
          elem = elem.split("$(")[1].split(")")[0];
          if (prikazAUTS) {
            if (prikazAUTS[elem]) {
              vrednost = prikazAUTS[elem];
              if (sifraAutsPoTipu && sifraAutsPoTipu[elem] === EnumTipovaVrednostiAtributa.SKUPOVNI) {
                let vrednostArray: any[] = [];
                let tempArray: any[] = [];
                if (!Array.isArray(vrednost) && auts.enumStavkeSve) {
                  tempArray = [vrednost];
                }
                tempArray.forEach((vre: any) => {
                  vrednostArray.push(auts.enumStavkeSve[vre.value]);
                });
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", "[" + vrednostArray + "]");
              } else {
                formulaZaPrikaz = formulaZaPrikaz.replace("$(" + elem + ")", vrednost);
              }
            } else {
              prikaz = false;
            }
          }
        }
      });
      if (vrednost && formulaZaPrikaz && vrednost !== "" && !formulaZaPrikaz.includes("$")) {
        prikaz = eval(formulaZaPrikaz);
      }
    }
    return prikaz;
  };

  const createRow = (key: number, label: string, value: any) => {
    return (
      <div key={key} className="grid flex-column">
        <div className="col font-bold">
          <label className="mb-0">{label}</label>
        </div>
        <div className="col pac-value">
          <label className="mb-0">{value}</label>
        </div>
      </div>
    );
  };

  const rowsUnos = () => {
    const sviPodaci = poslednjiUnos.autsi;
    let prikazAUTS: any = {};
    let sifraAutsPoTipu: any = {};
    const tipoviZaPrikaz = [
      EnumTipovaVrednostiAtributa.IZBORNI,
      EnumTipovaVrednostiAtributa.TEKSTUALNI,
      EnumTipovaVrednostiAtributa.BROJNI,
      EnumTipovaVrednostiAtributa.SKUPOVNI,
      EnumTipovaVrednostiAtributa.DATUMSKI,
    ];
    sviPodaci.forEach((red: any) => {
      const tvaNumVr = red.tvaNumVrednost;
      prikazAUTS[red.sifra] = tvaNumVr === EnumTipovaVrednostiAtributa.SKUPOVNI ? red.enumStavkeInit : red.vrednost;
      sifraAutsPoTipu[red.sifra] = tvaNumVr;
    });
    const vidljiviPodaci = sviPodaci.filter((red: any) => {
      return prikazivanjeAutsa(red, prikazAUTS, sifraAutsPoTipu) && tipoviZaPrikaz.includes(red.tvaNumVrednost);
    });
    const leftColumn = vidljiviPodaci.filter((a: any, i: number) => i % 2 === 0);
    const rightColumn = vidljiviPodaci.filter((a: any, i: number) => i % 2 !== 0);
    return (
      <div className="grid">
        <div className="col-6">{leftColumn.map((red: any) => createData(red))}</div>
        <div className="col-6">{rightColumn.map((red: any) => createData(red))}</div>
      </div>
    );
  };

  const createData = (red: any) => {
    if (red.tvaNumVrednost === EnumTipovaVrednostiAtributa.IZBORNI) {
      return createRow(red.id, red.naziv, red.vrednost ? Labels.LABEL_DA : Labels.LABEL_NE);
    } else if (red.tvaNumVrednost === EnumTipovaVrednostiAtributa.BROJNI) {
      return createRow(red.id, red.naziv, red.vrednost);
    } else if (red.tvaNumVrednost === EnumTipovaVrednostiAtributa.TEKSTUALNI) {
      return createRow(red.id, red.naziv, red.vrednost);
    } else if (red.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI) {
      let skupovnaVrednost = "";
      if (red.enumStavkeInit) {
        Object.keys(red.enumStavkeInit).forEach((key) => {
          let delimiter = "";
          if (Object.keys(red.enumStavkeInit).length > 1 && Object.keys(red.enumStavkeInit)[Object.keys(red.enumStavkeInit).length - 1] !== key) {
            delimiter = ", ";
          }
          skupovnaVrednost += red.enumStavkeInit[key].naziv + delimiter;
        });
      }
      return createRow(red.id, red.naziv, skupovnaVrednost);
    } else if (red.tvaNumVrednost === EnumTipovaVrednostiAtributa.DATUMSKI) {
      return createRow(red.id, red.naziv, red.vrednost ? moment.unix(red.vrednost / 1000).format(DATE_FORMAT) : "");
    }
  };

  const noviUnos = (unosID: any) => {
    history.push({
      pathname: Routers.UNOSI_NOVI,
      state: { tsaID: unosID, pacijentID: id },
    });
  };

  const template = (options: any) => {
    const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
    return (
      <div className={`${options.className} grid-justify-between p-0`}>
        <div className="col-1">
          <Button icon={toggleIcon} className={options.togglerClassName} onClick={options.onTogglerClick} />
        </div>
        <div className={`${options.titleClassName} col-7`}>{poslednjiUnos.unosNaziv}</div>
        <div className="col-4 text-right">
          {poslednjiUnos.snimljenUnosID !== "" && <Button icon="pi pi-user-edit" onClick={() => history.push(`/unosi/${poslednjiUnos.snimljenUnosID}`)} className="p-button-outlined pac-icon" />}
          {poslednjiUnos.snimljenUnosID === "" && poslednjiUnos.mozeSeInstancirati && (
            <Button icon="pi pi-plus" onClick={() => noviUnos(poslednjiUnos.unosID)} className="p-button-rounded p-button-outlined ml-2 pac-icon" />
          )}
        </div>
      </div>
    );
  };

  return (
    <Panel headerTemplate={template} toggleable>
      {rowsUnos()}
    </Panel>
  );
}
