import axios from "axios";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig, CALENDAR_DATE_FORMAT, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import PromenaLozinke from "./PromenaLozinke";

export default function KorisnickiProfil() {
  const [ulogovaniRadnik, setUlogovanRadnik] = useState<any>();
  // const [selectedTheme, setSelectedTheme] = useState<any>();
  const [displayDialog, setDisplayDialog] = useState(false);
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    loadData();
  });

  const loadData = () => {
    axios.get(Endpoint.RADNIK + "/" + authData.id, axiosConfig(authData.token)).then((response) => {
      setUlogovanRadnik(response.data);
      // setSelectedTheme({ value: response.data.theme, label: response.data.theme });
    });
  };

  const validateInput = () => {
    if (ulogovaniRadnik.ime === undefined || ulogovaniRadnik.ime === "") {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_KORISNIKA, Labels.MESSAGES_POLJE_IME);
      return false;
    }
    if (ulogovaniRadnik.prezime === undefined || ulogovaniRadnik.prezime === "") {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_KORISNIKA, Labels.MESSAGES_POLJE_PREZIME);
      return false;
    }
    return true;
  };

  const sacuvaj = () => {
    if (!validateInput()) {
      return;
    }
    axios
      .patch(Endpoint.RADNIK + "/" + ulogovaniRadnik.id, ulogovaniRadnik, axiosConfig(authData.token))
      .then((response) => {
        showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_SNIMLJEN_KORISNIK, ulogovaniRadnik.ime + " " + ulogovaniRadnik.prezime);
      })
      .catch((error) => {
        showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_SNIMANJA_KORISNIKA, ulogovaniRadnik.ime + " " + ulogovaniRadnik.prezime);
      });
  };

  const ponisti = () => {
    loadData();
  };

  const closeDialog = () => {
    setDisplayDialog(false);
  };

  if (!ulogovaniRadnik) {
    return null;
  }

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_SACUVAJ} icon="pi pi-save" className="p-button-success" onClick={sacuvaj} />
      <Button label={Labels.BUTTON_PROMENA_LOZINKE} className="p-button-primary" onClick={() => setDisplayDialog(true)} />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_PONISTI} className="p-button-danger" onClick={ponisti} />
    </React.Fragment>
  );

  return (
    <div className="layout-user-profile">
      <Panel header={Labels.TITLE_PANEL_LICNI_PODACI_KORISNIKA} className="panel-user">
        <div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="grid fluid align-items-center">
              <div className="col-4">{Labels.LABEL_KORISNICKO_IME}</div>
              <div className="col-8">
                <InputText disabled value={(ulogovaniRadnik && ulogovaniRadnik.username) || ""} />
              </div>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="grid fluid align-items-center">
              <div className="col-4">{Labels.LABEL_IME}</div>
              <div className="col-8">
                <InputText
                  value={(ulogovaniRadnik && ulogovaniRadnik.ime) || ""}
                  onChange={(e: any) => {
                    setUlogovanRadnik({
                      ...ulogovaniRadnik,
                      ime: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="grid fluid align-items-center">
              <div className="col-4">{Labels.LABEL_PREZIME}</div>
              <div className="col-8">
                <InputText
                  value={(ulogovaniRadnik && ulogovaniRadnik.prezime) || ""}
                  onChange={(e: any) => {
                    setUlogovanRadnik({
                      ...ulogovaniRadnik,
                      prezime: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="grid fluid align-items-center">
              <div className="col-4">{Labels.LABEL_DATUM_RODJENJA}</div>
              <div className="col-8">
                <Calendar
                  monthNavigator={true}
                  yearNavigator={true}
                  yearRange="1920:2030"
                  dateFormat={CALENDAR_DATE_FORMAT}
                  showIcon
                  value={ulogovaniRadnik && ulogovaniRadnik.datumRodjenja ? new Date(ulogovaniRadnik.datumRodjenja) : undefined}
                  onChange={(e: any) => {
                    const date = new Date(e.target.value);
                    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                    setUlogovanRadnik({
                      ...ulogovaniRadnik,
                      datumRodjenja: date,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="grid fluid align-items-center">
              <div className="col-4">{Labels.LABEL_POL}</div>
              <div className="col-8">
                <RadioButton
                  name="pol"
                  checked={ulogovaniRadnik && ulogovaniRadnik.pol === true ? ulogovaniRadnik.pol : false}
                  value={ulogovaniRadnik && ulogovaniRadnik.pol === true ? ulogovaniRadnik.pol : true}
                  onChange={(e: any) => {
                    setUlogovanRadnik({
                      ...ulogovaniRadnik,
                      pol: e.target.value,
                    });
                  }}
                />
                <label className="p-radiobutton-label">{Labels.LABEL_MUSKI}</label>
                <RadioButton
                  name="pol"
                  checked={ulogovaniRadnik && ulogovaniRadnik.pol === false ? !ulogovaniRadnik.pol : false}
                  value={ulogovaniRadnik && ulogovaniRadnik.pol === false ? ulogovaniRadnik.pol : false}
                  onChange={(e: any) => {
                    setUlogovanRadnik({
                      ...ulogovaniRadnik,
                      pol: e.target.value,
                    });
                  }}
                />
                <label className="p-radiobutton-label">{Labels.LABEL_ZENSKI}</label>
              </div>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="grid fluid align-items-center">
              <div className="col-4">{Labels.LABEL_ADRESA}</div>
              <div className="col-8">
                <InputText
                  value={(ulogovaniRadnik && ulogovaniRadnik.adresa) || ""}
                  onChange={(e: any) => {
                    setUlogovanRadnik({
                      ...ulogovaniRadnik,
                      adresa: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="grid fluid align-items-center">
              <div className="col-4">{Labels.LABEL_EMAIL}</div>
              <div className="col-8">
                <InputText
                  value={(ulogovaniRadnik && ulogovaniRadnik.email) || ""}
                  onChange={(e: any) => {
                    setUlogovanRadnik({
                      ...ulogovaniRadnik,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            <div className="grid fluid align-items-center">
              <div className="col-4">{Labels.LABEL_TELEFON}</div>
              <div className="col-8">
                <InputText
                  value={(ulogovaniRadnik && ulogovaniRadnik.telefon) || ""}
                  onChange={(e: any) => {
                    setUlogovanRadnik({
                      ...ulogovaniRadnik,
                      telefon: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <Toolbar left={leftContents} right={rightContents} />
          </div>
          {/* theme nam trenutno nisu potrebne
           <div className="xl-8 lg-8 md-8 sm-12">
            <div className="grid fluid align-items-center">
              <div className="col-4">{Labels.LABEL_THEME}</div>
              <div className="col-8">
                <Dropdown
                  value={ulogovaniRadnik && ulogovaniRadnik.theme ? ulogovaniRadnik.theme : ""}
                  options={Theme}
                  onChange={(e: any) => {
                    setUlogovanRadnik({
                      ...ulogovaniRadnik,
                      theme: e.value,
                    });
                  }}
                />
              </div>
            </div>
          </div> */}
        </div>
        <Dialog header={Labels.TITLE_DIALOG_PROMENA_LOZINKE + "(" + ulogovaniRadnik.username + ")"} visible={displayDialog} onHide={closeDialog} style={{ width: "50%" }}>
          <PromenaLozinke ulogovanRadnikId={ulogovaniRadnik.id} onCancel={closeDialog} />
        </Dialog>
      </Panel>
    </div>
  );
}
