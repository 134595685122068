export default class AuthData {
  token: string;
  id: number;
  username: string;
  imePrezime: string;
  ustanovaNaziv: string;

  constructor(token: string, id: number, username: string, imePrezime: string, ustanovaNaziv: string) {
    this.token = token;
    this.id = id;
    this.username = username;
    this.imePrezime = imePrezime;
    this.ustanovaNaziv = ustanovaNaziv;
  }
}
