import React, { useState } from "react";
import TipAtributaRouter2 from "./TipAtributaRouter2";
import Panel2 from "./Panel2";
import Slikovni from "./Slikovni";
import Tab from "./Tab";
import EnumTipovaVrednostiAtributa from "../../utils/EnumTipovaVrednostiAtributa";
import { useEffectOnce } from "../../utils/Utils";

export default function Auts2(props: any) {
  const {
    changeState,
    initValue,
    enumiPoAUTS,
    prikazAUTS,
    sifraAutsPoTipu,
    prikazivanjeAutsa,
    showHtmlInfo,
    hideHtmlInfo,
    autsiSeparatori,
    autsiSegmentiOrder,
    defaultTab,
    autsi,
    defaultPodTab,
    changePodTab,
    autsiPanel,
    oboji,
  } = props;

  const [autsiSegmentiOrderNePrikazujeSe, setAutsiSegmentiOrderNePrikazujeSe] = useState<any>([]);
  const [autsi2, setAutsi2] = useState<any>(autsi);
  const [autsiSeparatori2, setAutsiSeparatori2] = useState<any>(autsiSeparatori);
  const [state, setState] = useState({
    prikazTaba: true,
    poslednjiTab: [] as any,
    panelTab: {},
  });

  useEffectOnce(() => {
    let autsiSegmentiOrderTemp: any = {};
    Object.keys(autsiSegmentiOrder).map((id, a) => {
      if (autsiSegmentiOrder[id] !== null && !autsiSegmentiOrder[id].prikazujeSe && !state.poslednjiTab.includes(autsiSegmentiOrder[id].id)) {
        autsiSegmentiOrderTemp.push(autsiSegmentiOrder[id]);
      }
    });
    setAutsiSegmentiOrderNePrikazujeSe(autsiSegmentiOrderTemp);

    if (Object.keys(autsiSeparatori).length === 0 && autsi.length > 0) {
      autsiSeparatori[0] = { autsi: autsi };
      setAutsiSeparatori2([{ autsi: autsi }]);
    }
  });

  const changeTab = (value: any) => {
    changeState("defaultTab", value);
  };

  const changePrikazTabova = () => {
    setState({ ...state, prikazTaba: !state.prikazTaba });
  };

  const addTabova = (value: any) => {
    let poslednjiTabArray: any = state.poslednjiTab;
    poslednjiTabArray.push(value);
    setState({ ...state, poslednjiTab: poslednjiTabArray });
    changeTab(value);
  };

  const prikazivanjeHorizontalno = (auts: any) => {
    let bojenje = false;
    if (auts.relevantno && auts.prikazujeSe) {
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI && (initValue[auts.id] === undefined || initValue[auts.id].length === 0)) {
        bojenje = true;
      } else if (initValue[auts.id] === undefined || initValue[auts.id] === "") {
        bojenje = true;
      }
    }
    let classNaziv = "";
    if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.NASLOV) {
      classNaziv += "font-weight-bold";
    } else if (bojenje) {
      classNaziv += "bojenje_labele";
    }

    return (
      <div className="form-group autsDiv row card-body">
        <div className={classNaziv}>
          {auts.ispisujeSeLabela ? auts.naziv : ""}
          {auts.ispisujeSeLabela && !auts.opciono ? "* " : " "}
          {auts.ispisujeSeLabela && auts.relevantno ? <i className="fas fa-bell ml-2"></i> : ""}
          {auts.ispisujeSeLabela && auts.htmlInfo && (
            <button className="btn btn-outline-danger ml-1 mb-1 button_htmlInfo" onClick={() => showHtmlInfo(auts.htmlInfo)}>
              <i className="fas fa-info-circle"></i>
            </button>
          )}
        </div>

        <div className="col-md-3 mb-3">
          <TipAtributaRouter2
            tvaNumVrednost={auts.tvaNumVrednost}
            initValue={initValue[auts.id]}
            changeState={changeState}
            tipSkupaAtributaKey={auts.id}
            atributUTipuSkupaID={auts.id}
            auts={auts}
            enumiPoAUTS={enumiPoAUTS}
            prikazAUTS={prikazAUTS}
            sifraAutsPoTipu={sifraAutsPoTipu}
            zaPretragu={false}
          />
        </div>
      </div>
    );
  };

  const prikazivanjeJednogAutsa = (auts: any, podredjeniObject: any, segmentID: any) => {
    const { tvaNumVrednost } = auts;
    if (tvaNumVrednost === EnumTipovaVrednostiAtributa.PANEL) {
      return (
        <Panel2
          auts={auts}
          autsiPodredjeni={podredjeniObject}
          initValue={initValue}
          enumiPoAUTS={enumiPoAUTS}
          changeState={changeState}
          prikazAUTS={prikazAUTS}
          sifraAutsPoTipu={sifraAutsPoTipu}
          zaPretragu={false}
          prikazivanjeAutsa={prikazivanjeAutsa}
          prikazivanjeVertikalno={prikazivanjeVertikalno}
          showHtmlInfo={showHtmlInfo}
          hideHtmlInfo={hideHtmlInfo}
        />
      );
    } else if (tvaNumVrednost === EnumTipovaVrednostiAtributa.SLIKOVNI) {
      return (
        <Slikovni
          auts={auts}
          autsiPodredjeni={podredjeniObject}
          initValue={initValue}
          enumiPoAUTS={enumiPoAUTS}
          changeState={changeState}
          prikazAUTS={prikazAUTS}
          sifraAutsPoTipu={sifraAutsPoTipu}
          prikazivanjeAutsa={prikazivanjeAutsa}
        />
      );
    } else if (tvaNumVrednost === EnumTipovaVrednostiAtributa.TAB) {
      return (
        <Tab
          auts={auts}
          autsiPodredjeni={podredjeniObject}
          initValue={initValue[auts.id]}
          enumiPoAUTS={enumiPoAUTS}
          changeState={changeState}
          prikazAUTS={prikazAUTS}
          defaultPodTab={defaultPodTab !== undefined ? defaultPodTab[segmentID] : ""}
          sifraAutsPoTipu={sifraAutsPoTipu}
          zaPretragu={false}
          prikazivanjeAutsa={prikazivanjeAutsa}
          prikazivanjeVertikalno={prikazivanjeVertikalno}
          prikazivanjeJednogAutsa={prikazivanjeJednogAutsa}
        />
      );
    } else {
      return prikazivanjeVertikalno(auts);
    }
  };

  const prikazivanjeVertikalno = (auts: any) => {
    let bojenje = false;

    if (auts.relevantno && auts.prikazujeSe) {
      if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.SKUPOVNI && (initValue[auts.id] === undefined || initValue[auts.id].length === 0)) {
        bojenje = true;
      } else if (initValue[auts.id] === undefined || initValue[auts.id] === "") {
        bojenje = true;
      }
    }
    let classNaziv = "";
    if (auts.tvaNumVrednost === EnumTipovaVrednostiAtributa.NASLOV) {
      classNaziv += "col-md-2 mb-3 font-weight-bold ";
    } else {
      classNaziv += "col-md-2 mb-3 ";
    }
    if (bojenje && oboji) {
      classNaziv += "bojenje_labele ";
    }
    return (
      <div className="form-group autsDiv row card-body" key={auts.id}>
        <div className={classNaziv}>
          {auts.ispisujeSeLabela ? auts.naziv : ""}
          {auts.ispisujeSeLabela && !auts.opciono ? "* " : " "}
          {auts.ispisujeSeLabela && auts.relevantno ? <i className="fas fa-bell ml-2"></i> : ""}
          {auts.ispisujeSeLabela && auts.htmlInfo && (
            <button className="btn btn-outline-danger ml-1 mb-1 button_htmlInfo" onClick={() => showHtmlInfo(auts.htmlInfo)}>
              <i className="fas fa-info-circle"></i>
            </button>
          )}
        </div>

        <div className="col-md-4 mb-3">
          <TipAtributaRouter2
            tvaNumVrednost={auts.tvaNumVrednost}
            initValue={initValue[auts.id]}
            changeState={changeState}
            tipSkupaAtributaKey={auts.id}
            atributUTipuSkupaID={auts.id}
            auts={auts}
            enumiPoAUTS={enumiPoAUTS}
            prikazAUTS={prikazAUTS}
            sifraAutsPoTipu={sifraAutsPoTipu}
            zaPretragu={false}
          />
        </div>
      </div>
    );
  };

  // let autsiSeparatori2 = autsiSeparatori;
  // let autsiSegmentiOrderNePrikazujeSe: any = [];
  // Object.keys(autsiSegmentiOrder).map((id, a) => {
  //   //   const bla = autsiSegmentiOrder[id] as any;
  //   if (autsiSegmentiOrder[id] !== null && !autsiSegmentiOrder[id].prikazujeSe && !state.poslednjiTab.includes(autsiSegmentiOrder[id].id)) {
  //     autsiSegmentiOrderNePrikazujeSe.push(autsiSegmentiOrder[id]);
  //   }
  // });

  let tabMenu = Object.keys(autsiSegmentiOrder).map((id, a) => {
    if (autsiSegmentiOrderNePrikazujeSe.length > 0 && autsiSegmentiOrderNePrikazujeSe[0].id === autsiSegmentiOrder[id].id) {
      return (
        <a
          key={autsiSegmentiOrder[id].id}
          className="btn nav-item nav-link"
          data-toggle="tab"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          onClick={() => addTabova(autsiSegmentiOrder[id].id)}
        >
          <i className="fas fa-plus"></i>
        </a>
      );
    } else {
    }

    if (!autsiSegmentiOrder[id].prikazujeSe) {
      return null;
    }
    return (
      <a
        key={autsiSegmentiOrder[id].id}
        className={defaultTab === autsiSegmentiOrder[id].id ? "btn nav-item nav-link tabBorderColor tab active" : "btn nav-item nav-link tabBorderColor tab"}
        data-toggle="tab"
        role="tab"
        aria-controls="nav-home"
        aria-selected="true"
        onClick={() => changeTab(autsiSegmentiOrder[id].id)}
      >
        {autsiSegmentiOrder[id].naziv}
      </a>
    );
  });
  // if (Object.keys(autsiSeparatori2).length === 0 && autsi.length > 0) {
  //   autsiSeparatori2[0] = { autsi: autsi };
  // }

  let sviAutsi = Object.keys(autsiSeparatori2).map((id) => {
    if (autsiSeparatori2[id]) {
      let autsi = autsiSeparatori2[id].autsi;
      let autsiPanel2 = autsiPanel;
      let podredjeniObject: any = {};
      let podredjeniAutsi: any = [];
      autsiPanel2.map((auts: any) => {
        let podredjeniArray = autsi?.filter((x: any) => x.naredjeniAUTS === auts.id);
        podredjeniObject[auts.id] = podredjeniArray;
        podredjeniAutsi.push(podredjeniArray);
      });
      if (podredjeniAutsi.length > 0) {
        podredjeniAutsi?.map((pod: any, a: any) => {
          autsi = autsi?.filter((x: any) => !podredjeniAutsi[a].includes(x)).concat(podredjeniAutsi[a].filter((x: any) => !autsi.includes(x)));
        });
      }

      let podtaboviArray = autsi?.filter((x: any) => x.tvaNumVrednost === EnumTipovaVrednostiAtributa.TAB);
      let podTabovi = podtaboviArray?.map((podTab: any) => {
        return (
          <a
            key={podTab.id}
            className={defaultPodTab !== undefined && defaultPodTab[id] === podTab.id ? "btn nav-item nav-link tabBorderColor tab active" : "btn nav-item nav-link tabBorderColor tab"}
            data-toggle="tab"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
            onClick={() => changePodTab(id, podTab.id)}
          >
            {podTab.naziv}
          </a>
        );
      });

      let grupaAuts = autsi?.map((auts: any, a: any) => {
        let prikaz = prikazivanjeAutsa(auts);
        if (prikaz && auts.prikazujeSe) {
          return <div key={a}>{prikazivanjeJednogAutsa(auts, podredjeniObject, id)}</div>;
        }
      });

      if (!state.prikazTaba || !defaultTab || defaultTab.toString() !== id) {
        return null;
      }

      return (
        <div key={id} className={state.prikazTaba && defaultTab && defaultTab.toString() === id ? "" : "d-none"}>
          {podtaboviArray?.length > 0 && (
            <nav className="mb-4">
              <div className="nav nav-tabs" role="tablist">
                {podTabovi}
              </div>
            </nav>
          )}
          {grupaAuts}
        </div>
      );
    }
  });

  return (
    <div className="pt-2">
      {state.prikazTaba && (
        <nav>
          <div className="nav nav-tabs" role="tablist">
            {tabMenu}
          </div>
        </nav>
      )}
      <div className="pt-4 divScroll">{sviAutsi}</div>
    </div>
  );
}
