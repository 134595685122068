import axios from "axios";
import React, { useContext, useState } from "react";
import Endpoint from "./../../../endpoints/Endpoint";
import { AppContext } from "../../Store";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import DisplaySponzor from "./DisplaySponzor";
import CtSponzorDto from "./CtSponzorDto";

export default function DisplaySponsorList() {
  const { authData } = useContext(AppContext);
  const [sponzorOrganizationList, setSponzorOrganizationList] = useState([]);

  useEffectOnce(() => {
    const requestSponsorOrganizationList = axios.get(Endpoint.SPONZOR, axiosConfig(authData?.token, {}));

    axios
      .all([requestSponsorOrganizationList])
      .then(
        axios.spread((responseSponsorOrganizationList: any) => {
          setSponzorOrganizationList(responseSponsorOrganizationList.data.data);
        })
      )
      .catch((e) => {
        console.log(e);
      });
  });

  return (
    <div className="layout-sponsor">
      <div className="panel-sponsor">
        <div className="grid justify-center fluid">
          {sponzorOrganizationList.map((sponzor: CtSponzorDto) => {
            return (
              <div key={sponzor.id} className="col-2 margin-auto">
                <DisplaySponzor key={sponzor.id} sponzor={sponzor} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
