import React from 'react';
import PropTypes from 'prop-types';
import Lazyloader from './lazyloader';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import Endpoint from '../../endpoints/Endpoint';
import { axiosConfig } from "../../utils/Utils";

const token = localStorage.hasOwnProperty("ulogovanRadnik") ? JSON.parse(localStorage.getItem("ulogovanRadnik")).token : "";;

function setItem(key, obj) {
	if (!key) return null;
	try {
		localStorage.setItem(key, JSON.stringify(obj));
	} catch (err) {
		return null;
	}
}

//check if boootstrap and jquery javascript files are loaded
function isJsLoaded() {
	const head = document.getElementsByTagName('head')[0];
	const nodes = head.childNodes;
	let loaded = false;
	for (let ix = 0; ix < nodes.length; ix++) {
		let node = nodes.item(ix);
		if (node.href && node.href.indexOf('jquery.min.js') > -1) {
			loaded = true;
		}
	}
	return loaded;
}

// remove any bootstrap links
function removeCurrentTheme() {
	const head = document.getElementsByTagName('head')[0];
	const nodes = head.childNodes;
	const list = []
	for (let ix = 0; ix < nodes.length; ix++) {
		let node = nodes.item(ix);
		if (node.href && node.href.indexOf('bootstrap') > -1) {
			list.push(node)
		}
	}
	list.forEach((node) => { head.removeChild(node) });
}
//------------------------------------------------------------------------------
// Top level ThemeSwitcher Component
//------------------------------------------------------------------------------
class ThemeSwitcher extends React.Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.loadDefault = this.loadDefault.bind(this);

		this.themePath = props.themePath || '/themes/';
		if (this.themePath.charAt(this.themePath.length - 1) !== '/') {
			this.themePath = this.themePath + '/';
		}
		this.state = {
			loaded: false,
			currentTheme: this.props.defaultTheme
		};
	}

	componentDidMount() {
		if (!isJsLoaded()) {
			this.load();
		}
	}

	loadDefault() {
		Lazyloader.load('/bootstrap/bootstrap.min.css', function () {
			this.setState({ loaded: true, currentTheme: this.props.defaultTheme });
		}.bind(this));
	}

	load() {
		if (localStorage.getItem("ulogovanRadnik")) {
			axios.get(Endpoint.RADNIK + '/' + JSON.parse(localStorage.getItem("ulogovanRadnik")).id, axiosConfig(token))
				.then((res) => {
					let radnikTheme = res.data.theme;
					this.setState({ loaded: false })
					removeCurrentTheme();

					if (radnikTheme == null || radnikTheme === this.props.defaultTheme) {
						return this.loadDefault();
					}

					Lazyloader.load(this.themePath + radnikTheme + '/bootstrap.min.css', function () {
						setItem(this.props.storeThemeKey, radnikTheme);
						this.setState({ loaded: true, currentTheme: radnikTheme })
					}.bind(this));

				})
				.catch((error) => {
					NotificationManager.error('Greška prilikom poništavanja podataka!');
				});
		} else {
			return this.loadDefault();
		}
	}

	// pass reference to this down to ThemeChooser component
	getChildContext() {
		return {
			themeSwitcher: this,
			themes: this.props.themes,
			currentTheme: this.state.currentTheme
		};
	}

	render() {
		if (!this.state.loaded) return null;
		return this.props.children || <span />
	}
}

ThemeSwitcher.childContextTypes = {
	themeSwitcher: PropTypes.object,
	themes: PropTypes.array,
	currentTheme: PropTypes.string
};

ThemeSwitcher.propTypes = {
	themePath: PropTypes.string,
	defaultTheme: PropTypes.string,
	storeThemeKey: PropTypes.string,
	themes: PropTypes.array
};

ThemeSwitcher.defaultProps = {
	themePath: '/css/themes',
	defaultTheme: 'regix',
	storeThemeKey: null,
	themes: [
		'regix',
		'cerulean',
		'cosmo',
		'cyborg',
		'darkly',
		'flatly',
		'journal',
		'litera',
		'lumen',
		'lux',
		'materia',
		'minty',
		'pulse',
		'sandstone',
		'simplex',
		'sketchy',
		'slate',
		'solar',
		'spacelab',
		'superhero',
		'united',
		'yeti'
	]
};

export default ThemeSwitcher;
