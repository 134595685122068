import { InputSwitch } from "primereact/inputswitch";
import React from "react";

interface IzborniProps {
  label: string;
  naziv: string;
  vrednost: boolean;
  onChange: Function;
}

export default function Izborni(props: IzborniProps) {
  const { naziv, label, vrednost, onChange } = props;

  const onChangeLocal = () => {
    onChange({ naziv: naziv, vrednost: !vrednost, label: label });
  };

  return (
    <div className="xl-12 lg-12 md-12 sm-12">
      <div className="grid align-center">
        <div className="col-8">{label}</div>
        <div className="col-4">
          <InputSwitch checked={vrednost} onChange={onChangeLocal} />
        </div>
      </div>
    </div>
  );
}
