const AplikativnaPrava = {
  rad_sa_izvestajima: 1,
  administriranje_uloga: 2,
  administriranje_ustanova: 3,
  administriranje_radnika: 4,
  administriranje_registara: 5,
  administriranje_parametara: 6,
  administriranje_izvestaja: 7,
  izmena_unosa_svoje_ustanove: 8,
  izmena_unosa_svih_ustanova: 9,
  kreiranje_unosa_za_druge_radnike: 10,
  administriranje_pregleda_loga: 11,
  administriranje_statistike_unosa: 12,
  administriranje_upozorenja: 13,
};

const AplikativnaPravaAdministriranje = [2, 3, 4, 5, 6, 7, 11, 12, 13];

export { AplikativnaPrava, AplikativnaPravaAdministriranje };
