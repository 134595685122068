import axios from "axios";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Password } from "primereact/password";
import React, { useContext, useRef, useState } from "react";
import Endpoint from "../endpoints/Endpoint";
import Labels from "../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../infrastructure/MessageType";
import AuthData from "../model/AuthData";
import { axiosConfig } from "../utils/Utils";
import logo from "./../images/logo.png";
import { AppContext } from "./Store";

export default function NewLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordType, setShowPasswordType] = useState(true);
  const messages = useRef<any>(null);
  const { nazivAplikacije, updateAuthData } = useContext(AppContext);

  const responseLogIn = (radnik: AuthData) => {
    if (radnik) {
      updateAuthData(radnik);
      axios
        .get(
          Endpoint.REGISTAR + "/search/findByAktivan",
          axiosConfig(radnik.token, {
            aktivan: true,
          })
        )
        .then((response) => {
          var registri = response.data._embedded.registars;
          if (registri.length === 1) {
            let id = registri[0].id;
            return (window.location.href = "/registri/" + id);
          } else {
            return (window.location.href = "/registri");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      showMessage(Labels.TITLE_MESSAGES_NEISPRAVNO_KORISNICKO_IME_LOZINKA, MessageType.ERROR, "");
    }
  };

  const showMessage = (summary: string, severity: string, detail: string) => {
    messages.current.replace({
      severity: severity,
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const logIn = () => {
    if (username.trim() === "") {
      showMessage(Labels.TITLE_MESSAGES_NISTE_UNELI_KORISNICKO_IME, MessageType.ERROR, "");
      return;
    }
    if (password.trim() === "") {
      showMessage(Labels.TITLE_MESSAGES_NISTE_UNELI_SIFRU, MessageType.ERROR, "");
      return;
    }
    axios
      .post(Endpoint.LOGIN, {
        username: username,
        password: password,
      })
      .then((response) => {
        responseLogIn(response.data.ulogovaniRadnik);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      logIn();
    }
  };

  return (
    <div className="layout-login">
      <Card>
        <div className="grid fluid grid-nogutter dir-col">
          <div className="grid align-content-end mb-2">
            <div className="col-3">
              <img className="img_logo" src={logo} alt="logo" />
            </div>
            <div className="app-name col-9">{nazivAplikacije}</div>
          </div>
          <div className="col-12">
            <Messages ref={messages} />
          </div>
          <div className="p-inputgroup mb-2">
            <span className="p-inputgroup-addon">
              <i className="pi pi-user" />
            </span>
            <InputText
              onKeyDown={handleKeyDown}
              placeholder={Labels.PLACEHOLDER_KORISNICKO_IME}
              value={username}
              onChange={(e: any) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="p-inputgroup mb-2">
            <span className="p-inputgroup-addon">
              <i className="pi pi-lock" />
            </span>
            {showPasswordType && (
              <>
                <Password
                  onKeyDown={handleKeyDown}
                  placeholder={Labels.PLACEHOLDER_SIFRA}
                  feedback={false}
                  value={password}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                  }}
                />
                <span onClick={() => setShowPasswordType(!showPasswordType)} className="p-inputgroup-addon">
                  <i className="pi pi-eye-slash cursor-pointer"></i>
                </span>
              </>
            )}
            {!showPasswordType && (
              <>
                <InputText
                  onKeyDown={handleKeyDown}
                  value={password}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                  }}
                />
                <span onClick={() => setShowPasswordType(!showPasswordType)} className="p-inputgroup-addon">
                  <i className="pi pi-eye cursor-pointer"></i>
                </span>
              </>
            )}
          </div>
          <Button label={Labels.BUTTON_PRIJAVI_SE} icon="pi pi-sign-in" className="p-button-danger w-full block text-xl" onClick={logIn}></Button>
        </div>
      </Card>
    </div>
  );
}
