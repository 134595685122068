import axios from "axios";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useRef, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig } from "../../../utils/Utils";
import { AppContext } from "../../Store";

export default function Upozorenja() {
  const { setUpozorenje, authData } = useContext(AppContext);

  const [upozorenjeLocal, setUpozorenjeLocal] = useState("");
  const messages = useRef<any>(null);

  const snimiUpozorenje = () => {
    if (!upozorenjeLocal) {
      showMessage(Labels.TITLE_PANEL_UPOZORENJE, MessageType.ERROR, Labels.MESSAGES_UPOZORENJE);
      return;
    }
    axios
      .post(
        Endpoint.SNIMI_UPOZORENJE,
        {
          message: upozorenjeLocal,
        },
        axiosConfig(authData.token)
      )
      .then((response) => {
        showMessage(Labels.TITLE_PANEL_UPOZORENJE, MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO);
        setUpozorenje(upozorenjeLocal);
      })
      .catch((error) => {
        showMessage(Labels.TITLE_PANEL_UPOZORENJE, MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA);
        console.log(error);
      });
  };

  const showMessage = (summary: string, severity: string, detail: string) => {
    messages.current.replace({
      severity: severity,
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const obrisiUpozorenje = () => {
    axios
      .post(Endpoint.OBRISI_UPOZORENJE, {}, axiosConfig(authData.token))
      .then((response) => {
        showMessage(Labels.TITLE_PANEL_UPOZORENJE, MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO);

        setUpozorenjeLocal("");
        setUpozorenje("");
      })
      .catch((error) => {
        showMessage(Labels.TITLE_PANEL_UPOZORENJE, MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA);
      });
  };

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_SACUVAJ} icon="pi pi-save" className="p-button-success" onClick={snimiUpozorenje} />
      <Button label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={obrisiUpozorenje} />
    </React.Fragment>
  );

  return (
    <div className="layout-role">
      <div className="col-12">
        <Panel header={Labels.TITLE_PANEL_UPOZORENJE} className="panel-roles-list">
          <Messages ref={messages} />
          <Toolbar left={leftContents} />
          <InputTextarea rows={5} cols={100} className="mt-2" value={upozorenjeLocal} onChange={(event: any) => setUpozorenjeLocal(event.target.value)} />
        </Panel>
      </div>
    </div>
  );
}
