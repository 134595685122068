import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Toolbar } from "primereact/toolbar";
import React, { useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import CrudStavkaUnosa from "./CrudStavkaUnosa";
import { AppContext } from "../../Store";

export default function CrudStavkeUnosaList() {
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedStavkaUnosa, setSelectedStavkaUnosa] = useState<any>();
  const [stavkeUnosaList, setStavkeUnosaList] = useState();

  const [entityOperation, setEntityOperation] = useState<string>("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [stavkaUnosaToChange, setStavkaUnosaToChange] = useState<any>();
  const { id } = useParams<{ id?: string }>();
  const { authData } = useContext(AppContext);

  const messages = useRef<any>(null);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    if (id) {
      axios
        .get(
          Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findByTipSkupaAtributaAndAktivanOrderByRedosledAsc",
          axiosConfig(authData.token, { tipSkupaAtributa: `${Endpoint.TIP_HIJERARHIJE_ATRIBUTA}/${id}`, aktivan: true, page: 0, size: 50000000 })
        )
        .then((responseSpisakStavkiUnosa) => {
          setStavkeUnosaList(responseSpisakStavkiUnosa.data._embedded.atributUTipuSkupas);
        });
    }
  };

  const showMessage = (summary: string, severity: string, detail: string) => {
    messages.current.replace({
      severity: severity,
      summary: summary,
      detail: detail,
      closable: true,
      sticky: true,
    });
  };

  const createStavkaUnosa = (stavkaUnosa: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.ATRIBUT_U_TIPU_SKUPA, stavkaUnosa, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showMessage(Labels.TITLE_MESSAGES_USPESNO_KREIRANO, MessageType.SUCCESS, stavkaUnosa.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateStavkaUnosa = (stavkaUnosa: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(Endpoint.ATRIBUT_U_TIPU_SKUPA + `/${stavkaUnosa.id}`, stavkaUnosa, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showMessage(Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, MessageType.SUCCESS, stavkaUnosa.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteStavkaUnosa = (stavkaUnosa: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(Endpoint.ATRIBUT_U_TIPU_SKUPA + `/${stavkaUnosa.id}`, { aktivan: false }, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showMessage(Labels.TITLE_MESSAGES_USPESNO_OBRISANO, MessageType.SUCCESS, stavkaUnosa.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const dialogHeader = () => {
    const display = stavkaUnosaToChange ? stavkaUnosaToChange.naziv : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_POLJA_FORMULARA;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_POLJA_FORMULARA + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_POLJA_FORMULARA + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_POLJA_FORMULARA + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedStavkaUnosa;
        break;
    }
    setEntityOperation(entityOperation);
    setStavkaUnosaToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setDisplayDialog(false);
    setSelectedStavkaUnosa(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => closeDialog();

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
      <Button disabled={!selectedStavkaUnosa} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openDialog(EntityOperation.UPDATE)} />
      <Button disabled={!selectedStavkaUnosa} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => <Button disabled={!selectedStavkaUnosa} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openDialog(EntityOperation.DELETE)} />;

  return (
    <div>
      <div className="col-12">
        <Messages ref={messages} />
      </div>
      <Toolbar left={leftContents} right={rightContents} />
      <DataTable
        value={stavkeUnosaList}
        paginator={true}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
        rows={15}
        rowsPerPageOptions={[5, 10, 15, 20]}
        selectionMode="single"
        selection={selectedRow}
        onSelectionChange={(e) => setSelectedRow(e.value)}
        onRowSelect={(e) => setSelectedStavkaUnosa(e.data)}
        alwaysShowPaginator={false}
        emptyMessage={Labels.LABEL_NEMA_PODATAKA}
      >
        <Column className="column-align-left column-width-200px" field={"redosled"} header={Labels.COLUMN_HEADER_PRIKAZNI_REDNOSLED} filter sortable />
        <Column className="column-align-left" field={"sifra"} header={Labels.COLUMN_HEADER_SIFRA} filter sortable />
        <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filter sortable />
        <Column className="column-align-left" field={"tipAtributaNaziv"} header={Labels.COLUMN_HEADER_TIP_ATRIBUTA_NAZIV} filter sortable />
        <Column className="column-align-left" field={"nadredjeniAtributUTipuSkupaNaziv"} header={Labels.COLUMN_HEADER_NADREDJENI_ATRIBUT_U_TIPU_SKUPA_NAZIV} filter sortable />
      </DataTable>

      <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }}>
        <CrudStavkaUnosa
          stavkaUnosaOperation={entityOperation}
          stavkaUnosa={stavkaUnosaToChange}
          onCreateStavkaUnosa={createStavkaUnosa}
          onUpdateStavkaUnosa={updateStavkaUnosa}
          onDeleteStavkaUnosa={deleteStavkaUnosa}
          onCancel={onCancel}
        />
      </Dialog>
    </div>
  );
}
