import React from "react";

class Tab extends React.Component {
  render() {
    let autsi = [];
    if (this.props.autsiPodredjeni[this.props.auts.id]) {
      autsi = this.props.autsiPodredjeni[this.props.auts.id];
    }
    let grupaAuts = <div />;
    if (autsi && this.props.defaultPodTab && this.props.defaultPodTab === this.props.auts.id) {
      grupaAuts = autsi.map((auts, a) => {
        let prikaz = this.props.prikazivanjeAutsa(auts);
        if (prikaz && auts.prikazujeSe) {
          return <div key={a}>{this.props.prikazivanjeJednogAutsa(auts, this.props.autsiPodredjeni)}</div>;
        } else {
          return <div key={a} />;
        }
      });
    }
    return <div>{grupaAuts}</div>;
  }
}

export default Tab;
