import React from "react";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { brojRezultatePretrageOptions, findBrojRezultataByCode } from "../../../../utils/EnumBrojRezultataPretrage";

interface SkupovniProps {
  label: string;
  naziv: string;
  vrednost: number;
  onChange: Function;
}

export default function Skupovni(props: SkupovniProps) {
  const { naziv, label, vrednost, onChange } = props;

  const onChangeLocal = (e: DropdownChangeParams) => {
    onChange({ naziv: naziv, vrednost: e.target.value.code, label: label });
  };

  return (
    <div className="xl-12 lg-12 md-12 sm-12">
      <div className="grid align-center ">
        <div className="col-8">{label}</div>
        <div className="col-4">
          <Dropdown name="brojRezultatePretrage" value={findBrojRezultataByCode(vrednost)} options={brojRezultatePretrageOptions} onChange={onChangeLocal} optionLabel="name" />
        </div>
      </div>
    </div>
  );
}
