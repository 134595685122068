import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useRef, useState } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import CrudUstanova from "./CrudUstanova";

export default function CrudUstanovaList() {
  const [messages, setMessages] = useState<Messages>();
  const [ustanoveList, setUstanoveList] = useState();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [ustanovaToChange, setUstanovaToChange] = useState<any>();
  const [selectedUstanova, setSelectedUstanova] = useState();
  const [entityOperation, setEntityOperation] = useState<string>("");
  const { authData } = useContext(AppContext);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axios
      .get(
        Endpoint.USTANOVA + "/search/findByAktivan",
        axiosConfig(authData.token, {
          page: 0,
          size: 50000000,
          aktivan: true,
        })
      )
      .then((ustanovaCollection) => {
        setUstanoveList(ustanovaCollection.data._embedded.ustanovas);
      });
  };

  const showSuccess = (summary: string, detail: string) => {
    if (messages) {
      messages.replace({
        severity: "success",
        summary: summary,
        detail: detail,
        closable: true,
        sticky: true,
      });
    }
  };

  const createUstanova = (ustanova: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.USTANOVA, ustanova, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showSuccess(Labels.TITLE_MESSAGES_USPESNO_KREIRANO, ustanova.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateUstanova = (ustanova: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .put(Endpoint.USTANOVA + `/${ustanova.id}`, ustanova, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showSuccess(Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, ustanova.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteUstanova = (ustanova: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(
          Endpoint.USTANOVA + `/${ustanova.id}`,
          {
            aktivan: false,
          },
          axiosConfig(authData.token)
        )
        .then(() => {
          closeDialog();
          showSuccess(Labels.TITLE_MESSAGES_USPESNO_OBRISANO, ustanova.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const dialogHeader = () => {
    const display = ustanovaToChange ? ustanovaToChange.naziv : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_USTANOVE;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_USTANOVE + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_USTANOVE + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_USTANOVE + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedUstanova;
        break;
    }
    setEntityOperation(entityOperation);
    setUstanovaToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
    setSelectedUstanova(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => closeDialog();

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_KREIRAJ}
          icon="pi pi-save"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_IZMENI}
          icon="pi pi-inbox"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_OBRISI}
          icon="pi pi-inbox"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />;

  const dialogFooter = () => <Toolbar left={leftContentsDialog} right={rightContentsDialog} />;

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
      <Button disabled={!selectedUstanova} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openDialog(EntityOperation.UPDATE)} />
      <Button disabled={!selectedUstanova} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => <Button disabled={!selectedUstanova} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openDialog(EntityOperation.DELETE)} />;

  return (
    <div className="layout-institution">
      <div className="col-12">
        <Messages
          ref={(el: any) => {
            setMessages(el);
          }}
        />
      </div>
      <Panel header={Labels.TITLE_PANEL_CRUD_USTANOVA} className="panel-institution-list">
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            filterDisplay="row"
            value={ustanoveList}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedUstanova(e.data)}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left column-width-percent-35" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"sifra"} header={Labels.COLUMN_HEADER_SIFRA} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="p-sortable-column" field={"adresa"} header={Labels.COLUMN_HEADER_ADRESA} filterMatchMode="contains" showFilterMenu={false} filter />
            <Column className="p-sortable-column" field={"telefon"} header={Labels.COLUMN_HEADER_TELEFON} filterMatchMode="contains" showFilterMenu={false} filter />
          </DataTable>
          <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }} footer={dialogFooter()}>
            <CrudUstanova
              dialogRef={dialogRef}
              index={index}
              setIndex={setIndex}
              ustanovaOperation={entityOperation}
              ustanova={ustanovaToChange}
              onCreateUstanova={createUstanova}
              onUpdateUstanova={updateUstanova}
              onDeleteUstanova={deleteUstanova}
              onCancel={onCancel}
            />
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
