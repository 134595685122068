import moment from "moment";
import { EffectCallback, useEffect } from "react";
import EntityOperation from "../infrastructure/EnumEntityOperation";

const axiosConfig = (token: any, params?: any) => {
  return {
    headers: {
      Authorization: "Regix " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const isFormDisabled = (operation: string) => {
  let returnValue = true;

  if (!operation) return true;

  switch (operation) {
    case EntityOperation.CREATE:
    case EntityOperation.UPDATE:
      returnValue = false;
      break;
    case EntityOperation.READ:
    case EntityOperation.DELETE:
      returnValue = true;
      break;
    default:
      returnValue = true;
      break;
  }

  return returnValue;
};

const DATE_FORMAT = "DD.MM.YYYY.";
const DATE_TIME_FORMAT = "DD.MM.YYYY. HH:mm";
const NEW_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
const CALENDAR_DATE_FORMAT = "dd.mm.yy.";
const TIME_FORMAT = "HH:mm";
const TIME_FORMAT_SHORT = "h:mm";
const TIME_FORMAT_WITH_SECONDS = "HH:mm:ss";

const dateAndTime = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_FORMAT + " u " + TIME_FORMAT_SHORT);
  } else {
    return "Loše zadat datum";
  }
};

const date = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(DATE_FORMAT);
  } else {
    return "Loše zadat datum";
  }
};

const time = (UTCDate: string) => {
  var timestamp = Date.parse(UTCDate);
  if (isNaN(timestamp) === false) {
    var date = new Date(timestamp);
    return moment(date).format(TIME_FORMAT_SHORT);
  } else {
    return "Loše zadat datum";
  }
};

const imageExtension = (base64: string) => {
  if (base64) {
    switch (base64.charAt(0)) {
      case "/":
        return "jpg";
      case "i":
        return "png";
      case "R":
        return "gif";
      case "U":
        return "webp";
    }
  }
};

const getImage = (source: string, replaceImage: any) => {
  return source ? `data:image/${imageExtension(source)};base64,${source}` : replaceImage;
};

const useEffectOnce = (effect: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

let registarID = JSON.parse(localStorage.getItem("odabraniRegistar") || "null");

const setRegistarID = (newRegistar: string) => {
  registarID = newRegistar;
};
export {
  dateAndTime,
  date,
  time,
  axiosConfig,
  isFormDisabled,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  NEW_DATE_TIME_FORMAT,
  CALENDAR_DATE_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT_WITH_SECONDS,
  getImage,
  useEffectOnce,
  registarID,
  setRegistarID,
};
