import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState, useContext, useImperativeHandle } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { isFormDisabled } from "../../../utils/Utils";
import MessageType from "../../../infrastructure/MessageType";
import { AppContext } from "../../Store";

interface CrudKategorijeIzvestajaProps {
  kategorijaIzvestajaOperation: string;
  kategorijaIzvestaja?: any;
  onCreateKategorijaIzvestaja: Function;
  onUpdateKategorijaIzvestaja: Function;
  onDeleteKategorijaIzvestaja: Function;
  onCancel: Function;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudKategorijeIzvestaja(props: CrudKategorijeIzvestajaProps) {
  const { kategorijaIzvestajaOperation, kategorijaIzvestaja, onCreateKategorijaIzvestaja, onUpdateKategorijaIzvestaja, onDeleteKategorijaIzvestaja, dialogRef, setIndex, index } = props;
  const [kategorijaIzvestajaChange, setKategorijaIzvestajaChange] = useState<Partial<any>>(kategorijaIzvestaja);
  const { showGrowlMessage } = useContext(AppContext);

  const validateInput = (message: string) => {
    if (!kategorijaIzvestajaChange || !kategorijaIzvestajaChange.naziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    if (!kategorijaIzvestajaChange || !kategorijaIzvestajaChange.numerickaVrednost) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_NUMERICKA_VREDNOST);
      return false;
    }
    return true;
  };

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
        return;
      }
      onCreateKategorijaIzvestaja(kategorijaIzvestajaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
        });
    },
    onUpdate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
        return;
      }
      onUpdateKategorijaIzvestaja(kategorijaIzvestajaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
        });
    },
    onDelete: () => {
      onDeleteKategorijaIzvestaja(kategorijaIzvestajaChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    },
  }));

  return (
    <div className="panel-report">
      <TabView
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.TAB_KATEGORIJA_IZVESTAJA}>
          <div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NAZIV}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(kategorijaIzvestajaOperation)}
                    value={kategorijaIzvestajaChange && kategorijaIzvestajaChange.naziv ? kategorijaIzvestajaChange.naziv : ""}
                    onChange={(e: any) => {
                      setKategorijaIzvestajaChange({
                        ...kategorijaIzvestajaChange,
                        naziv: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NUMERICKA_VREDNOST}</div>
                <div className="col-8">
                  <InputNumber
                    disabled={isFormDisabled(kategorijaIzvestajaOperation)}
                    value={kategorijaIzvestajaChange && kategorijaIzvestajaChange.numerickaVrednost ? kategorijaIzvestajaChange.numerickaVrednost : null}
                    onValueChange={(e: any) => {
                      setKategorijaIzvestajaChange({
                        ...kategorijaIzvestajaChange,
                        numerickaVrednost: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
