import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState, useRef } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig, isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import CrudEnumStavka from "./CrudEnumStavka";

interface CrudEnumStavkaListProps {
  tipAtributaId: string;
  tipAtributaOperation: string;
}

export default function CrudEnumStavkaList(props: CrudEnumStavkaListProps) {
  const { tipAtributaId, tipAtributaOperation } = props;
  const [enumStavkaList, setEnumStavkaList] = useState();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [enumStavkaToChange, setEnumStavkaToChange] = useState<any>();
  const [selectedEnumStavka, setSelectedEnumStavka] = useState();
  const [entityOperation, setEntityOperation] = useState<string>("");
  const { showGrowlMessage, authData } = useContext(AppContext);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axios
      .get(
        Endpoint.ENUM_STAVKA + "/search/findByTipAtributaAndAktivanOrderByRedosled",
        axiosConfig(authData.token, {
          aktivan: true,
          tipAtributa: Endpoint.TIP_ATRIBUTA + "/" + tipAtributaId,
        })
      )
      .then((enumStavkaCollection) => {
        setEnumStavkaList(enumStavkaCollection.data._embedded.enumStavkas);
      });
  };

  const createEnumStavka = (enumStavka: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.ENUM_STAVKA, enumStavka, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO, enumStavka.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateEnumStavka = (enumStavka: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .put(Endpoint.ENUM_STAVKA + `/${enumStavka.id}`, enumStavka, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, enumStavka.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteEnumStavka = (enumStavka: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(
          Endpoint.ENUM_STAVKA + `/${enumStavka.id}`,
          {
            aktivan: false,
          },
          axiosConfig(authData.token)
        )
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO, enumStavka.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const dialogHeader = () => {
    const display = enumStavkaToChange ? enumStavkaToChange.naziv : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_ENUM_STAVKE;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_ENUM_STAVKE + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_ENUM_STAVKE + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_ENUM_STAVKE + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedEnumStavka;
        break;
    }
    setEntityOperation(entityOperation);
    setEnumStavkaToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
    setSelectedEnumStavka(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => {
    closeDialog();
  };

  const leftContents = () => (
    <React.Fragment>
      <Button disabled={isFormDisabled(tipAtributaOperation)} label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
      <Button
        disabled={!selectedEnumStavka || isFormDisabled(tipAtributaOperation)}
        label={Labels.BUTTON_IZMENI}
        icon="pi pi-upload"
        className="p-button-warning"
        onClick={() => openDialog(EntityOperation.UPDATE)}
      />
      <Button disabled={!selectedEnumStavka} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => (
    <Button
      disabled={!selectedEnumStavka || isFormDisabled(tipAtributaOperation)}
      label={Labels.BUTTON_OBRISI}
      icon="pi pi-inbox"
      className="p-button-danger"
      onClick={() => openDialog(EntityOperation.DELETE)}
    />
  );

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-save" className="p-button-success" onClick={() => dialogRef.current.onCreate()} />}
      {entityOperation === EntityOperation.UPDATE && <Button label={Labels.BUTTON_IZMENI} icon="pi pi-inbox" className="p-button-warning" onClick={() => dialogRef.current.onUpdate()} />}
      {entityOperation === EntityOperation.DELETE && <Button label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => dialogRef.current.onDelete()} />}
    </React.Fragment>
  );

  const rightContentsDialog = () => <Button label={Labels.BUTTON_ODUSTANI} icon="pi pi-times" className="p-button-danger" onClick={onCancel} />;

  const dialogFooter = () => <Toolbar left={leftContentsDialog} right={rightContentsDialog} />;

  return (
    <div className="layout-attribute">
      <Panel header={Labels.TITLE_PANEL_CRUD_ENUM_STAVKA} className="panel-attribute-list">
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            filterDisplay="row"
            value={enumStavkaList}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => setSelectedEnumStavka(e.data)}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"numVrednost"} header={Labels.COLUMN_HEADER_NUM_VREDNOST} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column className="column-align-left" field={"sifraVeza"} header={Labels.COLUMN_HEADER_SIFRA_VEZA} filterMatchMode="contains" showFilterMenu={false} filter sortable />
          </DataTable>
          <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }} footer={dialogFooter()}>
            <CrudEnumStavka
              setIndex={setIndex}
              index={index}
              dialogRef={dialogRef}
              enumStavkaOperation={entityOperation}
              enumStavka={enumStavkaToChange}
              onCreateEnumStavka={createEnumStavka}
              onUpdateEnumStavka={updateEnumStavka}
              onDeleteEnumStavka={deleteEnumStavka}
              onCancel={onCancel}
              tipAtributaId={tipAtributaId}
            />
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
