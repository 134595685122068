import axios from "axios";
import React, { useContext, useEffect } from "react";
import Endpoint from "../endpoints/Endpoint";
import { axiosConfig } from "../utils/Utils";
import { AppContext } from "./Store";

export default function Message() {
  const { upozorenje, setUpozorenje, authData } = useContext(AppContext);

  useEffect(() => {
    if (authData.token) {
      axios
        .get(Endpoint.GET_UPOZORENJE, axiosConfig(authData.token))
        .then((response) => {
          if (response.data.message) {
            setUpozorenje(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  if (!upozorenje) {
    return null;
  }

  return (
    <div className="card">
      <div className="card-body">
        <span className="blink_me">{upozorenje}</span>
      </div>
    </div>
  );
}
