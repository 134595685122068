import React, { useState, useContext, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { TabView, TabPanel } from "primereact/tabview";
import { getObjectOperator, objectAndOr } from "./../../utils/AUTSOperator";
import axios from "axios";
import Endpoint from "../../endpoints/Endpoint";
import { axiosConfig, NEW_DATE_TIME_FORMAT, DATE_FORMAT, CALENDAR_DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT, TIME_FORMAT_WITH_SECONDS, registarID, useEffectOnce } from "../../utils/Utils";
import { AppContext } from "../Store";
import Routers from "../../utils/Routers";
import TipAtributaRouter from "./../hijerarhija/TipAtributaRouter";
import { mapDataUNedelji } from "./../../utils/SerbianFormat";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import moment from "moment";
import MessageType from "../../infrastructure/MessageType";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EnumTipovaParametraStatistickogIzvestaja from "../../utils/EnumTipovaParametraStatistickogIzvestaja";
import { RegistarController } from "../../controllers/RegistarController";

interface ParametriProps {
  propsParametri: any;
  skupovniParametri: any;
  statistickiIzvestaj: any;
  idKategorijaStatistickogIzvestaja: any;
  propsChangeState?: any;
  openStatistickiIzvestaj: any;
}

const TAB_PARAMETRI = 0;
const TAB_GENERISANI_IZVESTAJI = 1;
const TAB_ZAKAZNI_IZVESTAJI = 2;

export default function NewParametri(props: ParametriProps) {
  const { propsParametri, skupovniParametri, statistickiIzvestaj, idKategorijaStatistickogIzvestaja, propsChangeState, openStatistickiIzvestaj } = props;
  const { authData, showGrowlMessage } = useContext(AppContext);
  const history = useHistory();
  const [tableDataGenerisani, setTableDataGenerisani] = useState(undefined);
  const [tableDataZakazani, setTableDataZakazani] = useState([]);
  const [tabViewIndex, setTabViewIndex] = useState(TAB_PARAMETRI);
  const { axiosEnumStavkaByAUTS, axiosAtributUTipuSkupaFindTVAByAUTS } = RegistarController();
  const idKategorijaStatistickogIzvestajaRef = useRef<String | undefined>(idKategorijaStatistickogIzvestaja);
  const initState = {
    fields: {},
    parametri: {},
    emails: [],
    checkZakazivanjeBuducnost: false,
    period: 0,
    periodOd: null,
    periodDo: null,
    datumVreme: null,
    vreme: null,
    ponavljanje: 0,
    danNedelja: [],
    danMesec: [],
    zakazivanjeIzvestajaIzmenaID: null,
    zakazivanjeIzvestajaFutureID: null,
    ipAdresa: undefined,
    redPretrageElementi: {},
    prikazTekucihUnosaKodPretrage: false,
    autsiPoTSA: {},
    autsiPoTSAFull: {},
    operatoriPoTSA: {},
    initValue: {},
    enumiPoAUTS: {},
    brojRedova: 0,
  };
  const [state, setState] = useState<any>(initState);

  const inputTextHidden = [EnumTipovaParametraStatistickogIzvestaja.HIDDEN, EnumTipovaParametraStatistickogIzvestaja.DYNAMIC_HIDDEN];
  const inputText = [EnumTipovaParametraStatistickogIzvestaja.TEKSTUALNI, EnumTipovaParametraStatistickogIzvestaja.NUMERIČKI, ...inputTextHidden];

  useEffectOnce(() => {
    const requestParametar = axios.get(Endpoint.PARAMETAR + "/izvestaji", axiosConfig(authData.token));
    const requestIzvestaj = axios.get(`${Endpoint.GET_IZVESTAJ_RESPONSE}/${idKategorijaStatistickogIzvestaja}`, axiosConfig(authData.token));
    axios
      .all([requestParametar, requestIzvestaj])
      .then(
        axios.spread((responseParametar: any, responseIzvestaj: any) => {
          let idRadnika = authData.id;
          let urlWebSocket = Endpoint.WS_URL + "/izvestajiWebSocket/websocket?radnikID=" + idRadnika;
          const client = new W3CWebSocket(urlWebSocket);
          client.onopen = () => {
            console.log("WebSocket Client Connected");
          };
          client.onmessage = (message: any) => {
            const results = JSON.parse(message.data);
            handleData(results);
          };
          setState({
            ...state,
            ipAdresa: responseParametar.data.ipAdresa,
            prikazTekucihUnosaKodPretrage: responseParametar.data.prikazTekucihUnosaKodPretrage,
          });
          handleData(responseIzvestaj.data);
        })
      )
      .catch((error) => {
        console.log(error);
      });

    if (registarID === null) {
      return history.push(Routers.REGISTRI);
    }
    getAllTipSkupaAtributa();
    getAllAtributUTipuSkupa();
  });

  useEffect(() => {
    if (idKategorijaStatistickogIzvestaja && tableDataGenerisani && idKategorijaStatistickogIzvestajaRef.current !== idKategorijaStatistickogIzvestaja) {
      idKategorijaStatistickogIzvestajaRef.current = idKategorijaStatistickogIzvestaja;
      axios
        .get(`${Endpoint.GET_IZVESTAJ_RESPONSE}/${idKategorijaStatistickogIzvestaja}`, axiosConfig(authData.token))
        .then((response) => {
          handleData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [idKategorijaStatistickogIzvestaja]);

  const responseAUTS = (response: any, key: number, redoviPretrage: any) => {
    const autsiPoTSASvi = state.autsiPoTSA;
    const autsiPoTSAFullSvi = state.autsiPoTSAFull;
    const filterOption = response.data._embedded.atributUTipuSkupas.filter((x: any) => x.tipVrednostiAtributaNumVrednost <= 5);
    const optionsAUTS = filterOption.map((atributUTipuSkupa: any, auts: any) => {
      let key = atributUTipuSkupa.id;
      let naziv = atributUTipuSkupa.naziv + (!state.prikazTekucihUnosaKodPretrage ? " (" + atributUTipuSkupa.tipSkupaAtributaNaziv + ")" : "");
      return { value: key, label: naziv };
    });

    autsiPoTSASvi[key] = optionsAUTS;
    autsiPoTSAFullSvi[key] = response.data._embedded.atributUTipuSkupas;
    setState({
      ...state,
      redPretrageElementi: redoviPretrage,
      autsiPoTSA: autsiPoTSASvi,
      autsiPoTSAFull: autsiPoTSAFullSvi,
    });
  };

  const getAllAtributUTipuSkupa = () => {
    axios
      .get(Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findAtributUTipuSkupaByRegistar", axiosConfig(authData.token, { registar: Endpoint.REGISTAR + "/" + registarID }))
      .then((response) => {
        responseAUTS(response, 0, {});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllTipSkupaAtributa = () => {
    axios
      .get(Endpoint.TIP_SKUPA_ATRIBUTA + "/search/findTipSkupaAtributaByRegistar", axiosConfig(authData.token, { registar: Endpoint.REGISTAR + "/" + registarID }))
      .then((response) => {
        const optionsTHA = response.data._embedded.tipHijerarhijeAtributas.map((tipHijerarhijeAtributa: any) => {
          let key = tipHijerarhijeAtributa.id;
          let naziv = tipHijerarhijeAtributa.naziv;
          return { value: key, label: naziv };
        });
        setState({ ...state, tipoviSkupaAtributa: optionsTHA });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (name: number, value: any, tip: number) => {
    const svi = state.fields;
    const paramSvi: any = state.parametri;
    const valueParam = [];
    svi[name] = value;
    const valParam: any = state.parametri;
    if (tip === EnumTipovaParametraStatistickogIzvestaja.SKUPOVNI) {
      if (Array.isArray(value)) {
        Object.keys(value).map((key: any) => {
          const element = value[key];
          valueParam.push(element.value + " - " + element.label);
        });
      } else {
        valueParam.push(value.label + " - " + value.label);
      }
    } else if (tip === EnumTipovaParametraStatistickogIzvestaja.DATUMSKI) {
      const date = new Date(value);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      value = date;
    }

    //kad se snimi front posalje 03.25 a back ga pretvori u 03.24 22:00 zasto?
    valParam[name] = value;
    paramSvi[name] = valueParam.length > 0 ? valueParam : value;
    setState({ ...state, fields: svi, parametri: paramSvi });
  };

  const dodajRedPretrage = () => {
    const brReda = state.brojRedova + 1;
    const redoviPretrage = state.redPretrageElementi;
    redoviPretrage[brReda] = {};
    setState({ ...state, redPretrageElementi: redoviPretrage, brojRedova: brReda });
  };

  const changeOperator = (key: any, value: any) => {
    const label = value.originalEvent.target.innerText;
    let redoviPretrage = state.redPretrageElementi;
    Object.assign(redoviPretrage[key], { operator: { value: value.value, label: label } });
    setState({ ...state, redPretrageElementi: redoviPretrage });
  };

  const changeVeza2 = (key: string, value: boolean) => {
    const redoviPretrage = state.redPretrageElementi;
    const redPretrage = redoviPretrage[key];
    const vezaValue = value ? { value: true, label: "I" } : { value: false, label: "ILI" };
    redPretrage.veza = vezaValue;
    setState({ ...state, redPretrageElementi: redoviPretrage });
  };

  const changeVeza = (key: any, value: any) => {
    const label = value.originalEvent.target.innerText;
    let redoviPretrage = state.redPretrageElementi;
    Object.assign(redoviPretrage[key], { veza: { label: label, value: value.value } });
    setState({ ...state, redPretrageElementi: redoviPretrage });
  };

  const changeTipaSkupaAtributa = (key: any, value: any) => {
    const redoviPretrage: any = state.redPretrageElementi;
    const redPretrage = redoviPretrage[key];
    redPretrage.tipSkupaAtributa = value;
    redoviPretrage[key] = redPretrage;

    axios
      .get(
        Endpoint.ATRIBUT_U_TIPU_SKUPA + "/search/findByTipSkupaAtributaAndAktivanZaPretraguOrderByRedosledAsc",
        axiosConfig(authData.token, { tipSkupaAtributa: Endpoint.TIP_SKUPA_ATRIBUTA + "/" + value.value })
      )
      .then((response) => {
        responseAUTS(response, key, redoviPretrage);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeAtributUTipuSkupa = (key: any, value: any) => {
    let redoviPretrage = state.redPretrageElementi;
    Object.assign(redoviPretrage[key], { atributUTipuSkupa: { value: value } });
    setState({ ...state, redPretrageElementi: redoviPretrage });
    axiosAtributUTipuSkupaFindTVAByAUTS({
      atributUTipuSkupa: Endpoint.ATRIBUT_U_TIPU_SKUPA + "/" + value,
    })
      .then((response: any) => {
        let tipVrednosti = response.data._embedded.tipVrednostiAtributas[0];
        Object.assign(redoviPretrage[key], { tipVrednosti: tipVrednosti });
        setState({ ...state, redPretrageElementi: redoviPretrage });
        let objectOperator = getObjectOperator(tipVrednosti.numerickaVrednost);
        let operatoriPoTSASvi = state.operatoriPoTSA;
        operatoriPoTSASvi = { ...operatoriPoTSASvi, ...{ [key]: objectOperator } };
        let initValueSvi = state.initValue;
        initValueSvi = {
          ...initValueSvi,
          ...{ [key]: "" },
        };
        getEnumStavkaByAUTS(value, initValueSvi, operatoriPoTSASvi);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const getEnumStavkaByAUTS = (value: any, initValueSvi: any, operatoriPoTSASvi: any) => {
    axiosEnumStavkaByAUTS({ auts: Endpoint.ATRIBUT_U_TIPU_SKUPA + "/" + value })
      .then((response: any) => {
        setState({ ...state, initValue: initValueSvi });
        setState({
          ...state,
          operatoriPoTSA: operatoriPoTSASvi,
          enumiPoAUTS: response.data._embedded.enumStavkas,
        });
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const obrisiRedPretrage = (key: string) => {
    delete state.redPretrageElementi[key];
    setState({
      ...state,
    });
  };

  const changeState = (stateName: any, stateKey: any, stateValue: any) => {
    if (Array.isArray(stateName)) {
      stateName.map((elem: any) => {
        const nameObj = elem[0]; //initValue
        let stateOne = state[nameObj];
        if (stateOne !== undefined) {
          stateOne = { ...stateOne, ...{ [elem[1]]: elem[2] } };
          setState({ ...state, [nameObj]: stateOne });
        }
      });
    } else {
      let stateSvi = state[stateName];
      if (stateSvi !== undefined) {
        let key = parseInt(stateKey);
        Object.assign(stateSvi, { [key]: stateValue });
        setState({ ...state, [stateName]: stateSvi });
      }
    }
  };

  const getArrayEmail = (emailText: string) => (emailText?.length > 0 ? emailText.split(",") : []);

  const validiracijaParametara = (parametri: any) => {
    let greska = false;
    propsParametri.forEach((propsParametar: any) => {
      if (!propsParametar.parametarStatistickiIzvestaj.opcioni) {
        if (!parametri.hasOwnProperty(propsParametar.parametarStatistickiIzvestaj.id)) {
          greska = true;
        }
      }
    });
    if (greska) {
      showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, Labels.MESSAGES_PARAMETAR_OBAVEZAN);
      return false;
    }
    return true;
  };

  const generisiIzvestaj = () => {
    // delete empty params
    const tempParams = { ...state.parametri };
    Object.keys(tempParams).forEach((key) => {
      if (!tempParams[key] || tempParams[key]?.length === 0) {
        delete tempParams[key];
      }
    });

    if (!validiracijaParametara(state.parametri)) {
      return;
    }

    axios
      .post(
        Endpoint.DODAJ_IZVESTAJ_U_NIZ,
        {
          siID: statistickiIzvestaj.key,
          parametri: tempParams,
          radnikID: authData.id,
          emails: getArrayEmail(state.emails),
          checkZakazivanjeBuducnost: state.checkZakazivanjeBuducnost,
          period: state.period,
          periodOd: moment(state.periodOd).format(DATE_FORMAT),
          periodDo: moment(state.periodDo).format(DATE_FORMAT),
          datumVreme: moment(state.datumVreme).format(DATE_TIME_FORMAT),
          vreme: moment(state.vreme).format(TIME_FORMAT),
          ponavljanje: state.ponavljanje,
          danNedelja: state.danNedelja,
          danMesec: state.danMesec,
          zakazivanjeIzvestajaIzmenaID: state.zakazivanjeIzvestajaIzmenaID !== null ? state.zakazivanjeIzvestajaIzmenaID.toString() : "",
          zakazivanjeIzvestajaFutureID: state.zakazivanjeIzvestajaFutureID !== null ? state.zakazivanjeIzvestajaFutureID : "",
          redPretrage: state.redPretrageElementi,
          initValue: state.initValue,
        },
        axiosConfig(authData.token)
      )
      .then((response) => {
        setTabViewIndex(state.checkZakazivanjeBuducnost ? TAB_ZAKAZNI_IZVESTAJI : TAB_GENERISANI_IZVESTAJI);
        showGrowlMessage(MessageType.SUCCESS, state.checkZakazivanjeBuducnost ? Labels.MESSAGES_USPESNO_ZAKAZAN_IZVESTAJ_ZA_BUDUCNOST : Labels.MESSAGES_USPESNO_GENERISAN_IZVESTAJ, "");
      })
      .catch((error) => {
        showGrowlMessage(MessageType.ERROR, error.response ? error.response.data.message : error.message, "");
      });
  };

  const izmeniIzvestaj = () => {
    axios
      .post(
        Endpoint.DODAJ_IZVESTAJ_U_NIZ,
        {
          siID: statistickiIzvestaj.id,
          parametri: state.parametri,
          radnikID: authData.id,
          // emails: state.emails,
          emails: getArrayEmail,
          checkZakazivanjeBuducnost: state.checkZakazivanjeBuducnost,
          period: state.period,
          periodOd: moment(state.periodOd).format(DATE_FORMAT),
          periodDo: moment(state.periodDo).format(DATE_FORMAT),
          datumVreme: moment(state.datumVreme).format(DATE_TIME_FORMAT),
          vreme: moment(state.vreme).format(TIME_FORMAT),
          ponavljanje: state.ponavljanje,
          danNedelja: state.danNedelja,
          danMesec: state.danMesec,
          zakazivanjeIzvestajaIzmenaID: state.zakazivanjeIzvestajaIzmenaID !== null ? state.zakazivanjeIzvestajaIzmenaID.toString() : "",
          zakazivanjeIzvestajaFutureID: state.zakazivanjeIzvestajaFutureID !== null ? state.zakazivanjeIzvestajaFutureID : "",
          redPretrage: state.redPretrageElementi,
          initValue: state.initValue,
        },
        axiosConfig(authData.token)
      )
      .then((response) => {
        setTabViewIndex(state.checkZakazivanjeBuducnost ? TAB_ZAKAZNI_IZVESTAJI : TAB_GENERISANI_IZVESTAJI);
        showGrowlMessage(MessageType.SUCCESS, state.checkZakazivanjeBuducnost ? Labels.MESSAGES_USPESNO_IZMENJEN_ZAKAZANI_IZVESTAJ_ZA_BUDUCNOST : Labels.MESSAGES_USPESNO_GENERISAN_IZVESTAJ, "");
      })
      .catch((error) => {
        showGrowlMessage(MessageType.ERROR, error.response.data.message, "");
      });
  };

  const prekiniGenerisanje = (giID: any) => {
    axios
      .post(Endpoint.DELETE_CURRENT, { radnikStatistickiIzvestajID: giID }, axiosConfig(authData.token))
      .then((response) => {
        showGrowlMessage(MessageType.SUCCESS, response.data.poruka, "");
      })
      .catch((error) => {
        showGrowlMessage(MessageType.ERROR, Labels.MESSAGES_GRESKA_PRI_PREKIDU_GENERISANJA, "");
        console.log(error);
      });
  };

  const obrisiZakazaniIzvestaj = (giID: any, ziID: any) => {
    axios
      .post(
        Endpoint.DELETE_RADNIK_STATISTICKI_IZVESTAJ_FUTURE,
        {
          radnikStatistickiIzvestajID: giID,
          zakazanIzvestajID: ziID,
        },
        axiosConfig(authData.token)
      )
      .then((response) => {
        showGrowlMessage(MessageType.SUCCESS, Labels.MESSAGES_USPESNO_OBRISAN_ZAKAZAN_IZVESTAJ_ZA_BUDUCNOST, "");
      })
      .catch((error) => {
        showGrowlMessage(MessageType.ERROR, Labels.MESSAGES_GRESKA_PRI_BRISANJU_ZAKAZANOG_IZVESTAJA, "");
        console.log(error);
      });
  };

  const obrisiGenerisaniIzvestaj = (giID: any) => {
    axios
      .post(Endpoint.OBRISI_IZVESTAJ, { radnikStatistickiIzvestajID: giID }, axiosConfig(authData.token))
      .then((response) => {
        showGrowlMessage(MessageType.SUCCESS, Labels.MESSAGES_USPENSO_OBRISAN_GENERISANI_IZVESTAJ, "");
      })
      .catch((error) => {
        showGrowlMessage(MessageType.ERROR, Labels.MESSAGES_GRESKA_PRILIKOM_BRISANJA_GENERISANOG_IZVESTAJA, "");
        console.log(error);
      });
  };

  const izmeniZakazaniIzvestaj = (rsiKey: any, ziID: any) => {
    let zakazanIzvestaj: any = {};
    axios
      .get(Endpoint.ZAKAZIVANJE_IZVESTAJA + "/" + ziID, axiosConfig(authData.token))
      .then((response) => {
        zakazanIzvestaj = response.data;
        axios
          .get(Endpoint.ZAKAZIVANJE_IZVESTAJA + "/" + ziID + "/statistickiIzvestaj", axiosConfig(authData.token, { projection: "getWithRelatedObjects" }))
          .then((response) => {
            let statistickiIzvestaj = response.data.statistickiIzvestaj;
            statistickiIzvestaj = { ...statistickiIzvestaj, key: statistickiIzvestaj.id, label: statistickiIzvestaj.naziv };
            openStatistickiIzvestaj(statistickiIzvestaj);
            let danNedelja = zakazanIzvestaj.danNedelja
              ? zakazanIzvestaj.danNedelja.split(",").map((dan: any, d: any) => {
                  return { value: dan, label: objectDanUNedelji[dan] };
                })
              : [];
            let danMesec = zakazanIzvestaj.danMesec
              ? zakazanIzvestaj.danMesec.split(",").map((dan: any, d: any) => {
                  return { value: dan, label: dan };
                })
              : [];

            axios
              .get(Endpoint.ZAKAZIVANJE_IZVESTAJA_PARAMETRI + "/search/findByZakazivanjeIzvestaja", axiosConfig(authData.token, { zakazivanjeIzvestaja: zakazanIzvestaj._links.self.href }))
              .then((response) => {
                let parametri: any = {};
                response.data._embedded.zakazivanjeIzvestajaParametris.map((parame: any, p: any) => {
                  parametri[parame.naziv] = parame.vrednost;
                });
                var items: any = {};
                propsParametri.map((param: any, i: any) => {
                  const stpsi = param.sifarnikTipovaParametraStatistickogIzvestaja;
                  const psi = param.parametarStatistickiIzvestaj;
                  if (inputText.includes(stpsi.numerickaVrednost)) {
                    items[psi.id] = parametri[psi.id];
                  } else if (stpsi.numerickaVrednost === EnumTipovaParametraStatistickogIzvestaja.DATUMSKI) {
                    items[psi.id] = new Date(moment.utc(parametri[psi.id]).format(NEW_DATE_TIME_FORMAT));
                  } else if (stpsi.numerickaVrednost === EnumTipovaParametraStatistickogIzvestaja.SKUPOVNI && psi.upit !== "") {
                    let value = parametri[psi.id].split(",").map((pa: any, q: any) => {
                      let parametar = pa.replace(" ", "").split("-");
                      let numVrednost = parametar[1] !== "" ? parametar[1] + " - " : "";
                      return { value: parseInt(parametar[0]), label: numVrednost + parametar[2] };
                    });
                    items[psi.id] = value;
                  }
                  setState({ ...state, fields: items });
                  handleChange(psi.id, items[psi.id], stpsi.numerickaVrednost);
                });
                let vremeDate = new Date();
                if (zakazanIzvestaj.vremeIzvrsavanjaVisetruko) {
                  let hoursArray = zakazanIzvestaj.vremeIzvrsavanjaVisetruko.split(":");
                  vremeDate.setHours(hoursArray[0], hoursArray[1], hoursArray[1]);
                } else if (zakazanIzvestaj.vremeIzvrsavanjaJednostruko) {
                  vremeDate = new Date(zakazanIzvestaj.vremeIzvrsavanjaJednostruko);
                }
                setState({
                  ...state,
                  checkZakazivanjeBuducnost: true,
                  emails: getArrayEmail(zakazanIzvestaj.email),
                  ponavljanje: zakazanIzvestaj.ponavljanje ? "1" : "0",
                  vreme: zakazanIzvestaj.vremeIzvrsavanjaVisetruko ? vremeDate : null,
                  datumVreme: zakazanIzvestaj.vremeIzvrsavanjaJednostruko ? vremeDate : null,
                  periodOd: zakazanIzvestaj.vaziOd ? new Date(zakazanIzvestaj.vaziOd) : null,
                  periodDo: zakazanIzvestaj.vaziDo ? new Date(zakazanIzvestaj.vaziDo) : null,
                  period: zakazanIzvestaj.period !== null ? zakazanIzvestaj.period + "" : "0",
                  danMesec: danMesec,
                  danNedelja: danNedelja,
                  zakazivanjeIzvestajaIzmenaID: ziID,
                  zakazivanjeIzvestajaFutureID: rsiKey,
                });
                setTabViewIndex(TAB_PARAMETRI);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleData = (data: any) => {
    const idRadnika = authData.id;
    const jsonResponseQ = data.jsonResponseQ;
    const jsonCurrentQ = data.jsonCurrentQ;
    const jsonFutureQ = data.jsonFutureQ;
    const dataGenerisani: any = [];
    const dataZakazani: any = [];
    if (jsonCurrentQ.currentQ) {
      jsonCurrentQ.currentQ.map((currentQ: any, c: any) => {
        if (idKategorijaStatistickogIzvestajaRef.current && idKategorijaStatistickogIzvestajaRef.current.toString() === currentQ.ksiID) {
          const naziv = currentQ.naziv;
          const parametri = currentQ.parametri;
          const vreme = (
            <div className="spinner-border text-secondary" style={{ width: "1rem", height: "1rem" }} role="status">
              <span className="sr-only"></span>
            </div>
          );
          const obrisiDugme = <Button label={Labels.BUTTON_OTKAZI} icon="pi pi-trash" onClick={() => prekiniGenerisanje(currentQ.giID)} />;
          const object = {
            vremeGenerisanja: vreme,
            nazivIzvestaja: naziv,
            parametri: parametri,
            akcije: obrisiDugme,
          };
          dataGenerisani.push(object);
        }
      });
    }
    jsonResponseQ.responseQ.map((responseQ: any, r: any) => {
      responseQ.ksi.map((ksi: any) => {
        if (ksi && idKategorijaStatistickogIzvestajaRef.current && idKategorijaStatistickogIzvestajaRef.current.toString() === ksi.ksiID) {
          const itemList = ksi.item;
          if (itemList) {
            itemList.map((item: any, i: any) => {
              const naziv = item.naziv;
              const parametri = item.parametri.split(";").map((param: any, p: any) => {
                return (
                  <div key={p}>
                    {param}
                    <br />
                  </div>
                );
              });
              const rsiKey = item.rsiKey;
              const obrisiDugme = <Button label={Labels.BUTTON_OBRISI} icon="pi pi-trash" onClick={() => obrisiGenerisaniIzvestaj(rsiKey)} />;
              const akcije = item.format.map((form: any, f: any) => {
                const formatNaziv = form.naziv;
                const formatIcon = form.icon;
                const putanjaStampaj = Endpoint.GENERISANI_IZVESTAJ + "?radnikID=" + idRadnika + "&generisaniIzvestajID=" + item.giID + "&format=" + formatNaziv;
                const stampajDugme = (
                  <a href={putanjaStampaj} target="_blank">
                    {formatNaziv}
                  </a>
                );
                return (
                  <div className="report-format-button" key={f}>
                    {stampajDugme}
                  </div>
                );
              });
              const vremeGenerisanja = item.vremeGenerisanja;
              const object = {
                vremeGenerisanja: vremeGenerisanja,
                nazivIzvestaja: naziv,
                parametri: parametri,
                akcije: (
                  <div className="grid align-content-center justify-content-center">
                    {akcije}
                    <div>{obrisiDugme}</div>
                  </div>
                ),
              };
              dataGenerisani.push(object);
            });
          }
        }
      });
    });

    const ksi = jsonFutureQ.ksi;
    ksi.map((ksi: any) => {
      if (ksi && idKategorijaStatistickogIzvestajaRef.current && idKategorijaStatistickogIzvestajaRef.current.toString() === ksi.ksiID) {
        const itemList = ksi.item;
        if (itemList) {
          itemList.map((item: any, i: any) => {
            const naziv = item.naziv;
            const parametri = item.parametri.split(";").map((param: any, p: any) => {
              return (
                <div key={p}>
                  {param}
                  <br />
                </div>
              );
            });
            const rsiKey = item.rsiKey;
            const ziID = item.ziID;
            const email = item.email.split(";").map((em: any, p: any) => {
              return (
                <div key={p}>
                  {em}
                  <br />
                </div>
              );
            });
            const periodPonavljanjaInt = item.periodPonavljanjaInt;
            let periodPonavljanja = "";
            let periodPonavljanjaTip = "";
            if (periodPonavljanjaInt === 0) {
              periodPonavljanjaTip = "Dnevno";
            } else if (periodPonavljanjaInt === 1) {
              periodPonavljanjaTip = "Nedeljno";
              periodPonavljanja += "(";
              periodPonavljanja += ")";
              periodPonavljanja = periodPonavljanja;
            } else if (periodPonavljanjaInt === 2) {
              periodPonavljanjaTip = "Mesečno";
              periodPonavljanja = item.ponavljanje;
            }
            const vremeGenerisanja = item.vremeGenerisanja.split(";").map((vreme: any, p: any) => {
              return (
                <div key={p}>
                  {vreme}
                  <br />
                </div>
              );
            });
            const object = {
              periodPonavljanja: (
                <div>
                  {periodPonavljanjaTip}
                  <br />
                  {periodPonavljanja}
                </div>
              ),
              vreme: vremeGenerisanja,
              nazivIzvestaja: naziv,
              email: email,
              parametri: parametri,
              akcije: (
                <div className="flex align-content-center justify-content-center">
                  <Button label={Labels.BUTTON_IZMENI} icon="pi pi-pencil" onClick={() => izmeniZakazaniIzvestaj(rsiKey, ziID)} />
                  <Button className="ml-2" label={Labels.BUTTON_OBRISI} icon="pi pi-trash" onClick={() => obrisiZakazaniIzvestaj(rsiKey, ziID)} />
                </div>
              ),
            };
            dataZakazani.push(object);
          });
        }
      }
    });
    setTableDataGenerisani(dataGenerisani);
    setTableDataZakazani(dataZakazani);
  };

  const handleChangeMultiSelectParam = (event: any, parametar: any) => {
    const value: any = [];
    skupovniParametri[parametar.parametarStatistickiIzvestaj.id].forEach((p: any) => {
      event.value.forEach((v: any) => {
        if (v === p.value) {
          value.push({ value: v, label: p.label });
        }
      });
    });
    handleChange(parametar.parametarStatistickiIzvestaj.id, value, EnumTipovaParametraStatistickogIzvestaja.SKUPOVNI);
  };

  const handleChangeDropdownParam = (event: any, parametar: any) => {
    const value: any = {};
    skupovniParametri[parametar.parametarStatistickiIzvestaj.id].forEach((p: any) => {
      if (event.value === p.value) {
        value.value = event.value;
        value.label = p.label;
      }
    });
    handleChange(parametar.parametarStatistickiIzvestaj.id, value, EnumTipovaParametraStatistickogIzvestaja.SKUPOVNI);
  };

  const handleChangeMultiSelectPeriod = (event: any, optionPeriod: any) => {
    const value: any = [];
    optionPeriod.forEach((x: any) => {
      event.value.forEach((v: any) => {
        if (v === x.value) {
          value.push({ value: v, label: x.label });
        }
      });
    });

    optionPeriod.length > 7 ? setState({ ...state, danMesec: value }) : setState({ ...state, danNedelja: value });
  };

  var items = propsParametri.map((param: any, i: any) => {
    const stpsi = param.sifarnikTipovaParametraStatistickogIzvestaja;
    const psi = param.parametarStatistickiIzvestaj;
    if (stpsi.numerickaVrednost === EnumTipovaParametraStatistickogIzvestaja.DATUMSKI) {
      return (
        <div className="grid flex" key={i}>
          <label className="col-4">{`${psi.naziv} ${psi.opcioni ? "" : "*"}`}</label>
          <div className="col-8">
            <Calendar
              dateFormat={CALENDAR_DATE_FORMAT}
              monthNavigator={true}
              yearNavigator={true}
              yearRange="1920:2030"
              showIcon
              value={state.fields[psi.id]}
              onChange={(e: any) => {
                handleChange(psi.id, e.value, EnumTipovaParametraStatistickogIzvestaja.DATUMSKI);
              }}
            />
          </div>
        </div>
      );
    } else if (stpsi.numerickaVrednost === EnumTipovaParametraStatistickogIzvestaja.SKUPOVNI && psi.upit !== "") {
      return (
        <div className="grid justify-content-center" key={i}>
          <div className="col-4">{`${psi.naziv} ${psi.opcioni ? "" : "*"}`}</div>
          <div className="col-8">
            {!psi.multiSelect && (
              <Dropdown
                filter
                value={state.fields[psi.id] ? state.fields[psi.id].value : ""}
                options={skupovniParametri[psi.id]}
                onChange={(e) => {
                  handleChangeDropdownParam(e, param);
                }}
                filterBy="naziv"
                style={{ width: "100%" }}
              />
            )}
            {psi.multiSelect && (
              <MultiSelect
                filter
                placeholder={Labels.PLACEHOLDER_IZABRANO_SVE}
                value={state.fields[psi.id] ? state.fields[psi.id].map((x: any) => x.value) : []}
                options={skupovniParametri[psi.id] ?? []}
                maxSelectedLabels={3}
                onChange={(e: any) => {
                  handleChangeMultiSelectParam(e, param);
                }}
              />
            )}
          </div>
        </div>
      );
    } else {
      return !inputTextHidden.includes(stpsi.numerickaVrednost) ? (
        <div className="grid align-content-center" key={i} style={{ display: inputTextHidden.includes(stpsi.numerickaVrednost) ? "none" : "" }}>
          <label className="col-4">{`${psi.naziv} ${psi.opcioni ? "" : "*"}`}</label>
          <div className="col-8">
            <InputText
              value={state.fields[psi.id] ? state.fields[psi.id] : ""}
              onChange={(e: any) => {
                handleChange(psi.id, e.target.value, -1);
              }}
            />
          </div>
        </div>
      ) : null;
    }
  });

  let pretraga = Object.keys(state.redPretrageElementi).map((key: any) => {
    return (
      <div className="grid align-content-center" key={key}>
        <div className="col-1">
          <Button icon="pi pi-minus" className="p-button-danger" onClick={() => obrisiRedPretrage(key)} />
        </div>
        <div className="col-2">
          {Object.keys(state.redPretrageElementi)[0] !== key && (
            <Dropdown
              className="w-full"
              placeholder={Labels.PLACEHOLDER_VEZA}
              onChange={(value: any) => changeVeza(key, value)}
              options={objectAndOr}
              optionLabel="label"
              value={state.redPretrageElementi[key]?.veza?.value}
            />
          )}
        </div>
        {state.prikazTekucihUnosaKodPretrage && (
          <div className="col-2">
            <Dropdown placeholder={Labels.PLACEHOLDER_ODABERITE_UNOS} filter onChange={(e) => changeTipaSkupaAtributa(key, e.value)} options={state.tipoviSkupaAtributa} />
          </div>
        )}
        <div className={state.prikazTekucihUnosaKodPretrage ? "col-3" : "col-5"}>
          <Dropdown
            placeholder={Labels.PLACEHOLDER_ODABERITE_STAVKU_UNOSA}
            filter
            value={state.redPretrageElementi[key]?.atributUTipuSkupa?.value}
            onChange={(e) => {
              changeAtributUTipuSkupa(key, e.value);
            }}
            options={state.prikazTekucihUnosaKodPretrage ? state.autsiPoTSA[key] : state.autsiPoTSA[0]}
            optionLabel="label"
          />
        </div>
        <div className="col-2">
          <Dropdown
            className="col vrednost"
            placeholder={Labels.PLACEHOLDER_OPERATOR}
            filter
            value={state.redPretrageElementi[key]?.operator?.value}
            onChange={(e) => changeOperator(key, e)}
            options={state.operatoriPoTSA[key]}
          />
        </div>
        <div className="col-2">
          <TipAtributaRouter
            tvaNumVrednost={state.redPretrageElementi[key].tipVrednosti ? state.redPretrageElementi[key].tipVrednosti.numerickaVrednost : null}
            initValue={state.initValue[key]}
            changeState={changeState}
            tipSkupaAtributaKey={key}
            atributUTipuSkupaID={state.redPretrageElementi[key].atributUTipuSkupa ? state.redPretrageElementi[key].atributUTipuSkupa.value : ""}
            auts={state.autsiPoTSAFull}
            enumiPoAUTS={state.enumiPoAUTS}
            zaPretragu={true}
            smallFormat={true}
          />
        </div>
      </div>
    );
  });

  const optionsDanNedelja = mapDataUNedelji.map((danNedelja: any, i: any) => {
    const dan = danNedelja.split("_");
    return { value: dan[0], label: dan[1] };
  });

  const optionsDanMesec: any = [];
  for (let i = 1; i <= 31; i++) {
    optionsDanMesec.push({ value: i, label: String(i) });
  }

  const ponavljanjeOptions = [
    { value: "0", label: "Jednostruko" },
    { value: "1", label: "Višestruko" },
  ];

  const periodOptions = [
    { value: "0", label: "Dan" },
    { value: "1", label: "Nedelja" },
    { value: "2", label: "Mesec" },
  ];

  const objectDanUNedelji: any = {
    2: "Ponedeljak",
    3: "Utorak",
    4: "Sreda",
    5: "Četvrtak",
    6: "Petak",
    7: "Subota",
    1: "Nedelja",
  };

  return (
    <div>
      <TabView activeIndex={tabViewIndex} onTabChange={(e) => setTabViewIndex(e.index)}>
        <TabPanel header={Labels.TAB_PARAMETRI}>
          <div className="flex justify-content-center  ">
            <div className=" flex align-items-center justify-content-center col-6 text-xl ">
              {statistickiIzvestaj ? Labels.LABEL_IZVESTAJ + " : " + statistickiIzvestaj.label : Labels.LABEL_IZVESTAJ_NIJE_IZABRAN}
            </div>
          </div>
          <hr />
          {items}
          {pretraga}
          <div className="nogutter">
            <div className="p-nogutter col-1">
              <Button icon="pi pi-plus" className="p-button-danger" onClick={dodajRedPretrage} />
            </div>
          </div>
          <hr />

          <div className="grid align-content-center">
            <div className="col-2">{Labels.LABEL_EMAIL}</div>
            <div className="col-4">
              <InputText
                placeholder="email"
                value={state.emails}
                onChange={(e: any) => {
                  setState({ ...state, emails: e.target.value });
                }}
              />
            </div>
          </div>
          <hr />

          <div className="grid align-content-center">
            <div className="col-3">{Labels.LABEL_ZAKAZIVANJE_ZA_BUDUCNOST}</div>
            <div className="col-3">
              <InputSwitch checked={state.checkZakazivanjeBuducnost} onChange={() => setState({ ...state, checkZakazivanjeBuducnost: !state.checkZakazivanjeBuducnost })} />
            </div>
          </div>
          {state.checkZakazivanjeBuducnost && (
            <div>
              <div className="grid align-content-center">
                <div className="col-3">{Labels.LABEL_PONAVLJANJE}</div>
                <div className="col-9">
                  <Dropdown
                    onChange={(event) => {
                      setState({ ...state, ponavljanje: event.target.value });
                    }}
                    value={state.ponavljanje}
                    options={ponavljanjeOptions}
                  />
                </div>
              </div>
              {state.ponavljanje === "0" && (
                <div className="grid align-content-center">
                  <div className="col-3">{Labels.LABEL_DATUM_I_VREME}</div>
                  <div className="col-3">
                    <Calendar
                      stepMinute={1.000001}
                      showTime={true}
                      dateFormat={CALENDAR_DATE_FORMAT}
                      monthNavigator={true}
                      yearNavigator={true}
                      yearRange="1920:2030"
                      showIcon
                      value={state.datumVreme}
                      onChange={(e: any) => {
                        setState({ ...state, datumVreme: e.target.value });
                      }}
                    />
                  </div>
                </div>
              )}
              {state.ponavljanje === "1" && (
                <div>
                  <div className="grid align-content-center">
                    <label className="col-2">{Labels.LABEL_PERIOD_VAZENJA}</label>
                    <div className="col-5">
                      <Calendar
                        dateFormat={CALENDAR_DATE_FORMAT}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1920:2030"
                        showIcon
                        value={state.periodOd}
                        onChange={(e: any) => {
                          setState({ ...state, periodOd: e.value });
                        }}
                      />
                    </div>
                    <div className="col-5">
                      <Calendar
                        dateFormat={CALENDAR_DATE_FORMAT}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1920:2030"
                        showIcon
                        value={state.periodDo}
                        onChange={(e: any) => {
                          setState({ ...state, periodDo: e.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid align-content-center">
                    <label className="col-2">{Labels.LABEL_PERIOD}</label>
                    <div className="col-10">
                      <Dropdown onChange={(event) => setState({ ...state, period: event.target.value })} value={state.period} options={periodOptions} />
                    </div>
                  </div>
                  {state.period === "1" && (
                    <div>
                      <div className="grid align-content-center">
                        <label className="col-2">{Labels.LABEL_DAN_U_NEDELJI}</label>
                        <div className="col-10">
                          <MultiSelect
                            placeholder={Labels.PLACEHOLDER_IZABERITE_DAN}
                            filter
                            value={state.danNedelja ? state.danNedelja.map((x: any) => x.value) : []}
                            onChange={(e) => {
                              handleChangeMultiSelectPeriod(e, optionsDanNedelja);
                            }}
                            options={optionsDanNedelja}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {state.period === "2" && (
                    <div>
                      <div className="grid align-content-center">
                        <label className="col-2">{Labels.LABEL_DAN_U_MESCU}</label>
                        <div className="col-10">
                          <MultiSelect
                            placeholder={Labels.PLACEHOLDER_IZABERITE_MESEC}
                            filter
                            value={state.danMesec ? state.danMesec.map((x: any) => x.value) : []}
                            onChange={(e) => {
                              handleChangeMultiSelectPeriod(e, optionsDanMesec);
                            }}
                            options={optionsDanMesec}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="grid align-content-center">
                      <label className="col-2">{Labels.LABEL_VREME}</label>
                      <div className="col-5">
                        <Calendar timeOnly showIcon showTime value={state.vreme} stepMinute={1.001} onChange={(e) => setState({ ...state, vreme: e.value })} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="grid p-fluid">
            {state.zakazivanjeIzvestajaIzmenaID ? (
              <div className="col-12">
                <div className="grid">
                  <div className="col-8">
                    <Button label={Labels.BUTTON_IZMENI_IZVESTAJ} className="p-button-warning" onClick={izmeniIzvestaj} />
                  </div>
                  <div className="col-4">
                    <Button label={Labels.BUTTON_ODUSTANI} className="p-button-danger" onClick={() => setState(initState)} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12">
                <Button label={Labels.BUTTON_GENERISI_IZVESTAJ} className="p-button-info" onClick={generisiIzvestaj} />
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel header={Labels.TAB_GENERISANI_IZVESTAJI}>
          <DataTable
            value={tableDataGenerisani}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={10}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column field={"vremeGenerisanja"} header={Labels.COLUMN_HEADER_VREME_GENERISANJA} sortable />
            <Column field={"nazivIzvestaja"} header={Labels.COLUMN_HEADER_NAZIV_IZVESTAJA} sortable />
            <Column field={"parametri"} header={Labels.COLUMN_HEADER_PARAMETRI} sortable />
            <Column field={"akcije"} header={Labels.COLUMN_HEADER_AKCIJE} sortable />
          </DataTable>
        </TabPanel>
        <TabPanel header={Labels.TAB_ZAKAZNI_IZVESTAJI}>
          <DataTable
            value={tableDataZakazani}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={10}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column field={"periodPonavljanja"} header={Labels.COLUMN_HEADER_PERIOD_PONAVLJANJA} sortable />
            <Column field={"vreme"} header={Labels.COLUMN_HEADER_VREME} sortable />
            <Column field={"nazivIzvestaja"} header={Labels.COLUMN_HEADER_NAZIV_IZVESTAJA} sortable />
            <Column field={"email"} header={Labels.COLUMN_HEADER_EMAIL} sortable />
            <Column field={"parametri"} header={Labels.COLUMN_HEADER_PARAMETRI} sortable />
            <Column field={"akcije"} header={Labels.COLUMN_HEADER_AKCIJE} sortable />
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  );
}
