import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { PickList } from "primereact/picklist";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useState, useImperativeHandle } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { axiosConfig, isFormDisabled, useEffectOnce } from "../../../utils/Utils";
import { AppContext } from "../../Store";
import CrudParametriStatistickogIzvestajaList from "./CrudParametriStatistickogIzvestajaList";

interface CrudStatistickiIzvestajiProps {
  statistickiIzvestajOperation: string;
  statistickiIzvestaj?: any;
  onCreateStatistickiIzvestaj: Function;
  onUpdateStatistickiIzvestaj: Function;
  onDeleteStatistickiIzvestaj: Function;
  onCancel: Function;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudStatistickiIzvestaji(props: CrudStatistickiIzvestajiProps) {
  const { statistickiIzvestajOperation, statistickiIzvestaj, onCreateStatistickiIzvestaj, onUpdateStatistickiIzvestaj, onDeleteStatistickiIzvestaj, dialogRef, setIndex, index } = props;
  const [statistickiIzvestajChange, setStatistickiIzvestajChange] = useState<Partial<any>>(statistickiIzvestaj);
  const [tipoviStatistickogIzvestaja, setTipoviStatistickogIzvestaja] = useState<any>();
  const [imaFormatStatistickogIzvestaja, setImaFormatStatistickogIzvestaja] = useState([]);
  const [nemaFormatStatistickogIzvestaja, setNemaFormatStatistickogIzvestaja] = useState([]);
  const { showGrowlMessage, authData } = useContext(AppContext);

  useEffectOnce(() => {
    const requestTipoviStatistickogIzvestaja = axios.get(Endpoint.TIP_STATISTICKOG_IZVESTAJA + "/search/findByAktivan", axiosConfig(authData.token, { aktivan: true, page: 0, size: 50000000 }));
    axios.all([requestTipoviStatistickogIzvestaja]).then(
      axios.spread((responseTipoviStatistickogIzvestaja) => {
        setTipoviStatistickogIzvestaja(responseTipoviStatistickogIzvestaja.data._embedded.tipStatistickogIzvestajas);
      })
    );
    if (statistickiIzvestaj) {
      const requestSviFormatiStatistickogIzvestaja = axios.get(
        Endpoint.FORMAT_STATISTICKIH_IZVESTAJA + "/search/findByAktivan",
        axiosConfig(authData.token, { aktivan: true, page: 0, size: 50000000 })
      );
      const requestImaFormateStatistickogIzvestaja = axios.get(
        Endpoint.VEZA_STATISTICKI_IZVESTAJ_I_FORMAT + "/search/findByAktivanAndStatistickiIzvestaj",
        axiosConfig(authData.token, { aktivan: true, projection: "getWithRelatedObjects", statistickiIzvestaj: Endpoint.STATISTICKI_IZVESTAJI + "/" + statistickiIzvestajChange.id })
      );
      axios
        .all([requestSviFormatiStatistickogIzvestaja, requestImaFormateStatistickogIzvestaja])
        .then(
          axios.spread((responseSviFormatiStatistickogIzvestaja, responseImaFormateStatistickogIzvestaja) => {
            const sviFormatiStatistickogIzvestajaArray = responseSviFormatiStatistickogIzvestaja.data._embedded.formatStatistickihIzvestajas;
            const imaFormateStatistickogIzvestajaArray = responseImaFormateStatistickogIzvestaja.data._embedded.vezaStatistickiIzvestajIFormats.map((format: any) => {
              return format.formatStatistickihIzvestaja;
            });
            const imaFormateStatistickogIzvestajaID = imaFormateStatistickogIzvestajaArray.map((f: any) => {
              return f.id;
            });
            const nemaFormateStatistickogIzvestajaArray = sviFormatiStatistickogIzvestajaArray.filter((f: any) => !imaFormateStatistickogIzvestajaID.includes(f.id));
            setImaFormatStatistickogIzvestaja(imaFormateStatistickogIzvestajaArray);
            setNemaFormatStatistickogIzvestaja(nemaFormateStatistickogIzvestajaArray);
          })
        )
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA, error);
        });
    } else {
      setStatistickiIzvestajChange({
        ...statistickiIzvestajChange,
        prikazujeSe: false,
      });
    }
  });

  const validateInput = (message: string) => {
    if (!statistickiIzvestajChange || !statistickiIzvestajChange.naziv) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    if (!statistickiIzvestajChange || !statistickiIzvestajChange.numerickaVrednost) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_NUMERICKA_VREDNOST);
      return false;
    }
    if (!statistickiIzvestajChange || !statistickiIzvestajChange.jasperFajl) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_JASPER);
      return false;
    }
    if (!statistickiIzvestajChange || !statistickiIzvestajChange.nazivTipaStatistickogIzvestaja) {
      showGrowlMessage(MessageType.ERROR, message, Labels.MESSAGES_POLJE_TIP_STATISTICKOG_IZVESTAJA);
      return false;
    }
    return true;
  };

  const formatStatistickogIzvestajaTemplete = (object: any) => {
    return (
      <div className="p-clearfix">
        <div>{object.nazivKratki}</div>
      </div>
    );
  };

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
        return;
      }
      onCreateStatistickiIzvestaj(statistickiIzvestajChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
        });
    },
    onUpdate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
        return;
      }
      onUpdateStatistickiIzvestaj(statistickiIzvestajChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
        });
    },
    onDelete: () => {
      onDeleteStatistickiIzvestaj(statistickiIzvestajChange)
        .then(() => {})
        .catch((error: any) => {
          showGrowlMessage(MessageType.ERROR, Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    },
  }));

  const onChangeFormatStatistickogIzvestaja = (event: any) => {
    if (imaFormatStatistickogIzvestaja.length < event.target.length) {
      const imaFormatStatistickogIzvestajaID = imaFormatStatistickogIzvestaja.map((format: any) => format.id);
      event.target.forEach((format: any) => {
        if (!imaFormatStatistickogIzvestajaID.includes(format.id)) {
          const vezaFormatIzvestaj = {
            statistickiIzvestaj: Endpoint.STATISTICKI_IZVESTAJI + "/" + statistickiIzvestaj.id,
            formatStatistickihIzvestaja: Endpoint.FORMAT_STATISTICKIH_IZVESTAJA + "/" + format.id,
          };
          axios.post(Endpoint.VEZA_STATISTICKI_IZVESTAJ_I_FORMAT, vezaFormatIzvestaj, axiosConfig(authData.token));
        }
      });
      setImaFormatStatistickogIzvestaja(event.target);
      setNemaFormatStatistickogIzvestaja(event.source);
    } else if (nemaFormatStatistickogIzvestaja.length < event.source.length) {
      const nemFormatStatIzvestajaID = nemaFormatStatistickogIzvestaja.map((format: any) => format.id);
      const statIzvestajFormatiDelete: any[] = [];
      event.source.forEach((format: any) => {
        if (!nemFormatStatIzvestajaID.includes(format.id)) {
          statIzvestajFormatiDelete.push(format.id);
        }
      });

      axios
        .post(
          Endpoint.VEZA_STATISTICKI_IZVESTAJ_I_FORMAT_BRISANJE,
          {
            statistickiIzvestajID: statistickiIzvestaj.id,
            formatiID: statIzvestajFormatiDelete,
          },
          axiosConfig(authData.token)
        )
        .then((res: any) => {
          setImaFormatStatistickogIzvestaja(event.target);
          setNemaFormatStatistickogIzvestaja(event.source);
        })
        .catch((error: any) => {});
    }
  };

  return (
    <div className="panel-report">
      <TabView
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.TAB_STATISTICKI_IZVESTAJ}>
          <div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NAZIV}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(statistickiIzvestajOperation)}
                    value={statistickiIzvestajChange && statistickiIzvestajChange.naziv ? statistickiIzvestajChange.naziv : ""}
                    onChange={(e: any) => {
                      setStatistickiIzvestajChange({
                        ...statistickiIzvestajChange,
                        naziv: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_NUMERICKA_VREDNOST}</div>
                <div className="col-8">
                  <InputNumber
                    disabled={isFormDisabled(statistickiIzvestajOperation)}
                    value={statistickiIzvestajChange && statistickiIzvestajChange.numerickaVrednost ? statistickiIzvestajChange.numerickaVrednost : null}
                    onValueChange={(e: any) => {
                      setStatistickiIzvestajChange({
                        ...statistickiIzvestajChange,
                        numerickaVrednost: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_JASPER}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(statistickiIzvestajOperation)}
                    value={statistickiIzvestajChange && statistickiIzvestajChange.jasperFajl ? statistickiIzvestajChange.jasperFajl : ""}
                    onChange={(e: any) => {
                      setStatistickiIzvestajChange({
                        ...statistickiIzvestajChange,
                        jasperFajl: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_PRIKAZUJE_SE}</div>
                <div className="col-8">
                  <InputSwitch
                    disabled={isFormDisabled(statistickiIzvestajOperation)}
                    checked={statistickiIzvestajChange && statistickiIzvestajChange.prikazujeSe ? statistickiIzvestajChange.prikazujeSe : false}
                    onChange={(e: any) => {
                      setStatistickiIzvestajChange({
                        ...statistickiIzvestajChange,
                        prikazujeSe: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_OPIS}</div>
                <div className="col-8">
                  <InputText
                    disabled={isFormDisabled(statistickiIzvestajOperation)}
                    value={statistickiIzvestajChange && statistickiIzvestajChange.opis ? statistickiIzvestajChange.opis : ""}
                    onChange={(e: any) => {
                      setStatistickiIzvestajChange({
                        ...statistickiIzvestajChange,
                        opis: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="xl-6 lg-6 md-6 sm-12">
              <div className="grid align-items-center">
                <div className="col-4">{Labels.LABEL_TIP_STATISTICKOG_IZVESTAJA}</div>
                <div className="col-8">
                  <Dropdown
                    disabled={isFormDisabled(statistickiIzvestajOperation)}
                    dataKey="id"
                    value={statistickiIzvestajChange && statistickiIzvestajChange.nazivTipaStatistickogIzvestaja ? statistickiIzvestajChange.nazivTipaStatistickogIzvestaja : ""}
                    options={tipoviStatistickogIzvestaja}
                    editable={true}
                    onChange={(e) => {
                      setStatistickiIzvestajChange({
                        ...statistickiIzvestajChange,
                        nazivTipaStatistickogIzvestaja: e.value.naziv,
                        tipStatistickogIzvestaja: Endpoint.STATISTICKI_IZVESTAJI + "/" + e.value.id,
                      });
                    }}
                    optionLabel="naziv"
                    filter={true}
                    filterBy="naziv"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header={Labels.TAB_FORMAT_STATISTICKOG_IZVESTAJA} disabled={statistickiIzvestajOperation === EntityOperation.CREATE}>
          <PickList
            source={nemaFormatStatistickogIzvestaja}
            target={imaFormatStatistickogIzvestaja}
            itemTemplate={formatStatistickogIzvestajaTemplete}
            sourceHeader={Labels.TITLE_FORMATI_KOJE_NEMA_STATISTICKI_IZVESTAJ}
            targetHeader={Labels.TITLE_FORMATI_KOJE_IMA_STATISTICKI_IZVESTAJ}
            sourceStyle={{ height: "650px" }}
            targetStyle={{ height: "650px" }}
            onChange={onChangeFormatStatistickogIzvestaja}
            showSourceControls={false}
            showTargetControls={false}
            style={{ pointerEvents: isFormDisabled(statistickiIzvestajOperation) ? "none" : "" }}
          />
        </TabPanel>
        <TabPanel header={Labels.TAB_PARAMETRI_STATISTICKOG_IZVESTAJA} disabled={statistickiIzvestajOperation === EntityOperation.CREATE}>
          <CrudParametriStatistickogIzvestajaList statistickiIzvestajOperation={statistickiIzvestajOperation} idStatistickiIzvestaj={statistickiIzvestajChange ? statistickiIzvestajChange.id : null} />
        </TabPanel>
      </TabView>
    </div>
  );
}
