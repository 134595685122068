import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useContext, useRef } from "react";
import Endpoint from "../../../endpoints/Endpoint";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { axiosConfig, useEffectOnce } from "../../../utils/Utils";
import CrudTipParametraStatistickogIzvestaja from "./CrudTipParametraStatistickogIzvestaja";
import MessageType from "../../../infrastructure/MessageType";
import { AppContext } from "../../Store";

export default function CrudTipParametraStatistickogIzvestajaList() {
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedTipParametraStatistickogIzvestaja, setSelectedTipParametraStatistickogIzvestaja] = useState();
  const [tipParametraStatistickogIzvestajaList, setTipParametraStatistickogIzvestajaList] = useState<any>();
  const [entityOperation, setEntityOperation] = useState<string>("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [tipParametraStatistickogIzvestajaToChange, setTipParametraStatistickogIzvestajaToChange] = useState<any>();
  const { showGrowlMessage, authData } = useContext(AppContext);
  const dialogRef = useRef<any>();
  const [index, setIndex] = useState<number>(0);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    axios
      .get(
        Endpoint.TIP_PARAMETRA_STATISTICKOG_IZVESTAJA + "/search/findByAktivan",
        axiosConfig(authData.token, {
          page: 0,
          size: 50000000,
          aktivan: true,
          projection: "getWithRelatedObjects",
        })
      )
      .then((tipoviParametaraStatistickogIzvestajaCollection) => {
        let dataList: any = [];
        const tipoviParametaraStatistickogIzvestaja = tipoviParametaraStatistickogIzvestajaCollection.data._embedded.tipParametraStatistickogIzvestajas;
        tipoviParametaraStatistickogIzvestaja.forEach((tipParamStatIzvestaja: any) => {
          const object = {
            naziv: tipParamStatIzvestaja.tipParametraStatistickogIzvestaja.naziv,
            sqlNumVrednost: tipParamStatIzvestaja.tipParametraStatistickogIzvestaja.sqlNumVrednost,
            upit: tipParamStatIzvestaja.tipParametraStatistickogIzvestaja.upit,
            id: tipParamStatIzvestaja.tipParametraStatistickogIzvestaja.id,
            link: tipParamStatIzvestaja.tipParametraStatistickogIzvestaja.link,
            logged: tipParamStatIzvestaja.tipParametraStatistickogIzvestaja.logged,
            nazivSifarnikaTipovaParametraStatistickogIzvestaja: tipParamStatIzvestaja.sifarnikTipovaParametraStatistickogIzvestaja.naziv,
          };
          dataList.push(object);
        });
        setTipParametraStatistickogIzvestajaList(dataList);
      });
  };

  const createTipParametraStatistickogIzvestaja = (tipParametraStatistickogIzvestaja: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(Endpoint.TIP_PARAMETRA_STATISTICKOG_IZVESTAJA, tipParametraStatistickogIzvestaja, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_KREIRANO, tipParametraStatistickogIzvestaja.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const updateTipParametraStatistickogIzvestaja = (tipParametraStatistickogIzvestaja: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(Endpoint.TIP_PARAMETRA_STATISTICKOG_IZVESTAJA + `/${tipParametraStatistickogIzvestaja.id}`, tipParametraStatistickogIzvestaja, axiosConfig(authData.token))
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_IZMENJENO, tipParametraStatistickogIzvestaja.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const deleteTipParametraStatistickogIzvestaja = (tipParametraStatistickogIzvestaja: any) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .patch(
          Endpoint.TIP_PARAMETRA_STATISTICKOG_IZVESTAJA + `/${tipParametraStatistickogIzvestaja.id}`,
          {
            aktivan: false,
          },
          axiosConfig(authData.token)
        )
        .then(() => {
          closeDialog();
          showGrowlMessage(MessageType.SUCCESS, Labels.TITLE_MESSAGES_USPESNO_OBRISANO, tipParametraStatistickogIzvestaja.naziv);
          fetchData();
          resolve();
        })
        .catch((error: any) => {
          reject(error.response.data.message);
        });
    });
  };

  const dialogHeader = () => {
    const display = tipParametraStatistickogIzvestajaToChange ? tipParametraStatistickogIzvestajaToChange.naziv : "";

    switch (entityOperation) {
      case EntityOperation.CREATE:
        return Labels.TITLE_DIALOG_KREIRANJE_TIPA_PARAMETRA;
      case EntityOperation.DELETE:
        return Labels.TITLE_DIALOG_BRISANJE_TIPA_PARAMETRA + display;
      case EntityOperation.UPDATE:
        return Labels.TITLE_DIALOG_IZMENA_TIPA_PARAMETRA + display;
      case EntityOperation.READ:
        return Labels.TITLE_DIALOG_DETALJI_TIPA_PARAMETRA + display;
      default:
        return "";
    }
  };

  const openDialog = (entityOperation: string) => {
    let r;
    switch (entityOperation) {
      case EntityOperation.UPDATE:
      case EntityOperation.READ:
      case EntityOperation.DELETE:
        r = selectedTipParametraStatistickogIzvestaja;
        break;
    }
    setEntityOperation(entityOperation);
    setTipParametraStatistickogIzvestajaToChange(r);
    setDisplayDialog(true);
  };

  const closeDialog = () => {
    setIndex(0);
    setDisplayDialog(false);
    setSelectedTipParametraStatistickogIzvestaja(undefined);
    setSelectedRow([]);
  };

  const onCancel = () => {
    closeDialog();
  };

  const leftContentsDialog = () => (
    <React.Fragment>
      {entityOperation === EntityOperation.CREATE && (
        <Button
          label={Labels.BUTTON_KREIRAJ}
          icon="pi pi-save"
          className="p-button-success"
          onClick={() => {
            dialogRef.current.onCreate();
          }}
        />
      )}
      {entityOperation === EntityOperation.UPDATE && (
        <Button
          label={Labels.BUTTON_IZMENI}
          icon="pi pi-inbox"
          className="p-button-warning"
          onClick={() => {
            dialogRef.current.onUpdate();
          }}
        />
      )}
      {entityOperation === EntityOperation.DELETE && (
        <Button
          label={Labels.BUTTON_OBRISI}
          icon="pi pi-inbox"
          className="p-button-danger"
          onClick={() => {
            dialogRef.current.onDelete();
          }}
        />
      )}
    </React.Fragment>
  );

  const rightContentsDialog = () => (
    <React.Fragment>
      <Button
        label={Labels.BUTTON_ODUSTANI}
        icon="pi pi-times"
        className="p-button-danger"
        onClick={() => {
          onCancel();
        }}
      />
    </React.Fragment>
  );

  const dialogFooter = () => {
    return (
      <div>
        <Toolbar left={leftContentsDialog} right={rightContentsDialog} />
      </div>
    );
  };

  const leftContents = () => (
    <React.Fragment>
      <Button label={Labels.BUTTON_KREIRAJ} icon="pi pi-plus" className="p-button-success" onClick={() => openDialog(EntityOperation.CREATE)} />
      <Button disabled={!selectedTipParametraStatistickogIzvestaja} label={Labels.BUTTON_IZMENI} icon="pi pi-upload" className="p-button-warning" onClick={() => openDialog(EntityOperation.UPDATE)} />
      <Button disabled={!selectedTipParametraStatistickogIzvestaja} label={Labels.BUTTON_DETALJI} icon="pi pi-bars" className="p-button-info" onClick={() => openDialog(EntityOperation.READ)} />
    </React.Fragment>
  );

  const rightContents = () => (
    <React.Fragment>
      <Button disabled={!selectedTipParametraStatistickogIzvestaja} label={Labels.BUTTON_OBRISI} icon="pi pi-inbox" className="p-button-danger" onClick={() => openDialog(EntityOperation.DELETE)} />
    </React.Fragment>
  );

  return (
    <div className="layout-report">
      <Panel header={Labels.TITLE_PANEL_CRUD_TIP_PARAMETRA} className="panel-report-list">
        <Toolbar left={leftContents} right={rightContents} />
        <div>
          <DataTable
            filterDisplay="row"
            value={tipParametraStatistickogIzvestajaList}
            paginator={true}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
            rows={20}
            rowsPerPageOptions={[5, 10, 20]}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowSelect={(e) => {
              setSelectedTipParametraStatistickogIzvestaja(e.data);
            }}
            alwaysShowPaginator={false}
            emptyMessage={Labels.LABEL_NEMA_PODATAKA}
          >
            <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV} filterMatchMode="contains" showFilterMenu={false} filter sortable />
            <Column
              className="column-align-left column-width-200px"
              field={"sqlNumVrednost"}
              header={Labels.COLUMN_HEADER_NUMERICKA_VREDNOST}
              filterMatchMode="contains"
              showFilterMenu={false}
              filter
              sortable
            />
          </DataTable>
          <Dialog header={dialogHeader()} visible={displayDialog} onHide={closeDialog} style={{ width: "90%" }} footer={dialogFooter()}>
            <CrudTipParametraStatistickogIzvestaja
              tipParametraStatistickogIzvestajaOperation={entityOperation}
              tipParametraStatistickogIzvestaja={tipParametraStatistickogIzvestajaToChange}
              onCreateTipParametraStatistickogIzvestaja={createTipParametraStatistickogIzvestaja}
              onUpdateTipParametraStatistickogIzvestaja={updateTipParametraStatistickogIzvestaja}
              onDeleteTipParametraStatistickogIzvestaja={deleteTipParametraStatistickogIzvestaja}
              onCancel={onCancel}
              setIndex={setIndex}
              index={index}
              dialogRef={dialogRef}
            />
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
