import React, { useRef, useState } from "react";
import { getImage } from "../../utils/Utils";
import TipAtributaRouter from "./TipAtributaRouter";

function Slikovni({ auts, autsiPodredjeni, initValue, enumiPoAUTS, changeState, prikazAUTS, sifraAutsPoTipu, prikazivanjeAutsa }) {
  const sviPodredjeni = useRef(autsiPodredjeni[auts.id].filter((p) => p.imageCoordinateX && p.imageCoordinateY && prikazivanjeAutsa(p)).sort((a, b) => (a.redosled > b.redosled ? 1 : -1)));
  const { backgroundImage, inicijalnaBrojnaVrednost, gornjaReferentnaBrojnaVrednost, donjaReferentnaBrojnaVrednost } = auts;

  const DIAMETER_DOT = useRef(inicijalnaBrojnaVrednost || 20);
  const WIDTH_CANVAS = useRef(gornjaReferentnaBrojnaVrednost);
  const HEIGHT_CANVAS = useRef(donjaReferentnaBrojnaVrednost);
  const [edit, setEdit] = useState(null);

  const imageDiv = {
    backgroundImage: `url(${getImage(backgroundImage, null)})`,
    width: WIDTH_CANVAS.current,
    height: HEIGHT_CANVAS.current,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    border: "1px solid black",
    padding: "0px",
    position: "relative",
  };

  const itemsList = () => {
    const newArray = [];
    sviPodredjeni.current.forEach((p, i) => {
      newArray.push(
        <div className={edit === p.id ? "alertsBorder p-col-12" : "p-col-12"} onClick={() => setEdit(null)} key={i} id={`${p.id}`}>
          <div className="listBoxBroj">{`${p.naziv} (${p.napomena})`}</div>
          <div className="listBoxPolje">
            <TipAtributaRouter
              tvaNumVrednost={p.tvaNumVrednost}
              initValue={initValue[p.id]}
              changeState={changeState}
              tipSkupaAtributaKey={p.id}
              atributUTipuSkupaID={p.id}
              auts={p}
              enumiPoAUTS={enumiPoAUTS ? enumiPoAUTS[p.id] : []}
              prikazAUTS={prikazAUTS}
              sifraAutsPoTipu={sifraAutsPoTipu}
              zaPretragu={false}
              smallFormat={true}
            />
          </div>
        </div>
      );
    });
    return newArray;
  };

  return (
    <React.Fragment>
      <div className={auts.ispisujeSeLabela ? "card w-100 layout-slikovni" : "w-100 layout-slikovni"} key={auts.id}>
        <h5 className={auts.ispisujeSeLabela ? "card-header mb-2" : "mb-2"}>{auts.ispisujeSeLabela ? auts.naziv : ""}</h5>
        <div className="p-col-12">
          <div className="p-grid">
            <div className="p-col-fixed" style={imageDiv}>
              {sviPodredjeni.current.map((p, i) => {
                return (
                  <div
                    key={i}
                    onClick={(e) => setEdit(p.id)}
                    className={initValue[p.id] ? "btn-danger dotDiv" : "bg-white dotDiv"}
                    style={{
                      width: `${DIAMETER_DOT.current}px`,
                      height: `${DIAMETER_DOT.current}px`,
                      marginTop: `${p.imageCoordinateY}px`,
                      marginLeft: `${p.imageCoordinateX}px`,
                    }}
                  >
                    <a href={`#${p.id}`}>
                      <p className="btn badge">{p.napomena}</p>
                    </a>
                  </div>
                );
              })}
            </div>
            <div className="p-col mr-3" style={{ height: HEIGHT_CANVAS.current, overflow: "auto" }}>
              {itemsList()}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Slikovni;
