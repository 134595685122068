const EnumTipovaParametraStatistickogIzvestaja = {
    TEKSTUALNI: 1,
    DATUMSKI: 2,
    HIDDEN: 3,
    SKUPOVNI: 4,
    NUMERIČKI: 5,
    DYNAMIC_HIDDEN: 6,
};


export default EnumTipovaParametraStatistickogIzvestaja;