import React from "react";

export default function Brojni({ initValue, changeState, auts, tipSkupaAtributaKey }) {
  const handleChange = (value) => {
    let stateArray = [
      ["initValue", tipSkupaAtributaKey, value],
      ["prikazAUTS", auts.sifra, value],
    ];
    changeState(stateArray);
  };

  return (
    <div className="d-flex">
      <input
        type="number"
        step="any"
        min={auts.tipAtributa && auts.tipAtributa.donjaGranicaBrojneVrednosti !== null ? auts.tipAtributa.donjaGranicaBrojneVrednosti : Number.MIN_SAFE_INTEGER}
        max={auts.tipAtributa && auts.tipAtributa.gornjaGranicaBrojneVrednosti !== null ? auts.tipAtributa.gornjaGranicaBrojneVrednosti : Number.MAX_SAFE_INTEGER}
        className="form-control"
        onChange={(event) => handleChange(event.target.value)}
        value={initValue ? initValue : ""}
        disabled={auts.formulaZaRacunanje}
      />
      {auts.tipAtributa && auts.tipAtributa.jedinica && <p className="ml-2 mt-1">{auts.tipAtributa.jedinica}</p>}
    </div>
  );
}
