import React from "react";
import { getImage } from "../../../utils/Utils";
import noImageIcon from "./../../../images/no-image-icon.png";
import CtSponzorDto from "./CtSponzorDto";

interface DisplaySponzor {
  sponzor: CtSponzorDto;
}

export default function DisplaySponzor(props: DisplaySponzor) {
  const { sponzor } = props;

  return (
    <div className="display-sponsor">
      <div className="mx-5 text-center">
        <div>{sponzor.description}</div>
        <a href={sponzor.websiteUrl} target="_blank" rel="noopener noreferrer">
          <img alt="L" className="logo-img" src={getImage(sponzor.logo!, noImageIcon)} />
        </a>
      </div>
    </div>
  );
}
