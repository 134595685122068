import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useImperativeHandle, useState } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { isFormDisabled } from "../../../utils/Utils";

interface CrudUstanovaProps {
  ustanovaOperation: string;
  ustanova?: any;
  onCreateUstanova: Function;
  onUpdateUstanova: Function;
  onDeleteUstanova: Function;
  onCancel: Function;
  dialogRef?: any;
  setIndex: Function;
  index: number;
}

export default function CrudUstanova(props: CrudUstanovaProps) {
  const { ustanovaOperation, ustanova, onCreateUstanova, onUpdateUstanova, onDeleteUstanova, dialogRef, setIndex, index } = props;
  let [messages, setMessages] = useState<Messages>();
  const [ustanovaChange, setUstanovaChange] = useState<Partial<any>>(ustanova);

  const validateInput = (message: string) => {
    if (!ustanovaChange || !ustanovaChange.naziv) {
      showError(message, Labels.MESSAGES_POLJE_NAZIV);
      return false;
    }
    return true;
  };

  useImperativeHandle(dialogRef, () => ({
    onCreate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA)) {
        return;
      }
      onCreateUstanova(ustanovaChange)
        .then(() => {})
        .catch((error: any) => {
          showError(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_KREIRANJA, error);
        });
    },
    onUpdate: () => {
      if (!validateInput(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE)) {
        return;
      }
      onUpdateUstanova(ustanovaChange)
        .then(() => {})
        .catch((error: any) => {
          showError(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_IZMENE, error);
        });
    },
    onDelete: () => {
      onDeleteUstanova(ustanovaChange)
        .then(() => {})
        .catch((error: any) => {
          showError(Labels.TITLE_MESSAGES_GRESKA_PRILIKOM_BRISANJA, error);
        });
    },
  }));

  const showError = (summary: string, detail: string) => {
    if (messages) {
      messages.replace({
        severity: "error",
        summary: summary,
        detail: detail,
        closable: true,
        sticky: true,
      });
    }
  };

  return (
    <div className="panel-institution">
      <div className="p-col-12">
        <Messages
          ref={(el: any) => {
            setMessages(el);
          }}
        />
      </div>
      <TabView
        renderActiveOnly={false}
        activeIndex={index}
        onTabChange={(e) => {
          setIndex(e.index);
        }}
      >
        <TabPanel header={Labels.TAB_USTANOVA}>
          <div className="col-8 xl:col-8 lg:col-8 md:col-6 sm:col-12">
            <div className="grid align-items-center">
              <div className="col-4 mb-3">{Labels.LABEL_NAZIV}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(ustanovaOperation)}
                  value={ustanovaChange && ustanovaChange.naziv ? ustanovaChange.naziv : ""}
                  onChange={(e: any) => {
                    setUstanovaChange({
                      ...ustanovaChange,
                      naziv: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_SIFRA}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(ustanovaOperation)}
                  value={ustanovaChange && ustanovaChange.sifra ? ustanovaChange.sifra : ""}
                  onChange={(e: any) => {
                    setUstanovaChange({
                      ...ustanovaChange,
                      sifra: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_ADRESA}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(ustanovaOperation)}
                  value={ustanovaChange?.adresa ?? ""}
                  onChange={(e: any) => {
                    setUstanovaChange({
                      ...ustanovaChange,
                      adresa: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_TELEFON}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(ustanovaOperation)}
                  value={ustanovaChange && ustanovaChange.telefon ? ustanovaChange.telefon : ""}
                  onChange={(e: any) => {
                    setUstanovaChange({
                      ...ustanovaChange,
                      telefon: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_USERNAME}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(ustanovaOperation)}
                  value={ustanovaChange && ustanovaChange.username ? ustanovaChange.username : ""}
                  onChange={(e: any) => {
                    setUstanovaChange({
                      ...ustanovaChange,
                      username: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-3">{Labels.LABEL_PASSWORD}</div>
              <div className="col-8 p-fluid p-0">
                <InputText
                  disabled={isFormDisabled(ustanovaOperation)}
                  value={ustanovaChange && ustanovaChange.password ? ustanovaChange.password : ""}
                  onChange={(e: any) => {
                    setUstanovaChange({
                      ...ustanovaChange,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
