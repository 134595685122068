import { useRef } from "react";
import ExportDataLogical, { ExportDataLogicalType } from "./ExportDataLogical";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import React from "react";

interface ExportDataType {
  data: any;
  name: any;
  dt: any;
  disabled?: boolean;
}

export default function ExportData(props: ExportDataType) {
  const { exportPdf, exportXls, exportCsv, exportJson }: ExportDataLogicalType = ExportDataLogical();
  const { data, name, dt, disabled } = props;
  const menu = useRef<any>(null);
  const items = [
    {
      label: Labels.LABEL_PDF,
      icon: "pi pi-file-pdf",
      command: (e: any) => exportPdf(data, name),
    },
    {
      label: Labels.LABEL_CSV,
      icon: "pi pi-file-o",
      command: (e: any) => exportCsv(data, dt, name),
    },
    {
      label: Labels.LABEL_XLS,
      icon: "pi pi-file-excel",
      command: (e: any) => exportXls(data, name),
    },
    {
      label: Labels.LABEL_JSON,
      icon: "pi pi-copy",
      command: (e: any) => exportJson(data, name),
    },
  ];

  return (
    <div>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <Button
        label={Labels.LABEL_EXPORT}
        disabled={disabled}
        icon="pi pi-file"
        tooltip={Labels.DOWNLOAD_DATA_TABLE}
        tooltipOptions={{ position: "bottom" }}
        onClick={(event: any) => menu.current.toggle(event)}
        className="p-button-outlined p-button-danger"
        style={{ color: "#f26235" }}
        aria-controls="popup_menu"
        aria-haspopup
      />
    </div>
  );
}
