import React from "react";
import { DataTable } from "primereact/datatable";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";

interface DialogPoljaProps {
  polja: any;
  onCancel: Function;
}
export default function DialogPolja(props: DialogPoljaProps) {
  const { polja, onCancel } = props;

  return (
    <div>
      <DataTable
        filterDisplay="row"
        value={polja}
        paginator={true}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={Labels.PAGINATION_SHOWING + " {first} " + Labels.DASH + " {last} " + Labels.PAGINATION_OF + " {totalRecords} "}
        rows={20}
        rowsPerPageOptions={[5, 10, 20]}
        selectionMode="single"
        alwaysShowPaginator={false}
        emptyMessage={Labels.LABEL_NEMA_PODATAKA}
      >
        <Column className="column-align-left" field={"naziv"} header={Labels.COLUMN_HEADER_NAZIV_POLJA} filterMatchMode="contains" showFilterMenu={false} filter sortable />
        <Column className="column-align-left" field={"vrednost.stara"} header={Labels.COLUMN_HEADER_STARA_VREDNOST} filterMatchMode="contains" showFilterMenu={false} filter sortable />
        <Column className="column-align-left" field={"vrednost.nova"} header={Labels.COLUMN_HEADER_NOVA_VREDNOST} filterMatchMode="contains" showFilterMenu={false} filter sortable />
      </DataTable>
      <Toolbar>
        <div className="p-toolbar-group-right">
          <Button
            label={Labels.BUTTON_ODUSTANI}
            icon="pi pi-times"
            className="p-button-danger"
            onClick={() => {
              onCancel();
            }}
          />
        </div>
      </Toolbar>
    </div>
  );
}
